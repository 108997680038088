import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, FormGroup, Row } from 'reactstrap';
import { FieldArray, Formik, FormikHelpers } from 'formik';
import PrimaryButton from 'src/components/Form/PrimaryButton';
import { ApplicationSingleSecurityDepositDto } from 'src/types/api/applications';
import * as Yup from 'yup';
import SingleSecurityDeposit from 'src/pages/Application/Update/Tabs/Securities/SingleSecurityDeposit';
import { ApplicationRealEstateType } from 'src/helpers/Enums/Application/ApplicationRealEstateType';
import { success } from 'src/services/toastr';
import Api from 'src/api';
import { transformErrors } from 'src/helpers';
import SubmitTabButton from 'src/pages/Application/Update/Tabs/Components/SubmitTabButton';
import SaveButton from 'src/components/Form/SaveButton';
import { ApplicationTabProps } from 'src/pages/Application/Update/Tabs/ApplicationTabData';
import SimpleTable from 'src/components/SimpleTable';
import { Cell, Column } from 'react-table';
import { SecurityDepositActionType } from 'src/helpers/Enums/SecurityDepositActionType';
import {
  DebtCollectionContextState,
  withDebtCollection,
} from 'src/pages/DebtCollection/Update/DebtCollectionContext';
import { StoreStageSecurityDepositsRequestDto } from 'src/types/api/DebtCollection';
import SidebarDataRow from 'src/components/DataBlocks/SidebarDataRow';
import Loader from 'src/components/Loader';

interface Props extends ApplicationTabProps, DebtCollectionContextState {}

const initialDeposit: ApplicationSingleSecurityDepositDto = {
  real_estate_type: '',
  real_estate_unique: '',
  real_estate_plot: '',
  real_estate_address: '',
  real_estate_value: null,
  real_estate_appraiser: '',
  real_estate_appraised_at: null,
  action_type: null,
  action_performed_at: null,
};

const INITIAL_REQUEST: StoreStageSecurityDepositsRequestDto = {
  security_deposits: [initialDeposit],
};

export type DynamicObject<T> = { [key: string]: T };

const TabSecurities: React.FC<Props> = ({ debtCollection, setDebtCollection, showSubmit }) => {
  const { t } = useTranslation();

  const [request, setRequest] = useState<StoreStageSecurityDepositsRequestDto>(INITIAL_REQUEST);

  useEffect(() => {
    if (!debtCollection) return;
    setRequest({
      security_deposits: debtCollection.security_deposits?.security_deposits ?? [],
    });
  }, [debtCollection]);

  const onSubmit = useCallback(
    async (request: any, helpers: FormikHelpers<StoreStageSecurityDepositsRequestDto>) => {
      if (!debtCollection) return;

      try {
        const response = await Api.debtCollection.storeSecurityDeposits(debtCollection.id, request);
        setDebtCollection(response);
        success(t('common.updated_success'));
      } catch (e: any) {
        helpers.setErrors(transformErrors(e.response?.errors));
      }

      return true;
    },
    [debtCollection, setDebtCollection, t],
  );

  const Schema = Yup.object().shape({
    security_deposits: Yup.array().of(
      Yup.object().shape({
        real_estate_type: Yup.string()
          .oneOf(Object.values(ApplicationRealEstateType).map((value) => value))
          .required(),
        real_estate_unique: Yup.string().required(),
        real_estate_plot: Yup.string().required(),
        real_estate_address: Yup.string().required(),
        real_estate_value: Yup.number().nullable(),
        real_estate_appraiser: Yup.string().nullable(),
        real_estate_appraised_at: Yup.string().nullable(),
      }),
    ),
  });

  const columns = useMemo<Column<ApplicationSingleSecurityDepositDto>[] | any>(
    () => [
      {
        Header: t('label.real_estate_type'),
        accessor: 'real_estate_type',
        Cell: (cell: Cell<ApplicationSingleSecurityDepositDto>) => (
          <div>{t('applications.real_estate_type.' + cell.value)}</div>
        ),
        disableSortBy: true,
      },
      {
        Header: t('label.real_estate_unique'),
        accessor: 'real_estate_unique',
        disableSortBy: true,
      },
      {
        Header: t('label.real_estate_plot'),
        accessor: 'real_estate_plot',
        disableSortBy: true,
      },
      {
        Header: t('label.real_estate_address'),
        accessor: 'real_estate_address',
        disableSortBy: true,
      },
      {
        Header: t('label.real_estate_value'),
        accessor: 'real_estate_value',
        disableSortBy: true,
      },
      {
        Header: t('label.real_estate_appraiser'),
        accessor: 'real_estate_appraiser',
        disableSortBy: true,
      },
      {
        Header: t('label.real_estate_appraised_at'),
        accessor: 'real_estate_appraised_at',
        disableSortBy: true,
      },
      {
        Header: t('label.action_type'),
        accessor: 'action_type',
        Cell: (cell: Cell<ApplicationSingleSecurityDepositDto>) => (
          <div>{t('applications.stage_action_types.' + cell.value)}</div>
        ),
        disableSortBy: true,
      },
      {
        Header: t('label.action_performed_at'),
        accessor: 'action_performed_at',
        disableSortBy: true,
      },
      {
        Header: t('label.action_model_key'),
        accessor: 'action_model_key',
        disableSortBy: true,
      },
    ],
    [t],
  );

  if (!debtCollection) {
    return <Loader />;
  }

  return (
    <React.Fragment>
      <div className={'d-flex justify-content-between'}>
        <div>
          <h3>{t('applications.securities')}</h3>
        </div>
        {showSubmit && (
          <div>
            <SubmitTabButton
              title={t('applications.tabs_submit.securities')}
              tabName={'securities'}
            />
          </div>
        )}
      </div>
      <hr />
      <Formik
        initialValues={request}
        enableReinitialize={true}
        validateOnChange={false}
        validationSchema={Schema}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, isSubmitting, values }) => (
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Row>
                <SidebarDataRow
                  label={t('label.surety_exists')}
                  value={
                    debtCollection.security_deposits.info.surety_exists
                      ? t('common.yes')
                      : t('common.no')
                  }
                />
                <SidebarDataRow
                  label={t('label.surety_name')}
                  value={debtCollection.security_deposits.info.surety_name}
                />
                <SidebarDataRow
                  label={t('label.surety_code')}
                  value={debtCollection.security_deposits.info.surety_code}
                />
                <SidebarDataRow
                  label={t('label.security_deposits_includes_vat')}
                  value={
                    debtCollection.security_deposits.info.security_deposits_includes_vat
                      ? t('common.yes')
                      : t('common.no')
                  }
                />

                <SidebarDataRow
                  label={t('label.security_measures')}
                  value={t(
                    'projects.security_measure.' +
                      debtCollection.security_deposits.info.security_measures,
                  )}
                />
                <SidebarDataRow
                  label={t('label.hypothec_number')}
                  value={debtCollection.security_deposits.info.hypothec_number}
                />
                <SidebarDataRow
                  label={t('label.security_deposit_appraisers')}
                  value={debtCollection.security_deposits.info.security_deposit_appraisers}
                />
                <SidebarDataRow
                  label={t('label.security_deposit_value')}
                  value={debtCollection.security_deposits.info.security_deposit_value}
                />
              </Row>
              <hr />
              <FieldArray name="security_deposits">
                {(formikArrayHelpers) => (
                  <>
                    <table className={'table table-sm small'}>
                      <thead>
                        <tr>
                          <th className={'col-2'}>{t('label.real_estate_type')}</th>
                          <th>{t('label.real_estate_unique')}</th>
                          <th>{t('label.real_estate_plot')}</th>
                          <th>{t('label.real_estate_address')}</th>
                          <th>{t('label.real_estate_value')}</th>
                          <th>{t('label.real_estate_appraiser')}</th>
                          <th>{t('label.real_estate_appraised_at')}</th>
                        </tr>
                      </thead>

                      {values.security_deposits.map((deposit, index) => {
                        return (
                          <SingleSecurityDeposit
                            formikArrayHelpers={formikArrayHelpers}
                            index={index}
                            key={index}
                            deposit={deposit}
                            deposits={values.security_deposits}
                          />
                        );
                      })}
                    </table>
                    {!values.security_deposits.find(
                      (deposit) => deposit.action_type === SecurityDepositActionType.SPLIT,
                    ) && (
                      <PrimaryButton
                        title={t('common.add')}
                        type={'button'}
                        className={'btn btn-secondary w-100'}
                        submitting={isSubmitting}
                        onClick={() => formikArrayHelpers.push(initialDeposit)}
                      />
                    )}
                  </>
                )}
              </FieldArray>
              <div className={'mt-4 mb-4'}>
                <SaveButton title={t('common.save')} submitting={isSubmitting} />
              </div>
              <hr />
              {debtCollection?.security_deposits?.history_security_deposits &&
                debtCollection?.security_deposits?.history_security_deposits?.length > 0 && (
                  <>
                    <h3>{t('applications.history_securities')}</h3>
                    <SimpleTable
                      columns={columns}
                      data={debtCollection?.security_deposits?.history_security_deposits ?? []}
                    />
                  </>
                )}
            </FormGroup>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default withDebtCollection(TabSecurities);
