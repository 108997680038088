import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, FormikHelpers } from 'formik';
import { Col, ColProps, Form, FormGroup, Row } from 'reactstrap';
import TextInput from 'src/components/Form/TextInput';
import Api from 'src/api';
import { success } from 'src/services/toastr';
import { transformErrors } from 'src/helpers';
import SaveButton from 'src/components/Form/SaveButton';
import {
  ProspectApplicationContextState,
  withProspectApplication,
} from '../ProspectApplicationContext';
import {
  ProspectApplicationRiskResponseDto,
  UpdateProspectApplicationRequestDto,
} from 'src/types/api/prospectApplications';
import SimpleTable from 'src/components/SimpleTable';
import { Cell, Column } from 'react-table';
import { AttachFilesRequestDto, DocumentResponseDto } from 'src/types/api/common';
import IsOutdatedBadge from 'src/components/Badges/IsOutdatedBadge';
import DownloadButton from 'src/components/DownloadButton';
import DatePickerInput from 'src/components/Form/DatePickerInput';
import SelectInterestFrequencyInput from 'src/components/Form/Select/SelectInterestFrequencyInput';
import SelectCreditDurationInput from 'src/components/Form/Select/SelectCreditDurationInput';
import TextAreaInput from 'src/components/Form/TextAreaInput';
import SelectYesNo from 'src/components/Form/Select/SelectYesNoInput';
import SelectInput, { ReactSelectOption } from 'src/components/Form/Select/SelectInput';
import { ApplicationType } from 'src/helpers/Enums/Application/ApplicationType';

const INITIAL_REQUEST: UpdateProspectApplicationRequestDto = {
  credit_purpose: null,
  country: null,
  project_description: null,
  required_amount: null,
  minimal_amount: null,
  maximum_amount: null,
  own_funds_amount: null,
  own_funds_origin: null,
  interest_frequency: null,
  credit_duration: null,
  source_of_funds: null,
  complete_funding_until: null,
  surety_exists: null,
  surety_name: null,
  surety_code: null,
};

const TabApplication: React.FC<ProspectApplicationContextState> = ({
  prospectApplication,
  setProspectApplication,
  isEditable,
}) => {
  const { t } = useTranslation();
  const [request, setRequest] = useState<UpdateProspectApplicationRequestDto>(INITIAL_REQUEST);
  const [risks, setRisks] = useState<ProspectApplicationRiskResponseDto[]>();
  const [applicationTypes, setApplicationTypes] = useState<ReactSelectOption[] | undefined>(
    undefined,
  );

  useEffect(() => {
    const applicationTypes: ReactSelectOption[] = [];

    Object.values(ApplicationType).map((applicationType) => {
      applicationTypes.push({
        value: applicationType,
        label: t('applications.type.' + applicationType),
      });

      setApplicationTypes(applicationTypes);
    });
  }, [t]);

  useEffect(() => {
    if (!prospectApplication || !prospectApplication?.prospect_user_company) return;

    Api.ProspectApplications.fetchProspectApplicationRisks(prospectApplication.id).then(
      (response) => {
        setRisks(response);
      },
    );

    setRequest(() => ({
      credit_purpose: prospectApplication.credit_purpose,
      country: prospectApplication.country,
      project_description: prospectApplication.project_description,
      required_amount: prospectApplication.required_amount,
      minimal_amount: prospectApplication.minimal_amount,
      maximum_amount: prospectApplication.maximum_amount,
      own_funds_amount: prospectApplication.own_funds_amount,
      own_funds_origin: prospectApplication.own_funds_origin,
      interest_frequency: prospectApplication.interest_frequency,
      credit_duration: prospectApplication.credit_duration,
      source_of_funds: prospectApplication.source_of_funds,
      complete_funding_until: prospectApplication.complete_funding_until,
      surety_exists: prospectApplication.surety_exists,
      surety_name: prospectApplication.surety_name,
      surety_code: prospectApplication.surety_code,
    }));
  }, [prospectApplication]);

  const onSubmit = useCallback(
    async (
      request: UpdateProspectApplicationRequestDto,
      helpers: FormikHelpers<UpdateProspectApplicationRequestDto>,
    ) => {
      if (!prospectApplication || !prospectApplication.prospect_user_company) return;

      try {
        const response = await Api.ProspectApplications.updateProspectApplication(
          prospectApplication.id,
          request,
        );

        setProspectApplication(response);
        success(t('common.updated_success'));
      } catch (e: any) {
        helpers.setErrors(transformErrors(e.response?.errors));
      }
    },
    [prospectApplication, t, setProspectApplication],
  );

  const colPropsThreePerRow: ColProps = {
    md: 4,
    className: 'mb-4',
  };

  const colPropsOnePerRow: ColProps = {
    md: 12,
    className: 'mb-4',
  };

  const documentColumns = useMemo<Column<AttachFilesRequestDto>[] | any>(
    () => [
      {
        Header: t('table.id'),
        accessor: 'id',
      },
      {
        Header: t('table.file_name'),
        accessor: 'name',
      },
      {
        Header: t('table.mime_type'),
        accessor: 'mime_type',
      },
      {
        Header: t('table.size'),
        accessor: 'size',
      },
      {
        Header: t('table.information'),
        width: 100,
        Cell: (cell: Cell<DocumentResponseDto>) => (
          <div className={'d-flex justify-content-center gap-2 text-center'}>
            <IsOutdatedBadge show={!!cell.row.original.is_outdated} />
          </div>
        ),
      },
      {
        Header: t('table.uploader'),
        accessor: 'uploader',
      },
      {
        Header: t('table.created_at'),
        accessor: 'created_at',
        Cell: (cell: Cell<AttachFilesRequestDto>) => (
          <div>{t('common.date_full', { date: cell.value })}</div>
        ),
      },
      {
        Header: t('table.action'),
        accessor: 'url',
        Cell: (cell: Cell<DocumentResponseDto>) => {
          if (!prospectApplication) return null;
          return (
            <div className={'d-flex gap-2'}>
              <DownloadButton url={cell.value} />
            </div>
          );
        },
      },
    ],
    [prospectApplication, t],
  );

  return (
    <React.Fragment>
      <h3 className={'flex'}>{t('prospect_applications.tabs.application')}</h3>
      <hr />

      <Formik initialValues={request} enableReinitialize={true} onSubmit={onSubmit}>
        {({ handleSubmit, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Row>
                <Col {...colPropsThreePerRow}>
                  <SelectInput
                    isMulti={true}
                    placeholder={t('label.application_type')}
                    name={'credit_purpose'}
                    options={applicationTypes}
                    isClearable={true}
                  />
                </Col>
                <Col {...colPropsThreePerRow}>
                  <TextInput type={'country'} name={'country'} disabled={!isEditable} />
                </Col>
                <Col {...colPropsThreePerRow}>
                  <TextInput
                    type={'project_description'}
                    name={'project_description'}
                    disabled={!isEditable}
                  />
                </Col>
                <Col {...colPropsThreePerRow}>
                  <TextInput
                    type={'required_amount'}
                    name={'required_amount'}
                    disabled={!isEditable}
                  />
                </Col>
                <Col {...colPropsThreePerRow}>
                  <TextInput
                    type={'minimal_amount'}
                    name={'minimal_amount'}
                    disabled={!isEditable}
                  />
                </Col>
                <Col {...colPropsThreePerRow}>
                  <TextInput
                    type={'maximum_amount'}
                    name={'maximum_amount'}
                    disabled={!isEditable}
                  />
                </Col>
                <Col {...colPropsThreePerRow}>
                  <TextInput
                    type={'own_funds_amount'}
                    name={'own_funds_amount'}
                    disabled={!isEditable}
                  />
                </Col>
                <Col {...colPropsThreePerRow}>
                  <TextInput
                    type={'own_funds_origin'}
                    name={'own_funds_origin'}
                    disabled={!isEditable}
                  />
                </Col>
                <Col {...colPropsThreePerRow}>
                  <SelectInterestFrequencyInput
                    isDisabled={!isEditable}
                    name={'interest_frequency'}
                    placeholder={t('label.interest_frequency')}
                  />
                </Col>
                <Col {...colPropsThreePerRow}>
                  <SelectCreditDurationInput
                    isDisabled={!isEditable}
                    placeholder={t('label.credit_duration')}
                    name={'credit_duration'}
                  />
                </Col>
                <Col {...colPropsThreePerRow}>
                  <DatePickerInput
                    name={'complete_funding_until'}
                    disabled={!isEditable}
                    matchFieldValueAndDisplayValue={true}
                  />
                </Col>
                <hr />
                <Col {...colPropsThreePerRow}>
                  <SelectYesNo name={'surety_exists'} isDisabled={!isEditable} />
                </Col>
                <Col {...colPropsThreePerRow}>
                  <TextInput type={'surety_name'} name={'surety_name'} disabled={!isEditable} />
                </Col>
                <Col {...colPropsThreePerRow}>
                  <TextInput type={'surety_code'} name={'surety_code'} disabled={!isEditable} />
                </Col>
                <hr />
                <Col {...colPropsOnePerRow}>
                  <TextAreaInput name={'source_of_funds'} disabled={!isEditable} />
                </Col>
              </Row>
              <Row>
                <div className="mt-4 mb-4">
                  <SaveButton
                    title={t('common.save')}
                    submitting={isSubmitting}
                    disabled={!isEditable}
                  />
                </div>
              </Row>
            </FormGroup>
          </Form>
        )}
      </Formik>
      <hr />
      <Row>
        <h3>{t('applications.risks')}</h3>
        {risks?.map((risk: ProspectApplicationRiskResponseDto) => (
          <div className="d-flex" key={risk.id}>
            <label className="form-check-label form-label" htmlFor={risk.id}>
              <input
                type="checkbox"
                className="form-check-input"
                name="risks"
                id={risk.id}
                checked={risk.selected}
              />
            </label>
            <p className="ms-4">
              <strong>{t(risk.name ?? '')}</strong>
              {t(risk.description ?? '')}
            </p>
          </div>
        ))}
      </Row>
      <Row>
        <h3>{t('applications.documents.list')}</h3>
        <SimpleTable columns={documentColumns} data={prospectApplication?.documents ?? []} />
      </Row>
    </React.Fragment>
  );
};

export default withProspectApplication(TabApplication);
