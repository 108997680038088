import Http from './http';
import { Authentication } from './services';
import CurrentUser from './services/currentUser';
import Roles from './services/roles';
import Users from 'src/api/services/users';
import UserCompanies from 'src/api/services/userCompanies';
import Application from 'src/api/services/applications';
import Translation from 'src/api/services/translations';
import S3 from 'src/api/services/common/s3';
import Media from 'src/api/services/common/media';
import Information from 'src/api/services/information';
import DocumentTemplates from 'src/api/services/documentTemplates';
import Project from 'src/api/services/project';
import BonusSystem from 'src/api/services/bonusSystem';
import AutoInvestment from 'src/api/services/autoInvestment';
import ProjectPayouts from 'src/api/services/payments/projectPayouts';
import P2POperation from 'src/api/services/operations';
import Client from 'src/api/services/client';
import SharedUsers from 'src/api/services/sharedUsers';
import Communication from 'src/api/services/communication';
import Countries from 'src/api/services/common/countries';
import BorrowerPayouts from 'src/api/services/payments/borrowerPayouts';
import TeamMembers from 'src/api/services/teamMembers';
import JobPositions from 'src/api/services/jobPosition';
import News from 'src/api/services/news';
import ExternalNews from 'src/api/services/externalNews';
import Reports from 'src/api/services/reports';
import Inquiries from 'src/api/services/inquiries';
import Faq from 'src/api/services/faq';
import Seo from './services/seo';
import LandingPageTranslations from 'src/api/services/landingPageTranslations';
import Invoices from 'src/api/services/payments/invoices';
import Dashboard from 'src/api/services/dashboard';
import ApplicationSecurityDeposit from 'src/api/services/applicationSecurityDeposit';
import ManualWithdrawals from 'src/api/services/payments/manualWithdrawals';
import AmlManagement from 'src/api/services/amlManagement';
import Import from 'src/api/services/import';
import FailedInvestmentPayouts from './services/payments/failedInvestmentPayouts';
import Estimates from './services/payments/estimates';
import Notifications from './services/notifications';
import ProspectApplications from './services/prospectApplications';
import FrozenDeveloperPayouts from 'src/api/services/payments/frozenDeveloperPayouts';
import DebtCollection from 'src/api/services/debtCollection';
import Forecasts from 'src/api/services/forecast';
import Rc from 'src/api/services/rc';

export const http = new Http();
export { http as Http };

const Api = {
  client: new Client(http),
  applicationSecurityDeposit: new ApplicationSecurityDeposit(http),
  authentication: new Authentication(http),
  currentUser: new CurrentUser(http),
  user: new Users(http),
  sharedUsers: new SharedUsers(http),
  roles: new Roles(http),
  documentTemplates: new DocumentTemplates(http),
  reports: new Reports(http),
  rc: new Rc(http),
  userCompanies: new UserCompanies(http),
  applications: new Application(http),
  translations: new Translation(http),
  information: new Information(http),
  s3: new S3(),
  media: new Media(http),
  projects: new Project(http),
  bonusSystem: new BonusSystem(http),
  autoInvestment: new AutoInvestment(http),
  import: new Import(http),
  payments: {
    projectPayouts: new ProjectPayouts(http),
    borrowerPayouts: new BorrowerPayouts(http),
    estimates: new Estimates(http),
    invoices: new Invoices(http),
    manualWithdrawals: new ManualWithdrawals(http),
    failedInvestmentPayouts: new FailedInvestmentPayouts(http),
    frozenDeveloperPayouts: new FrozenDeveloperPayouts(http),
  },
  operations: new P2POperation(http),
  communication: new Communication(http),
  countries: new Countries(http),
  teamMembers: new TeamMembers(http),
  jobPositions: new JobPositions(http),
  news: new News(http),
  externalNews: new ExternalNews(http),
  inquiries: new Inquiries(http),
  faq: new Faq(http),
  seo: new Seo(http),
  landingPageTranslations: new LandingPageTranslations(http),
  dashboard: new Dashboard(http),
  amlManagement: new AmlManagement(http),
  notifications: new Notifications(http),
  ProspectApplications: new ProspectApplications(http),
  debtCollection: new DebtCollection(http),
  forecasts: new Forecasts(http),
};

export default Api;
