import React from 'react';
import {
  Column,
  useExpanded,
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import { Table as BoostrapTable } from 'reactstrap';
import SortOrder from './Table/SortOrder';
import { useTranslation } from 'react-i18next';

interface Props {
  columns: Column<any>[];
  data: any | undefined;
  sortable?: boolean;
  isLoading?: boolean;
}

const SimpleTable: React.FC<Props> = ({ columns, data, isLoading, sortable = false }) => {
  const { t } = useTranslation();

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows, visibleColumns } =
    useTable(
      {
        columns,
        data: data ?? [],
        manualPagination: true,
        manualFilters: true,
        manualSortBy: true,
        manualGlobalFilter: true,
        defaultCanSort: sortable,
        autoResetPage: false,
        autoResetResize: false,
        autoResetExpanded: false,
      },
      useFilters,
      useGlobalFilter,
      useSortBy,
      useExpanded,
      usePagination,
    );

  return (
    <>
      <BoostrapTable responsive striped bordered hover {...getTableProps()}>
        <thead className={'table-secondary'}>
          {headerGroups.map((headerGroup, i) => {
            return (
              <tr {...headerGroup.getHeaderGroupProps()} key={i}>
                {headerGroup.headers.map((column, i) => (
                  <th
                    {...column.getHeaderProps(sortable ? column.getSortByToggleProps() : undefined)}
                    key={i}
                  >
                    <div className={'d-flex'}>
                      <span className={'me-auto'}>{column.render('Header')}</span>
                      <span>
                        {sortable && column.isSorted && (
                          <SortOrder type={column.sortType} isDesc={column.isSortedDesc} />
                        )}
                      </span>
                    </div>
                    <div>{column.Filter ? column.render('Filter') : null}</div>
                  </th>
                ))}
              </tr>
            );
          })}
        </thead>
        <tbody {...getTableBodyProps()}>
          {isLoading &&
            [...Array(10).keys()].map((i) => (
              <tr key={i}>
                {[...Array(columns.length).keys()].map((j) => (
                  <td className={'text-center'} key={j}>
                    <div className={'placeholder-glow'}>
                      <span className={'placeholder w-100'} />
                    </div>
                  </td>
                ))}
              </tr>
            ))}
          {!isLoading && rows.length === 0 && (
            <>
              <tr>
                <td className={'text-center'} colSpan={visibleColumns.length}>
                  {t('table.no_data')}
                </td>
              </tr>
            </>
          )}
          {!isLoading &&
            rows.length !== 0 &&
            rows.map((row, i) => {
              prepareRow(row);
              return (
                <React.Fragment key={i}>
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell, i) => {
                      return (
                        <td {...cell.getCellProps()} key={i}>
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                </React.Fragment>
              );
            })}
        </tbody>
      </BoostrapTable>
    </>
  );
};

export default SimpleTable;
