import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { ApplicationResponseDto } from 'src/types/api/applications';
import { setGlobalLoading } from 'src/modules/app/actions';
import Api from 'src/api';
import ApplicationTimeline from 'src/pages/Application/Update/ApplicationTimeline';
import { Permissions } from 'src/helpers/auth/permissions';
import TabCompany from 'src/pages/Application/Update/Tabs/TabCompany';
import TabCredit from 'src/pages/Application/Update/Tabs/TabCredit';
import TabCreditDetalisation from 'src/pages/Application/Update/Tabs/TabCreditDetails';
import TabFinancialInfo from 'src/pages/Application/Update/Tabs/TabFinancialInfo';
import TabSecurities from 'src/pages/Application/Update/Tabs/TabSecurities';
import TabDocuments from 'src/pages/Application/Update/Tabs/TabDocuments';
import TabPictures from 'src/pages/Application/Update/Tabs/TabPictures';
import TabActionHistory from 'src/pages/Application/Update/Tabs/TabActionHistory';
import TabRelatedProjects from 'src/pages/Application/Update/Tabs/TabRelatedProjects';
import ApplicationProvider from 'src/pages/Application/Update/ApplicationContext';
import { ApplicationDropdown } from 'src/pages/Application/Update/ApplicationDropdown';
import TabProtocol from 'src/pages/Application/Update/Tabs/TabProtocol';
import { ApplicationStatus } from 'src/types/app/applications';
import TabRisks from 'src/pages/Application/Update/Tabs/TabRisks';
import ApplicationTabData, {
  ApplicationTabProps,
  TabInfo,
} from 'src/pages/Application/Update/Tabs/ApplicationTabData';
import {
  mdiArchiveClock,
  mdiBookOutline,
  mdiCash,
  mdiCashCheck,
  mdiClipboardAlert,
  mdiFileDocumentOutline,
  mdiFileImageOutline,
  mdiFinance,
  mdiHistory,
  mdiSecurity,
  mdiStamper,
  mdiTransitConnectionVariant,
} from '@mdi/js';
import ApplicationStatusActions from './ApplicationStatusActions';
import TabStatusHistory from 'src/pages/Application/Update/Tabs/TabStatusHistory';
import ApplicationStatusBadge from './Tabs/Components/ApplicationStatusBadge';

const Tabs: TabInfo<ApplicationTabProps>[] = [
  {
    name: 'applications.company',
    icon: mdiBookOutline,
    component: TabCompany,
    permission: Permissions.BO__APPLICATIONS__VIEW_COMPANY,
  },
  {
    name: 'applications.credit',
    icon: mdiCash,
    component: TabCredit,
    permission: Permissions.BO__APPLICATIONS__VIEW_CREDIT,
  },
  {
    name: 'applications.credit_details',
    icon: mdiCashCheck,
    component: TabCreditDetalisation,
    permission: Permissions.BO__APPLICATIONS__VIEW_CREDIT_DETAILS,
  },
  {
    name: 'applications.financial_info',
    icon: mdiFinance,
    component: TabFinancialInfo,
    permission: Permissions.BO__APPLICATIONS__VIEW_FINANCIAL_INFO,
  },
  {
    name: 'applications.securities',
    icon: mdiSecurity,
    component: TabSecurities,
    permission: Permissions.BO__APPLICATIONS__VIEW_SECURITIES,
  },
  {
    name: 'applications.protocol',
    icon: mdiStamper,
    component: TabProtocol,
    permission: Permissions.BO__APPLICATIONS__VIEW_PROTOCOL,
  },
  {
    name: 'applications.risks',
    icon: mdiClipboardAlert,
    component: TabRisks,
    permission: Permissions.BO__APPLICATIONS__VIEW_RISKS,
  },
  {
    name: 'applications.documents',
    icon: mdiFileDocumentOutline,
    component: TabDocuments,
    permission: Permissions.BO__APPLICATIONS__VIEW_DOCUMENTS,
  },
  {
    name: 'applications.pictures',
    icon: mdiFileImageOutline,
    component: TabPictures,
    permission: Permissions.BO__APPLICATIONS__VIEW_PICTURES,
  },
  {
    name: 'applications.status_history',
    icon: mdiArchiveClock,
    component: TabStatusHistory,
    permission: Permissions.BO__APPLICATIONS__VIEW_STATUS_HISTORY,
  },
  {
    name: 'applications.action_history',
    icon: mdiHistory,
    component: TabActionHistory,
    permission: Permissions.BO__APPLICATIONS__VIEW_ACTION_HISTORY,
  },
  {
    name: 'applications.related_projects',
    icon: mdiTransitConnectionVariant,
    component: TabRelatedProjects,
    permission: Permissions.BO__APPLICATIONS__VIEW_RELATED_PROJECTS,
  },
];

const APPLICATION_STAGE = [
  ApplicationStatus.Pending.toString(),
  ApplicationStatus.Submitted.toString(),
  ApplicationStatus.Rejected.toString(),
];

const ANALYSIS_STAGE = [ApplicationStatus.Analyzing.toString()];

const COMMITTEE_STAGE = [
  ApplicationStatus.CommitteeReview.toString(),
  ApplicationStatus.ChiefCommitteeMemberReview.toString(),
];

const OFFER_STAGE = [
  ApplicationStatus.OfferAccepted.toString(),
  ApplicationStatus.Confirmed.toString(),
];

//TODO add protocol tabs on permissions, do not show multiple tabs!!
const Application: React.FC = () => {
  const { t } = useTranslation();
  const { applicationId } = useParams<{ applicationId: string }>();
  const [application, setApplication] = useState<ApplicationResponseDto>();
  const tabRef = React.useRef(null);

  useEffect(() => {
    setGlobalLoading(true);
    if (applicationId) {
      Api.applications.fetchApplication(applicationId).then((response) => {
        setApplication(response);
        setGlobalLoading(false);
      });
    }
  }, [applicationId]);

  if (!application) {
    return <></>;
  }

  const openProtocolTab = () => {
    if (tabRef && tabRef.current) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      tabRef.current();
    }
  };

  return (
    <React.Fragment>
      <ApplicationProvider application={application} setApplication={setApplication}>
        <Container fluid>
          <Row>
            <Col xs="12" className={'mb-4'}>
              <Card className="card h-100 bg-blue-400 text-light">
                <CardBody>
                  <div className={'float-end ms-5'}>
                    <ApplicationDropdown />
                  </div>
                  <Row>
                    <Col>
                      <div>{t('table.id')}</div>
                      <div>{application.pid}</div>
                    </Col>
                    <Col>
                      <div>{t('applications.name')}</div>
                      <div>{application.project_name}</div>
                    </Col>
                    {application.owner && (
                      <Col>
                        <div>{t('applications.company_name')}</div>
                        <div>{application.owner.name}</div>
                      </Col>
                    )}
                    {application.manager && (
                      <Col>
                        <div>{t('applications.manager_name')}</div>
                        <div>{application.manager.name}</div>
                      </Col>
                    )}
                    {application.analytic && (
                      <Col>
                        <div>{t('applications.analytic_name')}</div>
                        <div>{application.analytic.name}</div>
                      </Col>
                    )}
                    <Col>
                      <div>{t('applications.status')}</div>
                      <div>
                        <h5>
                          <ApplicationStatusBadge />
                        </h5>
                      </div>
                    </Col>
                    <Col>
                      <ApplicationStatusActions openProtocolTab={openProtocolTab} />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col xs="12">
              <Card className="mb-4 bg-blue-400 text-light">
                <CardBody>
                  <div className="hori-timeline">
                    <div className="events">
                      <Row>
                        <div className="col">
                          <div className="timeline-steps">
                            <ApplicationTimeline
                              stage={t('applications.stages.application')}
                              active={APPLICATION_STAGE.includes(application?.status)}
                              index={'1.'}
                            />
                            <ApplicationTimeline
                              stage={t('applications.stages.analysis')}
                              active={ANALYSIS_STAGE.includes(application?.status)}
                              index={'2.'}
                            />
                            <ApplicationTimeline
                              stage={t('applications.stages.committee')}
                              active={COMMITTEE_STAGE.includes(application?.status)}
                              index={'3.'}
                            />
                            <ApplicationTimeline
                              stage={t('applications.stages.offer')}
                              active={OFFER_STAGE.includes(application?.status)}
                              index={'4.'}
                            />
                          </div>
                        </div>
                      </Row>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xs="12">
              <Card className="mb-4">
                <CardBody>
                  <ApplicationTabData tabs={Tabs} vertical={true} tabRef={tabRef} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </ApplicationProvider>
    </React.Fragment>
  );
};

export default Application;
