import { Formik, FormikHelpers } from 'formik';
import React, { useCallback, useState } from 'react';
import {
  UpdateUserCompanyRequestDto,
  UserCompanyAddressResponseDto,
  UserCompanyResponseDto,
} from 'src/types/api/userCompanies';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Col, Form, FormGroup, Row } from 'reactstrap';
import TextInput from 'src/components/Form/TextInput';
import DatePickerInput from 'src/components/Form/DatePickerInput';
import CheckboxInput from 'src/components/Form/CheckboxInput';
import SelectCountryInput from '../../../../components/Form/Select/SelectCountryInput';
import SelectGovDocumentTypeInput from 'src/components/Form/Select/SelectGovDocumentTypeInput';
import SelectRepresentativeTitleInput from 'src/components/Form/Select/SelectRepresentativeTitleInput';
import { CompanyAddressTypeEnum } from 'src/helpers/Enums/CompanyAddressTypeEnum';
import { success } from 'src/services/toastr';
import PhoneNumberInput from 'src/components/Form/PhoneNumberInput';
import SaveButton from 'src/components/Form/SaveButton';
import SelectUserCompanySizeInput from 'src/components/Form/Select/SelectUserCompanySizeInput';
import { UserTypeEnum } from 'src/helpers/Enums/UserTypeEnum';
import SelectInvestorExperienceLevelTypeInput from 'src/components/Form/Select/SelectExperienceLevelInput';
import { InvestorExperienceLevelTypeEnum } from 'src/helpers/Enums/InvestorExperienceLevelTypeEnum';
import SelectManagementStructureInput from 'src/components/Form/Select/SelectManagementStructureInput';

interface Props {
  company: UserCompanyResponseDto;
  residentialAddress: UserCompanyAddressResponseDto | undefined;
  contactAddress: UserCompanyAddressResponseDto | undefined;
  onUpdate: (request: UpdateUserCompanyRequestDto) => Promise<UserCompanyResponseDto | void>;
}

interface IFormValues extends UpdateUserCompanyRequestDto {
  residential_address_street: string;
  residential_address_address: string;
  residential_address_city: string;
  contact_address_street: string;
  contact_address_address: string;
  contact_address_city: string;
}

const CompanyEdit: React.FC<Props> = ({
  company,
  residentialAddress,
  contactAddress,
  onUpdate,
}) => {
  const { t } = useTranslation();

  const [initialFormValues] = useState<IFormValues>({
    name: company.name ?? '',
    legal_code: company.legal_code ?? '',
    vat_code: company.vat_code ?? '',
    email: company.email ?? '',
    phone: company.phone ?? '',
    activity: company.activity ?? '',
    country: company.country ?? '',
    representative_title: company.representative_title ?? '',
    is_representative_executive: company.is_representative_executive ?? false,
    established_at: company.established_at ?? '',
    size: company.size,
    bank_name: company.bank_name ?? '',
    iban: company.iban ?? '',
    management_structure: company.management_structure ?? '',
    executive_first_name: company.executive_first_name ?? '',
    executive_last_name: company.executive_last_name ?? '',
    executive_address_house_number: company.executive_address_house_number ?? '',
    executive_address_street: company.executive_address_street ?? '',
    executive_address_city: company.executive_address_city ?? '',
    executive_address_country: company.executive_address_country ?? '',
    executive_phone: company.executive_phone ?? '',
    executive_email: company.executive_email ?? '',
    executive_gov_code: company.executive_gov_code ?? '',
    executive_gov_document_type: company.executive_gov_document_type ?? '',
    executive_gov_document_number: company.executive_gov_document_number ?? '',
    executive_gov_document_issue_country: company.executive_gov_document_issue_country ?? '',
    executive_gov_document_expiration_date: company.executive_gov_document_expiration_date ?? '',
    executive_is_pep: company.executive_is_pep ?? false,
    executive_tax_country: company.executive_tax_country ?? '',
    executive_nationality: company.executive_nationality ?? '',
    contact_address_street: contactAddress?.street ?? '',
    contact_address_address: contactAddress?.address ?? '',
    contact_address_city: contactAddress?.city ?? '',
    residential_address_address: residentialAddress?.address ?? '',
    residential_address_city: residentialAddress?.city ?? '',
    residential_address_street: residentialAddress?.street ?? '',
    addresses: [],
    investor_properties: {
      is_groupable_investments: company.investor_properties.is_groupable_investments ?? false,
      is_high_net_worth: company.investor_properties.is_high_net_worth ?? false,
      investor_experience_level:
        company.investor_properties.investor_experience_level ??
        InvestorExperienceLevelTypeEnum.Novice,
    },
    proxy_details: {
      proxy_valid_until: company.proxy_details?.proxy_valid_until ?? null,
      proxy_number: company.proxy_details?.proxy_number ?? '',
    },
  });

  const isDeveloper = company.type == UserTypeEnum.PROJECT_DEVELOPER;
  const isInvestor = company.type == UserTypeEnum.INVESTOR;

  const formatCompanyUpdateRequest = useCallback(
    (request: IFormValues): UpdateUserCompanyRequestDto => {
      const residentialAddress = {
        street: request.residential_address_street,
        address: request.residential_address_address,
        city: request.residential_address_city,
        country: request.country,
        type: CompanyAddressTypeEnum.RESIDENTIAL,
      };

      const contactAddress = {
        street: request.contact_address_street,
        address: request.contact_address_address,
        city: request.contact_address_city,
        country: request.country,
        type: CompanyAddressTypeEnum.CONTACT,
      };

      return {
        name: request.name,
        legal_code: request.legal_code,
        vat_code: request.vat_code,
        email: request.email,
        phone: request.phone,
        activity: request.activity,
        country: request.country,
        representative_title: request.representative_title,
        is_representative_executive: request.is_representative_executive,
        bank_name: request.bank_name,
        iban: request.iban,
        management_structure: request.management_structure,
        established_at: request.established_at,
        size: isDeveloper ? request.size : null,
        executive_first_name: request.executive_first_name,
        executive_last_name: request.executive_last_name,
        executive_address_house_number: request.executive_address_house_number,
        executive_address_street: request.executive_address_street,
        executive_address_city: request.executive_address_city,
        executive_address_country: request.executive_address_country,
        executive_phone: request.executive_phone,
        executive_email: request.executive_email,
        executive_tax_country: request.executive_tax_country,
        executive_nationality: request.executive_nationality,
        executive_gov_code: request.executive_gov_code,
        executive_gov_document_type: request.executive_gov_document_type,
        executive_gov_document_number: request.executive_gov_document_number,
        executive_gov_document_issue_country: request.executive_gov_document_issue_country,
        executive_gov_document_expiration_date: request.executive_gov_document_expiration_date,
        executive_is_pep: request.executive_is_pep,
        addresses: [residentialAddress, contactAddress],
        investor_properties: {
          is_groupable_investments: request.investor_properties.is_groupable_investments,
          is_high_net_worth: request.investor_properties.is_high_net_worth,
          investor_experience_level: request.investor_properties.investor_experience_level,
        },
        proxy_details: {
          proxy_valid_until: request.proxy_details.proxy_valid_until,
          proxy_number: request.proxy_details.proxy_number,
        },
      };
    },
    [isDeveloper],
  );

  const onSubmit = useCallback(
    async (request: IFormValues, helpers: FormikHelpers<IFormValues>) => {
      try {
        const formattedRequest = formatCompanyUpdateRequest(request);
        await onUpdate(formattedRequest);
        success(t('common.updated_success'));
      } catch (e: any) {
        helpers.setErrors(e.response?.errors);
      }
    },
    [t, formatCompanyUpdateRequest, onUpdate],
  );

  const CompanyEditSchema = Yup.object().shape({
    name: Yup.string().companyName().required(),
    legal_code: Yup.string().companyLegalCode().required(),
    vat_code: Yup.string().companyVatCode(),
    email: Yup.string().email().required(),
    phone: Yup.string().phone().required(),
    activity: Yup.string().max(255).nullable(),
    representative_title: Yup.string().companyRepresentativeTitle().required(),
    is_representative_executive: Yup.boolean().required(),
    bank_name: Yup.string().required(),
    iban: Yup.string().required(),
    management_structure: Yup.string().required(),
    established_at: Yup.date().required(),
    size: isDeveloper ? Yup.string().required() : Yup.string().nullable(),
    executive_first_name: Yup.string().firstName().required(),
    executive_last_name: Yup.string().lastName().required(),
    executive_address_house_number: Yup.string().addressLine().required(),
    executive_address_street: Yup.string().addressLine().required(),
    executive_address_city: Yup.string().city().required(),
    executive_address_country: Yup.string().residence().required(),
    executive_phone: Yup.string().phone().required(),
    executive_email: Yup.string().email().required(),
    executive_tax_country: Yup.string().residence().required(),
    executive_nationality: Yup.string().residence().required(),
    executive_gov_code: Yup.string()
      .governmentCode('executive_gov_document_issue_country')
      .required(),
    executive_gov_document_type: Yup.string().required().governmentDocument().required(),
    executive_gov_document_number: Yup.string().min(1).max(30).required(),
    executive_gov_document_issue_country: Yup.string().residence().required(),
    executive_gov_document_expiration_date: Yup.date().required(),
    residential_address_street: Yup.string().addressLine().required(),
    residential_address_address: Yup.string().addressLine().required(),
    residential_address_city: Yup.string().city().required(),
    contact_address_street: Yup.string().addressLine().required(),
    contact_address_address: Yup.string().addressLine().required(),
    contact_address_city: Yup.string().city().required(),
    proxy_details: Yup.object().shape({
      proxy_valid_until: Yup.date().optional().nullable(),
      proxy_number: Yup.string().optional().nullable(),
    }),
  });

  return (
    <>
      <Formik
        initialValues={initialFormValues}
        enableReinitialize={true}
        validationSchema={CompanyEditSchema}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Row>
                <Col sm={3} className={'mb-4'}>
                  <TextInput name={'name'} placeholder={t('users.company.name')} />
                </Col>
                <Col sm={3} className={'mb-4'}>
                  <TextInput name={'legal_code'} placeholder={t('users.company.legal_code')} />
                </Col>
                <Col sm={3} className={'mb-4'}>
                  <TextInput name={'vat_code'} placeholder={t('users.company.vat_code')} />
                </Col>
                <Col sm={3} className={'mb-4'}>
                  <DatePickerInput
                    name={'established_at'}
                    placeholder={t('users.company.established_at')}
                  />
                </Col>
                <Col sm={3} className={'mb-4'}>
                  <TextInput name={'activity'} placeholder={t('users.company.activity')} />
                </Col>
                <Col sm={3} className={'mb-4'}>
                  <TextInput name={'email'} placeholder={t('users.email')} />
                </Col>
                <Col sm={3} className={'mb-4'}>
                  <PhoneNumberInput name={'phone'} placeholder={t('users.phone')} />
                </Col>
                <Col sm={3} className={'mb-4'}>
                  <SelectCountryInput name={'country'} placeholder={t('users.residence')} />
                </Col>
                <Col sm={3} className={'mb-4'}>
                  <TextInput
                    name={'residential_address_street'}
                    placeholder={t('users.residential.street')}
                  />
                </Col>
                <Col sm={3} className={'mb-4'}>
                  <TextInput
                    name={'residential_address_address'}
                    placeholder={t('users.residential.address_line')}
                  />
                </Col>
                <Col sm={3} className={'mb-4'}>
                  <TextInput
                    name={'residential_address_city'}
                    placeholder={t('users.residential.city')}
                  />
                </Col>
                <Col sm={3} className={'mb-4'}>
                  <SelectRepresentativeTitleInput
                    name={'representative_title'}
                    placeholder={t('users.company.representative_title')}
                  />
                </Col>
                <Col sm={3} className={'mb-4'}>
                  <TextInput
                    name={'contact_address_street'}
                    placeholder={t('users.contact.street')}
                  />
                </Col>
                <Col sm={3} className={'mb-4'}>
                  <TextInput
                    name={'contact_address_address'}
                    placeholder={t('users.contact.address_line')}
                  />
                </Col>
                <Col sm={3} className={'mb-4'}>
                  <TextInput name={'contact_address_city'} placeholder={t('users.contact.city')} />
                </Col>
                <Col sm={3} className={'mb-4'}>
                  <CheckboxInput
                    name={'is_representative_executive'}
                    showPlaceholder={true}
                    placeholder={t('users.executive.is_representative')}
                  />
                </Col>
                {isInvestor && (
                  <Col sm={3} className={'mb-4'}>
                    <SelectInvestorExperienceLevelTypeInput
                      name={'investor_properties.investor_experience_level'}
                      placeholder={t('users.investor_experience_level')}
                    />
                  </Col>
                )}
                <hr />
                <Col sm={3} className={'mb-4'}>
                  <TextInput
                    name={'executive_first_name'}
                    placeholder={t('users.executive.first_name')}
                  />
                </Col>
                <Col sm={3} className={'mb-4'}>
                  <TextInput
                    name={'executive_last_name'}
                    placeholder={t('users.executive.last_name')}
                  />
                </Col>
                <Col sm={3} className={'mb-4'}>
                  <TextInput
                    name={'executive_address_house_number'}
                    placeholder={t('users.house_number')}
                  />
                </Col>
                <Col sm={3} className={'mb-4'}>
                  <TextInput
                    name={'executive_address_street'}
                    placeholder={t('users.executive.address.street')}
                  />
                </Col>
                <Col sm={3} className={'mb-4'}>
                  <TextInput
                    name={'executive_address_city'}
                    placeholder={t('users.executive.address.city')}
                  />
                </Col>
                <Col sm={3} className={'mb-4'}>
                  <SelectCountryInput
                    name={'executive_address_country'}
                    placeholder={t('users.executive.address.country')}
                  />
                </Col>
                <Col sm={3} className={'mb-4'}>
                  <PhoneNumberInput
                    name={'executive_phone'}
                    placeholder={t('users.executive.phone')}
                  />
                </Col>
                <Col sm={3} className={'mb-4'}>
                  <TextInput name={'executive_email'} placeholder={t('users.executive.email')} />
                </Col>
                <Col sm={3} className={'mb-4'}>
                  <SelectCountryInput
                    name={'executive_tax_country'}
                    placeholder={t('users.executive.tax_country')}
                  />
                </Col>
                <Col sm={3} className={'mb-4'}>
                  <SelectCountryInput
                    name={'executive_nationality'}
                    placeholder={t('users.executive.nationality')}
                  />
                </Col>
                <Col sm={3} className={'mb-4'}>
                  <TextInput
                    name={'executive_gov_code'}
                    placeholder={t('users.executive.gov_code')}
                  />
                </Col>
                <Col sm={3} className={'mb-4'}>
                  <SelectGovDocumentTypeInput
                    name={'executive_gov_document_type'}
                    placeholder={t('users.gov_document.type')}
                  />
                </Col>
                <Col sm={3} className={'mb-4'}>
                  <TextInput
                    name={'executive_gov_document_number'}
                    placeholder={t('users.gov_document.number')}
                  />
                </Col>
                <Col sm={3} className={'mb-4'}>
                  <SelectCountryInput
                    name={'executive_gov_document_issue_country'}
                    placeholder={t('users.gov_document.issuer_country')}
                  />
                </Col>
                <Col sm={3} className={'mb-4'}>
                  <DatePickerInput
                    name={'executive_gov_document_expiration_date'}
                    placeholder={t('users.gov_document.expiration_date')}
                  />
                </Col>
                <Col sm={3} className={'mb-4'}>
                  <CheckboxInput
                    name={'executive_is_pep'}
                    placeholder={t('users.executive.is_pep')}
                  />
                </Col>
                {isDeveloper && (
                  <Col sm={3} className={'mb-4'}>
                    <SelectUserCompanySizeInput
                      name={'size'}
                      placeholder={t('users.company.size')}
                    />
                  </Col>
                )}
                <Col sm={3} className={'mb-4'}>
                  <TextInput name={'bank_name'} placeholder={t('users.company.bank_name')} />
                </Col>
                <Col sm={3} className={'mb-4'}>
                  <TextInput name={'iban'} placeholder={t('users.company.iban')} />
                </Col>
                <Col sm={3} className={'mb-4'}>
                  <SelectManagementStructureInput
                    name={'management_structure'}
                    placeholder={t('users.company.management_structure')}
                  />
                </Col>
              </Row>
              <hr />
              <Row>
                <Col sm={3} className={'mb-4'}>
                  <DatePickerInput
                    name={'proxy_details.proxy_valid_until'}
                    placeholder={t('users.proxy_details.proxy_valid_until')}
                  />
                </Col>
                <Col sm={3} className={'mb-4'}>
                  <TextInput
                    name={'proxy_details.proxy_number'}
                    placeholder={t('users.proxy_details.proxy_number')}
                  />
                </Col>
              </Row>

              <div className={'mt-4 mb-4'}>
                <SaveButton title={t('common.submit')} submitting={isSubmitting} />
              </div>
            </FormGroup>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default CompanyEdit;
