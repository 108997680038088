import React, { useCallback, useEffect, useState } from 'react';
import { RouterProps, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Col, Container, Form, Row } from 'reactstrap';
import { setGlobalLoading } from 'src/modules/app/actions';
import { ReactSelectOption } from 'src/components/Form/Select/SelectInput';
import CountryLabel from 'src/components/Labels/CountryLabel';
import { ForecastPricingToolRequestDto } from 'src/types/api/forecast';
import * as Yup from 'yup';
import { Formik } from 'formik';
import SaveButton from 'src/components/Form/SaveButton';
import { SelectInput } from 'src/components/Form/Select';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import SelectCreditDurationInput from 'src/components/Form/Select/SelectCreditDurationInput';
import SelectRatingInput from 'src/components/Form/Select/SelectRatingInput';
import SelectSecurityMeasureInput from 'src/components/Form/Select/SelectSecurityMeasureInput';
import Api from 'src/api';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const CustomTooltip = ({ active, payload, label }) => {
  const { t } = useTranslation();

  if (active && payload && payload.length) {
    return (
      <div className="bg-white p-2 border border-dark ">
        <p>
          {t('forecasts.pricing_tool.graph.total_investments')}: {payload[0].value}
          <br />
          {t('forecasts.pricing_tool.graph.investment_amount')}: {label}
        </p>
      </div>
    );
  }

  return null;
};

const PricingTool: React.FC<RouterProps> = () => {
  const { t } = useTranslation();
  const [formValues] = useState<ForecastPricingToolRequestDto>({
    country: null,
    rating_profitus: null,
    credit_duration: null,
    security_measures: null,
  });
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [pricingToolData, setPricingToolData] = useState<any[] | undefined>([]);
  const [avgInterest3Months, setAvgInterest3Months] = useState<number | undefined>(0);
  const [avgInterest12Months, setAvgInterest12Months] = useState<number | undefined>(0);

  useEffect(() => {
    setGlobalLoading(false);
  }, []);

  const [countryOptions] = useState<ReactSelectOption[]>([
    {
      value: 'lt',
      label: <CountryLabel code={'lt'} />,
      customText: t('countries.lt'),
    },
    {
      value: 'lv',
      label: <CountryLabel code={'lv'} />,
      customText: t('countries.lv'),
    },
    {
      value: 'ee',
      label: <CountryLabel code={'ee'} />,
      customText: t('countries.ee'),
    },
    {
      value: 'es',
      label: <CountryLabel code={'es'} />,
      customText: t('countries.es'),
    },
  ]);

  const Schema = Yup.object().shape({
    rating_profitus: Yup.string().required(t('validation.required')),
    credit_duration: Yup.string().required(t('validation.required')),
    security_measures: Yup.string().required(t('validation.required')),
  });

  const handleSubmit = useCallback(async (request) => {
    setIsDisabled(true);
    await Api.forecasts
      .fetchPricingToolForecast(request)
      .then((r) => {
        setPricingToolData(r.graph);
        setAvgInterest3Months(r.average_interest_last_3_months);
        setAvgInterest12Months(r.average_interest_last_12_months);
      })
      .finally(() => {
        setIsDisabled(false);
      });
  }, []);

  return (
    <React.Fragment>
      <Container fluid>
        <Card>
          <CardBody>
            <div className={' mb-4'}>
              <h3 className={'m-0'}>{t('menu.forecast.pricing_tool')}</h3>
              <div className={'mt-4 w-100'}>
                <Formik
                  initialValues={formValues}
                  enableReinitialize={true}
                  onSubmit={handleSubmit}
                  validationSchema={Schema}
                >
                  {({ handleSubmit }) => (
                    <Form onSubmit={handleSubmit}>
                      <Row>
                        <Col md={6} className={'mb-3'}>
                          <div>
                            <SelectInput
                              name={'country'}
                              placeholder={t('common.country')}
                              options={countryOptions}
                              isClearable={true}
                            />
                          </div>
                        </Col>
                        <Col md={6}>
                          <SelectCreditDurationInput name={'credit_duration'} />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <SelectRatingInput
                            name={'rating_profitus'}
                            placeholder={t('label.rating_profitus')}
                          />
                        </Col>
                        <Col md={6}>
                          <SelectSecurityMeasureInput
                            name={'security_measures'}
                            placeholder={t('label.security_measures')}
                          />
                        </Col>
                      </Row>
                      <div className={'mt-4 mb-3'}>
                        <SaveButton title={t('common.submit')} submitting={isDisabled} />
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
              <hr />
              <div>
                <p>
                  {t('common.average_interest_months', { value: 3 })} -{' '}
                  <strong>{avgInterest3Months}%</strong>
                  <br />
                  {t('common.average_interest_months', { value: 12 })} -{' '}
                  <strong>{avgInterest12Months}%</strong>
                </p>
              </div>
              <hr />
              <div>
                <h4>{t('forecasts.pricing_tool.graph.title')}</h4>
                <ResponsiveContainer
                  className="mt-5"
                  width="100%"
                  height="100%"
                  minHeight="500px"
                  maxHeight={500}
                >
                  <LineChart
                    width={500}
                    height={300}
                    data={pricingToolData}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="amount_range" />
                    <YAxis />
                    <Tooltip
                      content={
                        <CustomTooltip active={undefined} payload={undefined} label={undefined} />
                      }
                    />
                    <Legend />
                    <Line type="monotone" dataKey="count" stroke="#8884d8" activeDot={{ r: 8 }} />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div>
          </CardBody>
        </Card>
      </Container>
    </React.Fragment>
  );
};
export default withRouter(PricingTool);
