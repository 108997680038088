import Client from 'src/api/services/client';
import {
  FilterRCReportsRequestDto,
  GenerateRcRequestDto,
  RCReportListResponseDto,
} from 'src/types/api/RC';
import { PaginationData } from 'src/types';

class Rc extends Client {
  api = {
    FETCH_RC_DATA: `${this.http.baseUrl}/rc/fetch`,
    FILTER_RC_REPORTS: `${this.http.baseUrl}/rc/filter`,
    DELETE_RC_REPORT: `${this.http.baseUrl}/rc/{reportId}`,
  };

  fetchRCData = (request: GenerateRcRequestDto): Promise<any> => {
    return this.http.post(this.api.FETCH_RC_DATA, request);
  };

  fetchFilteredReports = (
    request: FilterRCReportsRequestDto | undefined = undefined,
  ): Promise<PaginationData<RCReportListResponseDto>> => {
    return this.http.get(this.api.FILTER_RC_REPORTS, { params: request });
  };

  deleteReport = (reportId: string): Promise<any> => {
    const url = this.buildUrl(this.api.DELETE_RC_REPORT, { reportId });

    return this.http.delete(url);
  };
}

export default Rc;
