import React, { useCallback, useState } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';

import { BaseTabProps } from 'src/components/Tabs';
import { SelectAsyncInput } from 'src/components/Form/Select';
import SaveButton from 'src/components/Form/SaveButton';
import Api from 'src/api';
import { ReactSelectOption } from 'src/components/Form/Select/SelectInput';
import { success } from 'src/services/toastr';
import {
  FetchAutoInvestorDraftQueueByProjectRequest,
  AutoInvestorDraftQueueResponse,
} from 'src/types/api/autoInvestment';
import AutoInvestmentDraftResults from 'src/pages/AutoInvestment/AutoInvestmentDraftResults';
import { formatArray } from 'src/helpers/formatters/common';
import { PaginationDataFilter } from 'src/types';

type Props = BaseTabProps;

const TabProject: React.FC<Props> = ({}) => {
  const { t } = useTranslation();

  const [request] = useState<FetchAutoInvestorDraftQueueByProjectRequest>({
    project_id: '',
  });

  const [response, setResponse] = useState<AutoInvestorDraftQueueResponse | undefined>();

  const fetchProjects = async (inputValue?: string, loadWith?: Array<string>) => {
    const request: PaginationDataFilter = {
      page: 1,
      limit: 100,
      sort: [],
      search: inputValue,
      with: loadWith,
    };

    const response = await Api.autoInvestment.fetchProjectsBriefList(request);
    const projects: ReactSelectOption[] = [];

    response.data.map((project) => {
      projects.push({
        value: project.id ?? '',
        label: formatArray([project.private_id, project.project_name]),
      });
    });

    return projects;
  };

  const onSubmit = useCallback(
    async (
      request: FetchAutoInvestorDraftQueueByProjectRequest,
      helper: FormikHelpers<FetchAutoInvestorDraftQueueByProjectRequest>,
    ) => {
      try {
        await Api.autoInvestment
          .fetchAutoInvestmentProjectDraftQueue(request.project_id)
          .then((r) => setResponse(r));
        success(t('common.success'));
      } catch (e: any) {
        helper.setErrors(e.response?.errors);
      }
    },
    [t],
  );

  return (
    <>
      <div className={'mb-5'}>
        <Formik initialValues={request} onSubmit={onSubmit}>
          {({ handleSubmit, isSubmitting }) => (
            <Form onSubmit={handleSubmit}>
              <div className={'mb-4'}>
                <SelectAsyncInput
                  placeholder={t('label.project_id')}
                  name={'project_id'}
                  isClearable={true}
                  loadOptions={fetchProjects}
                />
              </div>
              <div className={'mb-4'}>
                <SaveButton title={t('common.submit')} submitting={isSubmitting} />
              </div>
            </Form>
          )}
        </Formik>
      </div>
      {response && (
        <div className={'mb-4'}>
          <AutoInvestmentDraftResults results={response} />
        </div>
      )}
    </>
  );
};

export default TabProject;
