import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Col, FormGroup, Row } from 'reactstrap';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import Api from 'src/api';
import useReportTypeStore from 'src/pages/Reports/View/ReportTypeStore';
import usePermissions from 'src/helpers/usePermissions';
import { ReactSelectOption } from 'src/components/Form/Select/SelectInput';
import { mapManagePermission, ReportTypeEnum } from 'src/helpers/Enums/ReportTypeEnum';
import { success } from 'src/services/toastr';
import TextInput from 'src/components/Form/TextInput';
import SaveButton from 'src/components/Form/SaveButton';
import DatePickerInput from 'src/components/Form/DatePickerInput';
import { SelectInput } from 'src/components/Form/Select';
import { GenerateReportRequestDto, ProjectDetailedReportFilterForm } from 'src/types/api/reports';
import { appendDateFilter, appendFilter, initialRequest } from 'src/pages/Reports/View/helpers';
import SelectColumnsInput from 'src/pages/Reports/View/Filters/Selects/SelectColumnsInput';
import { ProjectStatus } from 'src/types/app/projects';
import SelectCountryInput from 'src/components/Form/Select/SelectCountryInput';

const AVAILABLE_COLUMNS: string[] = [
  'pid',
  'manager',
  'developer_id',
  'developer_name',
  'analytic',
  'project_name',
  'interest_start_date',
  'interest_end_date',
  'financed_amount',
  'repaid_amount',
  'left_amount',
  'max_financed_amount',
  'xirr',
  'initial_rating',
  'actual_rating',
  'risk_category',
  'weighted_interest',
  'beneficiaries',
  'country',
  'city',
  'status',
  'mark',
  'has_stages',
  'loan_ratio',
  'loan_ratio_internal',
  'loan_ratio_external',
  'loan_ratio_max',
  'days_late',
  'security_measures',
  'loan_agreement_annex_number',
];

const TYPE: ReportTypeEnum = ReportTypeEnum.PROJECT_DETAILED;

const ProjectDetailedReportFilter: React.FC = () => {
  const { t } = useTranslation();
  const p = usePermissions();
  const { refreshTable } = useReportTypeStore();
  const [managerOptions, setManagerOptions] = useState<ReactSelectOption[]>([]);

  useEffect(() => {
    Api.user.fetchManagersOptions().then((res) => {
      setManagerOptions(res.map((manager: any) => ({ value: manager.value, label: manager.key })));
    });
  }, []);

  const [request] = useState<ProjectDetailedReportFilterForm>({
    name: null,
    columns: AVAILABLE_COLUMNS,
    funded_at_from: null,
    funded_at_to: null,
    status: null,
    country: null,
    manager_id: null,
  });

  const Schema = Yup.object().shape({
    funded_at_from: Yup.date().nullable(),
    funded_at_to: Yup.date().when(
      'funded_at_from',
      (funded_at_from: Date, schema: Yup.DateSchema) => {
        return funded_at_from
          ? schema.min(funded_at_from, t('label.funded_at_from'))
          : schema.nullable();
      },
    ),
  });

  const mapData = (form: ProjectDetailedReportFilterForm): GenerateReportRequestDto => {
    const request = initialRequest(form, TYPE);

    appendDateFilter(request, 'funded_at', form.funded_at_from, form.funded_at_to);
    appendFilter(request, 'status', form.status);
    appendFilter(request, 'country', form.country);
    appendFilter(request, 'manager_id', form.manager_id);

    return request;
  };

  const onSubmit = useCallback(
    async (
      request: ProjectDetailedReportFilterForm,
      helper: FormikHelpers<ProjectDetailedReportFilterForm>,
    ) => {
      try {
        await Api.reports.generateReport(mapData(request)).then(() => {
          refreshTable();
          success(t('common.success'));
        });
      } catch (e: any) {
        helper.setErrors(e.response?.errors);
      }
    },
    [refreshTable, t],
  );

  const [projectStatusOptions] = useState<ReactSelectOption[]>([
    ...Object.values(ProjectStatus).map((type) => ({
      value: type,
      label: t('projects.status.' + type),
    })),
  ]);

  if (!p.hasAll([mapManagePermission(TYPE)])) return null;

  return (
    <div className={'mb-4'}>
      <Card>
        <CardBody>
          <h4 className={'mb-4'}>{t('reports.filter.' + TYPE)}</h4>
          <div className={'mb-4'}>
            <Formik
              initialValues={request}
              onSubmit={onSubmit}
              enableReinitialize={true}
              validationSchema={Schema}
              validateOnChange={true}
            >
              {({ handleSubmit, isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <FormGroup>
                    <Row>
                      <Col sm={3} className={'mb-4'}>
                        <TextInput name={'name'} />
                      </Col>
                      <Col sm={1} className={'mb-4'}>
                        <SelectColumnsInput columns={AVAILABLE_COLUMNS} />
                      </Col>
                      <Col sm={2} className={'mb-4'}>
                        <DatePickerInput name={'funded_at_from'} showTimeSelect />
                      </Col>
                      <Col sm={2} className={'mb-4'}>
                        <DatePickerInput name={'funded_at_to'} showTimeSelect />
                      </Col>
                      <Col sm={2} className={'mb-4'}>
                        <SelectInput
                          name={'manager_id'}
                          options={managerOptions}
                          isClearable={true}
                        />
                      </Col>
                      <Col sm={2} className={'mb-4'}>
                        <SelectInput
                          name={'status'}
                          options={projectStatusOptions}
                          isMulti={true}
                          isClearable={true}
                        />
                      </Col>
                      <Col sm={2} className={'mb-4'}>
                        <SelectCountryInput name={'country'} />
                      </Col>
                    </Row>
                    <div className={'mb-4 mt-3'}>
                      <SaveButton title={t('common.generate')} submitting={isSubmitting} />
                    </div>
                  </FormGroup>
                </Form>
              )}
            </Formik>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default ProjectDetailedReportFilter;
