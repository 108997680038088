import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  InvestorKybDataV2ResponseDto,
  UserCompanyKybDataV2BeneficiaryResponseDto,
  UserCompanyKybDataV2PepResponseDto,
  UserCompanyKybDataV2ShareholderResponseDto,
} from 'src/types/api/userCompanies';
import SidebarDataRow from 'src/components/DataBlocks/SidebarDataRow';
import { Row } from 'reactstrap';
import { formatMultipleSelectValues } from 'src/helpers';
import BeneficiaryV2Block from 'src/pages/User/Update/Company/Kyb/BeneficiaryV2Block';

interface Props {
  kyb: InvestorKybDataV2ResponseDto;
}
const InvestorKybV2: React.FC<Props> = ({ kyb }) => {
  const { t } = useTranslation();

  return (
    <>
      <Row>
        <SidebarDataRow
          label={t('label.company.purpose')}
          value={formatMultipleSelectValues(
            kyb.purpose,
            kyb.other_purpose_text,
            'label.company.purpose.',
          )}
        />
        <SidebarDataRow
          label={t('label.company.activities')}
          value={formatMultipleSelectValues(
            kyb.activities,
            kyb.other_activities_text,
            'label.company.activities.',
          )}
        />
        <SidebarDataRow label={t('label.company.average_income')} value={kyb.average_income} />
        <SidebarDataRow
          label={t('label.company.cash_usage')}
          value={kyb.cash_usage}
          displayValue={t(`label.company.cash_usage.${kyb.cash_usage}`)}
        />
        <SidebarDataRow
          label={t('label.company.activity_regions')}
          value={formatMultipleSelectValues(
            kyb.activity_regions,
            kyb.other_activity_regions_text,
            'label.company.activity_regions.',
          )}
        />
        <SidebarDataRow
          label={t('label.company.is_not_operating_in_russia_belarus_ukraine_occupied_territories')}
          value={
            kyb.is_not_operating_in_russia_belarus_ukraine_occupied_territories
              ? t('common.yes')
              : t('common.no')
          }
        />
        <SidebarDataRow
          label={t('label.company.business_partners')}
          value={kyb.business_partners}
        />
        <SidebarDataRow label={t('label.company.bank_name')} value={kyb.bank_name} />
        <SidebarDataRow label={t('label.company.iban')} value={kyb.iban} />
        <SidebarDataRow
          label={t('label.company.management_structure')}
          value={kyb.management_structure}
          displayValue={t(`users.company.${kyb.management_structure}`)}
        />
        <SidebarDataRow
          label={t('label.company.has_bankruptcy_procedure')}
          value={kyb.has_bankruptcy_procedure ? t('common.yes') : t('common.no')}
        />
        <SidebarDataRow
          label={t('label.company.convicted')}
          value={kyb.convicted ? t('common.yes') : t('common.no')}
        />
        <SidebarDataRow
          label={t('label.company.planned_investment_amount')}
          value={kyb.planned_investment_amount}
          displayValue={t(
            `label.company.planned_investment_amount.${kyb.planned_investment_amount}`,
          )}
        />
        <SidebarDataRow
          label={t('label.company.origin_of_invested_funds')}
          value={formatMultipleSelectValues(
            kyb.origin_of_invested_funds,
            kyb.other_origin_of_invested_funds_text,
            'label.company.origin_of_invested_funds.',
          )}
        />
        <SidebarDataRow
          label={t('label.company.has_confirmed_data_integrity')}
          value={kyb.has_confirmed_data_integrity ? t('common.yes') : t('common.no')}
        />
        <SidebarDataRow
          label={t('label.company.has_confirmed_legitimacy')}
          value={kyb.has_confirmed_legitimacy ? t('common.yes') : t('common.no')}
        />
      </Row>
      {kyb.has_shareholders_with_more_than_25_percent && (
        <>
          <hr />
          <h6 className={'mb-3'}>
            <strong>{t('label.company.shareholders')}</strong>
          </h6>
          {kyb.shareholders?.map(
            (shareholder: UserCompanyKybDataV2ShareholderResponseDto, index: number) => {
              return (
                <Row key={index} className={'mb-5'}>
                  <SidebarDataRow label={t('label.name')} value={shareholder.name} />
                  <SidebarDataRow
                    label={t('label.country')}
                    value={t(`countries.${shareholder.country}`)}
                  />
                  <SidebarDataRow label={t('label.legal_code')} value={shareholder.legal_code} />
                  <SidebarDataRow label={t('label.company.shares')} value={shareholder.shares} />
                  <SidebarDataRow label={t('label.company.shares')} value={shareholder.shares} />
                  <SidebarDataRow
                    label={t('label.company.traded_securities_on_eu_exchanges')}
                    value={
                      shareholder.traded_securities_on_eu_exchanges
                        ? t('common.yes')
                        : t('common.no')
                    }
                  />
                </Row>
              );
            },
          )}
        </>
      )}
      <hr />
      <h6 className={'mb-3'}>
        <strong>{t('label.company.beneficiaries')}</strong>
      </h6>
      {kyb.beneficiaries?.map(
        (beneficiary: UserCompanyKybDataV2BeneficiaryResponseDto, index: number) => {
          return (
            <Row key={index} className={'mb-5'}>
              <BeneficiaryV2Block beneficiary={beneficiary} />
            </Row>
          );
        },
      )}
      {kyb.public_positions_held_by_related_individuals && (
        <>
          <hr />
          <h6 className={'mb-3'}>
            <strong>{t('label.company.public_officials')}</strong>
          </h6>
          {kyb.peps?.map((publicOfficial: UserCompanyKybDataV2PepResponseDto, index: number) => {
            return (
              <Row key={index}>
                <SidebarDataRow label={t('label.name')} value={publicOfficial.name} />
                <SidebarDataRow label={t('label.gov_code')} value={publicOfficial.gov_code} />
                <SidebarDataRow
                  label={t('label.responsibilities')}
                  value={publicOfficial.responsibilities}
                />
                <SidebarDataRow
                  label={t('label.company.relation_with_manager_or_representative')}
                  value={publicOfficial.relation_with_manager_or_representative}
                />
              </Row>
            );
          })}
        </>
      )}
    </>
  );
};

export default InvestorKybV2;
