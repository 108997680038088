import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from 'reactstrap';
import { HiddenColumnsProvider } from 'src/components/ShowTableColumnsDropdown';
import SimpleTableWithFooter from 'src/components/SimpleTableWithFooter';
import { Cell, Column } from 'react-table';
import { BorrowerPayoutListResponseDto } from 'src/types/api/payments/borrowerPayouts';
import {
  DebtCollectionContextState,
  withDebtCollection,
} from 'src/pages/DebtCollection/Update/DebtCollectionContext';
import { StageBorrowerPayoutDto } from 'src/types/api/DebtCollection';
import Loader from 'src/components/Loader';

const TabLatePayoutsList: React.FC<DebtCollectionContextState> = ({ debtCollection }) => {
  const { t } = useTranslation();

  const columns = useMemo<Column<StageBorrowerPayoutDto>[] | any>(
    () => [
      {
        Header: t('table.project_pid'),
        Footer: t('table.total'),
        accessor: 'project_pid',
        disableSortBy: true,
        width: 150,
      },
      {
        Header: t('table.debt_fee'),
        Footer: (data: { rows: any[] }) => {
          const total = data.rows.reduce((sum, row) => row.values.debt_fee + sum, 0);
          return t('common.money', { value: total });
        },
        accessor: 'debt_fee',
        disableSortBy: true,
        Cell: (cell: Cell<BorrowerPayoutListResponseDto>) => (
          <div>{cell.value !== null ? t('common.money', { value: cell.value }) : '-'}</div>
        ),
      },
      {
        Header: t('table.standard_interest'),
        Footer: (data: { rows: any[] }) => {
          const total = data.rows.reduce((sum, row) => row.values.total_basic_interest + sum, 0);
          return t('common.money', { value: total });
        },
        accessor: 'total_basic_interest',
        disableSortBy: true,
        Cell: (cell: Cell<BorrowerPayoutListResponseDto>) => (
          <div>{cell.value !== null ? t('common.money', { value: cell.value }) : '-'}</div>
        ),
      },
      {
        Header: t('table.additional_interest_investment'),
        Footer: (data: { rows: any[] }) => {
          const total = data.rows.reduce(
            (sum, row) => row.values.additional_interest_investment + sum,
            0,
          );
          return t('common.money', { value: total });
        },
        accessor: 'additional_interest_investment',
        disableSortBy: true,
        Cell: (cell: Cell<BorrowerPayoutListResponseDto>) => (
          <div>{cell.value !== null ? t('common.money', { value: cell.value }) : '-'}</div>
        ),
      },
      {
        Header: t('table.additional_interest_profitus'),
        Footer: (data: { rows: any[] }) => {
          const total = data.rows.reduce(
            (sum, row) => row.values.additional_interest_profitus + sum,
            0,
          );
          return t('common.money', { value: total });
        },
        accessor: 'additional_interest_profitus',
        disableSortBy: true,
        Cell: (cell: Cell<BorrowerPayoutListResponseDto>) => (
          <div>{cell.value !== null ? t('common.money', { value: cell.value }) : '-'}</div>
        ),
      },
      {
        Header: t('table.loan_repayment'),
        Footer: (data: { rows: any[] }) => {
          const total = data.rows.reduce((sum, row) => row.values.loan_repayment + sum, 0);
          return t('common.money', { value: total });
        },
        accessor: 'loan_repayment',
        disableSortBy: true,
        Cell: (cell: Cell<BorrowerPayoutListResponseDto>) => (
          <div>{cell.value !== null ? t('common.money', { value: cell.value }) : '-'}</div>
        ),
      },
      {
        Header: t('table.borrower_total'),
        Footer: (data: { rows: any[] }) => {
          const total = data.rows.reduce((sum, row) => row.values.borrower_total + sum, 0);
          return t('common.money', { value: total });
        },
        accessor: 'borrower_total',
        disableSortBy: true,
        Cell: (cell: Cell<BorrowerPayoutListResponseDto>) => (
          <div>{cell.value !== null ? t('common.money', { value: cell.value }) : '-'}</div>
        ),
      },
      {
        Header: t('table.profitus_total'),
        Footer: (data: { rows: any[] }) => {
          const total = data.rows.reduce((sum, row) => row.values.profitus_total + sum, 0);
          return t('common.money', { value: total });
        },
        accessor: 'profitus_total',
        disableSortBy: true,
        Cell: (cell: Cell<BorrowerPayoutListResponseDto>) => (
          <div>{cell.value !== null ? t('common.money', { value: cell.value }) : '-'}</div>
        ),
      },
      {
        Header: t('table.payment_date'),
        Footer: '-',
        accessor: 'payment_date',
        disableSortBy: true,
        Cell: (cell: Cell<BorrowerPayoutListResponseDto>) => (
          <div>{t('common.date', { date: cell.value })}</div>
        ),
      },
    ],
    [t],
  );

  if (!debtCollection?.borrower_payouts) {
    return <Loader />;
  }

  return (
    <React.Fragment>
      <HiddenColumnsProvider title={'DebtCollectionLatePayoutListIndex'}>
        <Container fluid>
          <h3>{t('debt_collection.late_payouts')}</h3>
          <hr />
          <SimpleTableWithFooter
            columns={columns.filter((item: any) => item)}
            data={debtCollection.borrower_payouts}
            id={'DebtCollectionLatePayoutTable'}
          />
        </Container>
      </HiddenColumnsProvider>
    </React.Fragment>
  );
};

export default withDebtCollection(TabLatePayoutsList);
