import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, ModalBody, ModalFooter, ModalHeader, Form, Row } from 'reactstrap';
import { success } from 'src/services/toastr';
import DenyButton from 'src/components/Form/DenyButton';
import SaveButton from 'src/components/Form/SaveButton';
import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import { ModalComponent } from 'src/components/Modal/ModalComponent';
import ModalCloseButton from 'src/components/Modal/ModalCloseButton';
import Api from 'src/api';
import {
  ProjectListResponseDto,
  ProjectResponseDto,
  UpdateProjectRefinancingProjectRequestDto,
} from 'src/types/api/projects';
import { Formik, FormikHelpers } from 'formik';
import { transformErrors } from 'src/helpers';
import { SelectAsyncInput } from 'src/components/Form/Select';
import SelectYesNoInput from 'src/components/Form/Select/SelectYesNoInput';
import { PaginationDataFilter } from 'src/types';
import i18n from 'i18next';
import { LanguageEnum } from 'src/helpers/Enums/LanguageEnum';

interface Props {
  project: ProjectResponseDto;
  setProject: (response: ProjectResponseDto) => void;
}

const INITIAL_REQUEST: UpdateProjectRefinancingProjectRequestDto = {
  allow_project_investment_transfers: null,
  refinancing_project: null,
};

const UpdateRefinancingProjectModal: React.FC<Props> = ({ project, setProject }) => {
  const { t } = useTranslation();
  const { hideModal } = useGlobalModalContext();
  const [request, setRequest] =
    useState<UpdateProjectRefinancingProjectRequestDto>(INITIAL_REQUEST);

  useEffect(() => {
    if (!project) return;

    setRequest(() => ({
      allow_project_investment_transfers: project.allow_project_investment_transfers,
      refinancing_project: project.refinancing_project?.id ?? null,
    }));
  }, [project]);

  const onSubmit = useCallback(
    async (
      request: UpdateProjectRefinancingProjectRequestDto,
      helpers: FormikHelpers<UpdateProjectRefinancingProjectRequestDto>,
    ) => {
      if (!project) return;

      try {
        const response = await Api.projects.updateRefinancingProject(project.id, request);
        hideModal();
        setProject(response);
        success(t('projects.modals.update_refinancing_project.success'));
      } catch (e: any) {
        helpers.setErrors(transformErrors(e.response?.errors));
      }

      return true;
    },
    [hideModal, project, setProject, t],
  );

  const fetchProjects = async (inputValue?: string, loadWith?: Array<string>) => {
    const request: PaginationDataFilter = {
      page: 1,
      limit: 10,
      sort: [],
      search: inputValue,
      with: loadWith,
    };
    const response = await Api.projects.fetchFilteredProjects(request).promise;

    if (response.data.length === 0)
      return [
        {
          value: '',
          label: t('common.no_results'),
          isDisabled: true,
        },
      ];

    return response.data.map((project: ProjectListResponseDto) => ({
      value: project.id,
      label: `${project.pid} ${
        project.project_name?.[i18n.resolvedLanguage as LanguageEnum] ?? ''
      }`,
    }));
  };

  return (
    <React.Fragment>
      <ModalComponent size={'lg'}>
        <div>
          <ModalHeader toggle={hideModal} close={<ModalCloseButton />}>
            {t('projects.modals.update_refinancing_project.title')}
          </ModalHeader>
          <ModalBody>
            <Formik initialValues={request} enableReinitialize={true} onSubmit={onSubmit}>
              {({ handleSubmit, isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <ModalBody>
                    <p className={'text-pre'}>
                      {t('projects.modals.update_refinancing_project.body')}
                    </p>
                    <hr />
                    <div>
                      <Row>
                        <Col md={6}>
                          <SelectAsyncInput
                            name={'refinancing_project'}
                            isClearable={true}
                            loadOptions={fetchProjects}
                          />
                        </Col>
                        <Col md={6}>
                          <SelectYesNoInput name={'allow_project_investment_transfers'} />
                        </Col>
                      </Row>
                    </div>
                    <hr />
                  </ModalBody>
                  <ModalFooter className={'d-flex justify-content-center'}>
                    <Col>
                      <DenyButton
                        title={t('common.cancel')}
                        onClick={(e) => {
                          e.preventDefault();
                          hideModal();
                        }}
                      />
                    </Col>
                    <Col>
                      <SaveButton
                        title={t('projects.modals.update_refinancing_project')}
                        submitting={isSubmitting}
                      />
                    </Col>
                  </ModalFooter>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </div>
      </ModalComponent>
    </React.Fragment>
  );
};
export default UpdateRefinancingProjectModal;
