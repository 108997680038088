import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Card, CardBody, Container } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Cell, Column } from 'react-table';
import { setGlobalLoading } from 'src/modules/app/actions';

import { PaginationData, PaginationDataFilter } from 'src/types';
import Api from 'src/api';
import { AutoHistoryResponseDto, UserFilterDto } from 'src/types/api/communication';
import { HiddenColumnsProvider } from 'src/components/ShowTableColumnsDropdown';
import Table from 'src/components/Table';
import DateFilter from 'src/components/Table/AdvanceFilters/DateFilter';
import { CommonDocumentTypeEnum } from 'src/helpers/Enums/CommonDocumentTypeEnum';
import { SelectAsyncInput } from 'src/components/Form/Select';
import { UserBriefResponseDto } from 'src/types/api/user';
import { Form, Formik } from 'formik';

const INITIAL_SORT_ORDER = [
  {
    id: 'created_at',
    desc: true,
  },
];

const AutoHistoryList: React.FC = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<PaginationData<AutoHistoryResponseDto>>();
  const [filteredUser, setFilteredUser] = useState<UserFilterDto>({ users_ids: [] });
  const columns = useMemo<Column<AutoHistoryResponseDto>[] | any>(
    () => [
      {
        Header: t('table.pid'),
        accessor: 'pid',
        sortType: 'string',
        width: 100,
      },
      {
        Header: t('table.subject'),
        accessor: 'subject',
        sortType: 'string',
        width: 200,
      },
      {
        Header: t('table.body'),
        accessor: 'body',
        sortType: 'string',
        width: 300,
      },
      {
        Header: t('table.channel'),
        accessor: 'channel',
        width: 100,
      },
      {
        Header: t('table.created_at_history'),
        accessor: 'created_at',
        Cell: (cell: Cell<AutoHistoryResponseDto>) => (
          <div>{t('common.date_full', { date: cell.value })}</div>
        ),
        Filter: DateFilter,
      },
    ],
    [t],
  );

  const fetchUsers = async (inputValue?: string, loadWith?: Array<string>) => {
    const request: PaginationDataFilter = {
      page: 1,
      limit: 100,
      sort: [],
      search: inputValue,
      with: loadWith,
    };
    const response = await Api.user.fetchFilteredUsersBrief(request);

    return response.data.map((user: UserBriefResponseDto) => ({
      value: user.id,
      label: [user.pid, user.name].filter((val) => val).join(' - '),
      class: user.class,
    }));
  };

  const fetchData = useCallback(
    async (request: PaginationDataFilter | undefined) => {
      if (filteredUser.users_ids.length === 0) {
        setData(undefined);
        return;
      }
      return Api.communication
        .fetchFilteredUsersHistory(filteredUser.users_ids, request)
        .then((response) => {
          setData(response);
        });
    },
    [filteredUser.users_ids],
  );

  const handleChange = (values: any) => {
    console.log(values);
    if (values.length <= 0) {
      setFilteredUser({ ...filteredUser, users_ids: [] });
    } else {
      const user_ids = values.map((option: any) => option.value);
      setFilteredUser({ ...filteredUser, users_ids: user_ids });
    }
  };

  useEffect(() => {
    setGlobalLoading(false);
  }, []);

  const exportCsv = useCallback(
    async (request: PaginationDataFilter | undefined) => {
      return Api.communication.exportAutoHistoryDataCsv(filteredUser.users_ids, request);
    },
    [filteredUser.users_ids],
  );

  return (
    <React.Fragment>
      <HiddenColumnsProvider title={'AutoHistoryListIndex'}>
        <Container fluid className={'auto-history-height'}>
          <Card>
            <CardBody>
              <h4 className={'mb-4'}>{t('menu.auto_history')}</h4>
              <Formik
                initialValues={filteredUser}
                onSubmit={() => {
                  return;
                }}
              >
                <Form>
                  <div className="mb-3">
                    <SelectAsyncInput
                      name={'user_id'}
                      isClearable={true}
                      isMulti={true}
                      loadOptions={fetchUsers}
                      onChange={(values) => handleChange(values)}
                    />
                  </div>
                </Form>
              </Formik>
              <Table
                title={'AutoHistoryListIndex'}
                onFetchData={fetchData}
                columns={columns}
                data={data}
                searchable={true}
                enableQueryFilter={true}
                initialSortBy={INITIAL_SORT_ORDER}
                disableFiltersInsideTable={true}
                exportLinks={[
                  {
                    onExport: exportCsv,
                    type: CommonDocumentTypeEnum.CSV,
                    fileName: 'auto_history',
                    buttonTitle: t('common.export'),
                  },
                ]}
              />
            </CardBody>
          </Card>
        </Container>
      </HiddenColumnsProvider>
    </React.Fragment>
  );
};

export default AutoHistoryList;
