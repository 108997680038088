import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Col, FormGroup, Row } from 'reactstrap';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import Api from 'src/api';
import { success } from 'src/services/toastr';
import TextInput from 'src/components/Form/TextInput';
import SaveButton from 'src/components/Form/SaveButton';
import DatePickerInput from 'src/components/Form/DatePickerInput';
import { appendFilter, initialRequest } from 'src/pages/RC/View/helpers';
import { GenerateRcRequestDto, RealEstateTransactionsFilterForm } from 'src/types/api/RC';
import RETypesInput from 'src/pages/RC/View/Filters/Selects/SelectRETypeInput';
import { RCTypeEnum } from 'src/helpers/Enums/RC/RCTypeEnum';
import SelectBasisOfProvidingDataInput from 'src/pages/RC/View/Filters/Selects/SelectBasisOfProvidingDataInput';
import DataDetailsInput from 'src/pages/RC/View/Filters/Selects/DataDetailsInput';
import MortgageInput from 'src/pages/RC/View/Filters/Selects/MortgageInput';
import useRCReportTypeStore from 'src/pages/RC/View/RCReportTypeStore';
import { RCDataTypeEnum } from 'src/helpers/Enums/RC/RCDataTypeEnum';
import { formatDateYyyyMm } from 'src/helpers/formatDate';

const TYPE: RCTypeEnum = RCTypeEnum.REAL_ESTATE_TRANSACTIONS;

const RealEstateTransactionsFilter: React.FC = () => {
  const { t } = useTranslation();
  const { refreshTable } = useRCReportTypeStore();
  const now = new Date();
  const oneMonthAgo = new Date();
  oneMonthAgo.setMonth(now.getMonth() - 1);

  const [request] = useState<RealEstateTransactionsFilterForm>({
    name: null,
    sdata_nuo: formatDateYyyyMm(oneMonthAgo),
    sdata_iki: formatDateYyyyMm(now),
  });

  const Schema = Yup.object().shape({
    sdata_nuo: Yup.string().required(),
    sdata_iki: Yup.string().required(),
    nt_tipas: Yup.array().optional().nullable(),
    sav_kodas: Yup.string().max(2).optional().nullable(),
    gyv_kodas: Yup.string().max(30).optional().nullable(),
    gat_kodas: Yup.string().max(80).optional().nullable(),
    zove_nr: Yup.string().max(70).optional().nullable(),
    metai_nuo: Yup.string().optional().nullable(),
    metai_iki: Yup.string().optional().nullable(),
    plotas_nuo: Yup.string().max(30).optional().nullable(),
    plotas_iki: Yup.string().max(30).optional().nullable(),
    vobt_tipas: Yup.string().max(30).optional().nullable(),
    pask_tipas: Yup.string().max(30).optional().nullable(),
    noskaicius: Yup.string().max(30).optional().nullable(),
    suzstatymas: Yup.string().optional().nullable(),
    sant_tipas: Yup.string().max(80).optional().nullable(),
    sakt_tipas: Yup.string().max(80).optional().nullable(),
    gavejas: Yup.string().max(20).optional().nullable(),
    opt: Yup.string().optional().nullable(),
    dtp_tipas: Yup.string().when('opt', {
      is: RCDataTypeEnum.FULL,
      then: Yup.string().required(),
      otherwise: Yup.string().optional().nullable(),
    }),
    dtp_reiksme: Yup.string().when('opt', {
      is: RCDataTypeEnum.FULL,
      then: Yup.string().max(255).required(),
      otherwise: Yup.string().max(255).optional().nullable(),
    }),
  });
  const mapData = (form: RealEstateTransactionsFilterForm): GenerateRcRequestDto => {
    const request = initialRequest(form, TYPE);

    appendFilter(request, 'sdata_nuo', form.sdata_nuo);
    appendFilter(request, 'sdata_iki', form.sdata_iki);
    appendFilter(request, 'nt_tipas', form.nt_tipas);
    appendFilter(request, 'sav_kodas', form.sav_kodas);
    appendFilter(request, 'gyv_kodas', form.gyv_kodas);
    appendFilter(request, 'gat_kodas', form.gat_kodas);
    appendFilter(request, 'zove_nr', form.zove_nr);
    appendFilter(request, 'metai_nuo', form.metai_nuo);
    appendFilter(request, 'metai_iki', form.metai_iki);
    appendFilter(request, 'plotas_nuo', form.plotas_nuo);
    appendFilter(request, 'plotas_iki', form.plotas_iki);
    appendFilter(request, 'vobt_tipas', form.vobt_tipas);
    appendFilter(request, 'pask_tipas', form.pask_tipas);
    appendFilter(request, 'noskaicius', form.noskaicius);
    appendFilter(request, 'suzstatymas', form.suzstatymas);
    appendFilter(request, 'sant_tipas', form.sant_tipas);
    appendFilter(request, 'sakt_tipas', form.sakt_tipas);
    appendFilter(request, 'gavejas', form.gavejas);
    appendFilter(request, 'opt', form.opt);
    appendFilter(request, 'dtp_tipas', form.dtp_tipas);
    appendFilter(request, 'dtp_reiksme', form.dtp_reiksme);

    return request;
  };

  const onSubmit = useCallback(
    async (
      request: RealEstateTransactionsFilterForm,
      helper: FormikHelpers<RealEstateTransactionsFilterForm>,
    ) => {
      try {
        await Api.rc.fetchRCData(mapData(request)).then(() => {
          refreshTable();
          success(t('common.success'));
        });
      } catch (e: any) {
        helper.setErrors(e.response?.errors);
      }
    },
    [refreshTable, t],
  );

  return (
    <div className={'mb-4'}>
      <Card>
        <CardBody>
          <h4 className={'mb-4'}>{t('rc.filter.' + TYPE)}</h4>
          <div className={'mb-4'}>
            <Formik
              initialValues={request}
              onSubmit={onSubmit}
              enableReinitialize={true}
              validationSchema={Schema}
              validateOnChange={true}
            >
              {({ handleSubmit, isSubmitting, values, setFieldValue }) => (
                <Form onSubmit={handleSubmit}>
                  <FormGroup>
                    <h5 className={'mb-4'}>{t('rc.filter.required')}</h5>
                    <Row>
                      <Col sm={2} className={'mb-4'}>
                        <DatePickerInput
                          name={'sdata_nuo'}
                          showMonthYearPicker
                          dateFormat={'yyyy-MM'}
                          onChangeAction={(value: any) => {
                            setFieldValue('sdata_nuo', formatDateYyyyMm(value));
                          }}
                        />
                      </Col>
                      <Col sm={2} className={'mb-4'}>
                        <DatePickerInput
                          name={'sdata_iki'}
                          showMonthYearPicker
                          dateFormat={'yyyy-MM'}
                          onChangeAction={(value: any) => {
                            setFieldValue('sdata_iki', formatDateYyyyMm(value));
                          }}
                        />
                      </Col>
                    </Row>
                    <h5 className={'mb-4'}>{t('rc.filter.optional')}</h5>
                    <Row>
                      <Col sm={2} className={'mb-4'}>
                        <RETypesInput name={'nt_tipas'} placeholder={'nt_tipas'} />
                      </Col>
                      <Col sm={2} className={'mb-4'}>
                        <TextInput name={'sav_kodas'} type={'number'} />
                      </Col>
                      <Col sm={2} className={'mb-4'}>
                        <TextInput name={'gyv_kodas'} />
                      </Col>
                      <Col sm={2} className={'mb-4'}>
                        <TextInput name={'gat_kodas'} />
                      </Col>
                      <Col sm={2} className={'mb-4'}>
                        <TextInput name={'zove_nr'} />
                      </Col>
                      <Col sm={2} className={'mb-4'}>
                        <DatePickerInput name={'metai_nuo'} showYearPicker dateFormat={'yyyy'} />
                      </Col>
                      <Col sm={2} className={'mb-4'}>
                        <DatePickerInput name={'metai_iki'} showYearPicker dateFormat={'yyyy'} />
                      </Col>
                      <Col sm={2} className={'mb-4'}>
                        <TextInput name={'plotas_nuo'} type={'number'} />
                      </Col>
                      <Col sm={2} className={'mb-4'}>
                        <TextInput name={'plotas_iki'} type={'number'} />
                      </Col>
                      <Col sm={2} className={'mb-4'}>
                        <TextInput name={'vobt_tipas'} />
                      </Col>
                      <Col sm={2} className={'mb-4'}>
                        <TextInput name={'pask_tipas'} />
                      </Col>
                      <Col sm={2} className={'mb-4'}>
                        <TextInput name={'noskaicius'} type={'number'} />
                      </Col>
                      <Col sm={2} className={'mb-4'}>
                        <MortgageInput name={'suzstatymas'} placeholder={''} />
                      </Col>
                      <Col sm={2} className={'mb-4'}>
                        <TextInput name={'sant_tipas'} type={'number'} />
                      </Col>
                      <Col sm={2} className={'mb-4'}>
                        <TextInput name={'sakt_tipas'} type={'number'} />
                      </Col>
                      <Col sm={2} className={'mb-4'}>
                        <TextInput name={'gavejas'} />
                      </Col>
                      <Col sm={2} className={'mb-4'}>
                        <DataDetailsInput name={'opt'} placeholder={''} />
                      </Col>
                      {values && values.opt === RCDataTypeEnum.FULL && (
                        <>
                          <Col sm={2} className={'mb-4'}>
                            <SelectBasisOfProvidingDataInput name={'dtp_tipas'} placeholder={''} />
                          </Col>
                          <Col sm={2} className={'mb-4'}>
                            <TextInput name={'dtp_reiksme'} />
                          </Col>
                        </>
                      )}
                    </Row>
                    <Row>
                      <hr />
                      <Col sm={2} className={'mb-4'}>
                        <TextInput name={'name'} />
                      </Col>
                    </Row>
                    <div className={'mb-4 mt-3'}>
                      <SaveButton title={t('common.generate')} submitting={isSubmitting} />
                    </div>
                  </FormGroup>
                </Form>
              )}
            </Formik>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default RealEstateTransactionsFilter;
