import React, { useCallback, useMemo, useState } from 'react';
import { BaseTabProps } from 'src/components/Tabs';
import { UserContextState, withUser } from 'src/pages/User/Update/User/UserContext';
import { useTranslation } from 'react-i18next';
import { PaginationData, PaginationDataFilter } from 'src/types';
import { Cell, Column } from 'react-table';
import Api from 'src/api';
import Table from 'src/components/Table';
import { HiddenColumnsProvider } from 'src/components/ShowTableColumnsDropdown';
import { CommonActionListResponseDto } from 'src/types/api/common';
import { Card, CardBody, Col, Row } from 'reactstrap';
import SidebarDataRow from 'src/components/DataBlocks/SidebarDataRow';
import { UserMarketingSettingTypeEnum } from 'src/helpers/Enums/UserMarketingAgreementsTypeEnum';

interface Props extends BaseTabProps, UserContextState {}

const TabAgreements: React.FC<Props> = ({ user }) => {
  const { t } = useTranslation();
  const [response, setResponse] = useState<PaginationData<CommonActionListResponseDto>>();

  const columns = useMemo<Column<CommonActionListResponseDto>[] | any>(
    () => [
      {
        Header: t('table.user'),
        accessor: 'user_name',
        Cell: (cell: Cell<CommonActionListResponseDto>) => {
          const row = cell.row.original;
          return (
            <div>
              {row.user_name} {row.original_user_name && <>({row.original_user_name})</>}
            </div>
          );
        },
      },
      {
        Header: t('table.event'),
        accessor: 'event',
        Cell: (cell: Cell<CommonActionListResponseDto>) => {
          const row = cell.row.original;
          return (
            <div>
              {t('audit.event.' + row.event, { type: t('audit.entity.' + row.audit_type) })}
            </div>
          );
        },
      },
      {
        Header: t('table.email'),
        accessor: 'new_values',
        Cell: (cell: Cell<CommonActionListResponseDto>) => <div>{cell.value?.email}</div>,
      },
      {
        Header: t('table.created_at'),
        accessor: 'created_at',
        Cell: (cell: Cell<CommonActionListResponseDto>) => (
          <div>{t('common.date_full', { date: cell.value })}</div>
        ),
      },
    ],
    [t],
  );

  const fetchData = useCallback(
    (request: PaginationDataFilter | undefined) => {
      if (!user) return Promise.resolve();

      return Api.user
        .fetchUserAgreements(user.id, request)
        .then((response) => setResponse(response));
    },
    [user],
  );

  return (
    <React.Fragment>
      <Col xs="12" className={'mb-4'}>
        <Card className="card h-100">
          <CardBody>
            <Row className="mt-4">
              <SidebarDataRow
                label={t('users.marketing.educational_marketing_via_email')}
                value={
                  user?.marketing_settings.filter(
                    (setting) => setting.name === UserMarketingSettingTypeEnum.EDUCATIONAL,
                  )[0]?.via_email
                    ? 'Yes'
                    : 'No'
                }
              />
              <SidebarDataRow
                label={t('users.marketing.promotional_marketing_via_email')}
                value={
                  user?.marketing_settings.filter(
                    (setting) => setting.name === UserMarketingSettingTypeEnum.PROMOTIONAL,
                  )[0]?.via_email
                    ? 'Yes'
                    : 'No'
                }
              />
              <SidebarDataRow
                label={t('users.marketing.newsletter_marketing_via_email')}
                value={
                  user?.marketing_settings.filter(
                    (setting) => setting.name === UserMarketingSettingTypeEnum.NEWSLETTER,
                  )[0]?.via_email
                    ? 'Yes'
                    : 'No'
                }
              />
              <SidebarDataRow
                label={t('users.marketing.invested_in_project_news_marketing_via_email')}
                value={
                  user?.marketing_settings.filter(
                    (setting) =>
                      setting.name === UserMarketingSettingTypeEnum.INVESTED_PROJECT_NEWS,
                  )[0]?.via_email
                    ? 'Yes'
                    : 'No'
                }
              />
              <SidebarDataRow
                label={t('users.marketing.survey_via_email')}
                value={
                  user?.marketing_settings.filter(
                    (setting) => setting.name === UserMarketingSettingTypeEnum.SURVEYS,
                  )[0]?.via_email
                    ? 'Yes'
                    : 'No'
                }
              />
              <SidebarDataRow
                label={t('users.marketing.survey_via_phone')}
                value={
                  user?.marketing_settings.filter(
                    (setting) => setting.name === UserMarketingSettingTypeEnum.SURVEYS,
                  )[0]?.via_phone
                    ? 'Yes'
                    : 'No'
                }
              />
              <SidebarDataRow
                label={t('users.marketing.promotional_marketing_via_phone')}
                value={
                  user?.marketing_settings.filter(
                    (setting) => setting.name === UserMarketingSettingTypeEnum.PROMOTIONAL,
                  )[0]?.via_phone
                    ? 'Yes'
                    : 'No'
                }
              />
              <SidebarDataRow
                label={t('users.marketing.direct_marketing_via_sms')}
                value={
                  user?.marketing_settings.filter(
                    (setting) => setting.name === UserMarketingSettingTypeEnum.DIRECT,
                  )[0]?.via_sms
                    ? 'Yes'
                    : 'No'
                }
              />
            </Row>
          </CardBody>
        </Card>
      </Col>
      <hr />
      <HiddenColumnsProvider title={'UserUpdateUserTabsTabAgreements'}>
        <Table
          title={'UserUpdateUserTabsTabAgreements'}
          columns={columns}
          data={response}
          onFetchData={fetchData}
        />
      </HiddenColumnsProvider>
    </React.Fragment>
  );
};

export default withUser(TabAgreements);
