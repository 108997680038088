import { ModelResponseDto } from 'src/types/api/common';

export type AmlRiskLevelChangeLogListResponseDto = {
  id: string;
  created_at: Date;
  resolved_at: Date | null;
  account: ModelResponseDto;
  account_legal_entity: string;
  account_type: string;
  old_risk_level: string | null;
  new_risk_level: string | null;
  risk_level_trigger: string | null;
  manual_change_reason: string | null;
};

export type AmlFrozenStatusChangeLogListResponseDto = {
  id: string;
  created_at: Date;
  account: ModelResponseDto;
  account_legal_entity: string;
  account_type: string;
  old_freeze_status: boolean | null;
  new_freeze_status: boolean | null;
  frozen_status_trigger: string | null;
  manual_change_reason: string | null;
};

export enum AmlIssueStatus {
  NEW = 'new',
  IN_PROGRESS = 'in_progress',
  RESOLVED_POSITIVE = 'resolved_positive',
  RESOLVED_NEGATIVE = 'resolved_negative',
}

export enum ResidenceCountryStatus {
  APPROVED = 'approved',
  DECLINED = 'declined',
}

export type ResidenceCountryResponseDto = {
  id: string;
  created_at: Date;
  account: ModelResponseDto;
  status: ResidenceCountryStatus;
  status_changed_at: Date;
  residence: string;
};

export type AmlIssuesLogListResponseDto = {
  id: string;
  created_at: Date;
  account: ModelResponseDto;
  status: AmlIssueStatus;
  reason: string;
  lawyer_comment: string | null;
  traits: any[];
};

export type LegalEntitySearchMatchItem = {
  resourceId: string | null;
  score: number | null;
  match: boolean | null;
  name: string | null;
  countries: string[] | null;
  dataSets: string[] | null;
};

export type LegalEntitySearchResponse = {
  matchCount: number;
  matches: LegalEntitySearchMatchItem[];
  resourceId: string;
};

export type AmlMarkIssueRequestDto = {
  delete: boolean;
};

export type AmlUpdateRequestDto = {
  lawyer_comment: string | null;
  new_status: AmlIssueStatus;
};
