import React from 'react';
import { Container } from 'reactstrap';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { DashboardPortfolioInformationBlock } from './DashboardPortfolioInformationBlock';
import { DashboardWeightedRatingBlock } from 'src/pages/Common/Dashboard/Portfolio/DashboardWeightedRatingBlock';

const PortfolioInfo: React.FC<RouteComponentProps> = () => {
  return (
    <React.Fragment>
      <div>
        <Container fluid={true}>
          <DashboardPortfolioInformationBlock />
        </Container>
        <hr />
        <Container fluid={true}>
          <DashboardWeightedRatingBlock />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(PortfolioInfo);
