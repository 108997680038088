import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import ActionModal from 'src/components/Modal/Modals/ActionModal';
import { success } from 'src/services/toastr';
import usePermissions from 'src/helpers/usePermissions';

interface Props
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  permissions?: string | string[];
  onDelete: () => Promise<any>;
  show?: boolean;
  modalTitle?: string;
  modalBody?: string;
  modalSize?: 'sm' | 'md' | 'lg' | 'xl';
  successMessage?: string;
}

const DeleteEntityButton: React.FC<Props> = ({
  permissions,
  onDelete,
  show,
  onClick,
  modalTitle,
  modalBody,
  modalSize,
  successMessage,
  ...props
}) => {
  const { t } = useTranslation();
  const p = usePermissions();
  const { showModal } = useGlobalModalContext();

  const deleteModal = useCallback(() => {
    showModal(
      <ActionModal
        title={modalTitle ?? t('common.delete')}
        onAction={() => onDelete()}
        body={modalBody ?? t('common.delete_generic')}
        actionText={modalTitle ?? t('common.delete')}
        size={modalSize}
        successMessage={successMessage}
      />,
    );
  }, [onDelete, showModal, t]);

  if ((show !== undefined && !show) || (permissions && p.cannot(permissions))) {
    return null;
  }

  return (
    <a
      href="#"
      onClick={(e) => {
        e.preventDefault();
      }}
    >
      <i className={'fas fa-trash-alt text-danger'} onClick={onClick ?? deleteModal} {...props} />
    </a>
  );
};

export default DeleteEntityButton;
