import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, ModalBody, ModalFooter, ModalHeader, Form } from 'reactstrap';
import { success } from 'src/services/toastr';
import DenyButton from 'src/components/Form/DenyButton';
import SaveButton from 'src/components/Form/SaveButton';
import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import { ModalComponent } from 'src/components/Modal/ModalComponent';
import ModalCloseButton from 'src/components/Modal/ModalCloseButton';
import Api from 'src/api';
import { ProjectResponseDto, RefinanceProjectRequestDto } from 'src/types/api/projects';
import { Formik, FormikHelpers } from 'formik';
import TextInput from 'src/components/Form/TextInput';
import * as Yup from 'yup';
import CheckboxInput from 'src/components/Form/CheckboxInput';
import { transformErrors } from 'src/helpers';

interface Props {
  project: ProjectResponseDto;
  setProject: (response: ProjectResponseDto) => void;
}

const INITIAL_REQUEST: RefinanceProjectRequestDto = {
  amount: null,
  skip_money_transfer: false,
};

const RefinanceProjectModal: React.FC<Props> = ({ project, setProject }) => {
  const { t } = useTranslation();
  const { hideModal } = useGlobalModalContext();
  const [isInAction, setIsInAction] = useState(false);

  const [request] = useState<RefinanceProjectRequestDto>(INITIAL_REQUEST);

  const RefinanceSchema = Yup.object().shape({
    amount: Yup.number().nullable(),
    skip_money_transfer: Yup.boolean().required(),
  });

  const onSubmit = useCallback(
    async (
      request: RefinanceProjectRequestDto,
      helpers: FormikHelpers<RefinanceProjectRequestDto>,
    ) => {
      if (isInAction) return;
      setIsInAction(true);

      await Api.projects
        .refinanceProject(project.id, request)
        .then((response) => {
          success(t('projects.modals.refinance.success'));
          hideModal();
          setProject(response);
        })
        .finally(() => {
          setIsInAction(false);
        })
        .catch((e) => {
          helpers.setErrors(transformErrors(e.response?.errors));
        });
    },
    [hideModal, isInAction, project.id, setProject, t],
  );

  if (project.refinancing_project == null) return null;

  return (
    <React.Fragment>
      <ModalComponent size={'lg'}>
        <div>
          <ModalHeader toggle={hideModal} close={<ModalCloseButton />}>
            {t('projects.modals.refinance.title')}
          </ModalHeader>
          <ModalBody>
            <Formik
              initialValues={request}
              enableReinitialize={true}
              onSubmit={onSubmit}
              validationSchema={RefinanceSchema}
            >
              {({ handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                  <ModalBody>
                    <p className={'text-pre'}>{t('projects.modals.refinance.body')}</p>
                    <hr />
                    {project.refinancing_project && (
                      <div>
                        <p>
                          <b>
                            {t('projects.modals.refinance.refinance_funded_amount') + ' '}
                            {t('common.money', { value: project.refinancing_project.amount })}
                          </b>
                        </p>
                        <p>
                          <b>
                            {t('projects.modals.refinance.refinance_investments_amount') + ' '}
                            {t('common.money', {
                              value: project.refinancing_project.transfer_amount,
                            })}
                          </b>
                        </p>
                        <p>
                          <b>
                            {t('projects.modals.refinance.refinance_balance') + ' '}
                            {t('common.money', { value: project.refinancing_project.balance })}
                          </b>
                        </p>
                      </div>
                    )}
                    <hr />
                    <div className={'mb-3'}>
                      <CheckboxInput name={'skip_money_transfer'} />
                    </div>
                    <div>
                      <TextInput name={'amount'} />
                    </div>
                  </ModalBody>
                  <ModalFooter className={'d-flex justify-content-center'}>
                    <Col>
                      <DenyButton
                        title={t('common.cancel')}
                        onClick={(e) => {
                          e.preventDefault();
                          hideModal();
                        }}
                      />
                    </Col>
                    <Col>
                      <SaveButton title={t('projects.modals.refinance')} submitting={isInAction} />
                    </Col>
                  </ModalFooter>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </div>
      </ModalComponent>
    </React.Fragment>
  );
};
export default RefinanceProjectModal;
