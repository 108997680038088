import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  ProspectApplicationContextState,
  withProspectApplication,
} from '../ProspectApplicationContext';
import SidebarDataRow from 'src/components/DataBlocks/SidebarDataRow';
import { ColProps, Row } from 'reactstrap';

const TabSignatures: React.FC<ProspectApplicationContextState> = ({ prospectApplication }) => {
  const { t } = useTranslation();

  const colProps: ColProps = {
    className: 'width-20',
  };

  return (
    <React.Fragment>
      <h3 className={'flex'}>{t('prospect_applications.tabs.signatures')}</h3>
      <hr />
      {prospectApplication?.signatures?.map((signature, index) => {
        return (
          <Row key={index} className="mb-5">
            <SidebarDataRow
              colProps={colProps}
              label={t('label.first_name')}
              value={signature.firstname}
            />
            <SidebarDataRow
              colProps={colProps}
              label={t('label.last_name')}
              value={signature.lastname}
            />
            <SidebarDataRow colProps={colProps} label={t('label.email')} value={signature.email} />
            <SidebarDataRow
              colProps={colProps}
              label={t('label.responsibilities')}
              value={signature.responsibilities}
            />
            <SidebarDataRow
              colProps={colProps}
              label={t('label.language')}
              value={t(`countries.${signature.language}`)}
            />
          </Row>
        );
      })}
    </React.Fragment>
  );
};

export default withProspectApplication(TabSignatures);
