import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, FormikHelpers } from 'formik';
import { Col, ColProps, Form, FormGroup, Row } from 'reactstrap';
import TextInput from 'src/components/Form/TextInput';
import Api from 'src/api';
import { success } from 'src/services/toastr';
import { transformErrors } from 'src/helpers';

import SaveButton from 'src/components/Form/SaveButton';
import {
  ProspectApplicationContextState,
  withProspectApplication,
} from '../ProspectApplicationContext';
import { UpdateProspectApplicationDetailsRequestDto } from 'src/types/api/prospectApplications';
import DatePickerInput from 'src/components/Form/DatePickerInput';
import { UseNumbers } from 'src/helpers/useNumbers';
import SelectYesNo from 'src/components/Form/Select/SelectYesNoInput';
import SelectInput, { ReactSelectOption } from 'src/components/Form/Select/SelectInput';
import { ApplicationType } from 'src/helpers/Enums/Application/ApplicationType';
import SelectRealEstateTypeInput from 'src/components/Form/Select/SelectRealEstateTypeInput';

const INITIAL_REQUEST: UpdateProspectApplicationDetailsRequestDto = {
  type: null,
  project_name: null,
  project_address: null,
  start_construction_at: null,
  end_construction_at: null,
  total_area: null,
  project_estimate: null,
  project_plot_price: null,
  project_engineering_price: null,
  project_documentation_price: null,
  project_construction_price: null,
  existing_investment_plot_price: null,
  existing_investment_engineering_price: null,
  existing_investment_documentation_price: null,
  existing_investment_construction_price: null,
  existing_investment_total: null,
  existing_investment_own_funds: null,
  existing_investment_lend_funds: null,
  presale_contracts: null,
  presale_contracts_amount: null,
  building_permit: null,
  assets_to_be_sold: null,
  debt_amount: null,
  debt_due_date: null,
  debt_purpose: null,
  debt_security_deposit: null,
  debt_security_deposit_value: null,
  debt_refinancing_reason: null,
  re_price_without_vat: null,
  re_price_vat: null,
  own_funds_amount: null,
  own_funds_origin: null,
  re_seller_name: null,
  re_address: null,
  re_purpose: null,
  re_value: null,
  re_evaluated_by: null,
  re_evaluated_at: null,
  loan_details_working_capitals: null,
  loan_details_others: null,
};

const TabApplicationDetails: React.FC<ProspectApplicationContextState> = ({
  prospectApplication,
  setProspectApplication,
  isEditable,
}) => {
  const { t } = useTranslation();

  const [request, setRequest] =
    useState<UpdateProspectApplicationDetailsRequestDto>(INITIAL_REQUEST);
  const [applicationTypes, setApplicationTypes] = useState<ReactSelectOption[] | undefined>(
    undefined,
  );

  useEffect(() => {
    setApplicationTypes((applicationTypes) => {
      const newApplicationTypes: ReactSelectOption[] = Object.values(ApplicationType).map(
        (applicationType) => ({
          value: applicationType,
          label: t('applications.type.' + applicationType),
        }),
      );

      return newApplicationTypes.filter((option) =>
        prospectApplication?.credit_purpose?.includes(option.value as string),
      );
    });
  }, [t, prospectApplication?.credit_purpose]);

  useEffect(() => {
    if (!prospectApplication || !prospectApplication?.prospect_application_details) return;

    const details = prospectApplication.prospect_application_details;
    setRequest(() => ({
      type: details.type,
      project_name: details.project_name,
      project_address: details.project_address,
      start_construction_at: details.start_construction_at,
      end_construction_at: details.end_construction_at,
      total_area: details.total_area,
      project_estimate: details.project_estimate,
      project_plot_price: details.project_plot_price,
      project_engineering_price: details.project_engineering_price,
      project_documentation_price: details.project_documentation_price,
      project_construction_price: details.project_construction_price,
      existing_investment_plot_price: details.existing_investment_plot_price,
      existing_investment_engineering_price: details.existing_investment_engineering_price,
      existing_investment_documentation_price: details.existing_investment_documentation_price,
      existing_investment_construction_price: details.existing_investment_construction_price,
      existing_investment_total: details.existing_investment_total,
      existing_investment_own_funds: details.existing_investment_own_funds,
      existing_investment_lend_funds: details.existing_investment_lend_funds,
      presale_contracts: details.presale_contracts,
      presale_contracts_amount: details.presale_contracts_amount,
      building_permit: details.building_permit,
      assets_to_be_sold: details.assets_to_be_sold,
      debt_amount: details.debt_amount,
      debt_due_date: details.debt_due_date,
      debt_purpose: details.debt_purpose,
      debt_security_deposit: details.debt_security_deposit,
      debt_security_deposit_value: details.debt_security_deposit_value,
      debt_refinancing_reason: details.debt_refinancing_reason,
      re_price_without_vat: details.re_price_without_vat,
      re_price_vat: details.re_price_vat,
      own_funds_amount: details.own_funds_amount,
      own_funds_origin: details.own_funds_origin,
      re_seller_name: details.re_seller_name,
      re_address: details.re_address,
      re_purpose: details.re_purpose,
      re_value: details.re_value,
      re_evaluated_by: details.re_evaluated_by,
      re_evaluated_at: details.re_evaluated_at,
      loan_details_working_capitals: details.loan_details_working_capitals,
      loan_details_others: details.loan_details_others,
    }));
  }, [prospectApplication]);

  const onSubmit = useCallback(
    async (
      request: UpdateProspectApplicationDetailsRequestDto,
      helpers: FormikHelpers<UpdateProspectApplicationDetailsRequestDto>,
    ) => {
      if (!prospectApplication || !prospectApplication.prospect_user_company) return;

      try {
        const response = await Api.ProspectApplications.updateProspectApplicationDetails(
          prospectApplication.id,
          request,
        );

        setProspectApplication(response);
        success(t('common.updated_success'));
      } catch (e: any) {
        helpers.setErrors(transformErrors(e.response?.errors));
      }
    },
    [prospectApplication, t, setProspectApplication],
  );

  const colPropsThreePerRow: ColProps = {
    md: 4,
    className: 'mb-4',
  };

  return (
    <React.Fragment>
      <h3 className={'flex'}>{t('prospect_applications.tabs.application_details')}</h3>
      <hr />
      <Formik initialValues={request} enableReinitialize={true} onSubmit={onSubmit}>
        {({ handleSubmit, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Row>
                <Col {...colPropsThreePerRow}>
                  <SelectInput
                    placeholder={t('label.application_type')}
                    name={'type'}
                    options={applicationTypes}
                    isClearable={true}
                  />
                </Col>
                <Col {...colPropsThreePerRow}>
                  <TextInput type={'project_name'} name={'project_name'} disabled={!isEditable} />
                </Col>
                <Col {...colPropsThreePerRow}>
                  <TextInput
                    type={'project_address'}
                    name={'project_address'}
                    disabled={!isEditable}
                  />
                </Col>
                <Col {...colPropsThreePerRow}>
                  <DatePickerInput
                    name={'start_construction_at'}
                    disabled={!isEditable}
                    matchFieldValueAndDisplayValue={true}
                  />
                </Col>
                <Col {...colPropsThreePerRow}>
                  <DatePickerInput
                    name={'end_construction_at'}
                    disabled={!isEditable}
                    matchFieldValueAndDisplayValue={true}
                  />
                </Col>
                <Col {...colPropsThreePerRow}>
                  <TextInput
                    type={'number'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                    name={'total_area'}
                    disabled={!isEditable}
                  />
                </Col>
                <Col {...colPropsThreePerRow}>
                  <TextInput
                    type={'number'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                    name={'project_estimate'}
                    disabled={!isEditable}
                  />
                </Col>
                <Col {...colPropsThreePerRow}>
                  <TextInput
                    type={'number'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                    name={'project_plot_price'}
                    disabled={!isEditable}
                  />
                </Col>
                <Col {...colPropsThreePerRow}>
                  <TextInput
                    type={'number'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                    name={'project_engineering_price'}
                    disabled={!isEditable}
                  />
                </Col>
                <Col {...colPropsThreePerRow}>
                  <TextInput
                    type={'number'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                    name={'project_documentation_price'}
                    disabled={!isEditable}
                  />
                </Col>
                <Col {...colPropsThreePerRow}>
                  <TextInput
                    type={'number'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                    name={'project_construction_price'}
                    disabled={!isEditable}
                  />
                </Col>
                <Col {...colPropsThreePerRow}>
                  <TextInput
                    type={'number'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                    name={'existing_investment_plot_price'}
                    disabled={!isEditable}
                  />
                </Col>
                <Col {...colPropsThreePerRow}>
                  <TextInput
                    type={'number'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                    name={'existing_investment_engineering_price'}
                    disabled={!isEditable}
                  />
                </Col>
                <Col {...colPropsThreePerRow}>
                  <TextInput
                    type={'number'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                    name={'existing_investment_documentation_price'}
                    disabled={!isEditable}
                  />
                </Col>
                <Col {...colPropsThreePerRow}>
                  <TextInput
                    type={'number'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                    name={'existing_investment_construction_price'}
                    disabled={!isEditable}
                  />
                </Col>
                <Col {...colPropsThreePerRow}>
                  <TextInput
                    type={'number'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                    name={'existing_investment_total'}
                    disabled={!isEditable}
                  />
                </Col>
                <Col {...colPropsThreePerRow}>
                  <TextInput
                    type={'number'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                    name={'existing_investment_own_funds'}
                    disabled={!isEditable}
                  />
                </Col>
                <Col {...colPropsThreePerRow}>
                  <TextInput
                    type={'number'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                    name={'existing_investment_lend_funds'}
                    disabled={!isEditable}
                  />
                </Col>
                <Col {...colPropsThreePerRow}>
                  <TextInput
                    type={'number'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                    name={'presale_contracts'}
                    disabled={!isEditable}
                  />
                </Col>
                <Col {...colPropsThreePerRow}>
                  <TextInput
                    type={'number'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                    name={'presale_contracts_amount'}
                    disabled={!isEditable}
                  />
                </Col>
                <Col {...colPropsThreePerRow}>
                  <SelectYesNo name={'building_permit'} isDisabled={!isEditable} />
                </Col>
                <hr />
                <h3>{t('prospect_applications.forms.assets_to_be_sold')}</h3>
                {request.assets_to_be_sold?.map((asset: any, index: number) => (
                  <>
                    <Col {...colPropsThreePerRow}>
                      <TextInput
                        type={'number'}
                        onKeyPress={UseNumbers.preventNonNumericalInput}
                        placeholder={t('label.real_estate_plot')}
                        name={'assets_to_be_sold.' + index + '.real_estate_plot'}
                        disabled={!isEditable}
                      />
                    </Col>
                    <Col {...colPropsThreePerRow}>
                      <label htmlFor="">{t('label.real_estate_type')}</label>
                      <SelectRealEstateTypeInput
                        name={'assets_to_be_sold.' + index + '.real_estate_type'}
                        isClearable={false}
                        isDisabled={!isEditable}
                        placeholder={t('label.real_estate_type')}
                        hideLabel={true}
                      />
                    </Col>
                    <Col {...colPropsThreePerRow}>
                      <TextInput
                        type={'number'}
                        onKeyPress={UseNumbers.preventNonNumericalInput}
                        placeholder={t('label.real_estate_count')}
                        name={'assets_to_be_sold.' + index + '.real_estate_count'}
                        disabled={!isEditable}
                      />
                    </Col>
                    <Col {...colPropsThreePerRow}>
                      <TextInput
                        type={'number'}
                        onKeyPress={UseNumbers.preventNonNumericalInput}
                        placeholder={t('label.real_estate_value')}
                        name={'assets_to_be_sold.' + index + '.real_estate_value'}
                        disabled={!isEditable}
                      />
                    </Col>
                    {index !== request.assets_to_be_sold.length - 1 && <hr />}
                  </>
                ))}
                <hr />
                <h3>{t('prospect_applications.forms.debt_section')}</h3>
                <Col {...colPropsThreePerRow}>
                  <TextInput
                    type={'number'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                    name={'debt_amount'}
                    disabled={!isEditable}
                  />
                </Col>
                <Col {...colPropsThreePerRow}>
                  <DatePickerInput
                    name={'debt_due_date'}
                    disabled={!isEditable}
                    matchFieldValueAndDisplayValue={true}
                  />
                </Col>
                <Col {...colPropsThreePerRow}>
                  <TextInput type={'debt_purpose'} name={'debt_purpose'} disabled={!isEditable} />
                </Col>
                <Col {...colPropsThreePerRow}>
                  <TextInput
                    type={'debt_security_deposit'}
                    name={'debt_security_deposit'}
                    disabled={!isEditable}
                  />
                </Col>
                <Col {...colPropsThreePerRow}>
                  <TextInput
                    type={'number'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                    name={'debt_security_deposit_value'}
                    disabled={!isEditable}
                  />
                </Col>
                <Col {...colPropsThreePerRow}>
                  <TextInput
                    type={'debt_refinancing_reason'}
                    name={'debt_refinancing_reason'}
                    disabled={!isEditable}
                  />
                </Col>
                <hr />
                <h3>{t('prospect_applications.forms.real_estate_section')}</h3>
                <Col {...colPropsThreePerRow}>
                  <TextInput
                    type={'number'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                    name={'re_price_without_vat'}
                    disabled={!isEditable}
                  />
                </Col>
                <Col {...colPropsThreePerRow}>
                  <TextInput
                    type={'number'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                    name={'re_price_vat'}
                    disabled={!isEditable}
                  />
                </Col>
                <Col {...colPropsThreePerRow}>
                  <TextInput
                    type={'re_seller_name'}
                    name={'re_seller_name'}
                    disabled={!isEditable}
                  />
                </Col>
                <Col {...colPropsThreePerRow}>
                  <TextInput type={'re_address'} name={'re_address'} disabled={!isEditable} />
                </Col>
                <Col {...colPropsThreePerRow}>
                  <TextInput type={'re_purpose'} name={'re_purpose'} disabled={!isEditable} />
                </Col>
                <Col {...colPropsThreePerRow}>
                  <TextInput
                    type={'number'}
                    name={'re_value'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                    disabled={!isEditable}
                  />
                </Col>
                <Col {...colPropsThreePerRow}>
                  <TextInput
                    type={'re_evaluated_by'}
                    name={'re_evaluated_by'}
                    disabled={!isEditable}
                  />
                </Col>
                <Col {...colPropsThreePerRow}>
                  <DatePickerInput
                    name={'re_evaluated_at'}
                    disabled={!isEditable}
                    matchFieldValueAndDisplayValue={true}
                  />
                </Col>
                <hr />{' '}
                <Col {...colPropsThreePerRow}>
                  <TextInput
                    type={'number'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                    name={'own_funds_amount'}
                    disabled={!isEditable}
                  />
                </Col>
                <Col {...colPropsThreePerRow}>
                  <TextInput
                    type={'own_funds_origin'}
                    name={'own_funds_origin'}
                    disabled={!isEditable}
                  />
                </Col>
                <Col {...colPropsThreePerRow}>
                  <TextInput
                    type={'text'}
                    name={'loan_details_working_capitals'}
                    disabled={!isEditable}
                  />
                </Col>
                <Col {...colPropsThreePerRow}>
                  <TextInput type={'text'} name={'loan_details_others'} disabled={!isEditable} />
                </Col>
              </Row>
              <Row>
                <div className="mt-4 mb-4">
                  <SaveButton
                    title={t('common.save')}
                    submitting={isSubmitting}
                    disabled={!isEditable}
                  />
                </div>
              </Row>
            </FormGroup>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default withProspectApplication(TabApplicationDetails);
