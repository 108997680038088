import React, { useCallback } from 'react';
import Api from 'src/api';
import SelectAsyncInput from './SelectAsyncInput';
import { ReactSelectOption } from './SelectInput';

interface Props {
  name: string;
  placeholder: string;
  onOptionChange: (hasMedia: boolean) => void;
}

interface FormatOptionLabel {
  value: any;
  label: string;
  customText: string;
}

export const formatOptionLabel = (data: unknown): JSX.Element => {
  return (
    <div className={'row'}>
      <div className={'col-auto'}>
        <span>
          <strong>{(data as FormatOptionLabel).label}</strong>
        </span>
      </div>
      <div className={'col-auto'}>
        <span>
          <p className={'p-0 m-0 text-muted'}>
            <em>{(data as FormatOptionLabel).customText}</em>
          </p>
        </span>
      </div>
    </div>
  );
};

const SelectBonusRulesInput: React.FC<Props> = ({ name, placeholder, onOptionChange }) => {
  const fetchBonusRules = useCallback(async () => {
    return (await Api.bonusSystem.fetchBonusRules()).map(
      (rule) =>
        ({
          value: String(rule.id),
          label: rule.title,
          has_media: rule.has_media,
          customText: rule.description,
        } as ReactSelectOption),
    );
  }, []);

  return (
    <SelectAsyncInput
      name={name}
      placeholder={placeholder}
      isMulti={true}
      isClearable={true}
      loadOptions={fetchBonusRules}
      formatOptionLabel={formatOptionLabel}
      onChange={(option: any) => {
        if (Array.isArray(option)) {
          const hasMedia = option.some((item) => item.has_media === true);
          onOptionChange(hasMedia);
        }
      }}
    />
  );
};

export default SelectBonusRulesInput;
