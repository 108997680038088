import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProjectContextState, withProject } from 'src/pages/Project/Update/ProjectContext';
import { Form, Formik, FormikHelpers } from 'formik';
import { Col, ColProps, FormGroup, Row } from 'reactstrap';
import { UpdateProjectOwnerRequestDto } from 'src/types/api/projects';
import TextInput from 'src/components/Form/TextInput';
import TextAreaInput from 'src/components/Form/TextAreaInput';
import WYSIWYGInput from 'src/components/Form/WYSIWYGInput';
import DatePickerInput from 'src/components/Form/DatePickerInput';
import LegalEntityView from 'src/components/Views/LegalEntityView';
import Api from 'src/api';
import { success } from 'src/services/toastr';
import { transformErrors } from 'src/helpers';
import SaveButton from 'src/components/Form/SaveButton';
import ActiveLocaleFlag from 'src/components/ActiveLocaleFlag';

const INITIAL_REQUEST: UpdateProjectOwnerRequestDto = {
  project_owner_activity: null,
  project_owner_legal_code: null,
  project_owner_address: null,
  project_owner_description: null,
  project_owner_established_at: null,
  project_owner_executive: null,
  project_owner_name: null,
};

const TabProjectOwner: React.FC<ProjectContextState> = ({
  project,
  setProject,
  language,
  onErrorHandling,
}) => {
  const { t } = useTranslation();

  const [request, setRequest] = useState<UpdateProjectOwnerRequestDto>(INITIAL_REQUEST);

  useEffect(() => {
    if (!project) return;

    setRequest(() => ({
      project_owner_activity: project.project_owner_activity,
      project_owner_address: project.project_owner_address,
      project_owner_legal_code: project.project_owner_legal_code,
      project_owner_description: project.project_owner_description,
      project_owner_established_at: project.project_owner_established_at,
      project_owner_executive: project.project_owner_executive,
      project_owner_name: project.project_owner_name,
    }));
  }, [project]);

  const onSubmit = useCallback(
    async (
      request: UpdateProjectOwnerRequestDto,
      helpers: FormikHelpers<UpdateProjectOwnerRequestDto>,
    ) => {
      if (!project) return;

      try {
        const response = await Api.projects.storeProjectOwner(project.id, request);
        setProject(response);
        success(t('common.updated_success'));
      } catch (e: any) {
        helpers.setErrors(transformErrors(e.response?.errors));
        onErrorHandling(e);
      }

      return true;
    },
    [onErrorHandling, project, setProject, t],
  );

  const colProps: ColProps = {
    md: 4,
    className: 'mb-4',
  };

  // Method used to generate common attributes by input name
  const multiLocaleAttrs = useCallback(
    (inputName: string) => {
      return {
        name: inputName + '.' + language,
        placeholder: t('label.' + inputName),
      };
    },
    [language, t],
  );

  return (
    <React.Fragment>
      <h3 className={'flex'}>
        {t('projects.project_owner')}
        <ActiveLocaleFlag locale={language} className={'tabs'} />
      </h3>
      <hr />
      <Formik initialValues={request} enableReinitialize={true} onSubmit={onSubmit}>
        {({ handleSubmit, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Row>
                <Col {...colProps} md={12}>
                  <p className={'mb-2'}>{t('label.legal_entity')}</p>
                  <strong>
                    <LegalEntityView entity={project?.project_owner_legal_entity} />
                  </strong>
                </Col>
                <Col {...colProps} md={6}>
                  <TextInput {...multiLocaleAttrs('project_owner_name')} />
                </Col>
                <Col {...colProps} md={6}>
                  <TextAreaInput {...multiLocaleAttrs('project_owner_legal_code')} />
                </Col>
                <Col {...colProps} md={12}>
                  <WYSIWYGInput {...multiLocaleAttrs('project_owner_description')} />
                </Col>

                <Col {...colProps}>
                  <TextInput {...multiLocaleAttrs('project_owner_address')} />
                </Col>

                <Col {...colProps}>
                  <TextInput {...multiLocaleAttrs('project_owner_activity')} />
                </Col>

                <Col {...colProps}>
                  <TextInput {...multiLocaleAttrs('project_owner_executive')} />
                </Col>

                <Col {...colProps}>
                  <DatePickerInput
                    {...multiLocaleAttrs('project_owner_established_at')}
                    matchFieldValueAndDisplayValue={true}
                  />
                </Col>

                <div className={'mt-4 mb-4'}>
                  <SaveButton title={t('common.save')} submitting={isSubmitting} />
                </div>
              </Row>
            </FormGroup>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default withProject(TabProjectOwner);
