import React from 'react';
import InvestmentsByTypeBarChart from 'src/pages/Common/Dashboard/Charts/InvestmentsByTypeBarChart';
import InvestmentsByTypePieChart from 'src/pages/Common/Dashboard/Charts/InvestmentsByTypePieChart';

const InvestmentsDashboardCharts = () => {
  return (
    <>
      <div className={'d-inline-flex justify-content-center w-100'}>
        <div className={'w-100 d-inline-flex'}>
          <InvestmentsByTypeBarChart />
        </div>
        <div className={'w-100 d-inline-flex'}>
          <InvestmentsByTypePieChart />
        </div>
      </div>
    </>
  );
};

export default InvestmentsDashboardCharts;
