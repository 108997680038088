import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'reactstrap';
import {
  DebtCollectionContextState,
  withDebtCollection,
} from 'src/pages/DebtCollection/Update/DebtCollectionContext';
import SidebarDataRow from 'src/components/DataBlocks/SidebarDataRow';
import CountryLabel from 'src/components/Labels/CountryLabel';
import Loader from 'src/components/Loader';

const TabProjectOwner: React.FC<DebtCollectionContextState> = ({ debtCollection }) => {
  const { t } = useTranslation();

  if (!debtCollection) {
    return <Loader />;
  }

  return (
    <React.Fragment>
      <h3>{t('applications.company')}</h3>
      <hr />
      <h5 className="mb-3">{t('applications.company_details')}</h5>
      <Row>
        <SidebarDataRow label={t('users.email')} value={debtCollection.owner.email} />
        <SidebarDataRow label={t('users.legal_code')} value={debtCollection.owner.legal_code} />
        <SidebarDataRow label={t('users.vat_code')} value={debtCollection.owner.vat_code} />
        <SidebarDataRow label={t('users.phone')} value={debtCollection.owner.phone} />
        <SidebarDataRow label={t('common.verified')} value={debtCollection.owner.verified} />
        <SidebarDataRow label={t('users.suspended_at')} value={debtCollection.owner.suspended_at} />
        <SidebarDataRow
          label={t('users.type')}
          value={t('common.type_' + debtCollection.owner.type)}
        />
        <SidebarDataRow
          label={t('users.country')}
          value={
            debtCollection.owner.country ? <CountryLabel code={debtCollection.owner.country} /> : ''
          }
        />
        <hr />
      </Row>
      <h5 className="mb-3">{t('applications.executive_details')}</h5>
      <Row>
        <SidebarDataRow
          label={t('users.first_name')}
          value={debtCollection.owner.executive_first_name}
        />
        <SidebarDataRow
          label={t('users.last_name')}
          value={debtCollection.owner.executive_last_name}
        />
        <SidebarDataRow
          label={t('users.house_number')}
          value={debtCollection.owner.executive_address_house_number}
        />
        <SidebarDataRow
          label={t('users.street')}
          value={debtCollection.owner.executive_address_street}
        />
        <SidebarDataRow
          label={t('users.city')}
          value={debtCollection.owner.executive_address_city}
        />
        <SidebarDataRow
          label={t('users.country')}
          value={
            debtCollection.owner.executive_address_country ? (
              <CountryLabel code={debtCollection.owner.executive_address_country} />
            ) : (
              ''
            )
          }
        />
        <SidebarDataRow label={t('users.phone')} value={debtCollection.owner.executive_phone} />
        <SidebarDataRow label={t('users.email')} value={debtCollection.owner.executive_email} />
        <SidebarDataRow
          label={t('users.gov_code')}
          value={debtCollection.owner.executive_gov_code}
        />
        <SidebarDataRow
          label={t('users.gov_document.type')}
          value={t(
            debtCollection.owner.executive_gov_document_type
              ? 'users.document_type.' +
                  debtCollection.owner.executive_gov_document_type?.toUpperCase()
              : '',
          )}
        />
        <SidebarDataRow
          label={t('users.gov_document.number')}
          value={debtCollection.owner.executive_gov_document_number}
        />
        <SidebarDataRow
          label={t('users.gov_document.issuer_country')}
          value={
            debtCollection.owner.executive_gov_document_issue_country ? (
              <CountryLabel code={debtCollection.owner.executive_gov_document_issue_country} />
            ) : (
              ''
            )
          }
        />
        <SidebarDataRow
          label={t('users.gov_document.expiration_date')}
          value={t('common.date', {
            date: debtCollection.owner.executive_gov_document_expiration_date,
          })}
        />
      </Row>
    </React.Fragment>
  );
};

export default withDebtCollection(TabProjectOwner);
