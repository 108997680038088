import React from 'react';
import DeleteEntityButton from 'src/components/Table/Buttons/DeleteEntityButton';
import { EditableField, EditableFieldData } from './Table/Fields/EditableFied';
import { useTranslation } from 'react-i18next';
import { StageHistoryTypeEnum } from 'src/helpers/Enums/Debt_collection/StageHistoryTypeEnum';
import StageHistoryType from 'src/components/DebtCollection/StageHistoryType';

interface ActivityFeedItemData {
  id: number | string;
  date: Date;
  author: string;
  message: string;
  type?: StageHistoryTypeEnum;
}

interface Props {
  data: ActivityFeedItemData;
  onDelete?: () => Promise<any>;
  onUpdate?: (fieldData: EditableFieldData) => Promise<any>;
  permissions?: string | string[];
}

const ActivityFeedItem: React.FC<Props> = ({ data, onDelete, onUpdate, permissions }) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <li className="feed-item pb-0">
        <div className="feed-item-list">
          <div className="row">
            <p className="text-muted mb-0 font-size-13 col-10">
              <span className="text-primary mr-2"> {data.author}</span>
              &nbsp;
              {t('common.date_full', { date: data.date })}
              {!!data.type && (
                <>
                  &nbsp;
                  <StageHistoryType value={data.type} />
                </>
              )}
            </p>
            {onDelete && (
              <div className="d-flex justify-content-end gap-3 col-2">
                <DeleteEntityButton permissions={permissions} onDelete={onDelete} />
              </div>
            )}
          </div>
          <div className="mt-0 mb-0">
            {onUpdate ? (
              <EditableField
                permissions={permissions}
                data={{ id: data.id }}
                text={data.message}
                submit={onUpdate}
              />
            ) : (
              <p style={{ whiteSpace: 'pre-wrap' }}>{data.message}</p>
            )}
          </div>
        </div>
      </li>
    </React.Fragment>
  );
};

export default ActivityFeedItem;
