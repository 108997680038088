import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Cell, Row } from 'react-table';
import { Card, CardBody, Container } from 'reactstrap';
import { generatePath, useLocation } from 'react-router-dom';
import { RouteList } from 'src/routes';

import Api from 'src/api';
import { Permissions } from 'src/helpers/auth/permissions';
import { PaginationData, PaginationDataFilter } from 'src/types';
import { setGlobalLoading } from 'src/modules/app/actions';
import { HiddenColumnsProvider } from 'src/components/ShowTableColumnsDropdown';
import Table, { Column } from 'src/components/Table';
import { BorrowerPayoutListResponseDto } from 'src/types/api/payments/borrowerPayouts';
import BorrowerPayoutType from 'src/components/Projects/BorrowerPayoutType';
import BorrowerPayoutStatus from 'src/components/Projects/BorrowerPayoutStatus';
import ViewEntityButton from 'src/components/Table/Buttons/ViewEntityButton';
import DateFilter from 'src/components/Table/AdvanceFilters/DateFilter';
import { getEnumFilterSelectValues } from 'src/helpers/Enums/enumHelper';
import { BorrowerPayoutStatusEnum } from 'src/helpers/Enums/Payments/BorrowerPayoutStatusEnum';
import { BorrowerPayoutTypeEnum } from 'src/helpers/Enums/Payments/BorrowerPayoutTypeEnum';
import BorrowerPayoutDropdown from './BorrowerPayoutDropdown';
import { BorrowerPayoutRouteStatusMap } from 'src/helpers/maps/PaymentMaps';
import ColumnMultiSelectFilter from 'src/components/Table/Filters/ColumnMultiSelectFilter';

const INITIAL_SORT_ORDER = [
  {
    id: 'received_at',
    desc: true,
  },
];

const BorrowerPayoutsList: React.FC = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<PaginationData<BorrowerPayoutListResponseDto>>();
  const [statusScheduledEnabled, setStatusScheduledEnabled] = useState<boolean>(false);
  const { pathname } = useLocation();

  let INITIAL_FILTERS: any[] = [];

  const filter = BorrowerPayoutRouteStatusMap[pathname];

  if (filter) {
    INITIAL_FILTERS = [
      {
        id: 'status',
        value: filter,
      },
    ];
  }

  useEffect(() => {
    setGlobalLoading(false);
  }, []);

  const columns = useMemo<Column<BorrowerPayoutListResponseDto>[] | any>(
    () => [
      {
        Header: t('table.id'),
        accessor: 'id',
        width: 200,
        disableSortBy: true,
      },
      {
        Header: t('table.status'),
        accessor: 'status',
        sortType: 'string',
        width: 250,
        Cell: (cell: Cell<BorrowerPayoutListResponseDto>) => (
          <BorrowerPayoutStatus value={cell.value} />
        ),
        Filter: ColumnMultiSelectFilter,
        filterProps: {
          options: getEnumFilterSelectValues(
            BorrowerPayoutStatusEnum,
            'payments.borrower_payouts.status',
          ),
        },
      },
      {
        Header: t('table.project_pid'),
        accessor: 'project_pid',
        sortType: 'string',
        width: 150,
      },
      {
        Header: t('table.owner_pid'),
        accessor: 'project_owner_pid',
        sortType: 'string',
        width: 150,
      },
      {
        Header: t('table.owner_name'),
        accessor: 'project_owner_name',
        sortType: 'string',
      },
      {
        Header: t('table.manager_name'),
        accessor: 'manager_name',
        sortType: 'string',
        disableSortBy: true,
        width: 250,
        Filter: ColumnMultiSelectFilter,
        filterProps: {
          request: Api.user.fetchManagersOptions(),
        },
      },
      statusScheduledEnabled
        ? {
            Header: t('table.email'),
            accessor: 'project_owner_email',
            disableSortBy: true,
          }
        : undefined,
      statusScheduledEnabled
        ? {
            Header: t('table.phone'),
            accessor: 'project_owner_phone',
            disableSortBy: true,
          }
        : undefined,
      {
        Header: t('table.project_name'),
        accessor: 'project_name',
        sortType: 'string',
      },
      {
        Header: t('table.security_measures'),
        accessor: 'security_measures',
        sortType: 'string',
        Cell: (cell: Cell<BorrowerPayoutListResponseDto>) => (
          <div>{cell.value ? t('projects.security_measure.' + cell.value) : null}</div>
        ),
      },
      {
        Header: t('table.type'),
        accessor: 'type',
        width: 250,
        Cell: (cell: Cell<BorrowerPayoutListResponseDto>) => (
          <BorrowerPayoutType value={cell.value} />
        ),
        Filter: ColumnMultiSelectFilter,
        filterProps: {
          options: getEnumFilterSelectValues(
            BorrowerPayoutTypeEnum,
            'payments.borrower_payouts.type',
          ),
        },
      },
      {
        Header: t('table.fine_on_pre_return'),
        accessor: 'fine_on_pre_return',
        sortType: 'string',
        Cell: (cell: Cell<BorrowerPayoutListResponseDto>) => (
          <div>{cell.value !== null ? t('common.money', { value: cell.value }) : '-'}</div>
        ),
      },
      {
        Header: t('table.debt_fee'),
        accessor: 'debt_fee',
        sortType: 'string',
        Cell: (cell: Cell<BorrowerPayoutListResponseDto>) => (
          <div>{cell.value !== null ? t('common.money', { value: cell.value }) : '-'}</div>
        ),
      },
      {
        Header: t('table.standard_interest'),
        accessor: 'total_basic_interest',
        sortType: 'string',
        Cell: (cell: Cell<BorrowerPayoutListResponseDto>) => (
          <div>{cell.value !== null ? t('common.money', { value: cell.value }) : '-'}</div>
        ),
      },
      {
        Header: t('table.additional_interest_investment'),
        accessor: 'additional_interest_investment',
        sortType: 'string',
        Cell: (cell: Cell<BorrowerPayoutListResponseDto>) => (
          <div>{cell.value !== null ? t('common.money', { value: cell.value }) : '-'}</div>
        ),
      },
      {
        Header: t('table.additional_interest_profitus'),
        accessor: 'additional_interest_profitus',
        sortType: 'string',
        Cell: (cell: Cell<BorrowerPayoutListResponseDto>) => (
          <div>{cell.value !== null ? t('common.money', { value: cell.value }) : '-'}</div>
        ),
      },
      {
        Header: t('table.loan_repayment'),
        accessor: 'loan_repayment',
        sortType: 'string',
        Cell: (cell: Cell<BorrowerPayoutListResponseDto>) => (
          <div>{cell.value !== null ? t('common.money', { value: cell.value }) : '-'}</div>
        ),
      },
      {
        Header: t('table.borrower_total'),
        accessor: 'borrower_total',
        sortType: 'string',
        Cell: (cell: Cell<BorrowerPayoutListResponseDto>) => (
          <div>{cell.value !== null ? t('common.money', { value: cell.value }) : '-'}</div>
        ),
      },
      {
        Header: t('table.profitus_total'),
        accessor: 'profitus_total',
        sortType: 'string',
        Cell: (cell: Cell<BorrowerPayoutListResponseDto>) => (
          <div>{cell.value !== null ? t('common.money', { value: cell.value }) : '-'}</div>
        ),
      },
      {
        Header: t('table.created_at'),
        accessor: 'created_at',
        sortType: 'string',
        Cell: (cell: Cell<BorrowerPayoutListResponseDto>) => (
          <div>{t('common.date_full', { date: cell.value })}</div>
        ),
        Filter: DateFilter,
      },
      {
        Header: t('table.payment_date'),
        accessor: 'payment_date',
        sortType: 'string',
        Cell: (cell: Cell<BorrowerPayoutListResponseDto>) => (
          <div>{t('common.date', { date: cell.value })}</div>
        ),
      },
      {
        Header: t('table.received_at'),
        accessor: 'received_at',
        sortType: 'string',
        Cell: (cell: Cell<BorrowerPayoutListResponseDto>) => (
          <div>{t('common.date_full', { date: cell.value })}</div>
        ),
      },
      {
        Header: t('table.paid_at'),
        accessor: 'paid_at',
        sortType: 'string',
        Cell: (cell: Cell<BorrowerPayoutListResponseDto>) => (
          <div>{t('common.date_full', { date: cell.value })}</div>
        ),
      },
      {
        Header: t('table.action'),
        width: 75,
        Cell: (cell: Cell<BorrowerPayoutListResponseDto>) => {
          return (
            <div className={'actions d-flex gap-2'}>
              <ViewEntityButton
                permissions={Permissions.BO__PAYMENTS__BORROWER_PAYOUTS__VIEW}
                route={{
                  pathname: generatePath(RouteList.PAYMENTS.BORROWER_PAYOUTS.VIEW, {
                    borrowerPayoutId: cell.row.original.id,
                    status: BorrowerPayoutRouteStatusMap[location.pathname] ?? 'all',
                  }),
                  state: { prevPath: location.pathname },
                }}
              />
            </div>
          );
        },
      },
    ],
    [t, statusScheduledEnabled],
  );

  const fetchData = useCallback(async (request: PaginationDataFilter | undefined) => {
    if (
      request?.filters?.find((item) => item.id === 'status')?.value ===
      BorrowerPayoutStatusEnum.SCHEDULED
    ) {
      setStatusScheduledEnabled(true);
    } else {
      setStatusScheduledEnabled(false);
    }

    return Api.payments.borrowerPayouts
      .fetchFilteredPayouts(request)
      .then((response) => setData(response));
  }, []);

  const rowProps = (row: Row<BorrowerPayoutListResponseDto>) => ({
    className: row.original.has_failed_payouts ? 'table-red' : '',
  });

  return (
    <React.Fragment>
      <HiddenColumnsProvider title={'BorrowerPayoutListIndex'}>
        <Container fluid>
          <Card>
            <CardBody>
              <div className={'d-flex align-items-center mb-4'}>
                <h4 className={'m-0'}>{t('menu.payments.borrower_payouts')}</h4>
                <BorrowerPayoutDropdown className={'ms-auto'} />
              </div>
              <Table
                title={'BorrowerPayoutListIndex'}
                onFetchData={fetchData}
                columns={columns.filter((item: any) => item)}
                data={data}
                searchable={true}
                enableQueryFilter={true}
                initialSortBy={INITIAL_SORT_ORDER}
                initialFilters={INITIAL_FILTERS}
                disableFiltersOutsideTable={true}
                rowProps={rowProps}
                striped={false}
              />
            </CardBody>
          </Card>
        </Container>
      </HiddenColumnsProvider>
    </React.Fragment>
  );
};

export default BorrowerPayoutsList;
