import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PaginationData, PaginationDataFilter } from 'src/types';
import { FrozenDeveloperAmountsResponseDto } from 'src/types/api/payments/frozenDeveloperAmounts';
import { Cell, Column } from 'react-table';
import Api from 'src/api';
import { setGlobalLoading } from 'src/modules/app/actions';
import { HiddenColumnsProvider } from 'src/components/ShowTableColumnsDropdown';
import { Card, CardBody, Container } from 'reactstrap';
import Table from 'src/components/Table';
import { Permissions } from 'src/helpers/auth/permissions';
import { generatePath } from 'react-router-dom';
import { RouteList } from 'src/routes';
import EditEntityButton from 'src/components/Table/Buttons/EditEntityButton';
import { getEnumFilterSelectValues } from 'src/helpers/Enums/enumHelper';
import { FrozenDeveloperPayoutsFilterEnum } from 'src/helpers/Enums/Payments/FrozenDeveloperPayoutsEnum';
import ColumnFilter from 'src/components/Table/Filters/ColumnFilter';

const INITIAL_SORT_ORDER = [
  {
    id: 'frozen_at',
    desc: true,
  },
];

const FrozenDeveloperAmountsList = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<PaginationData<FrozenDeveloperAmountsResponseDto>>();

  const columns = useMemo<Column<FrozenDeveloperAmountsResponseDto>[] | any>(
    () => [
      {
        Header: t('table.pid'),
        accessor: 'project_pid',
        sortType: 'string',
      },
      {
        Header: t('table.name'),
        accessor: 'project_name',
        sortType: 'string',
      },
      {
        Header: t('table.owner_pid'),
        accessor: 'project_owner_pid',
        sortType: 'string',
      },
      {
        Header: t('table.owner_name'),
        accessor: 'project_owner_name',
        sortType: 'string',
      },
      {
        Header: t('table.amount'),
        accessor: 'frozen_amount',
        sortType: 'number',
        Cell: (cell: Cell<FrozenDeveloperAmountsResponseDto>) => (
          <span>{t('common.money', { value: cell.value })}</span>
        ),
      },
      {
        Header: t('table.frozen_at'),
        accessor: 'frozen_at',
        sortType: 'datetime',
        width: 150,
        Cell: (cell: Cell<FrozenDeveloperAmountsResponseDto>) => (
          <span>{t('common.date', { date: cell.value })}</span>
        ),
      },
      {
        Header: t('table.released_at'),
        accessor: 'released_at',
        sortType: 'datetime',
        Filter: ColumnFilter,
        filterProps: {
          options: getEnumFilterSelectValues(
            FrozenDeveloperPayoutsFilterEnum,
            'payments.frozen_developer_payouts',
          ),
        },
        width: 150,
        Cell: (cell: Cell<FrozenDeveloperAmountsResponseDto>) => (
          <span>{t('common.date', { date: cell.value })}</span>
        ),
      },
      {
        Header: t('table.action'),
        disableSortBy: true,
        Cell: (cell: Cell<FrozenDeveloperAmountsResponseDto>) => {
          const row = cell.row.original;
          return (
            <div className={'actions d-flex gap-2'}>
              <EditEntityButton
                permissions={Permissions.BO__PAYMENTS__PROJECT_PAYOUTS__VIEW}
                route={generatePath(RouteList.PAYMENTS.PROJECT_PAYOUTS.VIEW, {
                  projectId: row.project_id,
                })}
              />
            </div>
          );
        },
      },
    ],
    [t],
  );

  const fetchData = useCallback(async (request: PaginationDataFilter | undefined) => {
    const response = await Api.payments.frozenDeveloperPayouts.fetchFrozenDeveloperPayouts(request);
    return setData(response);
  }, []);

  useEffect(() => {
    setGlobalLoading(false);
  }, []);

  return (
    <React.Fragment>
      <HiddenColumnsProvider title={'FrozenDeveloperPayoutListIndex'}>
        <Container fluid>
          <Card>
            <CardBody>
              <h4 className={'mb-4'}>{t('menu.payments.frozen_developer_payouts')}</h4>
              <Table
                title={'FrozenDeveloperPayoutListIndex'}
                onFetchData={fetchData}
                columns={columns}
                data={data}
                searchable={true}
                enableQueryFilter={true}
                initialSortBy={INITIAL_SORT_ORDER}
                disableFiltersOutsideTable={true}
              />
            </CardBody>
          </Card>
        </Container>
      </HiddenColumnsProvider>
    </React.Fragment>
  );
};

export default FrozenDeveloperAmountsList;
