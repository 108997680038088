import React, { useState } from 'react';
import { StateManagerProps } from 'react-select/dist/declarations/src/stateManager';
import { useTranslation } from 'react-i18next';
import { ReactSelectOption } from 'src/components/Form/Select/SelectInput';
import { SelectInput } from 'src/components/Form/Select';
import { RCDataTypeEnum } from 'src/helpers/Enums/RC/RCDataTypeEnum';

interface Props extends StateManagerProps {
  name: string;
  placeholder: string;
}

const DataDetailsInput: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const [options] = useState<ReactSelectOption[]>([
    { value: RCDataTypeEnum.PARTIAL, label: t('rc.partial') },
    {
      value: RCDataTypeEnum.FULL,
      label: t('rc.full'),
    },
  ]);

  return <SelectInput {...props} options={options} />;
};

export default DataDetailsInput;
