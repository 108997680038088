import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import { ModalComponent } from 'src/components/Modal/ModalComponent';
import { Form, FormGroup, ModalBody, ModalHeader } from 'reactstrap';
import ModalCloseButton from 'src/components/Modal/ModalCloseButton';
import { AmlIssuesLogListResponseDto, AmlIssueStatus } from 'src/types/api/amlManagement';
import { Formik } from 'formik';
import TextAreaInput from 'src/components/Form/TextAreaInput';
import * as Yup from 'yup';
import PrimaryButton from 'src/components/Form/PrimaryButton';
import Api from 'src/api';
import { success } from 'src/services/toastr';

interface Props {
  issue: AmlIssuesLogListResponseDto;
  handleUpdatedIssue: (updatedIssue: AmlIssuesLogListResponseDto) => void;
}

interface FormInterface {
  lawyer_comment: string | null;
}

const EditAmlIssueModal: React.FC<Props> = ({ issue, handleUpdatedIssue }) => {
  const { t } = useTranslation();
  const { hideModal } = useGlobalModalContext();
  const [newStatus, setNewStatus] = useState<AmlIssueStatus>(AmlIssueStatus.NEW);

  const EditAmlIssueSchema = Yup.object().shape({
    lawyer_comment: Yup.string().nullable(),
  });

  const onSubmit = (request: FormInterface) => {
    Api.amlManagement
      .changeStatus(issue.id, {
        lawyer_comment: request.lawyer_comment,
        new_status: newStatus,
      })
      .then((response) => {
        success(t('common.updated_success'));
        handleUpdatedIssue(response);
        hideModal();
      });
  };

  return (
    <React.Fragment>
      <ModalComponent size={'md'}>
        <div>
          <ModalHeader toggle={hideModal} close={<ModalCloseButton />}>
            {t('table.edit_aml_issue')}
          </ModalHeader>
          <ModalBody>
            <Formik
              initialValues={{
                lawyer_comment: issue.lawyer_comment,
              }}
              enableReinitialize={true}
              validationSchema={EditAmlIssueSchema}
              onSubmit={onSubmit}
            >
              {({
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
                <Form onSubmit={handleSubmit}>
                  <FormGroup>
                    <div className={'mb-3'}>
                      <TextAreaInput name={'lawyer_comment'} />
                    </div>
                    <div className={'mt-4 mb-4'}>
                      {issue.status === AmlIssueStatus.NEW && (
                        <PrimaryButton
                          className={'btn btn-danger col-md-12'}
                          title={t('common.mark_as_in_progress')}
                          onClick={() => setNewStatus(AmlIssueStatus.IN_PROGRESS)}
                          submitting={isSubmitting}
                        />
                      )}
                      {issue.status === AmlIssueStatus.IN_PROGRESS && (
                        <div className={'d-flex justify-content-center'}>
                          <PrimaryButton
                            className={'btn btn-danger col-md-6 m-1'}
                            title={t('common.mark_as_resolved_negative')}
                            onClick={() => setNewStatus(AmlIssueStatus.RESOLVED_NEGATIVE)}
                            submitting={isSubmitting}
                          />
                          <PrimaryButton
                            className={'btn btn-success col-md-6 m-1'}
                            title={t('common.mark_as_resolved_positive')}
                            onClick={() => setNewStatus(AmlIssueStatus.RESOLVED_POSITIVE)}
                            submitting={isSubmitting}
                          />
                        </div>
                      )}
                      {(issue.status === AmlIssueStatus.RESOLVED_POSITIVE ||
                        issue.status === AmlIssueStatus.RESOLVED_NEGATIVE) && (
                        <>
                          <PrimaryButton
                            className={'btn btn-danger col-md-12'}
                            title={t('common.save')}
                            onClick={() => setNewStatus(issue.status)}
                            submitting={isSubmitting}
                          />
                        </>
                      )}
                    </div>
                  </FormGroup>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </div>
      </ModalComponent>
    </React.Fragment>
  );
};

export default EditAmlIssueModal;
