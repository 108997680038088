import React from 'react';
import { useTranslation } from 'react-i18next';
import { UserCompanyKybDataV2BeneficiaryResponseDto } from 'src/types/api/userCompanies';
import SidebarDataRow from 'src/components/DataBlocks/SidebarDataRow';

interface Props {
  beneficiary: UserCompanyKybDataV2BeneficiaryResponseDto;
}
const BeneficiaryV2Block: React.FC<Props> = ({ beneficiary }) => {
  const { t } = useTranslation();

  return (
    <>
      <SidebarDataRow label={t('label.full_name')} value={beneficiary.full_name} />
      <SidebarDataRow label={t('label.gov_code')} value={beneficiary.gov_code} />
      <SidebarDataRow
        label={t('label.nationality')}
        value={t(`countries.${beneficiary.nationality}`)}
      />
      <SidebarDataRow
        label={t('label.company.tax_residency_country')}
        value={t(`countries.${beneficiary.tax_residency_country}`)}
      />
      <SidebarDataRow label={t('label.company.shares')} value={beneficiary.shares_direct} />
      <SidebarDataRow label={t('label.company.shares')} value={beneficiary.shares_not_direct} />
      <SidebarDataRow
        label={t('label.company.is_public_official')}
        value={beneficiary.is_public_official ? t('common.yes') : t('common.no')}
      />
      <SidebarDataRow
        label={t('label.nationality')}
        value={t(`countries.${beneficiary.nationality}`)}
      />
    </>
  );
};

export default BeneficiaryV2Block;
