import React from 'react';
import {
  DebtCollectionContextState,
  withDebtCollection,
} from 'src/pages/DebtCollection/Update/DebtCollectionContext';
import Loader from 'src/components/Loader';
import { HiddenColumnsProvider } from 'src/components/ShowTableColumnsDropdown';
import { Container } from 'reactstrap';
import Comments from 'src/pages/DebtCollection/Update/Tabs/Actions/Comments';
import ProjectEventUpdate from 'src/pages/DebtCollection/Update/Tabs/Actions/ProjectEventUpdate';
import { useTranslation } from 'react-i18next';
import RealEstateDeveloperNoticeDates from 'src/pages/DebtCollection/Update/Tabs/Actions/RealEstateDeveloperNoticeDates';

const TabActions: React.FC<DebtCollectionContextState> = ({ debtCollection }) => {
  const { t } = useTranslation();

  if (!debtCollection) {
    return <Loader />;
  }

  return (
    <React.Fragment>
      <HiddenColumnsProvider title={'DebtCollectionActionsIndex'}>
        <Container fluid>
          <h3>{t('debt_collection.actions')}</h3>
          <hr />
          <RealEstateDeveloperNoticeDates />
          <hr />
          <ProjectEventUpdate />
          <hr />
          <Comments />
        </Container>
      </HiddenColumnsProvider>
    </React.Fragment>
  );
};

export default withDebtCollection(TabActions);
