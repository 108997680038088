import React, { useCallback, useEffect, useState } from 'react';
import { Link, RouteComponentProps, useParams, withRouter } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { StaticContext } from 'react-router';

import Api from 'src/api';
import { RouteList } from 'src/routes';
import { Permissions } from 'src/helpers/auth/permissions';
import TabInvestmentPayouts from 'src/pages/Payment/BorrowerPayouts/Update/Tabs/TabInvestmentPayouts';
import TabInformation from 'src/pages/Payment/BorrowerPayouts/Update/Tabs/TabInformation';
import { setGlobalLoading } from 'src/modules/app/actions';
import TabData, { BaseTabProps, TabInfo } from 'src/components/Tabs';
import { BorrowerPayoutResponseDto } from 'src/types/api/payments/borrowerPayouts';
import BorrowerPayoutProvider from './BorrowerPayoutContext';
import BorrowerPayoutStatus from 'src/components/Projects/BorrowerPayoutStatus';
import BorrowerPayoutType from 'src/components/Projects/BorrowerPayoutType';
import PrimaryButton from 'src/components/Form/PrimaryButton';
import WalletProviderBadge from 'src/components/Badges/WalletProviderBadge';
import { BorrowerPayoutActions } from 'src/pages/Payment/BorrowerPayouts/Update/BorrowerPayoutActionsDropdown';
import Loader from 'src/components/Loader';
import usePusherStoreState from 'src/modules/pusheStoreState';
import VerifiedBadge from 'src/pages/User/Badges/VerifiedBadge';
import { WalletStatusEnum } from 'src/helpers/Enums/Payments/WalletStatusEnum';

const Tabs: TabInfo<BaseTabProps>[] = [
  {
    name: 'payments.borrower_payouts.information',
    iconClass: 'fas fa-money-check',
    component: TabInformation,
    permission: [Permissions.BO__PAYMENTS__BORROWER_PAYOUTS__VIEW],
  },
  {
    name: 'payments.borrower_payouts.investment_payouts',
    iconClass: 'fas fa-euro-sign',
    component: TabInvestmentPayouts,
    permission: [Permissions.BO__PAYMENTS__BORROWER_PAYOUTS__INVESTMENT_PAYOUTS__LIST],
  },
];

interface LocationProps {
  prevPath?: string;
}

const BorrowerPayout: React.FC<RouteComponentProps<object, StaticContext, LocationProps>> = ({
  location,
}) => {
  const { t } = useTranslation();
  const { borrowerPayoutId } = useParams<{ borrowerPayoutId: string }>();
  const [borrowerPayout, setBorrowerPayout] = useState<BorrowerPayoutResponseDto>();
  const { borrowerPayoutRecalculateLoading } = usePusherStoreState();

  const fetchData = useCallback(async () => {
    setGlobalLoading(true);
    if (borrowerPayoutId) {
      return Api.payments.borrowerPayouts.fetchBorrowerPayout(borrowerPayoutId).then((response) => {
        setBorrowerPayout(response);
        setGlobalLoading(false);
      });
    }
  }, [borrowerPayoutId]);

  useEffect(() => {
    fetchData();
  }, [borrowerPayoutId, fetchData]);

  if (!borrowerPayout) {
    return <></>;
  }

  return (
    <React.Fragment>
      <BorrowerPayoutProvider borrowerPayout={borrowerPayout} setBorrowerPayout={setBorrowerPayout}>
        <Container fluid>
          <Row>
            <Col md={1} className={'mb-4'}>
              <div>
                <Link to={location.state?.prevPath ?? RouteList.PAYMENTS.BORROWER_PAYOUTS.LIST.ALL}>
                  <PrimaryButton title={t('common.back')} type={'button'} />
                </Link>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs="12" className={'mb-4'}>
              {borrowerPayout.has_issues_with_investments ? (
                <div className="alert alert-danger">
                  {t('payments.borrower_payouts.issues_with_investments')}
                </div>
              ) : null}
              <Card className="card h-100">
                <CardBody>
                  <Row>
                    <Col md={2}>
                      <div>{t('payments.borrower_payouts.borrower_payout_id')}</div>
                      <div>{borrowerPayout?.id}</div>
                    </Col>
                    <Col md={2}>
                      <div>{t('payments.borrower_payouts.owner')}</div>
                      <div>
                        <span className={'fw-bold'}>{borrowerPayout.project_owner_pid}</span>&nbsp;
                        {borrowerPayout.project_owner_name}
                      </div>
                    </Col>
                    <Col md={2}>
                      <div>{t('payments.borrower_payouts.manager_name')}</div>
                      <div>{borrowerPayout.manager_name ?? '-'}</div>
                    </Col>
                    <Col md={2}>
                      <div>{t('payments.borrower_payouts.project')}</div>
                      <div>
                        <span className={'fw-bold'}>{borrowerPayout.project_pid}</span>&nbsp;
                        {borrowerPayout.project_name}
                      </div>
                    </Col>
                    <Col md={2}>
                      <div>{t('payments.borrower_payouts.security_measures')}</div>
                      <div>
                        {borrowerPayout.security_measures
                          ? t('projects.security_measure.' + borrowerPayout.security_measures)
                          : '-'}
                      </div>
                    </Col>
                    <Col md={2}>
                      <div>{t('payments.borrower_payouts.type')}</div>
                      <div>
                        <BorrowerPayoutType value={borrowerPayout.type} />
                      </div>
                    </Col>

                    <Col className={'text-end'}>
                      <BorrowerPayoutActions />
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col md={2}>
                      <div>{t('label.owner_wallet_provider')}</div>
                      <div className="d-flex align-items-center gap-1">
                        <WalletProviderBadge
                          value={borrowerPayout.owner_wallet?.provider ?? null}
                        />
                        <VerifiedBadge
                          verified={
                            borrowerPayout.owner_wallet?.status === WalletStatusEnum.VERIFIED
                          }
                        />
                      </div>
                    </Col>
                    <Col md={2}>
                      <div>{t('label.owner_wallet_balance')}</div>
                      <div>
                        {t('common.money', { value: borrowerPayout.owner_wallet?.balance })}
                      </div>
                    </Col>
                    <Col md={2}>
                      <div>{t('label.project_wallet_provider')}</div>
                      <div className="d-flex align-items-center gap-1">
                        <WalletProviderBadge
                          value={borrowerPayout.project_wallet?.provider ?? null}
                        />
                        <VerifiedBadge
                          verified={
                            borrowerPayout.project_wallet?.status === WalletStatusEnum.VERIFIED ||
                            borrowerPayout.project_wallet?.status === WalletStatusEnum.TECHNICAL
                          }
                        />
                      </div>
                    </Col>
                    <Col md={2}>
                      <div>{t('label.project_wallet_balance')}</div>
                      <div>
                        {t('common.money', { value: borrowerPayout.project_wallet?.balance })}
                      </div>
                    </Col>
                    <Col md={2}>
                      <div>{t('label.project_wallet_balance')}</div>
                      <div>
                        <BorrowerPayoutStatus value={borrowerPayout.status} />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col xs="12">
              <Card className="mb-4">
                {borrowerPayoutRecalculateLoading ? (
                  <div className="d-flex justify-content-center align-items-center mt-5 mb-5">
                    <Loader />
                    <span className="ml-5">{t('payments.borrower_payouts.recalculating')}</span>
                  </div>
                ) : (
                  <CardBody>{borrowerPayout && <TabData tabs={Tabs} vertical={true} />}</CardBody>
                )}
              </Card>
            </Col>
          </Row>
        </Container>
      </BorrowerPayoutProvider>
    </React.Fragment>
  );
};

export default withRouter(BorrowerPayout);
