import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Col, FormGroup, Row } from 'reactstrap';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import Api from 'src/api';
import useReportTypeStore from 'src/pages/Reports/View/ReportTypeStore';
import usePermissions from 'src/helpers/usePermissions';
import { mapManagePermission, ReportTypeEnum } from 'src/helpers/Enums/ReportTypeEnum';
import { success } from 'src/services/toastr';
import TextInput from 'src/components/Form/TextInput';
import SaveButton from 'src/components/Form/SaveButton';
import DatePickerInput from 'src/components/Form/DatePickerInput';
import { GenerateReportRequestDto, ProjectPaymentScheduleFilterForm } from 'src/types/api/reports';
import { appendDateFilter, appendFilter, initialRequest } from 'src/pages/Reports/View/helpers';
import SelectColumnsInput from 'src/pages/Reports/View/Filters/Selects/SelectColumnsInput';
import { SelectInput } from 'src/components/Form/Select';
import { getEnumSelectValues } from 'src/helpers/Enums/enumHelper';
import { BorrowerPayoutStatusEnum } from 'src/helpers/Enums/Payments/BorrowerPayoutStatusEnum';
import { ReactSelectOption } from 'src/components/Form/Select/SelectInput';
import { FilterSelectValuesResponseDto } from 'src/types/api/common';

const AVAILABLE_COLUMNS: string[] = [
  'id',
  'manager',
  'developer',
  'project',
  'type',
  'status',
  'loan_amount',
  'interest_amount',
  'additional_interest_amount',
  'fine_on_pre_return',
  'debt_fee',
  'total_amount',
  'remaining_amount',
  'interest_amount_left',
  'interest_end_date',
  'payment_date',
  'paid_at',
];

const TYPE: ReportTypeEnum = ReportTypeEnum.PROJECT_PAYMENT_SCHEDULE;

const ProjectPaymentScheduleReportFilter: React.FC = () => {
  const { t } = useTranslation();
  const p = usePermissions();
  const { refreshTable } = useReportTypeStore();

  const [managerOptions, setManagerOptions] = useState<ReactSelectOption[]>([]);

  useEffect(() => {
    Api.user.fetchManagersOptions().then((res) => {
      setManagerOptions(
        res.map((manager: FilterSelectValuesResponseDto) => ({
          value: manager.value,
          label: manager.key,
        })),
      );
    });
  }, []);

  const [request] = useState<ProjectPaymentScheduleFilterForm>({
    name: null,
    columns: AVAILABLE_COLUMNS,
    payment_at_from: null,
    payment_at_to: null,
    status: null,
    user_pid: null,
    manager_id: null,
  });

  const Schema = Yup.object().shape({
    payment_at_from: Yup.date().nullable(),
    payment_at_to: Yup.date().when(
      'payment_at_from',
      (payment_at_from: Date, schema: Yup.DateSchema) => {
        return payment_at_from
          ? schema.min(payment_at_from, t('label.payment_at_from'))
          : schema.nullable();
      },
    ),
  });

  const mapData = (form: ProjectPaymentScheduleFilterForm): GenerateReportRequestDto => {
    const request = initialRequest(form, TYPE);

    appendDateFilter(request, 'payment_at', form.payment_at_from, form.payment_at_to);
    appendFilter(request, 'status', form.status);
    appendFilter(request, 'user_pid', form.user_pid);
    appendFilter(request, 'manager_id', form.manager_id);

    return request;
  };

  const onSubmit = useCallback(
    async (
      request: ProjectPaymentScheduleFilterForm,
      helper: FormikHelpers<ProjectPaymentScheduleFilterForm>,
    ) => {
      try {
        await Api.reports.generateReport(mapData(request)).then(() => {
          refreshTable();
          success(t('common.success'));
        });
      } catch (e: any) {
        helper.setErrors(e.response?.errors);
      }
    },
    [refreshTable, t],
  );

  if (!p.hasAll([mapManagePermission(TYPE)])) return null;

  return (
    <div className={'mb-4'}>
      <Card>
        <CardBody>
          <h4 className={'mb-4'}>{t('reports.filter.' + TYPE)}</h4>
          <div className={'mb-4'}>
            <Formik
              initialValues={request}
              onSubmit={onSubmit}
              enableReinitialize={true}
              validationSchema={Schema}
              validateOnChange={true}
            >
              {({ handleSubmit, isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <FormGroup>
                    <Row>
                      <Col sm={3} className={'mb-4'}>
                        <TextInput name={'name'} />
                      </Col>
                      <Col sm={1} className={'mb-4'}>
                        <SelectColumnsInput columns={AVAILABLE_COLUMNS} />
                      </Col>
                      <Col sm={2} className={'mb-4'}>
                        <DatePickerInput name={'payment_at_from'} showTimeSelect />
                      </Col>
                      <Col sm={2} className={'mb-4'}>
                        <DatePickerInput name={'payment_at_to'} showTimeSelect />
                      </Col>
                      <Col sm={2} className={'mb-4'}>
                        <SelectInput
                          name={'status'}
                          options={getEnumSelectValues(
                            Object.values(BorrowerPayoutStatusEnum).filter(
                              (status) => status !== BorrowerPayoutStatusEnum.PERMISSION_TO_SELL,
                            ),
                            'payments.borrower_payouts.status',
                          )}
                        />
                      </Col>
                      <Col sm={2} className={'mb-4'}>
                        <TextInput name={'user_pid'} />
                      </Col>
                      <Col sm={2} className={'mb-4'}>
                        <SelectInput
                          name={'manager_id'}
                          options={managerOptions}
                          isClearable={true}
                        />
                      </Col>
                    </Row>
                    <div className={'mb-4 mt-3'}>
                      <SaveButton title={t('common.generate')} submitting={isSubmitting} />
                    </div>
                  </FormGroup>
                </Form>
              )}
            </Formik>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default ProjectPaymentScheduleReportFilter;
