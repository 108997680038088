import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody } from 'reactstrap';
import Select, { SingleValue } from 'react-select';
import { ReactSelectOption } from 'src/components/Form/Select/SelectInput';
import { StringParam, useQueryParam } from 'use-query-params';
import { RCTypeEnum } from 'src/helpers/Enums/RC/RCTypeEnum';
import useRCReportTypeStore from 'src/pages/RC/View/RCReportTypeStore';

const RCReportTypeSelect: React.FC = () => {
  const [, setQueryType] = useQueryParam('type', StringParam);
  const { type, setType } = useRCReportTypeStore();
  const { t } = useTranslation();

  const [options] = useState<ReactSelectOption[]>([
    ...Object.values(RCTypeEnum).map((type) => ({
      value: type,
      label: t('rc.type.' + type),
    })),
  ]);

  const value = (): ReactSelectOption | undefined => {
    if (type) {
      return {
        value: type.toString(),
        label: t('rc.type.' + type),
      };
    }

    return undefined;
  };

  return (
    <div className={'mb-4'}>
      <Card>
        <CardBody>
          <h4 className={'mb-4'}>{t('menu.rc')}</h4>
          <div className={'mb-3'}>
            <Select
              options={options}
              onChange={(newValue: SingleValue<ReactSelectOption>) => {
                if (newValue?.value) {
                  setType(newValue.value as RCTypeEnum);
                  setQueryType(typeof newValue.value === 'boolean' ? '' : newValue.value);
                }
              }}
              value={value()}
            />
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default RCReportTypeSelect;
