import Client from './client';
import { PaginationData, PaginationDataFilter } from 'src/types';
import {
  AddUserAccreditationRequestDto,
  AttachUserRoleRequestDto,
  FinancialRiskCalculatorResponseDto,
  FreezeUserAccountRequestDto,
  ImpersonateUserResponseDto,
  InvestorBriefResponseDto,
  RemoveUserAccreditationRequestDto,
  StoreUserCommentRequestDto,
  SuspendUserRequestDto,
  UpdateUserAccreditationRequestDto,
  UpdateUserRequestDto,
  UpdateUserRiskLevelRequestDto,
  UserAccreditationListResponseDto,
  UserAdminResponseDto,
  UserAmlRiskLevelChangeLogListResponseDto,
  UserBriefResponseDto,
  UserCommentResponseDto,
  UserInvestmentsResponseDto,
  UserRelateAccountRequestDto,
  UserRelatedAccountListResponseDto,
  UserResponseDto,
} from 'src/types/api/user';
import {
  AttachFilesRequestDto,
  CommonActionListResponseDto,
  FilterSelectValuesResponseDto,
  ModelResponseDto,
  SignUploadRequestDto,
} from 'src/types/api/common';
import { UserCompanyResponseDto } from 'src/types/api/userCompanies';
import {
  WalletMoneyOperationsResponseDto,
  WalletP2POperationsResponseDto,
} from 'src/types/api/operations';
import { AddAffiliateAmbassadorModalRequest } from 'src/components/Modal/Modals/AddAffiliateAmbassadorModal';
import { EditAffiliateAmbassadorModalRequest } from 'src/components/Modal/Modals/EditAffiliateAmbassadorModal';

class Users extends Client {
  api = {
    UPDATE_USER: `${this.http.baseUrl}/users/user/{userId}`,
    DELETE_USER: `${this.http.baseUrl}/users/user/{userId}`,

    FETCH_FILTERED_USERS: `${this.http.baseUrl}/users/filter`,
    FETCH_FILTERED_USERS_BRIEF: `${this.http.baseUrl}/users/brief`,
    FETCH_FILTERED_ADMIN_USERS: `${this.http.baseUrl}/users/admins/filter`,
    FETCH_FILTERED_USERS_INVESTORS: `${this.http.baseUrl}/users/investors`,
    FETCH_FILTERABLE_MANAGERS: `${this.http.baseUrl}/users/managers`,
    FETCH_FILTERABLE_MANAGERS_HISTORIC: `${this.http.baseUrl}/users/managers/historic`,
    FETCH_FILTERABLE_ANALYTICS: `${this.http.baseUrl}/users/analytics`,
    FETCH_FILTERABLE_ANALYTICS_HISTORIC: `${this.http.baseUrl}/users/analytics/historic`,
    FETCH_MANAGERS_OPTIONS: `${this.http.baseUrl}/users/managers/options`,
    FETCH_FILTERABLE_COMMITTEE_MEMBERS: `${this.http.baseUrl}/users/committee-members`,

    FETCH_USER: `${this.http.baseUrl}/users/user/{userId}`,
    FETCH_USER_ACTIONS: `${this.http.baseUrl}/users/user/{userId}/actions`,
    FETCH_USER_AUTO_INVESTOR_ACTIONS: `${this.http.baseUrl}/users/user/{userId}/auto-investor-actions`,
    FETCH_USER_AGREEMENTS: `${this.http.baseUrl}/users/user/{userId}/agreements`,
    IMPERSONATE_USER: `${this.http.baseUrl}/users/user/{userId}/impersonate`,
    SYNC_USER_WALLET: `${this.http.baseUrl}/users/user/{userId}/sync-wallet`,
    TWO_FACTOR_AUTH: `${this.http.baseUrl}/users/user/{userId}/2fa`,
    UPDATE_USER_ROLES: `${this.http.baseUrl}/users/user/{userId}/roles`,
    FETCH_INVESTMENTS: `${this.http.baseUrl}/users/user/{userId}/investments`,

    FETCH_AVAILABLE_RELATED_ACCOUNTS: `${this.http.baseUrl}/users/user/{userId}/related-accounts/available`,
    FETCH_RELATED_ACCOUNTS: `${this.http.baseUrl}/users/user/{userId}/related-accounts`,
    RELATE_ACCOUNTS: `${this.http.baseUrl}/users/user/{userId}/related-accounts`,
    UNRELATE_ACCOUNTS: `${this.http.baseUrl}/users/user/{userId}/related-accounts`,

    SUSPEND_USER: `${this.http.baseUrl}/users/user/{userId}/suspend`,
    REMOVE_USER_SUSPENSION: `${this.http.baseUrl}/users/user/{userId}/suspend`,

    SEND_USER_EMAIL_CONFIRMATION: `${this.http.baseUrl}/users/user/{userId}/email-confirmation`,
    SEND_USER_TEMPORARY_PASSWORD: `${this.http.baseUrl}/users/user/{userId}/temporary-password`,

    FETCH_USER_ACCREDITATIONS: `${this.http.baseUrl}/users/user/{userId}/accreditations`,
    ADD_USER_ACCREDITATION: `${this.http.baseUrl}/users/user/{userId}/accreditation`,
    REMOVE_USER_ACCREDITATION: `${this.http.baseUrl}/users/user/{userId}/accreditation`,

    FETCH_USER_COMMENTS: `${this.http.baseUrl}/users/user/{userId}/comments`,
    CREATE_USER_COMMENT: `${this.http.baseUrl}/users/user/comments`,
    UPDATE_USER_COMMENT: `${this.http.baseUrl}/users/user/comments/{commentId}`,
    DELETE_USER_COMMENT: `${this.http.baseUrl}/users/user/comments/{commentId}`,

    FILTER_DOCUMENTS: `${this.http.baseUrl}/users/user/{userId}/documents/filter`,
    UPLOAD_DOCUMENTS: `${this.http.baseUrl}/users/user/{userId}/documents`,
    ATTACH_DOCUMENTS: `${this.http.baseUrl}/users/user/{userId}/documents`,

    FETCH_WALLET_MONEY_OPERATIONS: `${this.http.baseUrl}/users/user/{userId}/operations/money`,
    FETCH_WALLET_P2P_OPERATIONS: `${this.http.baseUrl}/users/user/{userId}/operations/p2p`,

    FETCH_AML_RISK_LEVEL_CHANGE_LOG: `${this.http.baseUrl}/users/user/{userId}/aml/risk-level/filter`,
    UPDATE_AML_RISK_LEVEL: `${this.http.baseUrl}/users/user/{userId}/aml/risk-level`,
    FREEZE_USER_ACCOUNT: `${this.http.baseUrl}/users/user/{userId}/aml/freeze-status`,
    UNFREEZE_USER_ACCOUNT: `${this.http.baseUrl}/users/user/{userId}/aml/freeze-status`,

    UPDATE_USER_ACCREDITATION: `${this.http.baseUrl}/users/user/accreditation/{accreditationId}`,
    DELETE_USER_ACCREDITATION: `${this.http.baseUrl}/users/user/accreditation/{accreditationId}`,

    ADD_AFFILIATE_AMBASSADOR: `${this.http.baseUrl}/users/user/{userId}/affiliate-ambassador`,
    EDIT_AFFILIATE_AMBASSADOR: `${this.http.baseUrl}/users/user/{userId}/affiliate-ambassador/{affiliateAmbassadorId}`,
    REMOVE_AFFILIATE_AMBASSADOR: `${this.http.baseUrl}/users/user/{userId}/affiliate-ambassador/{affiliateAmbassadorId}`,

    ENABLE_GROUPABLE_INVESTMENTS: `${this.http.baseUrl}/users/user/{userId}/groupable-investments`,
    DISABLE_GROUPABLE_INVESTMENTS: `${this.http.baseUrl}/users/user/{userId}/groupable-investments`,

    ENABLE_HIGH_NET_WORTH: `${this.http.baseUrl}/users/user/{userId}/high-net-worth`,
    DISABLE_HIGH_NET_WORTH: `${this.http.baseUrl}/users/user/{userId}/high-net-worth`,

    FETCH_FINANCIAL_RISK_SUBMITS: `${this.http.baseUrl}/users/user/{userId}/financial-risk-submits`,
  };

  updateUser = (
    userId: number | string,
    request: UpdateUserRequestDto,
  ): Promise<UserResponseDto> => {
    const url = this.buildUrl(this.api.UPDATE_USER, { userId });

    return this.http.put(url, request);
  };

  attachUserRoles = (
    userId: number | string,
    request: AttachUserRoleRequestDto,
  ): Promise<UserResponseDto> => {
    const url = this.buildUrl(this.api.UPDATE_USER_ROLES, { userId });

    return this.http.post(url, request);
  };

  fetchFilteredUsers = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<UserResponseDto>> => {
    return this.http.get(this.api.FETCH_FILTERED_USERS, { params: request });
  };

  fetchFilteredUsersBrief = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<UserBriefResponseDto>> => {
    return this.http.get(this.api.FETCH_FILTERED_USERS_BRIEF, { params: request });
  };

  fetchFilteredAdminUsers = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<UserAdminResponseDto>> => {
    return this.http.get(this.api.FETCH_FILTERED_ADMIN_USERS, { params: request });
  };

  fetchFilteredInvestorsUsers = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<InvestorBriefResponseDto>> => {
    return this.http.get(this.api.FETCH_FILTERED_USERS_INVESTORS, { params: request });
  };

  fetchFilterableManagers = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<UserBriefResponseDto>> => {
    return this.http.get(this.api.FETCH_FILTERABLE_MANAGERS, { params: request });
  };

  fetchFilterableManagersHistoric = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<UserBriefResponseDto>> => {
    return this.http.get(this.api.FETCH_FILTERABLE_MANAGERS_HISTORIC, { params: request });
  };

  fetchFilterableAnalytics = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<UserBriefResponseDto>> => {
    return this.http.get(this.api.FETCH_FILTERABLE_ANALYTICS, { params: request });
  };

  fetchFilterableAnalyticsHistoric = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<UserBriefResponseDto>> => {
    return this.http.get(this.api.FETCH_FILTERABLE_ANALYTICS_HISTORIC, { params: request });
  };

  fetchManagersOptions = (): Promise<FilterSelectValuesResponseDto[]> => {
    return this.http.get(this.api.FETCH_MANAGERS_OPTIONS);
  };

  fetchFilterableCommitteeMembers = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<UserBriefResponseDto>> => {
    return this.http.get(this.api.FETCH_FILTERABLE_COMMITTEE_MEMBERS, { params: request });
  };

  suspendUser = (
    userId: number | string,
    request: SuspendUserRequestDto,
  ): Promise<UserResponseDto> => {
    const url = this.buildUrl(this.api.SUSPEND_USER, { userId });

    return this.http.put(url, request);
  };

  removeUserSuspension = (userId: number | string): Promise<UserResponseDto> => {
    const url = this.buildUrl(this.api.REMOVE_USER_SUSPENSION, { userId });

    return this.http.delete(url);
  };

  sendUserEmailConfirmation = (userId: number | string): Promise<UserResponseDto> => {
    const url = this.buildUrl(this.api.SEND_USER_EMAIL_CONFIRMATION, { userId });

    return this.http.put(url);
  };

  sendUserTemporaryPassword = (userId: number | string): Promise<UserResponseDto> => {
    const url = this.buildUrl(this.api.SEND_USER_TEMPORARY_PASSWORD, { userId });

    return this.http.put(url);
  };

  addUserAccreditation = (
    userId: number | string,
    request: AddUserAccreditationRequestDto,
  ): Promise<UserResponseDto> => {
    const url = this.buildUrl(this.api.ADD_USER_ACCREDITATION, { userId });

    return this.http.put(url, request);
  };

  removeUserAccreditation = (
    userId: number | string,
    request: RemoveUserAccreditationRequestDto,
  ): Promise<UserResponseDto> => {
    const url = this.buildUrl(this.api.REMOVE_USER_ACCREDITATION, { userId });

    return this.http.delete(url, request);
  };

  fetchUser = (userId: string | number): Promise<UserResponseDto> => {
    const url = this.buildUrl(this.api.FETCH_USER, { userId });

    return this.http.get(url);
  };

  fetchUserRelatedAccounts = (
    userId: string | number,
  ): Promise<UserRelatedAccountListResponseDto[]> => {
    const url = this.buildUrl(this.api.FETCH_RELATED_ACCOUNTS, { userId });

    return this.http.get(url);
  };

  fetchUserRelateAvailableAccounts = (
    userId: string | number,
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<ModelResponseDto>> => {
    const url = this.buildUrl(this.api.FETCH_AVAILABLE_RELATED_ACCOUNTS, { userId });

    return this.http.get(url, { params: request });
  };

  relateAccounts = (
    userId: string | number,
    request: UserRelateAccountRequestDto,
  ): Promise<any> => {
    const url = this.buildUrl(this.api.RELATE_ACCOUNTS, { userId });

    return this.http.post(url, request);
  };

  unrelateAccounts = (
    userId: string | number,
    request: UserRelateAccountRequestDto,
  ): Promise<any> => {
    const url = this.buildUrl(this.api.UNRELATE_ACCOUNTS, { userId });

    return this.http.delete(url, request);
  };

  fetchUserInvestments = (
    userId: string | number,
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<UserInvestmentsResponseDto>> => {
    const url = this.buildUrl(this.api.FETCH_INVESTMENTS, { userId });

    return this.http.get(url, { params: request });
  };

  fetchFinancialRiskSubmits = (
    userId: string | number,
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<FinancialRiskCalculatorResponseDto>> => {
    const url = this.buildUrl(this.api.FETCH_FINANCIAL_RISK_SUBMITS, { userId });

    return this.http.get(url, { params: request });
  };

  fetchUserAccreditations = (
    userId: string | number,
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<UserAccreditationListResponseDto>> => {
    const url = this.buildUrl(this.api.FETCH_USER_ACCREDITATIONS, { userId });

    return this.http.get(url, { params: request });
  };

  impersonateUser = (userId: string | number): Promise<ImpersonateUserResponseDto> => {
    const url = this.buildUrl(this.api.IMPERSONATE_USER, { userId });

    return this.http.post(url);
  };

  syncUserWallet = (userId: string | number): Promise<UserResponseDto> => {
    const url = this.buildUrl(this.api.SYNC_USER_WALLET, { userId });

    return this.http.post(url);
  };

  forceRemoveTwoFactorAuth = (userId: string | number): Promise<UserResponseDto> => {
    const url = this.buildUrl(this.api.TWO_FACTOR_AUTH, { userId });

    return this.http.delete(url);
  };

  fetchUserActions = (
    userId: string | number,
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<CommonActionListResponseDto>> => {
    const url = this.buildUrl(this.api.FETCH_USER_ACTIONS, { userId });

    return this.http.get(url, { params: request });
  };

  fetchUserAutoInvestorActions = (
    userId: string | number,
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<CommonActionListResponseDto>> => {
    const url = this.buildUrl(this.api.FETCH_USER_AUTO_INVESTOR_ACTIONS, { userId });

    return this.http.get(url, { params: request });
  };

  fetchUserAgreements = (
    userId: string | number,
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<CommonActionListResponseDto>> => {
    const url = this.buildUrl(this.api.FETCH_USER_AGREEMENTS, { userId });

    return this.http.get(url, { params: request });
  };

  fetchUserComments = (userId: string | number): Promise<Array<UserCommentResponseDto>> => {
    const url = this.buildUrl(this.api.FETCH_USER_COMMENTS, { userId });

    return this.http.get(url);
  };

  createUserComment = (request: StoreUserCommentRequestDto): Promise<UserCommentResponseDto> => {
    return this.http.post(this.api.CREATE_USER_COMMENT, request);
  };

  updateUserComment = (
    commentId: number | string,
    request: StoreUserCommentRequestDto,
  ): Promise<UserCommentResponseDto> => {
    const url = this.buildUrl(this.api.UPDATE_USER_COMMENT, { commentId });

    return this.http.put(url, request);
  };

  deleteUserComment = (commentId: number | string): Promise<any> => {
    const url = this.buildUrl(this.api.DELETE_USER_COMMENT, { commentId });

    return this.http.delete(url);
  };

  filterUserDocuments = (
    userId: string | number,
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<any> => {
    const url = this.buildUrl(this.api.FILTER_DOCUMENTS, { userId });

    return this.http.get(url, { params: request });
  };

  attachDocuments = (
    userId: string | number,
    request: AttachFilesRequestDto,
  ): Promise<UserCompanyResponseDto> => {
    const url = this.buildUrl(this.api.ATTACH_DOCUMENTS, { userId });

    return this.http.post(url, request);
  };

  uploadDocuments = (userId: string | number, request: SignUploadRequestDto): Promise<any> => {
    const url = this.buildUrl(this.api.UPLOAD_DOCUMENTS, { userId });

    return this.http.put(url, request);
  };

  fetchWalletMoneyOperations = (
    userId: string | number,
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<WalletMoneyOperationsResponseDto>> => {
    const url = this.buildUrl(this.api.FETCH_WALLET_MONEY_OPERATIONS, { userId });

    return this.http.get(url, { params: request });
  };

  fetchWalletP2POperations = (
    userId: string | number,
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<WalletP2POperationsResponseDto>> => {
    const url = this.buildUrl(this.api.FETCH_WALLET_P2P_OPERATIONS, { userId });

    return this.http.get(url, { params: request });
  };

  fetchAmlRiskLevelChangeLog = (
    userId: string | number,
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<UserAmlRiskLevelChangeLogListResponseDto>> => {
    const url = this.buildUrl(this.api.FETCH_AML_RISK_LEVEL_CHANGE_LOG, { userId });

    return this.http.get(url, { params: request });
  };

  updateRiskLevel = (
    userId: number | string,
    request: UpdateUserRiskLevelRequestDto,
  ): Promise<UserResponseDto> => {
    const url = this.buildUrl(this.api.UPDATE_AML_RISK_LEVEL, { userId });

    return this.http.put(url, request);
  };

  freezeUserAccount = (
    userId: number | string,
    request: FreezeUserAccountRequestDto,
  ): Promise<UserResponseDto> => {
    const url = this.buildUrl(this.api.FREEZE_USER_ACCOUNT, { userId });

    return this.http.put(url, request);
  };

  unfreezeUserAccount = (
    userId: number | string,
    request: FreezeUserAccountRequestDto,
  ): Promise<UserResponseDto> => {
    const url = this.buildUrl(this.api.UNFREEZE_USER_ACCOUNT, { userId });

    return this.http.delete(url, request);
  };

  updateUserAccreditation = (
    accreditationId: string | number,
    request: UpdateUserAccreditationRequestDto,
  ): Promise<UserAccreditationListResponseDto> => {
    const url = this.buildUrl(this.api.UPDATE_USER_ACCREDITATION, { accreditationId });

    return this.http.put(url, request);
  };

  deleteUserAccreditation = (accreditationId: string | number): Promise<any> => {
    const url = this.buildUrl(this.api.DELETE_USER_ACCREDITATION, { accreditationId });

    return this.http.delete(url);
  };

  deleteUser = (userId: number | string): Promise<UserResponseDto> => {
    const url = this.buildUrl(this.api.DELETE_USER, { userId });

    return this.http.delete(url);
  };

  addAffiliateAmbassador = (
    userId: number | string,
    request: AddAffiliateAmbassadorModalRequest,
  ): Promise<UserResponseDto> => {
    const url = this.buildUrl(this.api.ADD_AFFILIATE_AMBASSADOR, { userId });

    return this.http.post(url, request);
  };

  editAffiliateAmbassador = (
    userId: number | string,
    affiliateAmbassadorId: number | string,
    request: EditAffiliateAmbassadorModalRequest,
  ): Promise<UserResponseDto> => {
    const url = this.buildUrl(this.api.EDIT_AFFILIATE_AMBASSADOR, {
      userId,
      affiliateAmbassadorId,
    });

    return this.http.put(url, request);
  };

  deleteAffiliateAmbassador = (
    userId: number | string,
    affiliateAmbassadorId: number | string,
  ): Promise<UserResponseDto> => {
    const url = this.buildUrl(this.api.REMOVE_AFFILIATE_AMBASSADOR, {
      userId,
      affiliateAmbassadorId,
    });

    return this.http.delete(url);
  };

  enableGroupableInvestments = (userId: number | string): Promise<UserResponseDto> => {
    const url = this.buildUrl(this.api.ENABLE_GROUPABLE_INVESTMENTS, {
      userId,
    });

    return this.http.put(url);
  };

  disableGroupableInvestments = (userId: number | string): Promise<UserResponseDto> => {
    const url = this.buildUrl(this.api.DISABLE_GROUPABLE_INVESTMENTS, {
      userId,
    });

    return this.http.delete(url);
  };

  enableHighNetWorth = (userId: number | string): Promise<UserResponseDto> => {
    const url = this.buildUrl(this.api.ENABLE_HIGH_NET_WORTH, {
      userId,
    });

    return this.http.put(url);
  };

  disableHighNetWorth = (userId: number | string): Promise<UserResponseDto> => {
    const url = this.buildUrl(this.api.DISABLE_HIGH_NET_WORTH, {
      userId,
    });

    return this.http.delete(url);
  };
}

export default Users;
