import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Cell, Column } from 'react-table';
import Api from 'src/api';
import { Permissions } from 'src/helpers/auth/permissions';
import { BaseTabProps } from 'src/components/Tabs';
import { CompanyContextState, withCompany } from 'src/pages/User/Update/Company/CompanyContext';
import {
  UserRelateAccountRequestDto,
  UserRelatedAccountListResponseDto,
  UserResponseDto,
} from 'src/types/api/user';
import { capitalize, replace } from 'lodash';
import VerifiedBadge from 'src/pages/User/Badges/VerifiedBadge';
import DirectMarketingBadge from 'src/pages/User/Badges/DirectMarketingBadge';
import InvestedBadge from 'src/pages/User/Badges/InvestedBadge';
import WalletExistsBadge from 'src/pages/User/Badges/WalletExistsBadge';
import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import EditEntityButton from 'src/components/Table/Buttons/EditEntityButton';
import PermissionAction from 'src/components/PermissionAction';
import DeleteEntityButton from 'src/components/Table/Buttons/DeleteEntityButton';
import RelateAccountModal from 'src/components/Modal/Modals/RelateAccountModal';
import SimpleTable from 'src/components/SimpleTable';
import { LegalEntity } from 'src/types';

interface Props extends BaseTabProps, CompanyContextState {}

const TabRelatedAccounts: React.FC<Props> = ({ company }) => {
  const { t } = useTranslation();
  const { showModal } = useGlobalModalContext();

  const [response, setResponse] = useState<UserRelatedAccountListResponseDto[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const columns = useMemo<Column[] | any>(
    () => [
      {
        Header: t('table.id'),
        accessor: 'pid',
        sortType: 'string',
        width: 100,
      },
      {
        Header: t('table.name'),
        accessor: 'name',
        sortType: 'string',
        width: 250,
      },
      {
        Header: t('table.type'),
        accessor: 'type',
        sortType: 'string',
        width: 150,
        Cell: (cell: Cell<UserRelatedAccountListResponseDto>) => {
          return capitalize(replace(cell.value, '_', ' '));
        },
      },
      {
        Header: t('table.legal_entity'),
        accessor: 'legal_entity',
        sortType: 'string',
        width: 100,
        Cell: (cell: Cell<UserRelatedAccountListResponseDto>) => {
          return capitalize(cell.value);
        },
      },
      {
        Header: t('table.email'),
        accessor: 'email',
        sortType: 'string',
        width: 250,
      },
      {
        Header: t('table.phone'),
        accessor: 'phone',
        sortType: 'string',
      },
      {
        Header: t('table.registration_date'),
        accessor: 'created_at',
        sortType: 'datetime',
        width: 160,
        Cell: (cell: Cell<UserResponseDto>) => (
          <div>{t('common.date_full', { date: cell.value })}</div>
        ),
      },
      {
        Header: t('table.projects_remaining_amount'),
        accessor: 'projects_remaining_amount',
        width: 250,
      },
      {
        Header: t('table.information'),
        width: 110,
        Cell: (cell: Cell<UserRelatedAccountListResponseDto>) => {
          const val = cell.row.original;

          return (
            <div className={'actions d-flex gap-2'}>
              <VerifiedBadge verified={val.verified} />
              <DirectMarketingBadge
                via_email={val.direct_marketing.via_email ?? false}
                via_phone={val.direct_marketing.via_phone ?? false}
              />
              <InvestedBadge val_invested={val.invested} />
              <WalletExistsBadge wallet_exists={val.wallet_verified} />
            </div>
          );
        },
      },
      {
        Header: t('table.action'),
        width: 100,

        Cell: (cell: Cell<UserRelatedAccountListResponseDto>) => {
          const row = cell.row.original;
          return (
            <div className={'actions d-flex gap-2'}>
              {row.url && (
                <EditEntityButton route={row.url} permissions={Permissions.BO__USERS__VIEW} />
              )}
              <div>
                {!row.is_base_related && (
                  <PermissionAction permissions={Permissions.BO__USERS__MODIFY_RELATED_ACCOUNTS}>
                    <DeleteEntityButton
                      onDelete={() =>
                        detachRelatedAccount({
                          type: row.class,
                          id: row.id,
                        })
                      }
                      modalTitle={t('users.unrelate_account')}
                      modalBody={t('users.unrelate_account.description.company')}
                      successMessage={t('users.unrelate_account.success')}
                      modalSize={'md'}
                    />
                  </PermissionAction>
                )}
              </div>
            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, company],
  );

  const fetchData = useCallback(() => {
    if (!company) return Promise.resolve();

    return Api.userCompanies
      .fetchCompanyRelatedAccount(company.id)
      .then((response) => {
        setResponse(response);
      })
      .finally(() => setIsLoading(false));
  }, [company]);

  useEffect(() => {
    fetchData();
  }, [fetchData, company]);

  const detachRelatedAccount = useCallback(
    async (request: UserRelateAccountRequestDto) => {
      if (!company) return;

      setIsLoading(true);
      await Api.userCompanies.unrelateAccounts(company.id, request).finally(() => {
        fetchData();
      });
    },
    [company, fetchData],
  );

  const showRelateAccountModal = useCallback(() => {
    if (!company) return null;

    showModal(
      <RelateAccountModal
        onAction={async (request) => {
          setIsLoading(true);
          return Api.userCompanies.relateAccounts(company.id, request).finally(() => {
            fetchData();
          });
        }}
        currentUserId={company.id}
        currentUserType={LegalEntity.Company}
        title={t('users.relate_account')}
        body={t('users.relate_account.description.company')}
        successMessage={t('users.relate_account.success')}
      />,
    );
  }, [company, showModal, t, fetchData]);

  const getTotalProjectsRemainingAmount = () => {
    if (!response || !company) {
      return 0;
    }

    const relatedRemainingAmount = response.reduce(
      (sum, account) => sum + (account.projects_remaining_amount ?? 0),
      0,
    );

    const currentCompanyRemainingAmount = company.projects_remaining_amount ?? 0;

    return relatedRemainingAmount + currentCompanyRemainingAmount;
  };

  return (
    <React.Fragment>
      <div className={'row mb-3'}>
        <div className={'col'}>
          <PermissionAction permissions={Permissions.BO__USERS__MODIFY_RELATED_ACCOUNTS}>
            <div className={'float-end'}>
              <button onClick={showRelateAccountModal} className={'btn btn-primary w-100'}>
                {t('users.relate_account')}
              </button>
            </div>
          </PermissionAction>
        </div>
      </div>
      <SimpleTable columns={columns} data={response} isLoading={isLoading} />
      <div className={'d-flex justify-content-end'}>
        <div>
          <h5>
            {t('users.projects_total_remaining_amount')} -{' '}
            <strong>{t('common.money', { value: getTotalProjectsRemainingAmount() })}</strong>
          </h5>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withCompany(TabRelatedAccounts);
