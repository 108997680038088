import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Card, CardBody, Container } from 'reactstrap';
import { Cell, Column } from 'react-table';
import { useTranslation } from 'react-i18next';
import { setGlobalLoading } from 'src/modules/app/actions';
import Table from 'src/components/Table';
import Api from 'src/api';
import { PaginationData, PaginationDataFilter } from 'src/types';
import { HiddenColumnsProvider } from 'src/components/ShowTableColumnsDropdown';
import {
  DebtCollectionListResponseDto,
  DebtCollectionSubRowResponseDto,
} from 'src/types/api/DebtCollection';
import SimpleTable from 'src/components/SimpleTable';
import ViewEntityButton from 'src/components/Table/Buttons/ViewEntityButton';
import { Permissions } from 'src/helpers/auth/permissions';
import { generatePath } from 'react-router-dom';
import { RouteList } from 'src/routes';
import Expander from 'src/components/Table/Buttons/Expander';

const INITIAL_SORT_ORDER = [
  {
    id: 'id',
    desc: true,
  },
];

const DebtCollectionList: React.FC = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<PaginationData<DebtCollectionListResponseDto>>();
  const [expandedRowData, setExpandedRowData] = useState<DebtCollectionSubRowResponseDto[]>([]);

  useEffect(() => {
    setGlobalLoading(false);
  }, []);

  const fetchData = useCallback(async (request: PaginationDataFilter | undefined) => {
    return Api.debtCollection
      .fetchFilteredDebtCollections(request)
      .then((response) => setData(response));
  }, []);

  const fetchSubData = async (stageId: string) => {
    try {
      const response = await Api.debtCollection.fetchDebtCollectionExtendedList(stageId);
      setExpandedRowData((prev: any) => ({ ...prev, [stageId]: response }));
    } catch (error) {
      console.error('Failed to fetch data for row:', stageId, error);
    }
  };

  const columns = useMemo<Column<DebtCollectionListResponseDto>[] | any>(
    () => [
      {
        Header: t('table.loan_agreement_number'),
        accessor: 'loan_agreement_number',
        sortType: 'string',
        disableSortBy: true,
        width: 100,
      },
      {
        Header: t('table.owner_name'),
        accessor: 'owner_name',
        disableSortBy: true,
        width: 200,
      },
      {
        Header: t('table.debt_amount'),
        accessor: 'debt_amount',
        disableSortBy: true,
        width: 100,
      },
      {
        Header: t('table.days_late'),
        accessor: 'days_late',
        disableSortBy: true,
        width: 50,
      },
      {
        Header: t('table.notice_1_at'),
        accessor: 'notice_1_at',
        disableSortBy: true,
        width: 80,
      },
      {
        Header: t('table.notice_2_at'),
        accessor: 'notice_2_at',
        disableSortBy: true,
        width: 80,
      },
      {
        Header: t('table.contract_terminated_at'),
        accessor: 'contract_terminated_at',
        disableSortBy: true,
        width: 90,
      },
      {
        Header: t('table.last_comment'),
        accessor: 'last_comment',
        disableSortBy: true,
        width: 150,
      },
      {
        Header: t('table.status'),
        accessor: 'status',
        disableSortBy: true,
        width: 100,
      },
      {
        Header: t('table.actions'),
        disableSortBy: true,
        width: 60,
        Cell: (cell: Cell<DebtCollectionListResponseDto>) => (
          <div className={'actions d-flex justify-content-around'}>
            <Expander
              onClick={() => {
                cell.row.toggleRowExpanded();
                if (!cell.row.isExpanded) {
                  fetchSubData(cell.row.original.id);
                }
              }}
              row={cell.row}
            />
            <ViewEntityButton
              permissions={Permissions.BO__DEBT_COLLECTION__VIEW}
              route={{
                pathname: generatePath(RouteList.DEBT_COLLECTION.VIEW, {
                  stageId: cell.row.original.id,
                }),
                state: { prevPath: location.pathname },
              }}
              style={{ margin: 0 }}
            />
          </div>
        ),
      },
    ],
    [t],
  );

  const subColumns = useMemo<Column<DebtCollectionSubRowResponseDto>[] | any>(
    () => [
      {
        Header: t('table.loan_agreement_annex'),
        accessor: 'loan_agreement_annex',
        disableSortBy: true,
        width: 100,
      },
      {
        Header: t('table.start_date'),
        accessor: 'start_date',
        disableSortBy: true,
        width: 100,
        Cell: (cell: Cell<DebtCollectionSubRowResponseDto>) => (
          <div>{t('common.date', { date: cell.value })}</div>
        ),
      },
      {
        Header: t('table.project_pid'),
        accessor: 'project_pid',
        disableSortBy: true,
        width: 200,
      },
      {
        Header: t('table.debt_amount'),
        accessor: 'debt_amount',
        disableSortBy: true,
        width: 100,
      },
      {
        Header: t('table.days_late'),
        accessor: 'days_late',
        disableSortBy: true,
        width: 50,
      },
      {
        Header: t('table.interest_rate'),
        accessor: 'interest_rate',
        disableSortBy: true,
        width: 80,
      },
      {
        Header: t('table.payment_date'),
        accessor: 'payment_date',
        disableSortBy: true,
        width: 80,
        Cell: (cell: Cell<DebtCollectionSubRowResponseDto>) => (
          <div>{t('common.date', { date: cell.value })}</div>
        ),
      },
      {
        Header: t('table.payment_type'),
        accessor: 'payment_type',
        disableSortBy: true,
        width: 90,
      },
      {
        Header: t('table.last_payment_amount'),
        accessor: 'last_payment_amount',
        disableSortBy: true,
        width: 150,
      },
      {
        Header: t('table.last_payment_date'),
        accessor: 'last_payment_date',
        disableSortBy: true,
        width: 100,
        Cell: (cell: Cell<DebtCollectionSubRowResponseDto>) => (
          <div>{t('common.date', { date: cell.value })}</div>
        ),
      },
    ],
    [t],
  );

  return (
    <React.Fragment>
      <HiddenColumnsProvider title={'DebtCollectionListIndex'}>
        <Container fluid>
          <Card>
            <CardBody>
              <div className={'d-flex align-items-center mb-4'}>
                <h4 className={'m-0'}>{t('menu.payments.project_payouts')}</h4>
              </div>
              <Table
                title={'DebtCollectionListIndex'}
                onFetchData={fetchData}
                columns={columns}
                data={data}
                renderRowSubComponent={({ row }) => {
                  if (!expandedRowData[row.original.id]) {
                    return <div>Loading...</div>;
                  }
                  return (
                    <SimpleTable data={expandedRowData[row.original.id]} columns={subColumns} />
                  );
                }}
                searchable={true}
                enableQueryFilter={true}
                initialSortBy={INITIAL_SORT_ORDER}
              />
            </CardBody>
          </Card>
        </Container>
      </HiddenColumnsProvider>
    </React.Fragment>
  );
};

export default DebtCollectionList;
