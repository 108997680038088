import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as H from 'history';
import usePermissions from 'src/helpers/usePermissions';

interface Props {
  permissions?: string | string[];
  show?: boolean;
  route: string | H.LocationDescriptor;
  style?: object;
}

const ViewEntityButton: React.FC<Props> = ({ permissions, route, show, style }) => {
  const { t } = useTranslation();
  const p = usePermissions();

  if ((show !== undefined && !show) || (permissions && p.cannot(permissions))) {
    return null;
  }

  return (
    <React.Fragment>
      <Link to={route} className={'link-primary'} title={t('common.view')} style={style}>
        <i className={'fas fa-eye'} />
      </Link>
    </React.Fragment>
  );
};

export default ViewEntityButton;
