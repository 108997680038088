import React, { useEffect, useMemo } from 'react';
import { Container } from 'reactstrap';
import { StringParam, useQueryParam } from 'use-query-params';
import { setGlobalLoading } from 'src/modules/app/actions';
import { Permissions } from 'src/helpers/auth/permissions';
import usePermissions from 'src/helpers/usePermissions';
import { rcReportTypeFromString, RCTypeEnum } from 'src/helpers/Enums/RC/RCTypeEnum';
import RealEstateTransactionsFilter from 'src/pages/RC/View/Filters/RealEstateTransactionsFilter';
import RCReportsTable from 'src/pages/RC/View/RCReportsTable';
import RCReportTypeSelect from 'src/pages/RC/View/RCReportTypeSelect';
import useRCReportTypeStore from 'src/pages/RC/View/RCReportTypeStore';
import RealEstateTransactionsDetailedFilter from 'src/pages/RC/View/Filters/RealEstateTransactionsDetailedFilter';

const RC: React.FC = () => {
  const [queryType] = useQueryParam('type', StringParam);
  const { type, setType } = useRCReportTypeStore();
  const { cannot } = usePermissions();

  useEffect(() => {
    const type = rcReportTypeFromString(queryType);

    if (type) setType(type);

    setGlobalLoading(false);
  }, [queryType, setType]);

  const filter = useMemo<JSX.Element>(() => {
    if (cannot(Permissions.BO__RC_REPORTS_FETCH)) {
      return <></>;
    }

    switch (type) {
      case RCTypeEnum.REAL_ESTATE_TRANSACTIONS: {
        return <RealEstateTransactionsFilter />;
      }
      case RCTypeEnum.REAL_ESTATE_TRANSACTIONS_DETAILED: {
        return <RealEstateTransactionsDetailedFilter />;
      }
      default: {
        return <></>;
      }
    }
  }, [cannot, type]);

  return (
    <>
      <Container fluid style={{ minHeight: '600px' }}>
        <RCReportTypeSelect />
        {type && filter}
        {type && <RCReportsTable />}
      </Container>
    </>
  );
};

export default RC;
