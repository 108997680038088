import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Cell, Column } from 'react-table';
import { Card, CardBody } from 'reactstrap';
import Api from 'src/api';
import usePermissions from 'src/helpers/usePermissions';
import { PaginationData, PaginationDataFilter } from 'src/types';
import { HiddenColumnsProvider } from 'src/components/ShowTableColumnsDropdown';
import Table from 'src/components/Table';
import { Permissions } from 'src/helpers/auth/permissions';
import DeleteEntityButton from 'src/components/Table/Buttons/DeleteEntityButton';
import { printObjectsWithoutFormatValue } from 'src/helpers/formatters/object';
import ReportStatusBadge from 'src/pages/Reports/View/ReportStatusBadge';
import DownloadFileButton from 'src/components/Table/Buttons/DownloadFileButton';
import { RCReportListResponseDto } from 'src/types/api/RC';
import useRCReportTypeStore from 'src/pages/RC/View/RCReportTypeStore';
import { CommonDocumentTypeEnum } from 'src/helpers/Enums/CommonDocumentTypeEnum';

const INITIAL_SORT_ORDER = [
  {
    id: 'created_at',
    desc: true,
  },
];

const RCReportTable: React.FC = () => {
  const { t } = useTranslation();
  const p = usePermissions();
  const { type, refresh, refreshTable } = useRCReportTypeStore();
  const [data, setData] = useState<PaginationData<RCReportListResponseDto>>();

  const fetchData = useCallback(
    async (request: PaginationDataFilter | undefined) => {
      if (type)
        return Api.rc.fetchFilteredReports({ ...request, type: type }).then((r) => setData(r));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [type, refresh],
  );

  const columns = useMemo<Column<RCReportListResponseDto>[] | any>(() => {
    return [
      {
        Header: t('table.id'),
        accessor: 'id',
        sortType: 'string',
      },
      {
        Header: t('table.name'),
        sortType: 'string',
        accessor: 'name',
      },
      {
        Header: t('table.status'),
        accessor: 'status',
        sortType: 'text',
        Cell: (cell: Cell<RCReportListResponseDto>) => <ReportStatusBadge value={cell.value} />,
      },
      {
        Header: t('table.filters'),
        accessor: 'filters',
        disableSortBy: true,
        Cell: (cell: Cell<RCReportListResponseDto>) => (
          <div className={'d-flex flex-wrap'}>{printObjectsWithoutFormatValue(cell.value)}</div>
        ),
      },
      {
        Header: t('table.created_at'),
        accessor: 'created_at',
        sortType: 'datetime',
        Cell: (cell: Cell<RCReportListResponseDto>) => (
          <div>{t('common.date_full', { date: cell.value })}</div>
        ),
      },
      {
        Header: t('table.generated_at'),
        accessor: 'generated_at',
        sortType: 'datetime',
        Cell: (cell: Cell<RCReportListResponseDto>) => (
          <div>{t('common.date_full', { date: cell.value })}</div>
        ),
      },
      {
        Header: t('table.action'),
        width: 100,
        disableSortBy: true,
        Cell: (cell: Cell<RCReportListResponseDto> | any) => {
          return (
            <div className={'actions d-flex gap-2'}>
              {cell.row.original.document?.url && (
                <DownloadFileButton
                  type={CommonDocumentTypeEnum.XLSX}
                  name={cell.row.original.name}
                  url={cell.row.original.document.url}
                />
              )}
              {type && (
                <DeleteEntityButton
                  onDelete={() =>
                    Api.rc
                      .deleteReport(cell.row.original.id)
                      .then(() => cell.dispatch({ type: 'refreshData' }))
                  }
                  permissions={[Permissions.BO__RC_REPORTS_DELETE]}
                />
              )}
            </div>
          );
        },
      },
    ];
  }, [t, type]);

  if (!type) return null;

  if (!p.hasAll([Permissions.BO__RC_REPORTS_LIST])) return null;

  return (
    <div className={'mb-4'}>
      <Card>
        <CardBody>
          <div className={'d-flex justify-content-between align-items-center mb-4'}>
            <h4 className={'m-0'}>{t('rc.table.' + type)}</h4>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                refreshTable();
              }}
            >
              <i className={'fas fa-sync h4'} />
            </a>
          </div>
          <HiddenColumnsProvider title={'RCReportListIndex'}>
            <Table
              title={'ReportListIndex'}
              onFetchData={fetchData}
              columns={columns}
              data={data}
              searchable={true}
              enableQueryFilter={true}
              initialSortBy={INITIAL_SORT_ORDER}
            />
          </HiddenColumnsProvider>
        </CardBody>
      </Card>
    </div>
  );
};

export default RCReportTable;
