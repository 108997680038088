import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Col, FormGroup, Row } from 'reactstrap';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import Api from 'src/api';
import useReportTypeStore from 'src/pages/Reports/View/ReportTypeStore';
import usePermissions from 'src/helpers/usePermissions';
import { ReactSelectOption } from 'src/components/Form/Select/SelectInput';
import { mapManagePermission, ReportTypeEnum } from 'src/helpers/Enums/ReportTypeEnum';
import { success } from 'src/services/toastr';
import TextInput from 'src/components/Form/TextInput';
import SaveButton from 'src/components/Form/SaveButton';
import DatePickerInput from 'src/components/Form/DatePickerInput';
import { SelectInput } from 'src/components/Form/Select';
import { GeneralUserTypeEnum } from 'src/helpers/Enums/GeneralUserTypeEnum';
import { GenerateReportRequestDto, RegistrationReportFilterForm } from 'src/types/api/reports';
import { appendDateFilter, appendFilter, initialRequest } from 'src/pages/Reports/View/helpers';
import SelectColumnsInput from 'src/pages/Reports/View/Filters/Selects/SelectColumnsInput';
import SelectDirectMarketingInput from 'src/pages/Reports/View/Filters/Selects/SelectDirectMarketingInput';
import { UserMarketingSettingTypeEnum } from 'src/helpers/Enums/UserMarketingAgreementsTypeEnum';

const AVAILABLE_COLUMNS: string[] = [
  'pid',
  'general_type',
  'name',
  'code',
  'email',
  'phone',
  'street',
  'house_number',
  'residence',
  'is_user_accredited',
  'is_user_accreditation_failed',
  'allow_survey_via_email',
  'allow_educational_marketing_via_email',
  'allow_newsletter_marketing_via_email',
  'allow_promotional_marketing_via_email',
  'allow_invested_project_news_via_email',
  'allow_promotional_marketing_via_phone',
  'allow_survey_via_phone',
  'allow_direct_marketing_via_sms',
  'channel_notification_language',
  'wallet_provider',
  'wallet_status',
  'wallet_balance',
  'registered_at',
  'last_login_at',
  'last_investment_at',
  'migration_info',
  'investor_experience_level',
  'is_high_net_worth',
];

const TYPE: ReportTypeEnum = ReportTypeEnum.REGISTRATIONS;

const RegistrationReportFilter: React.FC = () => {
  const { t } = useTranslation();
  const p = usePermissions();
  const { refreshTable } = useReportTypeStore();

  const [request] = useState<RegistrationReportFilterForm>({
    name: null,
    columns: AVAILABLE_COLUMNS,
    registered_at_from: null,
    registered_at_to: null,
    general_type: null,
    marketing_setting: null,
    marketing_type: null,
  });

  const Schema = Yup.object().shape({
    registered_at_from: Yup.date().nullable(),
    registered_at_to: Yup.date().when(
      'registered_at_from',
      (registered_at_from: Date, schema: Yup.DateSchema) => {
        return registered_at_from
          ? schema.min(registered_at_from, t('label.registered_at_from'))
          : schema.nullable();
      },
    ),
    marketing_type: Yup.string(), // email, phone, sms, undefined, none
    marketing_setting: Yup.string().test(
      'valid-setting',
      t('common.incorrect_marketing_setting'),
      function (value) {
        const { marketing_type } = this.parent; // Access marketing_type from parent object
        if (!marketing_type) return true; // If marketing_type is not provided, skip validation
        if (value === undefined || value === 'none') return true;

        console.log('value', value);
        console.log('marketing_type', marketing_type);

        const emailMarketingTypes = [
          UserMarketingSettingTypeEnum.SURVEYS,
          UserMarketingSettingTypeEnum.EDUCATIONAL,
          UserMarketingSettingTypeEnum.NEWSLETTER,
          UserMarketingSettingTypeEnum.INVESTED_PROJECT_NEWS,
          UserMarketingSettingTypeEnum.PROMOTIONAL,
        ];

        const phoneMarketingTypes = [
          UserMarketingSettingTypeEnum.SURVEYS,
          UserMarketingSettingTypeEnum.PROMOTIONAL,
        ];

        const smsMarketingTypes = [UserMarketingSettingTypeEnum.DIRECT];

        switch (value) {
          case 'email':
            return emailMarketingTypes.includes(marketing_type as UserMarketingSettingTypeEnum);
          case 'phone':
            return phoneMarketingTypes.includes(marketing_type as UserMarketingSettingTypeEnum);
          case 'sms':
            return smsMarketingTypes.includes(marketing_type as UserMarketingSettingTypeEnum);
          default:
            return true;
        }
      },
    ),
  });

  const mapData = (form: RegistrationReportFilterForm): GenerateReportRequestDto => {
    const request = initialRequest(form, TYPE);

    appendDateFilter(request, 'registered_at', form.registered_at_from, form.registered_at_to);
    appendFilter(request, 'general_type', form.general_type);
    appendFilter(request, 'marketing_setting', form.marketing_setting);
    appendFilter(request, 'marketing_type', form.marketing_type);

    return request;
  };

  const onSubmit = useCallback(
    async (
      request: RegistrationReportFilterForm,
      helper: FormikHelpers<RegistrationReportFilterForm>,
    ) => {
      try {
        await Api.reports.generateReport(mapData(request)).then(() => {
          refreshTable();
          success(t('common.success'));
        });
      } catch (e: any) {
        helper.setErrors(e.response?.errors);
      }
    },
    [refreshTable, t],
  );

  const [generalUserSelectOptions] = useState<ReactSelectOption[]>([
    {
      value: '',
      label: t('common.all'),
    },
    ...Object.values(GeneralUserTypeEnum).map((type) => ({
      value: type,
      label: t('reports.general_type.' + type),
    })),
  ]);

  const [marketingTypeOptions] = useState<ReactSelectOption[]>([
    {
      value: '',
      label: t('common.all'),
    },
    ...Object.values(UserMarketingSettingTypeEnum).map((type) => ({
      value: type,
      label: t('reports.user_marketing_settings.' + type),
    })),
  ]);

  if (!p.hasAll([mapManagePermission(TYPE)])) return null;

  return (
    <div className={'mb-4'}>
      <Card>
        <CardBody>
          <h4 className={'mb-4'}>{t('reports.filter.' + TYPE)}</h4>
          <div className={'mb-4'}>
            <Formik
              initialValues={request}
              onSubmit={onSubmit}
              enableReinitialize={true}
              validationSchema={Schema}
              validateOnChange={true}
            >
              {({ handleSubmit, isSubmitting, values }) => (
                <Form onSubmit={handleSubmit}>
                  <FormGroup>
                    <Row>
                      <Col sm={3} className={'mb-4'}>
                        <TextInput name={'name'} />
                      </Col>
                      <Col sm={1} className={'mb-4'}>
                        <SelectColumnsInput columns={AVAILABLE_COLUMNS} />
                      </Col>
                      <Col sm={2} className={'mb-4'}>
                        <DatePickerInput name={'registered_at_from'} showTimeSelect />
                      </Col>
                      <Col sm={2} className={'mb-4'}>
                        <DatePickerInput name={'registered_at_to'} showTimeSelect />
                      </Col>
                      <Col sm={2} className={'mb-4'}>
                        <SelectInput name={'general_type'} options={generalUserSelectOptions} />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={3} className={'mb-4'}>
                        <SelectInput
                          name={'marketing_type'}
                          options={marketingTypeOptions}
                          placeholder={'Marketing setting'}
                        />
                      </Col>
                      <Col sm={2} className={'mb-4'}>
                        <SelectDirectMarketingInput
                          name={'marketing_setting'}
                          placeholder={'Allowed Marketing'}
                          marketing_type={values.marketing_type}
                        />
                      </Col>
                    </Row>
                    <div className={'mb-4 mt-3'}>
                      <SaveButton title={t('common.generate')} submitting={isSubmitting} />
                    </div>
                  </FormGroup>
                </Form>
              )}
            </Formik>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default RegistrationReportFilter;
