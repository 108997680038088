import React, { useState } from 'react';
import { getEnumSelectValues } from 'src/helpers/Enums/enumHelper';
import { SelectInput } from 'src/components/Form/Select/index';
import { ReactSelectOption } from 'src/components/Form/Select/SelectInput';
import { LoanRatioEnum } from 'src/helpers/Enums/LoanRatioEnum';

interface Props {
  name: string;
}

const SelectLoanRatioInput: React.FC<Props> = ({ name }) => {
  const [options] = useState<ReactSelectOption[]>(() =>
    getEnumSelectValues(LoanRatioEnum, 'label.loan_ratio'),
  );

  const defaultOption = options.find((option) => option.value === LoanRatioEnum.LTV);

  return <SelectInput name={name} defaultValue={defaultOption} options={options} />;
};

export default SelectLoanRatioInput;
