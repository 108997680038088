import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  InvestorKybDataV1ResponseDto,
  UserCompanyKybDataV1BeneficiaryResponseDto,
  UserCompanyPublicOfficialResponseDto,
} from 'src/types/api/userCompanies';
import SidebarDataRow from 'src/components/DataBlocks/SidebarDataRow';
import { Row } from 'reactstrap';
import { formatMultipleSelectValues } from 'src/helpers';
import BeneficiaryV1Block from 'src/pages/User/Update/Company/Kyb/BeneficiaryV1Block';

interface Props {
  kyb: InvestorKybDataV1ResponseDto;
}
const InvestorKybV1: React.FC<Props> = ({ kyb }) => {
  const { t } = useTranslation();

  return (
    <>
      <Row>
        <SidebarDataRow
          label={t('label.company.planned_investment_amount')}
          value={kyb.planned_investment_amount}
          displayValue={t(`users.${kyb.planned_investment_amount}`)}
        />
        <SidebarDataRow
          label={t('label.company.activity_regions')}
          value={formatMultipleSelectValues(
            kyb.activity_regions,
            kyb.other_activity_regions_text,
            'users.company.activity_regions.',
          )}
        />
        <SidebarDataRow
          label={t('label.company.has_cash_operations')}
          value={kyb.has_cash_operations}
          displayValue={kyb.has_cash_operations ? t('common.yes') : t('common.no')}
        />
        <SidebarDataRow
          label={t('label.company.ytd_cash_operations_amount')}
          value={kyb?.ytd_cash_operations_amount}
          displayValue={t(`users.${kyb?.ytd_cash_operations_amount}`)}
        />
        <SidebarDataRow
          label={t('label.company.investment_amount_source')}
          value={kyb.investment_amount_source}
        />
        <SidebarDataRow
          label={t('label.company.ytd_income')}
          value={kyb?.ytd_income}
          displayValue={t('common.money', {
            value: kyb?.ytd_income,
          })}
        />
        <SidebarDataRow
          label={t('label.company.management_structure')}
          value={kyb.management_structure}
          displayValue={t(`users.company.${kyb.management_structure}`)}
        />
        <SidebarDataRow
          label={t('label.company.has_bankruptcy_procedure')}
          value={kyb.has_bankruptcy_procedure ? t('common.yes') : t('common.no')}
        />
        <SidebarDataRow
          label={t('label.company.is_shareholder_beneficiary_family_member_public_official')}
          value={
            kyb.is_shareholder_beneficiary_family_member_public_official
              ? t('common.yes')
              : t('common.no')
          }
        />
        <SidebarDataRow
          label={t('label.company.has_confirmed_data_integrity')}
          value={kyb.has_confirmed_data_integrity ? t('common.yes') : t('common.no')}
        />
      </Row>
      <hr />
      <h6 className={'mb-3'}>
        <strong>{t('label.company.beneficiaries')}</strong>
      </h6>
      {kyb.beneficiaries?.map(
        (beneficiary: UserCompanyKybDataV1BeneficiaryResponseDto, index: number) => {
          return (
            <Row key={index} className={'mb-5'}>
              <BeneficiaryV1Block beneficiary={beneficiary} />
            </Row>
          );
        },
      )}
      <div>
        <hr />
        <h6 className={'mb-3'}>
          <strong>{t('label.company.public_officials')}</strong>
        </h6>
        {kyb.public_officials?.map(
          (publicOfficial: UserCompanyPublicOfficialResponseDto, index: number) => {
            return (
              <Row key={index}>
                <SidebarDataRow label={t('label.details')} value={publicOfficial.details} />
              </Row>
            );
          },
        )}
      </div>
    </>
  );
};

export default InvestorKybV1;
