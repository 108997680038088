export enum Permissions {
  SS__ACTIONS__EMAIL_VERIFIED = 'ss.actions.email_verified',
  SS__ACTIONS__WALLET_VERIFIED = 'ss.actions.wallet_verified',
  SS__ACTIONS__CONTACT_DATA_FILLED = 'ss.actions.contact_data_filled',
  SS__ACTIONS__USER_KYC_FILLED = 'ss.actions.user_kyc_filled',
  SS__ACTIONS__VIEW_NON_PUBLIC_PROJECT = 'ss.actions.view_non_public_project',
  SS__ACTIONS__ACCREDITATION_VERIFIED = 'ss.actions.accreditation_verified',
  SS__ACTIONS__COMPANY_KYB_FILLED = 'ss.actions.company_kyb_filled',
  SS__ACTIONS__INVESTOR_QUIZ_FILLED = 'ss.actions.investor_quiz_filled',
  SS__ACTIONS__INSTALL_PWA = 'ss.actions.install_pwa',
  SS__REGISTERED = 'ss.registered',
  SS__ACTIONS__PAY_BY_BANK = 'ss.actions.pay_by_bank',
  SS__ENABLED__SECONDARY_MARKET = 'ss.enabled.secondary_market',
  SS__ENABLED__AML = 'ss.enabled.aml',
  SS__ENABLED__AUTO_INVESTMENTS = 'ss.enabled.auto_investments',
  BO__GUEST = 'bo.guest',
  BO__ACTIONS__ACCESS_PANEL = 'bo.actions.access_panel',
  BO__ACTIONS__MANAGE_COMMITTEE_MEMBERS = 'bo.actions.manage_committee_members',
  BO__ROLES__FILTER = 'bo.roles.filter',
  BO__ROLES__UPDATE = 'bo.roles.update',
  BO__ROLES__CREATE = 'bo.roles.create',
  BO__ROLES__VIEW = 'bo.roles.view',
  BO__ROLES__DELETE = 'bo.roles.delete',
  BO__USERS__FILTER = 'bo.users.filter',
  BO__USERS__VIEW = 'bo.users.view',
  BO__USERS__VIEW_ACCREDITATIONS = 'bo.users.view_accreditations',
  BO__USERS__VIEW_DOCUMENTS = 'bo.users.view_documents',
  BO__USERS__VIEW_LEMONWAY_DOCUMENTS = 'bo.users.view_lemonway_documents',
  BO__USERS__EDIT_LEMONWAY_DOCUMENTS = 'bo.users.edit_lemonway_documents',
  BO__USERS__VIEW_ACTIONS = 'bo.users.view_actions',
  BO__USERS__VIEW_AGREEMENTS = 'bo.users.view_agreements',
  BO__USERS__VIEW_RELATED_ACCOUNTS = 'bo.users.view_related_accounts',
  BO__USERS__MODIFY_RELATED_ACCOUNTS = 'bo.users.modify_related_accounts',
  BO__USERS__VIEW_INVESTMENTS = 'bo.users.view_investments',
  BO__USERS__VIEW_WALLET_MONEY_OPERATIONS = 'bo.users.view_wallet_money_operations',
  BO__USERS__VIEW_WALLET_P2P_OPERATIONS = 'bo.users.view_wallet_p2p_operations',
  BO__USERS__VIEW_AML = 'bo.users.view_aml',
  BO__USERS__VIEW_COMMENTS = 'bo.users.view_comments',
  BO__USERS__VIEW_BRIEF = 'bo.users.view_brief',
  BO__USERS__ADMIN_FILTER = 'bo.users.admin_filter',
  BO__USERS__UPDATE = 'bo.users.update',
  BO__USERS__IMPERSONATE = 'bo.users.impersonate',
  BO__USERS__SUSPEND = 'bo.users.suspend',
  BO__USERS__DELETE_USER = 'bo.users.delete_user',
  BO__USERS__SYNC_WALLET = 'bo.users.sync_wallet',
  BO__USERS__ASSIGN_ADMINISTRATOR = 'bo.users.assign_administrator',
  BO__USERS__ADD_ACCREDITATION = 'bo.users.add_accreditation',
  BO__USERS__MANAGE_AFFILIATE_AMBASSADORS = 'bo.users.manage_affiliate_ambassadors',
  BO__USERS__MANAGE_GROUPABLE_INVESTMENTS = 'bo.users.manage_groupable_investments',
  BO__USERS__MANAGE_IS_HIGH_NET_WORTH = 'bo.users.manage_is_high_net_worth',
  BO__USERS__REMOVE_2FA = 'bo.users.remove_2fa',
  BO__USERS__EDIT_ACCREDITATIONS = 'bo.users.edit_accreditations',
  BO__USERS__REMOVE_ACCREDITATION = 'bo.users.remove_accreditation',
  BO__USERS__SEND_NOTIFICATION = 'bo.users.send_notification',
  BO__USERS__SEND_EMAIL_CONFIRMATION = 'bo.users.send_email_confirmation',
  BO__USERS__SEND_TEMPORARY_PASSWORD = 'bo.users.send_temporary_password',
  BO__USERS__UPLOAD_DOCUMENTS = 'bo.users.upload_documents',
  BO__USERS__UPLOAD_LEMONWAY_DOCUMENTS = 'bo.users.upload_lemonway_documents',
  BO__USERS__CREATE_COMMENT = 'bo.users.create_comment',
  BO__USERS__UPDATE_COMMENT = 'bo.users.update_comment',
  BO__USERS__DELETE_COMMENT = 'bo.users.delete_comment',
  BO__USERS__UPDATE_RISK_STATUS = 'bo.users.update_risk_status',
  BO__USERS__FREEZE_ACCOUNT = 'bo.users.freeze_account',
  BO__USERS__CHANGE_PROJECT_DEVELOPER_MANAGER = 'bo.users.change_project_developer_manager',
  BO__APPLICATIONS__FILTER = 'bo.applications.filter',
  BO__APPLICATIONS__VIEW = 'bo.applications.view',
  BO__APPLICATIONS__VIEW_COMPANY = 'bo.applications.view_company',
  BO__APPLICATIONS__VIEW_CREDIT = 'bo.applications.view_credit',
  BO__APPLICATIONS__VIEW_CREDIT_DETAILS = 'bo.applications.view_credit_details',
  BO__APPLICATIONS__VIEW_SECURITIES = 'bo.applications.view_securities',
  BO__APPLICATIONS__VIEW_PROTOCOL = 'bo.applications.view_protocol',
  BO__APPLICATIONS__VIEW_DOCUMENTS = 'bo.applications.view_documents',
  BO__APPLICATIONS__VIEW_PICTURES = 'bo.applications.view_pictures',
  BO__APPLICATIONS__VIEW_STATUS_HISTORY = 'bo.applications.view_status_history',
  BO__APPLICATIONS__VIEW_ACTION_HISTORY = 'bo.applications.view_action_history',
  BO__APPLICATIONS__VIEW_RISKS = 'bo.applications.view_risks',
  BO__APPLICATIONS__VIEW_RELATED_PROJECTS = 'bo.applications.view_related_projects',
  BO__APPLICATIONS__VIEW_FINANCIAL_INFO = 'bo.applications.view_financial_info',
  BO__APPLICATIONS__CREATE = 'bo.applications.create',
  BO__APPLICATIONS__REJECT = 'bo.applications.reject',
  BO__APPLICATIONS__CHANGE_MANAGER = 'bo.applications.change_manager',
  BO__APPLICATIONS__CHANGE_ANALYTIC = 'bo.applications.change_analytic',
  BO__APPLICATIONS__CHANGE_OWNER = 'bo.applications.change_owner',
  BO__APPLICATIONS__MANAGE = 'bo.applications.manage',
  BO__APPLICATIONS__CONVERT_TO_PROJECT = 'bo.applications.convert_to_project',
  BO__APPLICATIONS__UPDATE_CREDIT = 'bo.applications.update.credit',
  BO__APPLICATIONS__UPDATE_CREDIT_DETAILS = 'bo.applications.update_credit_details',
  BO__APPLICATIONS__UPDATE_SECURITIES = 'bo.applications.update_securities',
  BO__APPLICATIONS__UPDATE_DOCUMENTS = 'bo.applications.update_documents',
  BO__APPLICATIONS__UPDATE_PICTURES = 'bo.applications.update_pictures',
  BO__APPLICATIONS__UPDATE_RISKS = 'bo.applications.update_risks',
  BO__APPLICATIONS__UPDATE_FINANCIAL_INFO = 'bo.applications.update_financial_info',
  BO__APPLICATIONS__UPDATE_LOCKED_APPLICATIONS = 'bo.applications.update_locked_applications',
  BO__APPLICATIONS__UPDATE_SALES_MANAGER_PROTOCOL = 'bo.applications.update_sales_manager_protocol',
  BO__APPLICATIONS__UPDATE_ANALYTIC_PROTOCOL = 'bo.applications.update_analytic_protocol',
  BO__APPLICATIONS__UPDATE_COMMITTEE_MEMBER_PROTOCOL = 'bo.applications.update_committee_member_protocol',
  BO__PROJECTS__FILTER = 'bo.projects.filter',
  BO__PROJECTS__VIEW = 'bo.projects.view',
  BO__PROJECTS__VIEW_WALLET_P2P_OPERATIONS = 'bo.projects.view_wallet_p2p_operations',
  BO__PROJECTS__VIEW_INVESTMENTS = 'bo.projects.view_investments',
  BO__PROJECTS__REFUND_INVESTMENTS = 'bo.projects.refund_investments',
  BO__PROJECTS__REFUND_INVESTMENTS_ANYTIME = 'bo.projects.refund_investments_anytime',
  BO__PROJECTS__VIEW_RESERVED_INVESTMENTS = 'bo.projects.view_reserved_investments',
  BO__PROJECTS__CREATE_RESERVED_INVESTMENTS = 'bo.projects.create_reserved_investments',
  BO__PROJECTS__EDIT_RESERVED_INVESTMENTS = 'bo.projects.edit_reserved_investments',
  BO__PROJECTS__VIEW_INVESTMENT = 'bo.projects.view_investment',
  BO__PROJECTS__VIEW_PROJECT = 'bo.projects.view_project',
  BO__PROJECTS__VIEW_OWNER = 'bo.projects.view_owner',
  BO__PROJECTS__VIEW_SECURITIES = 'bo.projects.view_securities',
  BO__PROJECTS__VIEW_DOCUMENTS = 'bo.projects.view_documents',
  BO__PROJECTS__VIEW_PICTURES = 'bo.projects.view_pictures',
  BO__PROJECTS__VIEW_ARTICLES = 'bo.projects.view_articles',
  BO__PROJECTS__VIEW_FINANCIAL_INFO = 'bo.projects.view_financial_info',
  BO__PROJECTS__VIEW_DEBT_COLLECTION = 'bo.projects.view_debt_collection',
  BO__PROJECTS__VIEW_STATUS_HISTORY = 'bo.projects.view_status_history',
  BO__PROJECTS__VIEW_ACTION_HISTORY = 'bo.projects.view_action_history',
  BO__PROJECTS__GENERATE_PAYOUTS = 'bo.projects.project.generate_payouts',
  BO__PROJECTS__CREATE_ARTICLE = 'bo.projects.create_article',
  BO__PROJECTS__STAGE = 'bo.projects.stage',
  BO__PROJECTS__UPDATE = 'bo.projects.update',
  BO__PROJECTS__UPDATE_INVESTMENT = 'bo.projects.update_investment',
  BO__PROJECTS__UPDATE_SPECIAL_FIELDS_AFTER_FUNDED = 'bo.projects.update_special_fields_after_funded',
  BO__PROJECTS__UPDATE_PROJECT = 'bo.projects.update_project',
  BO__PROJECTS__UPDATE_OWNER_INFORMATION = 'bo.projects.update_owner',
  BO__PROJECTS__UPDATE_SECURITIES = 'bo.projects.update_securities',
  BO__PROJECTS__UPDATE_DOCUMENTS = 'bo.projects.update_documents',
  BO__PROJECTS__DEVELOPER_LOAN_AGREEMENT__MANAGE = 'bo.projects.developer_loan_agreement.manage',
  BO__PROJECTS__INVESTOR_LOAN_AGREEMENT__MANAGE = 'bo.projects.investor_loan_agreement.manage',
  BO__PROJECTS__UPDATE_PICTURES = 'bo.projects.update_pictures',
  BO__PROJECTS__UPDATE_ARTICLE = 'bo.projects.update_article',
  BO__PROJECTS__UPDATE_FINANCIAL_INFO = 'bo.projects.update_financial_info',
  BO__PROJECTS__MARK_READY_FOR_INVESTMENTS = 'bo.projects.mark_ready_for_investments',
  BO__PROJECTS__SEND_EMAIL_AFTER_READY_FOR_INVESTMENTS = 'bo.projects.send_email_after_ready_for_investments',
  BO__PROJECTS__MARK_COMING_SOON = 'bo.projects.mark_coming_soon',
  BO__PROJECTS__MARK_OPEN_FOR_INVESTMENTS = 'bo.projects.mark_open_for_investments',
  BO__PROJECTS__MARK_FUNDED = 'bo.projects.mark_funded',
  BO__PROJECTS__MARK_NOT_FUNDED = 'bo.projects.mark_not_funded',
  BO__PROJECTS__MARK_CONFIRMED = 'bo.projects.mark_confirmed',
  BO__PROJECTS__MARK_FINISHED = 'bo.projects.mark_finished',
  BO__PROJECTS__REOPEN_PROJECT_FOR_INVESTMENTS = 'bo.projects.reopen_project_for_investments',
  BO__PROJECTS__REFINANCE_PROJECT = 'bo.projects.refinance_project',
  BO__COMMUNICATION__MANAGEMENT__VIEW = 'bo.communication.management.view',
  BO__COMMUNICATION__MANAGEMENT__FILTER_PROJECTS = 'bo.communication.management.filter_projects',
  BO__COMMUNICATION__MANAGEMENT__NOTIFY = 'bo.communication.management.notify',
  BO__COMMUNICATION__CUSTOM_HISTORY__VIEW = 'bo.communication.custom_history.view',
  BO__COMMUNICATION__AUTO_HISTORY__VIEW = 'bo.communication.auto_history.view',
  BO__PAYMENTS__PROJECT_PAYOUTS__LIST = 'bo.payments.project_payouts.list',
  BO__PAYMENTS__PROJECT_PAYOUTS__VIEW = 'bo.payments.project_payouts.view',
  BO__PAYMENTS__PROJECT_PAYOUTS__VIEW__DATA = 'bo.payments.project_payouts.view.data',
  BO__PAYMENTS__PROJECT_PAYOUTS__VIEW__BORROWER_PAYOUTS = 'bo.payments.project_payouts.view.borrower_payouts',
  BO__PAYMENTS__PROJECT_PAYOUTS__VIEW__LOAN_FINES = 'bo.payments.project_payouts.view.loan_fines',
  BO__PAYMENTS__PROJECT_PAYOUTS__SET__ADDITIONAL_LOAN_ATTRIBUTE = 'bo.payments.project_payouts.set.additional_loan_attribute',
  BO__PAYMENTS__PROJECT_PAYOUTS__STORE__LOAN_FINE = 'bo.payments.project_payouts.store.loan_fine',
  BO__PAYMENTS__PROJECT_PAYOUTS__UPDATE__LOAN_FINE = 'bo.payments.project_payouts.update.loan_fine',
  BO__PAYMENTS__PROJECT_PAYOUTS__DELETE__LOAN_FINE = 'bo.payments.project_payouts.delete.loan_fine',
  BO__PAYMENTS__PROJECT_PAYOUTS__UPDATE__DATA = 'bo.payments.project_payouts.update.data',
  BO__PAYMENTS__PROJECT_PAYOUTS__UPDATE__BORROWER_PAYOUTS = 'bo.payments.project_payouts.update.borrower_payouts',
  BO__PAYMENTS__PROJECT_PAYOUTS__UPDATE__CONFIRMED_BORROWER_PAYOUTS = 'bo.payments.project_payouts.update.confirmed_borrower_payouts',
  BO__PAYMENTS__PROJECT_PAYOUTS__TRANSFER_FUNDS = 'bo.payments.project_payouts.transfer_funds',
  BO__PAYMENTS__BORROWER_PAYOUTS__LIST = 'bo.payments.borrower_payouts.list',
  BO__PAYMENTS__BORROWER_PAYOUTS__VIEW = 'bo.payments.borrower_payouts.view',
  BO__PAYMENTS__BORROWER_PAYOUTS__CREATE_PARTIAL_RETURN = 'bo.payments.borrower_payouts.create_partial_return',
  BO__PAYMENTS__BORROWER_PAYOUTS__ESTIMATE__PAYOUT = 'bo.payments.borrower_payouts.estimate.payout',
  BO__PAYMENTS__FROZEN_DEVELOPER_PAYOUTS__LIST = 'bo.payments.frozen_developer_payouts.list',
  BO__PAYMENTS__BORROWER_PAYOUTS__CONFIRM_BORROWER_PAYOUT = 'bo.payments.borrower_payouts.confirm_borrower_payout',
  BO__PAYMENTS__PROJECT_PAYOUTS__REGENERATE_PAYMENT_SCHEDULE_DOCS = 'bo.payments.project_payouts.regenerate_payment_schedule_docs',
  BO__PAYMENTS__BORROWER_PAYOUTS__IMPORT_INVESTMENT_PAYOUTS = 'bo.payments.borrower_payouts.import_investment_payouts',
  BO__PAYMENTS__BORROWER_PAYOUTS__MODIFY_RECEIVED_AT = 'bo.payments.borrower_payouts.modify_received_at',
  BO__PAYMENTS__BORROWER_PAYOUTS__INVESTMENT_PAYOUTS__EXPORT_OBSOLETE = 'bo.payments.borrower_payouts.investment_payouts.export_obsolete',
  BO__PAYMENTS__BORROWER_PAYOUTS__INVESTMENT_PAYOUTS__LIST = 'bo.payments.borrower_payouts.investment_payouts.list',
  BO__PAYMENTS__BORROWER_PAYOUTS__INVESTMENT_PAYOUTS__CONFIRM_INVESTMENT_PAYOUT = 'bo.payments.borrower_payouts.investment_payouts.confirm_investment_payout',
  BO__PAYMENTS__BORROWER_PAYOUTS__INVESTMENT_PAYOUTS__RETRY_FAILED_INVESTMENT_PAYOUT = 'bo.payments.borrower_payouts.investment_payouts.retry_failed_investment_payout',
  BO__PAYMENTS__INVOICES__LIST = 'bo.payments.invoices.list',
  BO__PAYMENTS__INVOICES__STORE = 'bo.payments.invoices.store',
  BO__PAYMENTS__INVOICES__UPDATE = 'bo.payments.invoices.update',
  BO__PAYMENTS__INVOICES__DOWNLOAD_DOCUMENT = 'bo.payments.invoices.download_document',
  BO__PAYMENTS__MANUAL_WITHDRAWALS__LIST = 'bo.payments.manual_withdrawals.list',
  BO__PAYMENTS__MANUAL_WITHDRAWALS__CONFIRM = 'bo.payments.manual_withdrawals.confirm',
  BO__DOCUMENT_TEMPLATES__LIST = 'bo.document_templates.list',
  BO__DOCUMENT_TEMPLATES__VIEW = 'bo.document_templates.view',
  BO__DOCUMENT_TEMPLATES__VIEW__ACTIONS = 'bo.document_templates.view.actions',
  BO__DOCUMENT_TEMPLATES__EDIT = 'bo.document_templates.edit',
  BO__MEDIA_GENERATE_PUBLIC_URL = 'bo.media.generate_public_url',
  BO__AUTO_INVESTMENT__VIEW = 'bo.auto_investment.view',
  BO__AUTO_INVESTMENT__FETCH_PROJECT_DRAFT_QUEUE = 'bo.auto_investment.fetch_project_draft_queue',
  BO__AUTO_INVESTMENT__FETCH_FILTER_DRAFT_QUEUE = 'bo.auto_investment.fetch_filter_draft_queue',
  BO__LOCALE__TRANSLATIONS__FILTER = 'bo.locale.translations.filter',
  BO__LOCALE__TRANSLATIONS__UPDATE = 'bo.locale.translations.update',
  BO__LOCALE__TRANSLATIONS__DELETE = 'bo.locale.translations.delete',
  BO__BONUS_SYSTEM__LIST = 'bo.bonus_system.list',
  BO__BONUS_SYSTEM__VIEW = 'bo.bonus_system.view',
  BO__BONUS_SYSTEM__STORE = 'bo.bonus_system.store',
  BO__BONUS_SYSTEM__EDIT = 'bo.bonus_system.edit',
  BO__BONUS_SYSTEM__DISABLE = 'bo.bonus_system.disable',
  BO__OPERATIONS__P2P__LIST = 'bo.operations.p2p.list',
  BO__OPERATIONS__MONEY__LIST = 'bo.operations.money.list',
  BO__DEBT_COLLECTION__LIST = 'bo.debt_collection.list',
  BO__DEBT_COLLECTION__VIEW = 'bo.debt_collection.view',
  BO__DEBT_COLLECTION__DOCUMENTS_VIEW = 'bo.debt_collection.documents_view',
  BO__DEBT_COLLECTION__UPDATE_ACTIONS = 'bo.debt_collection.update_actions',
  BO__DEBT_COLLECTION__UPDATE_SECURITIES = 'bo.debt_collection.update_securities',
  BO__DEBT_COLLECTION__TERMINATE_CONTRACT = 'bo.debt_collection.terminate_contract',
  BO__LANDING_PAGE__INQUIRIES__VIEW = 'bo.landing_page.inquiries.view',
  BO__LANDING_PAGE__NEWS__VIEW = 'bo.landing_page.news.view',
  BO__LANDING_PAGE__NEWS__CREATE = 'bo.landing_page.news.create',
  BO__LANDING_PAGE__NEWS__UPDATE = 'bo.landing_page.news.update',
  BO__LANDING_PAGE__NEWS__DELETE = 'bo.landing_page.news.delete',
  BO__LANDING_PAGE__NEWS__MANAGE_PICTURES = 'bo.landing_page.news.manage.pictures',
  BO__LANDING_PAGE__EXTERNAL_NEWS__VIEW = 'bo.landing_page.external_news.view',
  BO__LANDING_PAGE__EXTERNAL_NEWS__UPDATE = 'bo.landing_page.external_news.update',
  BO__LANDING_PAGE__EXTERNAL_NEWS__CREATE = 'bo.landing_page.external_news.create',
  BO__LANDING_PAGE__EXTERNAL_NEWS__DELETE = 'bo.landing_page.external_news.delete',
  BO__LANDING_PAGE__JOB_POSITIONS__VIEW = 'bo.landing_page.job_positions.view',
  BO__LANDING_PAGE__JOB_POSITIONS__UPDATE = 'bo.landing_page.job_positions.update',
  BO__LANDING_PAGE__JOB_POSITIONS__CREATE = 'bo.landing_page.job_positions.create',
  BO__LANDING_PAGE__JOB_POSITIONS__DELETE = 'bo.landing_page.job_positions.delete',
  BO__LANDING_PAGE__TEAM_MEMBERS__VIEW = 'bo.landing_page.team_members.view',
  BO__LANDING_PAGE__TEAM_MEMBERS__UPDATE = 'bo.landing_page.team_members.update',
  BO__LANDING_PAGE__TEAM_MEMBERS__CREATE = 'bo.landing_page.team_members.create',
  BO__LANDING_PAGE__TEAM_MEMBERS__DELETE = 'bo.landing_page.team_members.delete',
  BO__LANDING_PAGE__TEAM_MEMBERS__MANAGE_PICTURES = 'bo.landing_page.team_members.manage.pictures',
  BO__LANDING_PAGE__FAQ__VIEW = 'bo.landing_page.faq.view',
  BO__LANDING_PAGE__FAQ__UPDATE = 'bo.landing_page.faq.update',
  BO__LANDING_PAGE__FAQ__CREATE = 'bo.landing_page.faq.create',
  BO__LANDING_PAGE__FAQ__DELETE = 'bo.landing_page.faq.delete',
  BO__LANDING_PAGE__TRANSLATIONS__VIEW = 'bo.landing_page.translations.view',
  BO__LANDING_PAGE__TRANSLATIONS__FILTER = 'bo.landing_page.translations.filter',
  BO__LANDING_PAGE__TRANSLATIONS__UPDATE = 'bo.landing_page.translations.update',
  BO__LANDING_PAGE__TRANSLATIONS__DELETE = 'bo.landing_page.translations.delete',
  BO__LANDING_PAGE__SEO__VIEW = 'bo.landing_page.seo.view',
  BO__LANDING_PAGE__SEO__UPDATE = 'bo.landing_page.seo.update',
  BO__REPORTS__VIEW = 'bo.reports.view',
  BO__REPORTS__GENERATE = 'bo.reports.generate',
  BO__REPORTS__EDIT = 'bo.reports.edit',
  BO__REPORTS__DELETE = 'bo.reports.delete',
  BO__REPORTS__REGISTRATIONS__MANAGE = 'bo.reports.registrations.manage',
  BO__REPORTS__INVESTMENTS__MANAGE = 'bo.reports.investments.manage',
  BO__REPORTS__APPLICATIONS__MANAGE = 'bo.reports.applications.manage',
  BO__REPORTS__PROJECTS__MANAGE = 'bo.reports.projects.manage',
  BO__REPORTS__DETAILED_PROJECTS__MANAGE = 'bo.reports.detailed_projects.manage',
  BO__REPORTS__PROJECT_PAYMENT_SCHEDULE__MANAGE = 'bo.reports.project_payment_schedule.manage',
  BO__REPORTS__INVESTORS_PORTFOLIO_ACTIVITY__MANAGE = 'bo.reports.investors_portfolio_activity.manage',
  BO__REPORTS__LATE_PROJECTS__MANAGE = 'bo.reports.late_projects.manage',
  BO__REPORTS__TRANSACTIONS__MANAGE = 'bo.reports.transactions.manage',
  BO__REPORTS__WALLETS__MANAGE = 'bo.reports.wallets.manage',
  BO__REPORTS__SECONDARY_MARKET_INVESTMENTS__MANAGE = 'bo.reports.secondary_market_investments.manage',
  BO__REPORTS__BONUSES__MANAGE = 'bo.reports.bonuses.manage',
  BO__REPORTS__BONUSES_AMOUNT__MANAGE = 'bo.reports.bonuses_amount.manage',
  BO__REPORTS__BONUSES_INTEREST__MANAGE = 'bo.reports.bonuses_interest.manage',
  BO__REPORTS__INVESTORS__MANAGE = 'bo.reports.investors.manage',
  BO__REPORTS__VMI313__MANAGE = 'bo.reports.vmi313.manage',
  BO__REPORTS__VMI312__MANAGE = 'bo.reports.vmi312.manage',
  BO__REPORTS__LB__MANAGE = 'bo.reports.lb.manage',
  BO__REPORTS__REFERRALS__MANAGE = 'bo.reports.referrals.manage',
  BO__REPORTS__AFFILIATE_REGISTRATIONS__MANAGE = 'bo.reports.affiliate_registrations.manage',
  BO__REPORTS__AFFILIATE_INVESTMENT_TRANSACTIONS__MANAGE = 'bo.reports.affiliate_investment_transactions.manage',
  BO__REPORTS__INVESTMENT_PAYOUTS__MANAGE = 'bo.reports.investment_payouts.manage',
  BO__REPORTS__BORROWER_PAYOUTS__MANAGE = 'bo.reports.borrower_payouts.manage',
  BO__REPORTS__NEWSLETTER_SUBSCRIBERS__MANAGE = 'bo.reports.newsletter_subscribers.manage',
  BO__REPORTS__INVESTORS_FIRST_BONUS__MANAGE = 'bo.reports.investors_first_bonus.manage',
  BO__REPORTS__USER_MARKETING_SETTINGS__MANAGE = 'bo.reports.investors_portfolio_activity.manage',
  BO__REPORTS__WANT_TO_INVEST__MANAGE = 'bo.reports.want_to_invest.manage',
  BO__RC_REPORTS_FETCH = 'bo.rc_reports.fetch',
  BO__RC_REPORTS_LIST = 'bo.rc_reports.list',
  BO__RC_REPORTS_DELETE = 'bo.rc_reports.delete',
  BO__AML_MANAGEMENT__LIST = 'bo.aml_management.list',
  BO__AML_MANAGEMENT__ISSUES__MARK_FOR_DELETION = 'bo.aml_management.issues.mark_for_deletion',
  BO__IMPORTS__VIEW = 'bo.imports.view',
  BO__DASHBOARD__WALLETS_DATA = 'bo.dashboard.wallets_data',
  BO__DASHBOARD__MARKETING_DATA = 'bo.dashboard.marketing_data',
  BO__DASHBOARD__PORTFOLIO_DATA = 'bo.dashboard.portfolio_data',
  BO__DASHBOARD__INVESTMENTS_DATA = 'bo.dashboard.investments_data',
  BO__PROJECTS__ADD_BONUS_TO_INVESTMENT = 'bo.projects.add_bonus_to_investment',
  BO__PROJECTS__EMAIL_CAMPAIGNS = 'bo.projects.email_campaigns',
  BO__REPORTS__WANT_TO_TRANSFER_INVESTMENT__MANAGE = 'bo.reports.want_to_transfer_investment.manage',
  BO__PROSPECT_APPLICATIONS__FILTER = 'bo.prospect_applications.filter',
  BO__PROSPECT_APPLICATIONS__VIEW = 'bo.prospect_applications.view',
  BO__FORECAST__LIST = 'bo.forecast.list',
  BO__FORECAST__PRICING_TOOL = 'bo.forecast.pricing_tool',
}
