import { Field, useField, useFormikContext } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { UncontrolledTooltip } from 'reactstrap';

export interface Props
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  name: string;
  showPlaceholder?: boolean;
  translationPrefix?: string;
  tooltip?: string;
}

const CheckboxInput: React.FC<Props> = ({ name, ...props }) => {
  const { t } = useTranslation();
  const [field, meta] = useField(name);
  const formik = useFormikContext();

  if (!props.placeholder && props.showPlaceholder) {
    props.placeholder = t(props.translationPrefix + name);
  }

  const isError = meta?.touched && !!meta?.error && formik.submitCount > 0;

  return (
    <div className={`form-check`}>
      <label className={`form-check-label form-label ${!!meta.error ? ' is-invalid' : ''}`}>
        <Field
          onChange={field.onChange}
          onBlur={field.onBlur}
          name={name}
          type="checkbox"
          className={`form-check-input${isError ? ' is-invalid' : ''}`}
          {...props}
        />
        <div className={`ms-2 d-inline-block ${props.disabled ? 'label-text-disabled' : ''}`}>
          {props.placeholder}
        </div>
        {props.tooltip ? (
          <>
            <i className={'ms-2 fas fa-info-circle'} id={name} />
            <UncontrolledTooltip target={name}>{props.tooltip}</UncontrolledTooltip>
          </>
        ) : null}
      </label>

      <div className={!!meta.error ? 'invalid-feedback' : ''}>{meta.error}</div>
    </div>
  );
};

CheckboxInput.defaultProps = {
  translationPrefix: 'label.',
  showPlaceholder: true,
};

export default CheckboxInput;
