import React, { createContext, useContext } from 'react';
import { DebtCollectionResponseDto } from 'src/types/api/DebtCollection';

export interface DebtCollectionContextState {
  debtCollection: DebtCollectionResponseDto | undefined;
  setDebtCollection: (debtCollection: DebtCollectionResponseDto) => void;
}

const DebtCollectionContext = createContext<DebtCollectionContextState>({
  debtCollection: undefined,
  setDebtCollection: () => {
    return;
  },
});

export const DebtCollectionProvider: React.FC<DebtCollectionContextState> = ({
  debtCollection,
  setDebtCollection,
  children,
}) => {
  return (
    <DebtCollectionContext.Provider value={{ debtCollection, setDebtCollection }}>
      {children}
    </DebtCollectionContext.Provider>
  );
};

type Optionalize<T extends K, K> = Omit<T, keyof K>;

export function withDebtCollection<
  P extends DebtCollectionContextState = DebtCollectionContextState,
>(Component: React.ComponentType<P>) {
  const WithDebtCollection = ({ ...props }: Optionalize<P, DebtCollectionContextState>) => {
    const c = useContext(DebtCollectionContext);
    return <Component {...c} {...(props as P)} />;
  };

  WithDebtCollection.displayName = `withDebtCollection${Component.displayName}`;

  return WithDebtCollection;
}

export default DebtCollectionProvider;
