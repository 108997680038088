import React, { useCallback, useEffect, useState } from 'react';
import { FormGroup, ModalBody, ModalHeader } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import Api from 'src/api';

import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import { ModalComponent } from 'src/components/Modal/ModalComponent';
import ModalCloseButton from 'src/components/Modal/ModalCloseButton';
import { ProjectDetailValueDto } from 'src/types/api/projects';
import LanguageSwitcher from 'src/components/LanguageSwitcher';
import { error, success } from 'src/services/toastr';
import SaveButton from 'src/components/Form/SaveButton';
import { defaultTranslatableValue } from 'src/helpers';
import LocaleFormik from 'src/components/Form/LocaleFormik/LocaleFormik';
import WYSIWYGInput from 'src/components/Form/WYSIWYGInput';
import { ReactSelectOption } from 'src/components/Form/Select/SelectInput';
import { ProjectEmailCampaignTypeEnum } from 'src/helpers/Enums/Project/ProjectEmailCampaignTypeEnum';
import { SelectInput } from 'src/components/Form/Select';

interface Props {
  projectId: string;
  refetchDescriptions: () => void;
  values?: ProjectDetailValueDto<string> | null;
}

const INITIAL_VALUES = {
  project_open_for_investments_email_campaign_description: defaultTranslatableValue(),
  type: ProjectEmailCampaignTypeEnum.PROJECT_OPEN_FOR_INVESTMENT_CAMPAIGN,
};

const CampaignCreateDescriptionModal: React.FC<Props> = ({
  projectId,
  values,
  refetchDescriptions,
}) => {
  const { hideModal } = useGlobalModalContext();
  const [description, setDescription] = useState(INITIAL_VALUES);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const [options] = useState<ReactSelectOption[]>([
    ...Object.values(ProjectEmailCampaignTypeEnum).map((type) => ({
      value: type,
      label: t('projects.campaigns.type.' + type),
    })),
  ]);

  const onSubmit = useCallback(
    async (request) => {
      setIsLoading(true);
      try {
        await Api.projects.storeEmailCampaignDescription(projectId, request);
        hideModal();
        success(t('common.updated_success'));
        refetchDescriptions();
      } catch (e: any) {
        error(t('common.updated_success'));
      } finally {
        setIsLoading(false);
      }
    },
    [refetchDescriptions, projectId, hideModal, t],
  );

  useEffect(() => {
    if (!values) return;

    setDescription({
      ...description,
      project_open_for_investments_email_campaign_description: values,
    });
  }, [description, values]);

  return (
    <ModalComponent size={'lg'}>
      <ModalHeader toggle={hideModal} close={<ModalCloseButton />}>
        {t('common.edit_description')}
      </ModalHeader>
      <ModalBody>
        <LocaleFormik initialValues={description} enableReinitialize={true} onSubmit={onSubmit}>
          {({ locale, setLocale, multiLocaleAttrs }) => (
            <>
              <LanguageSwitcher locale={locale} setLocale={setLocale} />
              <FormGroup>
                <SelectInput name={'type'} options={options} isDisabled={true} />
              </FormGroup>
              <FormGroup>
                <WYSIWYGInput
                  {...multiLocaleAttrs('project_open_for_investments_email_campaign_description')}
                  placeholder={t('label.email_campaign_description')}
                />
              </FormGroup>
              <FormGroup>
                <SaveButton title={t('common.save')} submitting={isLoading} />
              </FormGroup>
            </>
          )}
        </LocaleFormik>
      </ModalBody>
    </ModalComponent>
  );
};

export default CampaignCreateDescriptionModal;
