import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Container } from 'reactstrap';
import { Cell, Column } from 'react-table';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Api from 'src/api';
import { Permissions } from 'src/helpers/auth/permissions';
import { setGlobalLoading } from 'src/modules/app/actions';
import Table from 'src/components/Table';
import { PaginationData, PaginationDataFilter } from 'src/types';
import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import PermissionAction from 'src/components/PermissionAction';
import { HiddenColumnsProvider } from 'src/components/ShowTableColumnsDropdown';
import { ProjectLoanFinesResponseDto } from 'src/types/api/payments/projectPayouts';
import StoreLoanFineModal from 'src/pages/Payment/ProjectPayments/Update/Tabs/TabLoanFines/List/StoreLoanFineModal';
import UpdateLoanFineModal from 'src/pages/Payment/ProjectPayments/Update/Tabs/TabLoanFines/List/UpdateLoanFineModal';
import DeleteEntityModal from 'src/components/Table/Buttons/DeleteEntityButton';

const INITIAL_SORT_ORDER = [
  {
    id: 'created_at',
    desc: true,
  },
];

const ProjectLoanFinesList: React.FC = () => {
  const { t } = useTranslation();
  const { projectId } = useParams<{ projectId: string }>();
  const [data, setData] = useState<PaginationData<ProjectLoanFinesResponseDto>>();
  const { showModal } = useGlobalModalContext();
  const [refreshCount, setRefreshCount] = useState(0);

  useEffect(() => {
    setGlobalLoading(false);
  }, []);

  const fetchData = useCallback(
    async (request: PaginationDataFilter | undefined) => {
      return Api.payments.projectPayouts
        .fetchFilteredLoanFines(projectId, request)
        .then((response) => setData(response));
    },
    // eslint-disable-next-line
    [projectId],
  );

  const showUpdateLoanFineModal = useCallback(
    (loanFineId, cell) => {
      showModal(
        <UpdateLoanFineModal
          projectId={projectId}
          loanFineId={loanFineId}
          onAction={() => cell.dispatch({ type: 'refreshData' })}
        />,
      );
    },
    [projectId, showModal],
  );

  const columns = useMemo<Column<ProjectLoanFinesResponseDto>[] | any>(
    () => [
      {
        Header: t('table.id'),
        accessor: 'id',
        sortType: 'string',
        width: 200,
      },
      {
        Header: t('table.type'),
        accessor: 'type',
        sortType: 'string',
        width: 200,
      },
      {
        Header: t('table.fine_start_at'),
        accessor: 'fine_start_at',
        sortType: 'string',
        width: 200,
      },
      {
        Header: t('table.fine_end_at'),
        accessor: 'fine_end_at',
        sortType: 'string',
        width: 200,
      },
      {
        Header: t('table.reason'),
        accessor: 'reason',
        sortType: 'string',
        width: 200,
      },
      {
        Header: t('table.action'),
        Cell: (cell: Cell<ProjectLoanFinesResponseDto> | any) => {
          const row = cell.row.original;
          return (
            <div className={'actions d-flex gap-2'}>
              <div className={'link-primary cursor'} title={t('common.edit')}>
                <i
                  className={'fas fa-pencil-alt'}
                  onClick={() => showUpdateLoanFineModal(row.id, cell)}
                />
              </div>
              <PermissionAction
                permissions={Permissions.BO__PAYMENTS__PROJECT_PAYOUTS__DELETE__LOAN_FINE}
              >
                <DeleteEntityModal
                  title={t('payments.loan_fines.delete_loan_fine')}
                  onDelete={() => {
                    return Api.payments.projectPayouts
                      .deleteProjectLoanFine(projectId, row.id)
                      .then(() => cell.dispatch({ type: 'refreshData' }));
                  }}
                />
              </PermissionAction>
            </div>
          );
        },
      },
    ],
    [projectId, showUpdateLoanFineModal, t],
  );

  const showStoreLoanFineModal = useCallback(() => {
    showModal(
      <StoreLoanFineModal
        projectId={projectId}
        onAction={async () => setRefreshCount((prev) => prev + 1)}
      />,
    );
  }, [projectId, showModal]);

  return (
    <React.Fragment>
      <HiddenColumnsProvider title={'TabLoanFinesList'}>
        <Container fluid>
          <h3 className={'m-0'}>{t('menu.loan_fines')}</h3>
          <hr />
          <Table
            title={'TabLoanFinesList'}
            onFetchData={fetchData}
            columns={columns}
            data={data}
            searchable={true}
            enableQueryFilter={true}
            initialSortBy={INITIAL_SORT_ORDER}
            refreshOnValueChange={refreshCount}
            createComponent={
              <PermissionAction
                permissions={Permissions.BO__PAYMENTS__PROJECT_PAYOUTS__STORE__LOAN_FINE}
              >
                <button onClick={showStoreLoanFineModal} className={'btn btn-primary w-100'}>
                  {t('common.create_new')}
                </button>
              </PermissionAction>
            }
          />
        </Container>
      </HiddenColumnsProvider>
    </React.Fragment>
  );
};

export default ProjectLoanFinesList;
