import React, { useEffect, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import AdvanceFilter, { AdvanceFilterProps } from './AdvanceFilter';
import CancelButton from 'src/components/Table/AdvanceFilters/Buttons/CancelButton';
import Button from 'src/components/Table/AdvanceFilters/Buttons/Button';
import DatePickerHeader from './DatePickerHeader';
import { format, endOfDay } from 'date-fns';
import TextInput from 'src/components/Inputs/TextInput';
import { DateParam, useQueryParam } from 'use-query-params';

const DateFilter: React.FC<AdvanceFilterProps> = ({ start, end, hidePredefinedDates, column }) => {
  const { t, i18n } = useTranslation();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [startDate, setStartDate] = useQueryParam('startDate', DateParam);
  const [endDate, setEndDate] = useQueryParam('endDate', DateParam);
  const [startInputDate, setStartInputDate] = useState<string>(start);
  const [endInputDate, setEndInputDate] = useState<string>(end);

  const title = useMemo<string>(() => {
    if (startDate && endDate) {
      return `${t('common.date', { date: startDate })} - ${t('common.date', { date: endDate })}`;
    } else if (startDate) {
      return `${t('common.date', { date: startDate })}`;
    }

    return '';
  }, [t, startDate, endDate]);

  const onChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;

    setStartDate(start);
    setEndDate(end);
    start && setStartInputDate(format(start, 'yyyy-MM-dd'));
    end && setEndInputDate(format(end, 'yyyy-MM-dd'));
  };

  const clearValues = () => {
    setStartDate(null);
    setEndDate(null);
    setStartInputDate('');
    setEndInputDate('');

    column.setFilter({ startDate: null, endDate: null });
  };

  const onCancelButtonClicked = () => {
    clearValues();
  };

  const onSaveButtonClicked = async () => {
    setIsOpen(false);
    const endD = endDate ? endOfDay(endDate) : null;

    column.setFilter({ startDate: startDate, endDate: endD });
  };

  useEffect(() => {
    !!start && setStartDate(start);
  }, [start, setStartDate]);

  useEffect(() => {
    !!end && setEndDate(end);
  }, [end, setEndDate]);

  return (
    <AdvanceFilter
      hidePredefinedDates={hidePredefinedDates}
      icon={'icon-calendar'}
      title={title}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <div className={'title'}>{t('communication.set_the_time_frame')}</div>
      <hr className={'divider'} />

      <div className={'action-options-inputs'}>
        <TextInput
          label={t('common.from')}
          name={'start'}
          value={startInputDate}
          onChange={(e) => {
            const value = e.target.value;
            setStartInputDate(value);

            Date.parse(value) && setStartDate(new Date(value));
          }}
        />
        <TextInput
          label={t('common.to')}
          name={'end'}
          value={endInputDate}
          onChange={(e) => {
            const value = e.target.value;
            setEndInputDate(value);

            Date.parse(value) && setEndDate(new Date(value));
          }}
        />
      </div>
      <DatePicker
        selected={startDate}
        onChange={onChange}
        startDate={startDate}
        endDate={endDate}
        disabledKeyboardNavigation
        selectsRange
        inline
        renderCustomHeader={({ date, increaseMonth, decreaseMonth, changeYear, changeMonth }) => (
          <DatePickerHeader
            increaseMonth={increaseMonth}
            decreaseMonth={decreaseMonth}
            locale={i18n.resolvedLanguage}
            date={date}
            changeMonth={changeMonth}
            changeYear={changeYear}
            startFromYear={new Date().getFullYear()}
            endYearRange={30}
            hasFutureRange={false}
            showTitle={false}
          />
        )}
      />
      <div className={'action-options-btns'}>
        <CancelButton onClick={() => onCancelButtonClicked()}>{t('common.cancel')}</CancelButton>
        <Button onClick={() => onSaveButtonClicked()}>{t('common.save')}</Button>
      </div>
    </AdvanceFilter>
  );
};

export default DateFilter;
