import Client from './client';
import { PaginationData, PaginationDataFilter } from 'src/types';
import {
  AmlFrozenStatusChangeLogListResponseDto,
  AmlIssuesLogListResponseDto,
  AmlRiskLevelChangeLogListResponseDto,
  AmlUpdateRequestDto,
  LegalEntitySearchResponse,
  ResidenceCountryResponseDto,
} from 'src/types/api/amlManagement';
import { WorklistInformationResponseDto } from 'src/pages/AmlManagement/Issues';

class AmlManagement extends Client {
  api = {
    FETCH_FILTERED_RISK_LEVEL_CHANGE_LOG: `${this.http.baseUrl}/aml-management/risk-level/filter`,
    FETCH_FILTERED_FROZEN_STATUS_CHANGE_LOG: `${this.http.baseUrl}/aml-management/frozen-status/filter`,
    FETCH_FILTERED_ISSUES_LOG: `${this.http.baseUrl}/aml-management/issues/filter`,
    MARK_ISSUE: `${this.http.baseUrl}/aml-management/issues/issue/{auditId}/mark`,
    CHANGE_STATUS: `${this.http.baseUrl}/aml-management/issues/issue/{auditId}`,
    FILTER_BY_USER: `${this.http.baseUrl}/users/user/{userId}/aml/issues/filter`,
    FILTER_BY_COMPANY: `${this.http.baseUrl}/users/company/{companyId}/aml/issues/filter`,
    PERFORM_AML_SCREENING_FOR_COMPANY: `${this.http.baseUrl}/aml-management/company/{companyId}/screen`,
    VIEW_WORKLIST_INFORMATION: `${this.http.baseUrl}/aml-management/worklist/information`,
    FETCH_RESIDENCE_COUNTRIES: `${this.http.baseUrl}/aml-management/residence-countries/filter`,
    APPROVE_USER_RESIDENCE_COUNTRY: `${this.http.baseUrl}/aml-management/residence-countries/approve/{userId}`,
    DECLINE_USER_RESIDENCE_COUNTRY: `${this.http.baseUrl}/aml-management/residence-countries/decline/{userId}`,
  };

  fetchFilteredRiskLevelChangeLog = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<AmlRiskLevelChangeLogListResponseDto>> => {
    return this.http.get(this.api.FETCH_FILTERED_RISK_LEVEL_CHANGE_LOG, { params: request });
  };

  fetchFilteredFrozenStatusChangeLog = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<AmlFrozenStatusChangeLogListResponseDto>> => {
    return this.http.get(this.api.FETCH_FILTERED_FROZEN_STATUS_CHANGE_LOG, { params: request });
  };

  fetchFilteredIssuesLog = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<AmlIssuesLogListResponseDto>> => {
    return this.http.get(this.api.FETCH_FILTERED_ISSUES_LOG, { params: request });
  };

  fetchFilteredIssuesLogByUser = (
    userId: string,
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<AmlIssuesLogListResponseDto>> => {
    const url = this.buildUrl(this.api.FILTER_BY_USER, { userId });

    return this.http.get(url, { params: request });
  };

  viewWorklistInformation = (): Promise<WorklistInformationResponseDto> => {
    const url = this.buildUrl(this.api.VIEW_WORKLIST_INFORMATION, {});

    return this.http.get(url);
  };

  fetchResidenceCountry = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<ResidenceCountryResponseDto>> => {
    return this.http.get(this.api.FETCH_RESIDENCE_COUNTRIES, { params: request });
  };

  approveAccount = (userId: string): Promise<ResidenceCountryResponseDto> => {
    const url = this.buildUrl(this.api.APPROVE_USER_RESIDENCE_COUNTRY, { userId });

    return this.http.post(url);
  };

  declineAccount = (userId: string): Promise<ResidenceCountryResponseDto> => {
    const url = this.buildUrl(this.api.DECLINE_USER_RESIDENCE_COUNTRY, { userId });

    return this.http.post(url);
  };

  fetchFilteredIssuesLogByCompany = (
    companyId: string,
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<AmlIssuesLogListResponseDto>> => {
    const url = this.buildUrl(this.api.FILTER_BY_COMPANY, { companyId });

    return this.http.get(url, { params: request });
  };

  performAmlScreeningForCompany = (companyId: string): Promise<LegalEntitySearchResponse> => {
    const url = this.buildUrl(this.api.PERFORM_AML_SCREENING_FOR_COMPANY, { companyId });

    return this.http.get(url);
  };

  changeStatus = (
    auditId: string,
    request: AmlUpdateRequestDto,
  ): Promise<AmlIssuesLogListResponseDto> => {
    const url = this.buildUrl(this.api.CHANGE_STATUS, { auditId });

    return this.http.put(url, request);
  };
}

export default AmlManagement;
