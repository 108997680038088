import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import AuthenticationRoutes from './Authentication/AuthenticationRoutes';
import CommonRoutes from './Common/CommonRoutes';
import { RouteList } from '../routes';
import RolesRoutes from './Role/RolesRoutes';
import UserRoutes from './User/UserRoutes';
import ProfileRoutes from './Profile/ProfileRoutes';
import ApplicationRoutes from './Application/ApplicationRoutes';
import LocaleRoutes from './Locale/LocaleRoutes';
import DocumentTemplateRoutes from 'src/pages/DocumentTemplates/DocumentRoutes';
import ProjectRoutes from 'src/pages/Project/ProjectRoutes';
import BonusSystemRoutes from './BonusSystem/BonusSystemRoutes';
import PaymentRoutes from 'src/pages/Payment/PaymentRoutes';
import OperationRoutes from 'src/pages/Operation/OperationRoutes';
import AutoInvestmentRoutes from 'src/pages/AutoInvestment/AutoInvestmentRoutes';
import CommunicationRoutes from 'src/pages/Communication/CommunicationRoutes';
import LandingPageRoutes from 'src/pages/LandingPage/LandingPageRoutes';
import ReportRoutes from 'src/pages/Reports/ReportRoutes';
import AmlManagementRoutes from 'src/pages/AmlManagement/AmlManagementRoutes';
import ImportRoutes from 'src/pages/Import/ImportRoutes';
import NotificationsRoutes from 'src/pages/Notifications/NotificationsRoutes';
import ProspectApplicationRoutes from 'src/pages/ProspectApplication/ProspectApplicationRoutes';
import DebtCollectionRoutes from 'src/pages/DebtCollection/DebtCollectionRoutes';
import ForecastRoutes from 'src/pages/Forecast/ForecastRoutes';
import RCRoutes from 'src/pages/RC/RCReportRoutes';

const Routes: React.FC = () => {
  return (
    <>
      <Switch>
        <Route exact path={RouteList.HOME}>
          <Redirect to={RouteList.DASHBOARD.DASHBOARD.LIST} />
        </Route>
        {AuthenticationRoutes()}
        {CommonRoutes()}
        {UserRoutes()}
        {ProfileRoutes()}
        {RolesRoutes()}
        {ApplicationRoutes()}
        {LocaleRoutes()}
        {DocumentTemplateRoutes()}
        {ReportRoutes()}
        {RCRoutes()}
        {ProjectRoutes()}
        {BonusSystemRoutes()}
        {AutoInvestmentRoutes()}
        {PaymentRoutes()}
        {OperationRoutes()}
        {DebtCollectionRoutes()}
        {CommunicationRoutes()}
        {LandingPageRoutes()}
        {AmlManagementRoutes()}
        {ImportRoutes()}
        {NotificationsRoutes()}
        {ForecastRoutes()}
        {/*<Redirect to={RouteList.NOT_FOUND} />*/}
        {ProspectApplicationRoutes()}
        <Redirect to={RouteList.NOT_FOUND} />
      </Switch>
    </>
  );
};

export default Routes;
