import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Card, CardBody, Container, Row } from 'reactstrap';
import {
  PaymentContextState,
  withPayment,
} from 'src/pages/Payment/ProjectPayments/Update/PaymentContext';
import { useTranslation } from 'react-i18next';
import SidebarDataRow from 'src/components/DataBlocks/SidebarDataRow';
import { ColProps } from 'reactstrap/types/lib/Col';
import PrimaryButton from 'src/components/Form/PrimaryButton';
import { Permissions } from 'src/helpers/auth/permissions';
import PermissionAction from 'src/components/PermissionAction';
import ActionModal from 'src/components/Modal/Modals/ActionModal';
import Api from 'src/api';
import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import { PaginationData } from 'src/types';
import { FrozenDeveloperAmountsResponseDto } from 'src/types/api/payments/frozenDeveloperAmounts';
import { Cell, Column } from 'react-table';
import { setGlobalLoading } from 'src/modules/app/actions';
import ConfirmButton from 'src/components/ConfirmButton';
import { HiddenColumnsProvider } from 'src/components/ShowTableColumnsDropdown';
import Table from 'src/components/Table';

const TabProjectFinancing: React.FC<PaymentContextState> = ({ payment, setPayment }) => {
  const { t } = useTranslation();
  const { showModal } = useGlobalModalContext();
  const [data, setData] = useState<PaginationData<FrozenDeveloperAmountsResponseDto>>();

  const fetchData = useCallback(
    async (request) => {
      if (!payment) {
        return;
      }

      const response = await Api.payments.frozenDeveloperPayouts.fetchProjectFrozenDeveloperPayouts(
        payment.id,
        request,
      );
      return setData(response);
    },
    [payment],
  );

  const columns = useMemo<Column<FrozenDeveloperAmountsResponseDto>[] | any>(
    () => [
      {
        Header: t('table.pid'),
        accessor: 'project_pid',
        sortType: 'string',
      },
      {
        Header: t('table.name'),
        accessor: 'project_name',
        sortType: 'string',
      },
      {
        Header: t('table.owner_pid'),
        accessor: 'project_owner_pid',
        sortType: 'string',
      },
      {
        Header: t('table.owner_name'),
        accessor: 'project_owner_name',
        sortType: 'string',
      },
      {
        Header: t('table.status'),
        Cell: (cell: Cell<FrozenDeveloperAmountsResponseDto>) => {
          const row = cell.row.original;

          return (
            <span>{row.released_at === null ? t('common.frozen') : t('common.released')}</span>
          );
        },
      },
      {
        Header: t('table.amount'),
        accessor: 'frozen_amount',
        sortType: 'number',
        Cell: (cell: Cell<FrozenDeveloperAmountsResponseDto>) => (
          <span>{t('common.money', { value: cell.value })}</span>
        ),
      },
      {
        Header: t('table.frozen_at'),
        accessor: 'frozen_at',
        sortType: 'datetime',
        width: 150,
        Cell: (cell: Cell<FrozenDeveloperAmountsResponseDto>) => (
          <span>{t('common.date', { date: cell.value })}</span>
        ),
      },
      {
        Header: t('table.released_at'),
        accessor: 'released_at',
        sortType: 'datetime',
        width: 150,
        Cell: (cell: Cell<FrozenDeveloperAmountsResponseDto>) => (
          <span>{t('common.date', { date: cell.value })}</span>
        ),
      },
      {
        Header: t('table.action'),
        disableSortBy: true,
        Cell: (cell: Cell<FrozenDeveloperAmountsResponseDto>) => {
          const row = cell.row.original;
          return (
            <div className={'actions d-flex gap-2'}>
              <ConfirmButton
                onAction={async () => {
                  Api.payments.frozenDeveloperPayouts
                    .releaseFrozenDeveloperAmount(row.id)
                    .then(() => {
                      fetchData(undefined);
                    });
                }}
                className={'btn btn-primary'}
                body={t('payments.frozen_developer_payouts.confirm_release')}
                disabled={row.released_at !== null}
                actionText={t('common.release')}
                title={t('common.release')}
              />
            </div>
          );
        },
      },
    ],
    [t, fetchData],
  );

  useEffect(() => {
    setGlobalLoading(false);
  }, []);

  const transferFunds = useCallback(() => {
    if (!payment) {
      return;
    }

    showModal(
      <ActionModal
        title={t('payments.project_financing.transfer_funds.title')}
        body={t('payments.project_financing.transfer_funds.body')}
        successMessage={t('payments.project_financing.transfer_funds.success')}
        actionText={t('common.submit')}
        onAction={async () => {
          return Api.payments.projectPayouts.transferFunds(payment.id).then(setPayment);
        }}
      />,
    );
  }, [payment, setPayment, showModal, t]);

  if (!payment) return null;

  const colProps: ColProps = {
    md: 4,
    sm: 6,
    xs: 12,
  };

  return (
    <>
      <div className={'d-flex justify-content-between'}>
        <div>
          <h3>{t('payments.project_financing')}</h3>
        </div>
        <div>
          <PermissionAction permissions={Permissions.BO__PAYMENTS__PROJECT_PAYOUTS__TRANSFER_FUNDS}>
            <PrimaryButton
              title={t('payments.project_financing.transfer_funds')}
              onClick={transferFunds}
            />
          </PermissionAction>
        </div>
      </div>
      <hr />
      <Row>
        <SidebarDataRow
          label={t('label.project_wallet_amount')}
          value={payment.project_wallet_amount}
          displayValue={t('common.money', { value: payment.project_wallet_amount })}
          colProps={colProps}
        />
        <SidebarDataRow
          label={t('label.owner_wallet_amount')}
          value={payment.owner_wallet_amount}
          displayValue={t('common.money', { value: payment.owner_wallet_amount })}
          colProps={colProps}
        />
        <SidebarDataRow
          label={t('label.funds_status')}
          value={t(
            'label.funds_status.' +
              (payment.is_funds_transferred ? 'transferred' : 'untransferred'),
          )}
          colProps={colProps}
        />
      </Row>
      <hr />
      <Row>
        <SidebarDataRow
          label={t('label.platform_fee')}
          value={payment.platform_fee}
          displayValue={t('common.money', { value: payment.platform_fee })}
          colProps={colProps}
        />
        <SidebarDataRow
          label={t('label.contract_administration_fee')}
          value={payment.contract_administration_fee}
          displayValue={t('common.money', { value: payment.contract_administration_fee })}
          colProps={colProps}
        />
        <SidebarDataRow
          label={t('label.mortgage_administration_fee')}
          value={payment.mortgage_administration_fee}
          displayValue={t('common.money', { value: payment.mortgage_administration_fee })}
          colProps={colProps}
        />
        <SidebarDataRow
          label={t('label.project_uploaded_to_platform_fee')}
          value={payment.project_uploaded_to_platform_fee}
          displayValue={t('common.money', { value: payment.project_uploaded_to_platform_fee })}
          colProps={colProps}
        />
      </Row>
      <hr />
      <Row>
        <div>{t('label.payments_information')}</div>
        <div className={'mb-2'}>{payment.payments_information ?? '-'}</div>
        <hr />
      </Row>
      <Row>
        <HiddenColumnsProvider title={'FrozenDeveloperPayoutListIndex'}>
          <Container fluid>
            <Card>
              <CardBody>
                <h4 className={'mb-4'}>{t('menu.payments.frozen_developer_payouts')}</h4>
                <Table
                  title={'FrozenDeveloperPayoutListIndex'}
                  onFetchData={fetchData}
                  columns={columns}
                  data={data}
                  searchable={false}
                  enableQueryFilter={false}
                  disableFiltersInsideTable={true}
                  disableFiltersOutsideTable={true}
                />
              </CardBody>
            </Card>
          </Container>
        </HiddenColumnsProvider>{' '}
      </Row>
    </>
  );
};
export default withPayment(TabProjectFinancing);
