import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Cell } from 'react-table';

import Api from 'src/api';

import { PaginationData, PaginationDataFilter } from 'src/types';
import {
  ProjectCampaignDescriptionResponseDto,
  ProjectEmailCampaignsListResponseDto,
} from 'src/types/api/projects';
import { ProjectContextState, withProject } from 'src/pages/Project/Update/ProjectContext';

import { success } from 'src/services/toastr';
import IsActiveBadge from 'src/components/Badges/IsActiveBadge';
import ConfirmButton from 'src/components/ConfirmButton';
import ProjectEmailCampaignStatusBadge from './Badges/ProjectEmailCampaignStatusBadge';

import Table from 'src/components/Table';
import CampaignCreateDescriptionModal from './Modals/CampaignCreateDescriptionModal';
import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import { ProjectEmailCampaignStatusEnum } from 'src/helpers/Enums/Project/ProjectEmailCampaignStatusEnum';
import PrimaryButton from 'src/components/Form/PrimaryButton';

const INITIAL_REQUEST = {
  automatic_campaign: false,
};

const INITIAL_SORT_ORDER = [
  {
    id: 'created_at',
    desc: true,
  },
];

const TabCampaigns: React.FC<ProjectContextState> = ({ project, language }) => {
  const { t } = useTranslation();
  const [campaignDescriptions, setCampaignDescriptions] =
    useState<ProjectCampaignDescriptionResponseDto>({
      project_open_for_investments_email_campaign_description: null,
    });

  const [request, setRequest] = useState(INITIAL_REQUEST);
  const [response, setResponse] = useState<PaginationData<ProjectEmailCampaignsListResponseDto>>();
  const { showModal } = useGlobalModalContext();

  const [isLoading, setIsLoading] = useState({
    sync_all: false,
    sync_single: false,
    send_test: false,
    send_now: false,
    toggle_automatically_send_campaigns: false,
  });

  useEffect(() => {
    if (!project) return;

    setRequest({
      automatic_campaign: project?.should_send_auto_campaign,
    });

    handleFetchProjectCampaignDescriptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  const fetchData = useCallback(
    async (request: PaginationDataFilter | undefined) => {
      if (!project) return Promise.resolve();

      const response = await Api.projects.fetchProjectEmailCampaigns(project.id, request);
      return setResponse(response);
    },
    [project],
  );

  const handleFetchProjectCampaignDescriptions = useCallback(async () => {
    if (!project) return;

    Api.projects.fetchProjectCampaignDescriptions(project?.id).then((data) => {
      setCampaignDescriptions(data);
      handleSyncAllCampaigns(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project, t]);

  const handleSendTest = useCallback(
    async (campaignId) => {
      if (!project) return;

      Api.projects.sendCampaignTest(project?.id, campaignId).then(() => {
        success(t('common.send_test'));
      });
    },
    [project, t],
  );

  const handleSendNow = useCallback(
    (campaignId) => {
      if (!project) return;

      Api.projects.sendCampaignNow(project?.id, campaignId).then(() => {
        success(t('common.send'));
      });
    },
    [t, project],
  );

  const handleSyncCampaign = useCallback(
    (campaignId) => {
      if (!project) return;

      Api.projects.syncCampaign(project?.id, campaignId).then(() => {
        success(t('common.sync'));
        fetchData(undefined);
      });
    },
    [t, project, fetchData],
  );

  const handleSyncAllCampaigns = useCallback(
    (ignoreSuccessToast = false) => {
      if (!project) return;
      setIsLoading({ ...isLoading, sync_all: true });

      Api.projects
        .syncCampaigns(project?.id)
        .then(() => {
          if (!ignoreSuccessToast) {
            success(t('common.sync'));
          }

          fetchData(undefined);
        })
        .finally(() => {
          setIsLoading({ ...isLoading, sync_all: false });
        });
    },
    [t, project, fetchData, isLoading],
  );

  const handleCampaignAutoSending = useCallback(
    (campaignId) => {
      if (!project) return;

      Api.projects.toggleProjectSingleCampaignAutoSend(project?.id, campaignId).then(() => {
        success(t('common.toggle_auto_sending'));
        fetchData(undefined);
      });
    },
    [fetchData, t, project],
  );

  const handleToggleSendCampaignsAuto = useCallback(() => {
    if (!project) return;

    setIsLoading({ ...isLoading, toggle_automatically_send_campaigns: true });

    if (request.automatic_campaign) {
      Api.projects
        .cancelProjectCampaignAutoSend(project?.id)
        .then(() => setRequest({ automatic_campaign: false }))
        .finally(() => {
          setIsLoading({ ...isLoading, toggle_automatically_send_campaigns: false });
        });
    } else {
      Api.projects
        .setProjectCampaignAutoSend(project?.id)
        .then(() => setRequest({ automatic_campaign: true }))
        .finally(() => {
          setIsLoading({ ...isLoading, toggle_automatically_send_campaigns: false });
        });
    }
  }, [request, project, isLoading]);

  const columns = useMemo<any>(
    () => [
      {
        Header: t('table.title'),
        accessor: 'title',
        width: 100,
      },
      {
        Header: t('table.type'),
        width: 200,
        accessor: 'type',
        Cell: (cell: any) => {
          return t('projects.campaigns.type.' + cell.row.original.type);
        },
      },
      {
        Header: t('table.status'),
        accessor: 'status',
        width: 70,
        Cell: (cell: any) => {
          return <ProjectEmailCampaignStatusBadge value={cell.row.original.status} />;
        },
      },
      {
        Header: t('table.has_project_description'),
        accessor: 'has_custom_description',
        width: 70,
        Cell: (cell: any) => {
          return <IsActiveBadge value={cell.row.original.has_custom_description} />;
        },
      },
      {
        Header: t('table.will_send_automatically'),
        accessor: 'will_send_automatically',
        width: 70,
        Cell: (cell: any) => {
          return <IsActiveBadge value={cell.row.original.will_send_automatically} />;
        },
      },
      {
        Header: t('table.external_id'),
        accessor: 'external_id',
        width: 80,
      },
      {
        Header: t('table.action'),
        accessor: '',
        width: 300,
        Cell: (cell: Cell<ProjectEmailCampaignsListResponseDto>) => {
          if (!project) return;

          const campaignId: string = cell.row.original.id;
          const previewUrl: string | null = cell.row.original.preview_url;

          return (
            <div className={'d-flex gap-2'}>
              {previewUrl && (
                <a href={previewUrl} target="_blank" rel={'noreferrer'} className={'btn btn-dark'}>
                  {t('common.preview')}
                </a>
              )}
              <ConfirmButton
                onAction={async () => handleSyncCampaign(campaignId)}
                className={'btn btn-primary'}
                body={t('projects.campaigns.confirm_sync.body')}
                actionText={t('common.sync')}
                title={t('common.sync')}
              />
              <ConfirmButton
                disabled={cell.row.original.status === ProjectEmailCampaignStatusEnum.SENT}
                onAction={async () => handleSendTest(campaignId)}
                className={'btn btn-warning'}
                body={t('projects.campaigns.confirm_send_test.body')}
                actionText={t('projects.campaigns.confirm_send_test.action')}
                title={t('common.send_test')}
              />
              <ConfirmButton
                disabled={cell.row.original.status === ProjectEmailCampaignStatusEnum.SENT}
                onAction={async () => handleSendNow(campaignId)}
                className={'btn btn-success'}
                body={t('projects.campaigns.confirm_send.body')}
                actionText={t('projects.campaigns.confirm_send.action')}
                title={t('common.send')}
              />
              <button
                disabled={cell.row.original.status === ProjectEmailCampaignStatusEnum.SENT}
                onClick={async () => handleCampaignAutoSending(campaignId)}
                className={'btn btn-secondary'}
              >
                {t('common.toggle_auto_sending')}
              </button>
            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [language],
  );

  return (
    <React.Fragment>
      <div className={'d-flex justify-content-between'}>
        <div>
          <h3 className={'flex'}>{t('projects.campaigns')}</h3>
          <div>
            <span>{t('projects.campaigns.send_open_project_campaigns_automatically')}:</span>
            <span className={'ms-2'}>
              <IsActiveBadge value={request.automatic_campaign} />
            </span>
          </div>
        </div>
        {project ? (
          <div className={'d-flex mt-3 flex'}>
            <div>
              <PrimaryButton
                className={'ms-3 btn btn-success d-block'}
                title={t('common.edit_description')}
                onClick={() =>
                  showModal(
                    <CampaignCreateDescriptionModal
                      projectId={project?.id}
                      values={
                        campaignDescriptions.project_open_for_investments_email_campaign_description
                      }
                      refetchDescriptions={handleFetchProjectCampaignDescriptions}
                    />,
                  )
                }
              />
            </div>
            <div>
              <PrimaryButton
                className={'ms-3 btn btn-primary d-block'}
                onClick={() => handleToggleSendCampaignsAuto()}
                title={t('projects.campaigns.toggle_automatically_send_campaigns')}
                submitting={isLoading.toggle_automatically_send_campaigns}
              />
            </div>
            <div>
              <PrimaryButton
                className={'ms-3 btn btn-primary d-block'}
                onClick={() => handleSyncAllCampaigns()}
                title={t('projects.campaigns.sync_all_campaigns')}
                submitting={isLoading.sync_all}
              />
            </div>
          </div>
        ) : null}
      </div>
      <hr />
      <Table
        columns={columns}
        data={response}
        onFetchData={fetchData}
        title={'ProjectEmailCampaigns'}
        initialSortBy={INITIAL_SORT_ORDER}
      />
    </React.Fragment>
  );
};

export default withProject(TabCampaigns);
