import React, { useCallback, useEffect, useState } from 'react';
import {
  ApplicationContextState,
  withApplication,
} from 'src/pages/Application/Update/ApplicationContext';
import { Col, ColProps, Form, FormGroup, Row } from 'reactstrap';
import { FieldArray, Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { StoreProtocolAnalyticRequestDto } from 'src/types/api/applications';
import Api from 'src/api';
import { success } from 'src/services/toastr';
import TextInput from 'src/components/Form/TextInput';
import TextAreaInput from 'src/components/Form/TextAreaInput';
import SelectRatingInput from 'src/components/Form/Select/SelectRatingInput';
import SubmitTabButton from 'src/pages/Application/Update/Tabs/Components/SubmitTabButton';
import SaveButton from 'src/components/Form/SaveButton';
import WYSIWYGInput from 'src/components/Form/WYSIWYGInput';
import { ApplicationTabProps } from 'src/pages/Application/Update/Tabs/ApplicationTabData';
import { transformErrors } from 'src/helpers';
import { UseNumbers } from 'src/helpers/useNumbers';
import CheckboxInput from 'src/components/Form/CheckboxInput';
import SelectRiskCategoryInput from 'src/components/Form/Select/SelectRiskCategoryInput';
import DatePickerInput from 'src/components/Form/DatePickerInput';

interface Props extends ApplicationTabProps, ApplicationContextState {}

const INITIAL_REQUEST: StoreProtocolAnalyticRequestDto = {
  fine_on_pre_return: null,
  platform_fee: null,
  debt_fee: null,
  administration_fee: null,
  contract_administration_fee: null,
  basic_interest: null,
  finances: '',
  interest_rate_math: null,
  loan_ratio_internal: null,
  notes: '',
  rating_credit_info_bankrupt: null,
  rating_credit_info_default: null,
  initial_rating: null,
  actual_rating: null,
  risk_category: null,
  stageable_until: null,
  is_stageable: false,
  mortgage_administration_fee: null,
  project_uploaded_to_platform_fee: null,
  payments_information: '',
  other_conditions: null,
};

const ProtocolAnalytic: React.FC<Props> = ({ application, setApplication, showSubmit }) => {
  const { t } = useTranslation();

  const [request, setRequest] = useState<StoreProtocolAnalyticRequestDto>(INITIAL_REQUEST);

  useEffect(() => {
    if (!application?.protocol) return;

    setRequest({
      fine_on_pre_return: application.protocol.fine_on_pre_return,
      platform_fee: application.protocol.platform_fee,
      debt_fee: application.protocol.debt_fee,
      administration_fee: application.protocol.administration_fee,
      contract_administration_fee: application.protocol.contract_administration_fee,
      basic_interest: application.protocol.basic_interest,
      finances: application.protocol.finances,
      interest_rate_math: application.protocol.interest_rate_math,
      loan_ratio_internal: application.protocol.loan_ratio_internal,
      notes: application.protocol.notes,
      rating_credit_info_bankrupt: application.protocol.rating_credit_info_bankrupt,
      rating_credit_info_default: application.protocol.rating_credit_info_default,
      initial_rating: application.protocol.initial_rating,
      actual_rating: application.protocol.actual_rating,
      risk_category: application.protocol.risk_category,
      stageable_until: application.stageable_until,
      is_stageable: application.is_stageable,
      mortgage_administration_fee: application.protocol.mortgage_administration_fee,
      project_uploaded_to_platform_fee: application.protocol.project_uploaded_to_platform_fee,
      payments_information: application.protocol.payments_information,
      other_conditions: application.protocol.other_conditions,
    });
  }, [application]);

  const ProtocolSchema = Yup.object().shape({
    administration_fee: Yup.number().required().min(0),
    platform_fee: Yup.number().required().min(0).max(100),
    contract_administration_fee: Yup.number().required().min(0).max(100),
    debt_fee: Yup.number().required().min(0),
    fine_on_pre_return: Yup.number().required().min(0).max(100),
    mortgage_administration_fee: Yup.number().required().min(0),
    project_uploaded_to_platform_fee: Yup.number().required().min(0),

    basic_interest: Yup.number().required().max(100),
    interest_rate_math: Yup.number().nullable().max(100),
    loan_ratio_internal: Yup.number().required().max(100),
    rating_credit_info_bankrupt: Yup.number().nullable().min(1).max(99),
    rating_credit_info_default: Yup.number().nullable().min(1).max(99),
    actual_rating: Yup.string().required(),
    risk_category: Yup.string().required(),
    finances: Yup.string().nullable().max(1000),
    other_conditions: Yup.string().nullable().optional(),
  });

  const onSubmit = useCallback(
    async (request: any, helpers: FormikHelpers<StoreProtocolAnalyticRequestDto>) => {
      if (!application) return;

      try {
        const response = await Api.applications.storeProtocolAnalytic(application.id, request);
        setApplication(response);
        success(t('common.updated_success'));
      } catch (e: any) {
        helpers.setErrors(transformErrors(e.response?.errors));
      }

      return true;
    },
    [application, setApplication, t],
  );

  const colProps: ColProps = {
    sm: 3,
    className: 'mb-4',
  };

  const colPropsHalf: ColProps = {
    sm: 6,
    className: 'mb-4',
  };

  return (
    <React.Fragment>
      <div className={'d-flex justify-content-between'}>
        <div>
          <h3>{t('applications.protocol.analytic')}</h3>
        </div>
        {showSubmit && (
          <div>
            <SubmitTabButton title={t('applications.tabs_submit.protocol')} tabName={'protocol'} />
          </div>
        )}
      </div>
      <hr />
      <Formik
        initialValues={request}
        enableReinitialize={true}
        validationSchema={ProtocolSchema}
        onSubmit={onSubmit}
      >
        {({
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Row>
                <Col {...colProps}>
                  <TextInput
                    type={'number'}
                    step={'any'}
                    name={'administration_fee'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                  />
                </Col>
                <Col {...colProps}>
                  <TextInput
                    type={'number'}
                    step={'any'}
                    name={'platform_fee'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                  />
                </Col>
                <Col {...colProps}>
                  <TextInput
                    type={'number'}
                    step={'any'}
                    name={'contract_administration_fee'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                  />
                </Col>
                <Col {...colProps}>
                  <TextInput
                    type={'number'}
                    step={'any'}
                    name={'debt_fee'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                  />
                </Col>
                <Col {...colProps}>
                  <TextInput
                    type={'number'}
                    step={'any'}
                    name={'fine_on_pre_return'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                  />
                </Col>
                <Col {...colProps}>
                  <TextInput
                    type={'number'}
                    step={'any'}
                    name={'mortgage_administration_fee'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                  />
                </Col>
                <Col {...colProps}>
                  <TextInput
                    type={'number'}
                    step={'any'}
                    name={'project_uploaded_to_platform_fee'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                  />
                </Col>
              </Row>
              <hr />
              <Row>
                <Col {...colProps}>
                  <TextInput
                    type={'number'}
                    step={'any'}
                    name={'basic_interest'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                  />
                </Col>
                <Col {...colProps}>
                  <TextInput
                    type={'number'}
                    step={'any'}
                    name={'interest_rate_math'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                  />
                </Col>
                <Col {...colProps}>
                  <TextInput
                    type={'number'}
                    step={'any'}
                    name={'loan_ratio_internal'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                  />
                </Col>
                <Col {...colProps}>
                  <TextInput
                    type={'number'}
                    step={'any'}
                    name={'rating_credit_info_bankrupt'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                  />
                </Col>
                <Col {...colProps}>
                  <TextInput
                    type={'number'}
                    step={'any'}
                    name={'rating_credit_info_default'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                  />
                </Col>
                <Col {...colProps}>
                  <SelectRatingInput
                    name={'initial_rating'}
                    placeholder={t('label.initial_rating')}
                    className={'z-100'}
                    isDisabled={true}
                  />
                </Col>
                <Col {...colProps}>
                  <SelectRatingInput
                    name={'actual_rating'}
                    placeholder={t('label.actual_rating')}
                    className={'z-100'}
                  />
                </Col>
                <Col {...colProps}>
                  <SelectRiskCategoryInput
                    name={'risk_category'}
                    placeholder={t('label.risk_category')}
                    className={'z-100'}
                  />
                </Col>
              </Row>
              <hr />
              <Row>
                <Col {...colProps}>
                  <DatePickerInput name={'stageable_until'} matchFieldValueAndDisplayValue={true} />
                </Col>
                <Col {...colProps} className={'align-items-center align-self-center'}>
                  <CheckboxInput name={'is_stageable'} showPlaceholder={true} />
                </Col>
              </Row>
              <hr />
              <Row>
                <Col {...colPropsHalf}>
                  <WYSIWYGInput name={'finances'} />
                </Col>
                <Col {...colPropsHalf}>
                  <WYSIWYGInput name={'notes'} />
                </Col>
              </Row>
              <div className={'mt-5'}>
                <h3>{t('applications.protocol.sales_manager')}</h3>
              </div>
              <hr />
              <Row>
                <Col {...colProps}>
                  <TextInput
                    type={'number'}
                    step={'any'}
                    disabled={true}
                    name={'total_planned_credit'}
                    value={application?.protocol?.total_planned_credit ?? ''}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                  />
                </Col>
                <Col {...colProps}>
                  <TextInput
                    disabled={true}
                    name={'loan_ratio'}
                    value={application?.protocol?.loan_ratio ?? ''}
                  />
                </Col>
                <Col {...colProps}>
                  <TextInput
                    type={'number'}
                    step={'any'}
                    disabled={true}
                    name={'loan_ratio_external'}
                    value={application?.protocol?.loan_ratio_external ?? ''}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                  />
                </Col>
                <Col {...colProps}>
                  <TextInput
                    value={application?.protocol?.increased_interest ?? ''}
                    type={'number'}
                    step={'any'}
                    disabled={true}
                    name={'increased_interest'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                  />
                </Col>
                <Col {...colProps}>
                  <TextInput
                    type={'number'}
                    step={'any'}
                    disabled={true}
                    name={'interest_rate_offer'}
                    value={application?.protocol?.interest_rate_offer ?? ''}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                  />
                </Col>
                <Col {...colProps}>
                  <TextInput
                    type={'number'}
                    step={'any'}
                    disabled={true}
                    name={'days_to_get_money'}
                    value={application?.protocol?.days_to_get_money ?? ''}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                  />
                </Col>
              </Row>
              <Row>
                <Col className={'mb-4'}>
                  <TextAreaInput
                    disabled={true}
                    name={'other_conditions'}
                    value={application?.protocol?.other_conditions ?? ''}
                  />
                </Col>
              </Row>
              <Row>
                <Col {...colPropsHalf}>
                  <WYSIWYGInput
                    name={'risks_description'}
                    disabled={true}
                    customValue={application?.protocol?.risks_description ?? ''}
                  />
                </Col>
                <Col {...colPropsHalf}>
                  <WYSIWYGInput
                    name={'reason_to_lend_description'}
                    disabled={true}
                    customValue={application?.protocol?.reason_to_lend_description ?? ''}
                  />
                </Col>
                <Col {...colPropsHalf}>
                  <TextAreaInput
                    disabled={true}
                    name={'early_return_rules'}
                    value={application?.protocol?.early_return_rules ?? ''}
                  />
                </Col>
                <Col {...colPropsHalf}>
                  <TextAreaInput
                    disabled={true}
                    name={'exceptional_investment_conditions_description'}
                    value={
                      application?.protocol?.exceptional_investment_conditions_description ?? ''
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col {...colPropsHalf}>
                  <TextAreaInput
                    disabled={true}
                    name={'payments_information'}
                    value={application?.protocol?.payments_information ?? ''}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <FieldArray name="bonus_interests">
                    {() => (
                      <>
                        <table className={'table table-sm small'}>
                          <thead>
                            <tr>
                              <th className={'col-2'}>{t('label.bonus_amount')}</th>
                              <th className={'col-2'}>{t('label.bonus_interest')}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {application?.bonus_interests
                              ? application.bonus_interests.map((bonus, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>
                                        <TextInput
                                          type={'number'}
                                          step={'any'}
                                          name={`bonus_interests[${index}].amount`}
                                          placeholder={t('label.bonus_amount')}
                                          hideLabel={true}
                                          disabled={true}
                                          value={bonus.amount}
                                          onKeyPress={UseNumbers.preventNonNumericalInput}
                                        />
                                      </td>
                                      <td>
                                        <TextInput
                                          type={'number'}
                                          step={'any'}
                                          name={`bonus_interests[${index}].bonus_interest`}
                                          placeholder={t('label.bonus_amount')}
                                          hideLabel={true}
                                          disabled={true}
                                          value={bonus.bonus_interest}
                                          onKeyPress={UseNumbers.preventNonNumericalInput}
                                        />
                                      </td>
                                    </tr>
                                  );
                                })
                              : null}
                          </tbody>
                        </table>
                      </>
                    )}
                  </FieldArray>
                </Col>
              </Row>
              <div className={'mt-4 mb-4'}>
                <SaveButton
                  disabled={!!application?.protocol?.submitted_analytic_at}
                  title={application?.protocol ? t('common.save') : t('common.create')}
                  submitting={isSubmitting}
                />
              </div>
            </FormGroup>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default withApplication(ProtocolAnalytic);
