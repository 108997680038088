import React, { useCallback, useEffect, useState } from 'react';
import {
  DebtCollectionContextState,
  withDebtCollection,
} from 'src/pages/DebtCollection/Update/DebtCollectionContext';
import Loader from 'src/components/Loader';
import { HiddenColumnsProvider } from 'src/components/ShowTableColumnsDropdown';
import { Container } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import ActivityFeedItem from 'src/components/ActivityFeedItem';
import { Permissions } from 'src/helpers/auth/permissions';
import Api from 'src/api';
import { StageHistoryResponseDto } from 'src/types/api/DebtCollection';
import { StageHistoryTypeEnum } from 'src/helpers/Enums/Debt_collection/StageHistoryTypeEnum';

const TabHistory: React.FC<DebtCollectionContextState> = ({ debtCollection }) => {
  const { t } = useTranslation();

  const [data, setData] = useState<Array<StageHistoryResponseDto>>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fetchData = useCallback(async () => {
    if (!debtCollection?.id) return Promise.resolve();
    setIsLoading(true);
    return Api.debtCollection.fetchHistory(debtCollection.id).then((response) => {
      setData(response);
      setIsLoading(false);
    });
  }, [debtCollection]);

  const handleMessageByType = (history: StageHistoryResponseDto) => {
    switch (history.type) {
      case StageHistoryTypeEnum.EVENT_UPDATE:
        return t('debt_collection.project_event_update.' + history.comment);
      case StageHistoryTypeEnum.EMAIL:
        return history.comment;
      default:
        return history.comment;
    }
  };

  useEffect(() => {
    fetchData();
  }, [debtCollection, fetchData]);

  if (!debtCollection) {
    return <Loader />;
  }

  return (
    <React.Fragment>
      <HiddenColumnsProvider title={'DebtCollectionActionsIndex'}>
        <Container fluid>
          <h3>{t('debt_collection.history')}</h3>
          <hr />
          {isLoading && <Loader />}
          <ul className="activity-feed mb-0 ps-2">
            {data?.map((item) => {
              return (
                <ActivityFeedItem
                  key={item.id}
                  data={{
                    id: item.id,
                    date: item.updated_at,
                    author: item.author_name,
                    message: handleMessageByType(item),
                    type: item.type,
                  }}
                  permissions={Permissions.BO__DEBT_COLLECTION__UPDATE_ACTIONS}
                />
              );
            })}
          </ul>
        </Container>
      </HiddenColumnsProvider>
    </React.Fragment>
  );
};

export default withDebtCollection(TabHistory);
