import React, { useCallback, useMemo, useState } from 'react';
import Table from 'src/components/Table';
import { Cell, Column } from 'react-table';
import { PaginationData, PaginationDataFilter } from 'src/types';
import Api from 'src/api';
import { useTranslation } from 'react-i18next';
import { Badge } from 'reactstrap';
import { UserAccreditationListResponseDto } from 'src/types/api/user';
import { BaseTabProps } from 'src/components/Tabs';
import { UserContextState, withUser } from 'src/pages/User/Update/User/UserContext';
import { HiddenColumnsProvider } from 'src/components/ShowTableColumnsDropdown';
import EditEntityButtonEvent from 'src/components/Table/Buttons/EditEntityButtonEvent';
import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import FormModal from 'src/components/Modal/Modals/FormModal';
import EditUserAccreditationForm from 'src/pages/User/Update/User/Tabs/Forms/EditUserAccreditationForm';
import DeleteEntityButton from 'src/components/Table/Buttons/DeleteEntityButton';

interface Props extends BaseTabProps, UserContextState {}

const TabAccreditations: React.FC<Props> = ({ user }) => {
  const { t } = useTranslation();
  const [response, setResponse] = useState<PaginationData<UserAccreditationListResponseDto>>();
  const { showModal } = useGlobalModalContext();

  const handleAccreditationUpdate = useCallback(
    (accreditation: UserAccreditationListResponseDto) => {
      if (response) {
        const accreditations = { ...response };
        const index = accreditations.data.findIndex((acc) => acc.id === accreditation.id);
        accreditations.data[index] = accreditation;

        setResponse(accreditations);
      }
    },
    [response],
  );

  const columns = useMemo<Column<UserAccreditationListResponseDto>[] | any>(
    () => [
      {
        Header: t('table.full_name'),
        accessor: 'full_name',
      },
      {
        Header: t('table.gov_code'),
        accessor: 'gov_code',
      },
      {
        Header: t('table.dob'),
        accessor: 'dob',
      },
      {
        Header: t('table.gender'),
        accessor: 'gender',
      },
      {
        Header: t('table.residence'),
        accessor: 'residence',
      },
      {
        Header: t('table.document_number'),
        accessor: 'document_number',
      },
      {
        Header: t('table.document_type'),
        accessor: 'document_type',
        Cell: (cell: Cell<UserAccreditationListResponseDto>) =>
          cell.value ? <span>{t('users.document_type.' + cell.value)}</span> : null,
      },
      {
        Header: t('table.document_doi'),
        accessor: 'document_doi',
        Cell: (cell: Cell<UserAccreditationListResponseDto>) => (
          <>{t('common.date', { date: cell.value })}</>
        ),
      },
      {
        Header: t('table.document_doe'),
        accessor: 'document_doe',
        Cell: (cell: Cell<UserAccreditationListResponseDto>) => (
          <>{t('common.date', { date: cell.value })}</>
        ),
      },
      {
        Header: t('users.status'),
        Cell: (cell: Cell<UserAccreditationListResponseDto>) => {
          const v: UserAccreditationListResponseDto = cell.row.original;
          return (
            <>
              {v.accredited_at ? (
                <Badge className={'bg-success'}>{t('common.verified')}</Badge>
              ) : (
                <Badge className={'bg-danger'}>{t('common.failed')}</Badge>
              )}
            </>
          );
        },
      },
      {
        Header: t('users.document_reason'),
        Cell: (cell: Cell<UserAccreditationListResponseDto>) => {
          const v = cell.row.original;
          return <>{v.accredited_at ? v.accreditation_reason : v.fail_reason}</>;
        },
      },
      {
        Header: t('users.accreditation_type'),
        width: 125,
        Cell: (cell: Cell<UserAccreditationListResponseDto>) => {
          const v = cell.row.original;
          return v.type ? <>{t('users.accreditation_type.' + v.type)}</> : null;
        },
      },
      {
        Header: t('users.accredited_at'),
        width: 140,
        Cell: (cell: Cell<UserAccreditationListResponseDto>) => {
          return <div>{t('common.date_full', { date: cell.row.original.accredited_at })}</div>;
        },
      },
      {
        Header: t('users.created_at'),
        width: 140,
        Cell: (cell: Cell<UserAccreditationListResponseDto>) => {
          return <div>{t('common.date_full', { date: cell.row.original.created_at })}</div>;
        },
      },
      {
        Header: t('table.actions'),
        Cell: (cell: Cell<UserAccreditationListResponseDto> | any) => {
          const v = cell.row.original;
          return (
            <div className={'d-flex'}>
              <EditEntityButtonEvent
                event={() =>
                  showModal(
                    <FormModal
                      title={t('users.edit_user_accreditation')}
                      size={'xl'}
                      form={
                        <EditUserAccreditationForm
                          accreditation={v}
                          handleAccreditationUpdate={handleAccreditationUpdate}
                        />
                      }
                    />,
                  )
                }
              />
              {v.type === 'manual' && (
                <div className={'ms-2'}>
                  <DeleteEntityButton
                    onDelete={() =>
                      Api.user
                        .deleteUserAccreditation(v.id)
                        .then(() => cell.dispatch({ type: 'refreshData' }))
                    }
                  />
                </div>
              )}
              {v.external_url && (
                <a href={v.external_url} target={'_blank'} rel="noreferrer">
                  {t('common.external_url')}
                </a>
              )}
            </div>
          );
        },
      },
    ],
    [handleAccreditationUpdate, showModal, t],
  );

  const fetchData = useCallback(
    (request: PaginationDataFilter | undefined) => {
      if (!user) return Promise.resolve();

      return Api.user
        .fetchUserAccreditations(user.id, request)
        .then((response) => setResponse(response));
    },
    [user],
  );

  return (
    <React.Fragment>
      <HiddenColumnsProvider title={'UserUpdateUserTabsTabAccreditations'}>
        <Table
          title={'UserUpdateUserTabsTabAccreditations'}
          columns={columns}
          data={response}
          onFetchData={fetchData}
          searchable={true}
        />
      </HiddenColumnsProvider>
    </React.Fragment>
  );
};

export default withUser(TabAccreditations);
