import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import usePermissions from 'src/helpers/usePermissions';
import { downloadXLSXFile, downloadXMLFile, openMedia } from 'src/services/media';
import { CommonDocumentTypeEnum } from 'src/helpers/Enums/CommonDocumentTypeEnum';

interface Props {
  permissions?: string | string[];
  show?: boolean;
  url: string;
  type?: CommonDocumentTypeEnum;
  name?: string;
}

const DownloadFileButton: React.FC<Props> = ({ permissions, url, show, type, name }) => {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState<boolean>(false);
  const p = usePermissions();

  if ((show !== undefined && !show) || (permissions && p.cannot(permissions))) {
    return null;
  }

  const handleClick = async () => {
    setDisabled(true);
    try {
      switch (type) {
        case CommonDocumentTypeEnum.XML:
          await downloadXMLFile(url, name ?? 'report');
          break;
        case CommonDocumentTypeEnum.XLSX:
          await downloadXLSXFile(url, name ?? 'report');
          break;
        default:
          await openMedia(url);
          break;
      }
    } finally {
      setDisabled(false);
    }
  };

  return (
    <React.Fragment>
      <button
        disabled={disabled}
        className={'btn btn-link p-0 mx-2'}
        title={t('common.download')}
        onClick={handleClick}
      >
        <i className={'fas fa-download'} />
      </button>
    </React.Fragment>
  );
};

export default DownloadFileButton;
