import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  ProjectDeveloperKybDataV1ResponseDto,
  UserCompanyKybDataV1BeneficiaryResponseDto,
  UserCompanyKybDataV1PepResponseDto,
} from 'src/types/api/userCompanies';
import SidebarDataRow from 'src/components/DataBlocks/SidebarDataRow';
import { Row } from 'reactstrap';
import BeneficiaryV1Block from 'src/pages/User/Update/Company/Kyb/BeneficiaryV1Block';

interface Props {
  kyb: ProjectDeveloperKybDataV1ResponseDto;
}
const InvestorKybV1: React.FC<Props> = ({ kyb }) => {
  const { t } = useTranslation();

  return (
    <>
      <Row>
        <SidebarDataRow
          label={t('label.company.management_structure')}
          value={kyb.management_structure}
          displayValue={t(`users.company.${kyb.management_structure}`)}
        />
        <SidebarDataRow
          label={t('label.company.has_bankruptcy_procedure')}
          value={kyb.has_bankruptcy_procedure ? t('common.yes') : t('common.no')}
        />
        <SidebarDataRow
          label={t('label.company.is_shareholder_beneficiary_family_member_public_official')}
          value={
            kyb.is_shareholder_beneficiary_family_member_public_official
              ? t('common.yes')
              : t('common.no')
          }
        />
        <SidebarDataRow
          label={t('label.company.has_confirmed_data_integrity')}
          value={kyb.has_confirmed_data_integrity ? t('common.yes') : t('common.no')}
        />
      </Row>
      <hr />
      <h6 className={'mb-3'}>
        <strong>{t('label.company.beneficiaries')}</strong>
      </h6>
      {kyb.beneficiaries?.map(
        (beneficiary: UserCompanyKybDataV1BeneficiaryResponseDto, index: number) => {
          return (
            <Row key={index} className={'mb-5'}>
              <BeneficiaryV1Block beneficiary={beneficiary} />
            </Row>
          );
        },
      )}
      {kyb.public_officials?.map(
        (publicOfficial: UserCompanyKybDataV1PepResponseDto, index: number) => {
          return (
            <div key={index}>
              <hr />
              <h6 className={'mb-3'}>
                <strong>{t('label.company.public_officials')}</strong>
              </h6>
              <Row key={index}>
                <SidebarDataRow label={t('label.details')} value={publicOfficial.details} />
              </Row>
            </div>
          );
        },
      )}
    </>
  );
};

export default InvestorKybV1;
