import Client from 'src/api/services/client';
import { PaginationData, PaginationDataFilter } from 'src/types';
import { FrozenDeveloperAmountsResponseDto } from 'src/types/api/payments/frozenDeveloperAmounts';

class FrozenDeveloperPayouts extends Client {
  api = {
    FETCH_FROZEN_DEVELOPER_PAYOUTS: `${this.http.baseUrl}/payments/frozen-developer-payouts/filter`,
    FETCH_PROJECT_DEVELOPER_PAYOUTS: `${this.http.baseUrl}/payments/frozen-developer-payouts/project/{projectId}/filter`,

    RELEASE_FROZEN_DEVELOPER_AMOUNT: `${this.http.baseUrl}/payments/frozen-developer-payouts/{frozenDeveloperPayoutId}/release`,
  };

  fetchFrozenDeveloperPayouts = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<FrozenDeveloperAmountsResponseDto>> => {
    return this.http.get(this.api.FETCH_FROZEN_DEVELOPER_PAYOUTS, { params: request });
  };

  fetchProjectFrozenDeveloperPayouts = (
    projectId: string,
    request?: PaginationDataFilter,
  ): Promise<PaginationData<FrozenDeveloperAmountsResponseDto>> => {
    const url = this.buildUrl(this.api.FETCH_PROJECT_DEVELOPER_PAYOUTS, {
      projectId,
    });

    return this.http.get(url, { params: request });
  };

  releaseFrozenDeveloperAmount = (frozenDeveloperPayoutId: string): Promise<any> => {
    const url = this.buildUrl(this.api.RELEASE_FROZEN_DEVELOPER_AMOUNT, {
      frozenDeveloperPayoutId,
    });
    return this.http.put(url);
  };
}

export default FrozenDeveloperPayouts;
