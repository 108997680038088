import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Col, FormGroup, Row } from 'reactstrap';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import Api from 'src/api';
import { success } from 'src/services/toastr';
import TextInput from 'src/components/Form/TextInput';
import SaveButton from 'src/components/Form/SaveButton';
import { appendFilter, initialRequest } from 'src/pages/RC/View/helpers';
import { GenerateRcRequestDto, RealEstateTransactionsDetailedFilterForm } from 'src/types/api/RC';
import { RCTypeEnum } from 'src/helpers/Enums/RC/RCTypeEnum';
import SelectBasisOfProvidingDataInput from 'src/pages/RC/View/Filters/Selects/SelectBasisOfProvidingDataInput';
import DataDetailsInput from 'src/pages/RC/View/Filters/Selects/DataDetailsInput';
import useRCReportTypeStore from 'src/pages/RC/View/RCReportTypeStore';
import { RCDataTypeEnum } from 'src/helpers/Enums/RC/RCDataTypeEnum';

const TYPE: RCTypeEnum = RCTypeEnum.REAL_ESTATE_TRANSACTIONS_DETAILED;

const RealEstateTransactionsDetailedFilter: React.FC = () => {
  const { t } = useTranslation();
  const { refreshTable } = useRCReportTypeStore();

  const [request] = useState<RealEstateTransactionsDetailedFilterForm>({
    name: null,
    sandoriu_id: '',
  });

  const Schema = Yup.object().shape({
    sandoriu_id: Yup.string().required(),
    gavejas: Yup.string().max(20).optional().nullable(),
    opt: Yup.string().optional().nullable(),
    dtp_tipas: Yup.string().when('opt', {
      is: RCDataTypeEnum.FULL,
      then: Yup.string().required(),
      otherwise: Yup.string().optional().nullable(),
    }),
    dtp_reiksme: Yup.string().when('opt', {
      is: RCDataTypeEnum.FULL,
      then: Yup.string().max(255).required(),
      otherwise: Yup.string().max(255).optional().nullable(),
    }),
  });

  const mapData = (form: RealEstateTransactionsDetailedFilterForm): GenerateRcRequestDto => {
    const request = initialRequest(form, TYPE);

    appendFilter(request, 'sandoriu_id', form.sandoriu_id);
    appendFilter(request, 'gavejas', form.gavejas);
    appendFilter(request, 'opt', form.opt);
    appendFilter(request, 'dtp_tipas', form.dtp_tipas);
    appendFilter(request, 'dtp_reiksme', form.dtp_reiksme);

    return request;
  };

  const onSubmit = useCallback(
    async (
      request: RealEstateTransactionsDetailedFilterForm,
      helper: FormikHelpers<RealEstateTransactionsDetailedFilterForm>,
    ) => {
      try {
        await Api.rc.fetchRCData(mapData(request)).then(() => {
          refreshTable();
          success(t('common.success'));
        });
      } catch (e: any) {
        helper.setErrors(e.response?.errors);
      }
    },
    [refreshTable, t],
  );

  return (
    <div className={'mb-4'}>
      <Card>
        <CardBody>
          <h4 className={'mb-4'}>{t('rc.filter.' + TYPE)}</h4>
          <div className={'mb-4'}>
            <Formik
              initialValues={request}
              onSubmit={onSubmit}
              enableReinitialize={true}
              validationSchema={Schema}
              validateOnChange={true}
            >
              {({ handleSubmit, isSubmitting, values }) => (
                <Form onSubmit={handleSubmit}>
                  <FormGroup>
                    <h5 className={'mb-4'}>{t('rc.filter.required')}</h5>
                    <Row>
                      <Col sm={2} className={'mb-4'}>
                        <TextInput name={'sandoriu_id'} />
                      </Col>
                    </Row>
                    <h5 className={'mb-4'}>{t('rc.filter.optional')}</h5>
                    <Row>
                      <Col sm={2} className={'mb-4'}>
                        <TextInput name={'gavejas'} />
                      </Col>
                      <Col sm={2} className={'mb-4'}>
                        <DataDetailsInput name={'opt'} placeholder={''} />
                      </Col>
                      {values && values.opt === RCDataTypeEnum.FULL && (
                        <>
                          <Col sm={2} className={'mb-4'}>
                            <SelectBasisOfProvidingDataInput name={'dtp_tipas'} placeholder={''} />
                          </Col>
                          <Col sm={2} className={'mb-4'}>
                            <TextInput name={'dtp_reiksme'} />
                          </Col>
                        </>
                      )}
                    </Row>
                    <Row>
                      <hr />
                      <Col sm={2} className={'mb-4'}>
                        <TextInput name={'name'} />
                      </Col>
                    </Row>
                    <div className={'mb-4 mt-3'}>
                      <SaveButton title={t('common.generate')} submitting={isSubmitting} />
                    </div>
                  </FormGroup>
                </Form>
              )}
            </Formik>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default RealEstateTransactionsDetailedFilter;
