import Table, { Column } from 'src/components/Table';
import React, { useCallback, useMemo, useState } from 'react';
import { PaginationData, PaginationDataFilter } from 'src/types';
import Api from 'src/api';
import { AmlIssuesLogListResponseDto } from 'src/types/api/amlManagement';
import { Cell } from 'react-table';
import { ModelField } from 'src/components/Table/Fields/ModelField';
import PrimaryButton from 'src/components/Form/PrimaryButton';
import AmlTraitsViewModal from 'src/pages/AmlManagement/AmlTraitsViewModal';
import EditAmlIssueModal from 'src/pages/AmlManagement/Edit/EditAmlIssueModal';
import { useTranslation } from 'react-i18next';
import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import { LegalEntityClassEnum } from 'src/helpers/Enums/LegalEntityClassEnum';

interface ModelInterface {
  id: string;
  type: LegalEntityClassEnum;
}

interface Props {
  model: ModelInterface | null;
}

const AmlIssuesTable = ({ model }: Props) => {
  const { t } = useTranslation();
  const { showModal } = useGlobalModalContext();
  const [data, setData] = useState<PaginationData<AmlIssuesLogListResponseDto>>();

  const fetchData = useCallback(
    async (request: PaginationDataFilter | undefined) => {
      if (model) {
        if (model.type === LegalEntityClassEnum.USER) {
          return Api.amlManagement
            .fetchFilteredIssuesLogByUser(model.id, request)
            .then((response) => setData(response));
        } else if (model.type === LegalEntityClassEnum.USER_COMPANY) {
          return Api.amlManagement
            .fetchFilteredIssuesLogByCompany(model.id, request)
            .then((response) => setData(response));
        }
      }

      return Api.amlManagement
        .fetchFilteredIssuesLog(request)
        .then((response) => setData(response));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleUpdatedIssue = useCallback(
    (updatedIssue: AmlIssuesLogListResponseDto) => {
      if (data) {
        const clonedData = { ...data };

        const amlIssueToBeUpdated = clonedData.data.findIndex(
          (item) => item.id === updatedIssue.id,
        );

        if (amlIssueToBeUpdated !== -1) {
          clonedData.data[amlIssueToBeUpdated] = updatedIssue;

          setData(clonedData);
        }
      }
    },
    [data],
  );

  const columns = useMemo<Column<AmlIssuesLogListResponseDto>[] | any>(() => {
    return [
      {
        Header: t('table.id'),
        accessor: 'id',
        sortType: 'number',
        width: 150,
      },
      {
        Header: t('table.created_at'),
        accessor: 'created_at',
        width: 150,
        Cell: (cell: Cell<AmlIssuesLogListResponseDto>) => (
          <div>{t('common.date_full', { date: cell.value })}</div>
        ),
      },
      {
        Header: t('table.resolved_at'),
        accessor: 'resolved_at',
        width: 150,
        Cell: (cell: Cell<AmlIssuesLogListResponseDto>) => (
          <div>{t('common.date_full', { date: cell.value })}</div>
        ),
      },
      {
        Header: t('table.account_id'),
        accessor: 'account.id',
        width: 100,
      },
      {
        Header: t('table.account'),
        accessor: 'account',
        Cell: (cell: Cell<AmlIssuesLogListResponseDto>) => {
          const value = cell.row.original.account;

          return <ModelField model={value} />;
        },
      },
      {
        Header: t('table.status'),
        accessor: 'status',
        width: 125,
        Cell: (cell: Cell<AmlIssuesLogListResponseDto>) => {
          return <div>{cell.row.original.status}</div>;
        },
      },
      {
        Header: t('table.reason'),
        accessor: 'reason',
        width: 200,
        Cell: (cell: Cell<AmlIssuesLogListResponseDto>) => {
          return <div>{cell.row.original.reason}</div>;
        },
      },
      {
        Header: t('table.lawyer_comment'),
        accessor: 'lawyer_comment',
        width: 200,
        Cell: (cell: Cell<AmlIssuesLogListResponseDto>) => {
          return <div>{cell.row.original.lawyer_comment}</div>;
        },
      },
      {
        Header: t('table.traits'),
        accessor: 'traits',
        width: 150,
        Cell: (cell: Cell<AmlIssuesLogListResponseDto>) => {
          return (
            <div>
              {cell.row.original.traits ? (
                <PrimaryButton
                  className={'btn btn-primary col-md-12'}
                  title={t('common.view_traits')}
                  onClick={() =>
                    showModal(<AmlTraitsViewModal traits={cell.row.original.traits} />)
                  }
                />
              ) : (
                <span>-</span>
              )}
            </div>
          );
        },
      },
      {
        Header: t('table.action'),
        accessor: 'resolved',
        width: 100,
        Cell: (cell: Cell<AmlIssuesLogListResponseDto>) => {
          return (
            <PrimaryButton
              className={'btn btn-success col-md-12'}
              title={t('common.edit')}
              onClick={() =>
                showModal(
                  <EditAmlIssueModal
                    issue={cell.row.original}
                    handleUpdatedIssue={handleUpdatedIssue}
                  />,
                )
              }
            />
          );
        },
      },
    ];
  }, [handleUpdatedIssue, showModal, t]);

  return (
    <Table
      title={'AmlRiskLevelChangeLogListIndex'}
      onFetchData={fetchData}
      columns={columns}
      data={data}
      searchable={true}
      enableQueryFilter={true}
      disableFiltersOutsideTable={true}
    />
  );
};

export default AmlIssuesTable;
