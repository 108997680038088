import React from 'react';
import AuthRoute from '../AuthRoute';
import { RouteList } from 'src/routes';
import PageLayout from 'src/containers/PageLayout';
import { Permissions } from 'src/helpers/auth/permissions';
import AmlChangeLog from 'src/pages/AmlManagement/RiskLevel';
import AmlIssues from 'src/pages/AmlManagement/Issues';
import FrozenStatusChangeLog from 'src/pages/AmlManagement/FrozenStatus';
import ResidenceCountry from 'src/pages/AmlManagement/ResidenceCountry';

const AmlManagementRoutes: any = () => [
  <AuthRoute
    path={RouteList.AML_MANAGEMENT.RISK_LEVEL}
    component={AmlChangeLog}
    layout={PageLayout}
    permissions={[Permissions.BO__AML_MANAGEMENT__LIST]}
    redirect={RouteList.AUTHENTICATION.LOGIN}
    key={RouteList.AML_MANAGEMENT.RISK_LEVEL}
    exact
  />,
  <AuthRoute
    path={RouteList.AML_MANAGEMENT.FROZEN_STATUS}
    component={FrozenStatusChangeLog}
    layout={PageLayout}
    permissions={[Permissions.BO__AML_MANAGEMENT__LIST]}
    redirect={RouteList.AUTHENTICATION.LOGIN}
    key={RouteList.AML_MANAGEMENT.FROZEN_STATUS}
    exact
  />,
  <AuthRoute
    path={RouteList.AML_MANAGEMENT.ISSUES}
    component={AmlIssues}
    layout={PageLayout}
    permissions={[Permissions.BO__AML_MANAGEMENT__LIST]}
    redirect={RouteList.AUTHENTICATION.LOGIN}
    key={RouteList.AML_MANAGEMENT.ISSUES}
    exact
  />,
  <AuthRoute
    path={RouteList.AML_MANAGEMENT.RESIDENCE_COUNTRY}
    component={ResidenceCountry}
    layout={PageLayout}
    permissions={[Permissions.BO__AML_MANAGEMENT__LIST]}
    redirect={RouteList.AUTHENTICATION.LOGIN}
    key={RouteList.AML_MANAGEMENT.RESIDENCE_COUNTRY}
    exact
  />,
];

export default AmlManagementRoutes;
