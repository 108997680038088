import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Form, Formik, FormikHelpers } from 'formik';
import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import ModalCloseButton from 'src/components/Modal/ModalCloseButton';
import { ModalComponent } from 'src/components/Modal/ModalComponent';
import Api from 'src/api';
import { ReactSelectOption } from 'src/components/Form/Select/SelectInput';
import { success } from 'src/services/toastr';

import { SelectAsyncInput } from 'src/components/Form/Select';
import { UserRelateAccountRequestDto } from 'src/types/api/user';
import SaveButton from 'src/components/Form/SaveButton';
import DenyButton from 'src/components/Form/DenyButton';
import { LegalEntity, PaginationDataFilter } from 'src/types';
import { ModelResponseDto } from 'src/types/api/common';

interface IForm {
  account: string;
}

interface AssignManagerModalProps {
  onAction: (request: UserRelateAccountRequestDto) => Promise<any>;
  currentUserId: string;
  currentUserType: LegalEntity;
  title?: string;
  body?: string;
  successMessage?: string;
}

const RelateAccountModal: React.FC<AssignManagerModalProps> = ({
  onAction,
  currentUserId,
  currentUserType,
  title,
  body,
  successMessage,
}) => {
  const { t } = useTranslation();
  const { hideModal } = useGlobalModalContext();
  const [changeApplicationManagerRequest] = useState<IForm>({
    account: '',
  });

  const fetchAvailableAccountsToRelate = async (inputValue?: string, loadWith?: Array<string>) => {
    const request: PaginationDataFilter = {
      page: 1,
      limit: 100,
      sort: [],
      search: inputValue,
      with: loadWith,
    };

    const response =
      currentUserType == LegalEntity.Personal
        ? await Api.user.fetchUserRelateAvailableAccounts(currentUserId, request)
        : await Api.userCompanies.fetchCompanyRelateAvailableAccounts(currentUserId, request);
    const items: ReactSelectOption[] = [];

    response.data.map((model: ModelResponseDto) => {
      items.push({
        value: model.id + '.' + model.type,
        label: model.pid ? `(${model.pid}) ${model.name}` : model.name,
      });
    });

    return items;
  };

  const onSubmit = useCallback(
    (request: IForm, helper: FormikHelpers<IForm>) => {
      return onAction({
        id: request.account.split('.')[0],
        type: request.account.split('.')[1],
      })
        .then(() => {
          success(successMessage ?? t('common.updated_success'));
          hideModal();
        })
        .catch((e) => {
          helper.setErrors(e.response?.errors);
        });
    },
    [onAction, successMessage, t, hideModal],
  );

  return (
    <React.Fragment>
      <ModalComponent size={'md'}>
        <div>
          <ModalHeader toggle={hideModal} close={<ModalCloseButton />}>
            {title}
          </ModalHeader>
          <Formik initialValues={changeApplicationManagerRequest} onSubmit={onSubmit}>
            {({ handleSubmit, isSubmitting }) => (
              <Form onSubmit={handleSubmit}>
                <ModalBody>
                  <SelectAsyncInput
                    placeholder={body}
                    name={'account'}
                    isClearable={true}
                    loadOptions={fetchAvailableAccountsToRelate}
                  />
                </ModalBody>
                <ModalFooter className={'d-flex justify-content-center'}>
                  <Col>
                    <DenyButton title={t('common.cancel')} onClick={hideModal} />
                  </Col>
                  <Col>
                    <SaveButton title={t('common.submit')} submitting={isSubmitting} />
                  </Col>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </div>
      </ModalComponent>
    </React.Fragment>
  );
};

export default RelateAccountModal;
