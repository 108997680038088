import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { DashboardPageGraphDataDto, DashboardPageGraphSearchDto } from 'src/types/api/dashboard';
import * as Yup from 'yup';
import Api from 'src/api';
import { getCurrentMonthFirstDay, getCurrentMonthLastDay } from 'src/helpers/utilts';
import { Formik } from 'formik';
import { Form } from 'reactstrap';
import DatePickerInput from 'src/components/Form/DatePickerInput';
import SaveButton from 'src/components/Form/SaveButton';
import Loader from 'src/components/Loader';
import { PieChart, Pie, ResponsiveContainer, Tooltip, Cell, Legend } from 'recharts';

const InvestmentsByTypePieChart = () => {
  const { t } = useTranslation();

  const [data, setData] = useState<DashboardPageGraphDataDto[]>([]);

  const Schema = Yup.object().shape({
    start_date: Yup.date().required(),
    end_date: Yup.date()
      .required()
      .when('start_date', (start_date, schema) => {
        return start_date
          ? schema.min(start_date, t('validation.date.min', { min: start_date }))
          : schema;
      }),
  });

  useEffect(() => {
    Api.dashboard
      .fetchHighNetWorthPieChartData(getCurrentMonthFirstDay(), getCurrentMonthLastDay())
      .then((response) => {
        setData(response.data);
      });
  }, []);

  const handleSearch = async (values: DashboardPageGraphSearchDto) => {
    const response = await Api.dashboard.fetchHighNetWorthPieChartData(
      values.start_date,
      values.end_date,
    );
    setData(response.data);

    return true;
  };

  const COLORS = ['#8884d8', '#82ca9d'];
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
  }: {
    cx: number;
    cy: number;
    midAngle: number;
    innerRadius: number;
    outerRadius: number;
    percent: number;
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
    <div className="w-100">
      <div className="card-ml-2 card-mr-2">
        <h3>{t('dashboard.high_net_worth_pie_chart')}</h3>
        <Formik
          initialValues={{
            start_date: getCurrentMonthFirstDay(),
            end_date: getCurrentMonthLastDay(),
          }}
          validationSchema={Schema}
          enableReinitialize={true}
          onSubmit={handleSearch}
        >
          {({ handleSubmit, isSubmitting }) => (
            <Form onSubmit={handleSubmit}>
              <div style={{ display: 'inline-flex' }} className="w-100">
                <div className="mb-2 w-50">
                  <div className="mr-5">
                    <DatePickerInput name="start_date" />
                  </div>
                </div>
                <div className="mb-2 w-50">
                  <div className="ml-5">
                    <DatePickerInput name="end_date" />
                  </div>
                </div>
              </div>
              <SaveButton title={t('common.search')} submitting={isSubmitting} />
            </Form>
          )}
        </Formik>
        {data.length === 0 ? (
          <div className="d-flex justify-content-center align-items-center mt-5">
            <Loader />
          </div>
        ) : (
          <ResponsiveContainer
            className="mt-5"
            width="100%"
            height="100%"
            minHeight="500px"
            maxHeight={500}
          >
            <PieChart width={500} height={500}>
              <Legend />
              <Pie
                data={data}
                cx="50%"
                cy="50%"
                labelLine={false}
                label={renderCustomizedLabel}
                outerRadius={150}
                fill="#8884d8"
                dataKey="value"
                animationBegin={200}
                animationDuration={500}
              >
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                    name={t('dashboard.' + entry.name)}
                  />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
        )}
      </div>
    </div>
  );
};

export default InvestmentsByTypePieChart;
