import React, { useCallback, useEffect, useState } from 'react';
import {
  DebtCollectionContextState,
  withDebtCollection,
} from 'src/pages/DebtCollection/Update/DebtCollectionContext';
import Loader from 'src/components/Loader';
import { HiddenColumnsProvider } from 'src/components/ShowTableColumnsDropdown';
import { Col, Container, FormGroup, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { StageStatusChangeRequestDto } from 'src/types/api/DebtCollection';
import { FormikHelpers } from 'formik';
import Api from 'src/api';
import { success } from 'src/services/toastr';
import { defaultTranslatableValue, transformErrors, translatableValueSchema } from 'src/helpers';
import * as Yup from 'yup';
import { StageStatusEnum } from 'src/helpers/Enums/Debt_collection/StageStatusEnum';
import SelectInput from 'src/components/Form/Select/SelectInput';
import PermissionAction from 'src/components/PermissionAction';
import { Permissions } from 'src/helpers/auth/permissions';
import LocaleFormik from 'src/components/Form/LocaleFormik/LocaleFormik';
import LanguageSwitcher from 'src/components/LanguageSwitcher';
import DatePickerInput from 'src/components/Form/DatePickerInput';
import TextAreaInput from 'src/components/Form/TextAreaInput';
import SaveButton from 'src/components/Form/SaveButton';

const TabContractTermination: React.FC<DebtCollectionContextState> = ({
  debtCollection,
  setDebtCollection,
}) => {
  const { t } = useTranslation();

  const [initialValues, setInitialValues] = useState<StageStatusChangeRequestDto>();

  const onSubmit = useCallback(
    async (
      request: StageStatusChangeRequestDto,
      helpers: FormikHelpers<StageStatusChangeRequestDto>,
    ) => {
      if (!debtCollection) return;
      try {
        if (request.status !== StageStatusEnum.OTHER) {
          request.interest_calculation_terminated_at = null;
        }

        const response = await Api.debtCollection.changeStatus(debtCollection.id, request);
        setDebtCollection(response);
        success(t('common.updated_success'));
      } catch (e: any) {
        helpers.setErrors(transformErrors(e.response?.errors));
      }

      return true;
    },
    [t],
  );

  const DataSchema = Yup.object().shape({
    purpose: translatableValueSchema(Yup.string().required()),
    status: Yup.string().required(),
    contract_terminated_at: Yup.date().required(),
    interest_calculation_terminated_at: Yup.date().when('status', {
      is: StageStatusEnum.OTHER,
      then: Yup.date().required(),
      otherwise: Yup.date().nullable(),
    }),
  });

  useEffect(() => {
    if (debtCollection) {
      setInitialValues({
        purpose:
          debtCollection && debtCollection.latest_status_reason
            ? debtCollection.latest_status_reason
            : defaultTranslatableValue(),
        status:
          debtCollection && debtCollection.status
            ? debtCollection.status
            : StageStatusEnum.RECOVERING,
        contract_terminated_at: debtCollection.contract_terminated_at
          ? debtCollection.contract_terminated_at
          : new Date(),
        interest_calculation_terminated_at: debtCollection.interest_calculation_terminated_at
          ? debtCollection.interest_calculation_terminated_at
          : new Date(),
      });
    }
  }, [debtCollection, setInitialValues]);

  if (!debtCollection?.borrower_payouts) {
    return <Loader />;
  }

  return (
    <React.Fragment>
      <HiddenColumnsProvider title={'DebtCollectionActionsIndex'}>
        <Container fluid>
          <h3>{t('debt_collection.contract_termination')}</h3>
          <hr />
          <PermissionAction permissions={Permissions.BO__DEBT_COLLECTION__TERMINATE_CONTRACT}>
            {initialValues && (
              <LocaleFormik
                initialValues={initialValues}
                enableReinitialize={true}
                validationSchema={DataSchema}
                onSubmit={onSubmit}
              >
                {({ isSubmitting, locale, setLocale, multiLocaleAttrs, values }) => (
                  <>
                    <LanguageSwitcher locale={locale} setLocale={setLocale} />
                    <FormGroup>
                      <Row>
                        <Col sm={12} className={'mb-4'}>
                          <SelectInput
                            name={'status'}
                            options={Object.values(StageStatusEnum).map((value) => {
                              return {
                                label: t(`debt_collection.status.${value}`),
                                value: value,
                                isDisabled: ![
                                  StageStatusEnum.OTHER,
                                  StageStatusEnum.RECOVERING,
                                ].includes(value),
                              };
                            })}
                          />
                        </Col>
                        <Col sm={12} className={'mb-4'}>
                          <DatePickerInput name={'contract_terminated_at'} />
                        </Col>
                        {values.status === StageStatusEnum.OTHER && (
                          <Col sm={12} className={'mb-4'}>
                            <DatePickerInput name={'interest_calculation_terminated_at'} />
                          </Col>
                        )}
                        <Col sm={12} className={'mb-4'}>
                          <TextAreaInput {...multiLocaleAttrs('purpose')} />
                        </Col>
                      </Row>
                      <div className={'mt-4 mb-4'}>
                        <SaveButton title={t('common.submit')} submitting={isSubmitting} />
                      </div>
                    </FormGroup>
                  </>
                )}
              </LocaleFormik>
            )}
          </PermissionAction>
        </Container>
      </HiddenColumnsProvider>
    </React.Fragment>
  );
};

export default withDebtCollection(TabContractTermination);
