import { BaseTabProps } from 'src/components/Tabs';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik, FormikHelpers } from 'formik';
import { Col, FormGroup, Row } from 'reactstrap';

import Api from 'src/api';
import {
  FetchAutoInvestorDraftQueueByFilterRequest,
  AutoInvestorDraftQueueResponse,
} from 'src/types/api/autoInvestment';
import { success } from 'src/services/toastr';
import SaveButton from 'src/components/Form/SaveButton';
import SelectCreditDurationInput from 'src/components/Form/Select/SelectCreditDurationInput';
import SelectRatingInput from 'src/components/Form/Select/SelectRatingInput';
import TextInput from 'src/components/Form/TextInput';
import AutoInvestmentDraftResults from 'src/pages/AutoInvestment/AutoInvestmentDraftResults';
import { UseNumbers } from 'src/helpers/useNumbers';
import SelectInvestmentPurposeInput from 'src/components/Form/Select/SelectInvestmentPurposeInput';
import SelectSecurityMeasureInput from 'src/components/Form/Select/SelectSecurityMeasureInput';
import SelectCountryInput from 'src/components/Form/Select/SelectCountryInput';
import SelectRiskCategoryInput from 'src/components/Form/Select/SelectRiskCategoryInput';

type Props = BaseTabProps;

const TabFilter: React.FC<Props> = ({}) => {
  const { t } = useTranslation();

  const [request] = useState<FetchAutoInvestorDraftQueueByFilterRequest>({
    basic_interest: 0,
    credit_duration: null,
    initial_rating: '',
    risk_category: '',
    investment_purpose: '',
    security_measures: '',
    country: '',
  });

  const [response, setResponse] = useState<AutoInvestorDraftQueueResponse | undefined>();

  const onSubmit = useCallback(
    async (
      request: FetchAutoInvestorDraftQueueByFilterRequest,
      helper: FormikHelpers<FetchAutoInvestorDraftQueueByFilterRequest>,
    ) => {
      try {
        await Api.autoInvestment
          .fetchAutoInvestmentFilterDraftQueue(request)
          .then((r) => setResponse(r));
        success(t('common.success'));
      } catch (e: any) {
        helper.setErrors(e.response?.errors);
      }
    },
    [t],
  );

  return (
    <>
      <div className={'mb-5'}>
        <Formik initialValues={request} onSubmit={onSubmit} enableReinitialize={true}>
          {({ handleSubmit, isSubmitting }) => (
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Row>
                  <Col md={4}>
                    <div className={'mb-4'}>
                      <TextInput
                        name={'basic_interest'}
                        placeholder={t('label.basic_interest')}
                        type={'number'}
                        step={'any'}
                        onKeyPress={UseNumbers.preventNonNumericalInput}
                      />
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className={'mb-4'}>
                      <SelectCreditDurationInput
                        name={'credit_duration'}
                        placeholder={t('label.credit_duration')}
                        isClearable={true}
                      />
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className={'mb-4'}>
                      <SelectRatingInput
                        name={'initial_rating'}
                        placeholder={t('label.initial_rating')}
                        isClearable={true}
                      />
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className={'mb-4'}>
                      <SelectRiskCategoryInput
                        name={'risk_category'}
                        placeholder={t('label.risk_category')}
                        className={'z-100'}
                      />
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className={'mb-4'}>
                      <SelectInvestmentPurposeInput
                        placeholder={t('label.investment_purpose')}
                        name={'investment_purpose'}
                      />
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className={'mb-4'}>
                      <SelectSecurityMeasureInput
                        placeholder={t('label.security_measures')}
                        name={'security_measures'}
                      />
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className={'mb-4'}>
                      <SelectCountryInput name={'country'} />
                    </div>
                  </Col>
                </Row>

                <div className={'mb-4 mt-5'}>
                  <SaveButton title={t('common.submit')} submitting={isSubmitting} />
                </div>
              </FormGroup>
            </Form>
          )}
        </Formik>
      </div>
      {response && (
        <div className={'mb-4'}>
          <AutoInvestmentDraftResults results={response} />
        </div>
      )}
    </>
  );
};

export default TabFilter;
