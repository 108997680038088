import React, { useEffect, useState } from 'react';
import { RouterProps, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Container } from 'reactstrap';

import { setGlobalLoading } from 'src/modules/app/actions';
import { HiddenColumnsProvider } from 'src/components/ShowTableColumnsDropdown';
import AmlIssuesTable from 'src/pages/AmlManagement/AmlIssuesTable';
import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import ViewWorklistInformationModal from 'src/pages/AmlManagement/ViewWorklistInformationModal';
import Api from 'src/api';

export interface WorklistInformationResponseDto {
  lastScreenedDate: Date;
  businessMonitorRecordsCount: number;
  individualMonitorRecordsCount: number;
  individualOpenMonitorRecordsCount: number;
  individualOpenMatchesCount: number;
  individualFalsePositiveMatchesCount: number;
  individualTruePositiveMatchesCount: number;
  individualDiscardedMatchesCount: number;
  businessOpenMonitorRecordsCount: number;
  businessOpenMatchesCount: number;
  businessFalsePositiveMatchesCount: number;
  businessTruePositiveMatchesCount: number;
  businessDiscardedMatchesCount: number;
  id: string;
  name: string;
  threshold: number;
  frequency: string;
  dataSets: string[];
  scheduleDayOfTheWeek: string;
  scheduleMonth: string;
}

const AmlIssues: React.FC<RouterProps> = () => {
  const { t } = useTranslation();
  const { showModal } = useGlobalModalContext();
  const [worklistInformation, setWorklistInformation] =
    useState<WorklistInformationResponseDto | null>(null);

  useEffect(() => {
    setGlobalLoading(false);

    Api.amlManagement.viewWorklistInformation().then((response) => {
      setWorklistInformation(response);
    });
  }, []);

  return (
    <React.Fragment>
      <Container fluid>
        <Card>
          <CardBody>
            <div className={'d-flex align-items-center mb-4'}>
              <h4 className={'m-0'}>{t('menu.aml_management.issues')}</h4>
            </div>
            <div>
              <span className="mr-5">
                <b>{t('menu.worklist_threshhold')}:</b> {worklistInformation?.threshold ?? '-'}
              </span>
              <button
                className="btn btn-primary"
                disabled={worklistInformation === null}
                onClick={() => {
                  if (worklistInformation) {
                    showModal(
                      <ViewWorklistInformationModal worklistInformation={worklistInformation} />,
                    );
                  }
                }}
              >
                {t('menu.view_worklist')}
              </button>
            </div>
            <HiddenColumnsProvider title={'AmlRiskLevelChangeLogListIndex'}>
              <AmlIssuesTable model={null} />
            </HiddenColumnsProvider>
          </CardBody>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default withRouter(AmlIssues);
