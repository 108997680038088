import React from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import { ModalComponent } from 'src/components/Modal/ModalComponent';
import { ModalBody, ModalHeader } from 'reactstrap';
import ModalCloseButton from 'src/components/Modal/ModalCloseButton';
import MatchesTraitsTable from 'src/pages/AmlManagement/MatchesTraitsTable';

interface Props {
  traits: any;
}

const AmlTraitsViewModal: React.FC<Props> = ({ traits }) => {
  const { t } = useTranslation();
  const { hideModal } = useGlobalModalContext();

  return (
    <React.Fragment>
      <ModalComponent size={'lg'}>
        <div>
          <ModalHeader toggle={hideModal} close={<ModalCloseButton />}>
            {t('table.view_matches')}
          </ModalHeader>
          <ModalBody>
            <MatchesTraitsTable traits={traits} />
          </ModalBody>
        </div>
      </ModalComponent>
    </React.Fragment>
  );
};

export default AmlTraitsViewModal;
