import React from 'react';
import { useTranslation } from 'react-i18next';
import TextInput from 'src/components/Form/TextInput';
import { ArrayHelpers } from 'formik/dist/FieldArray';
import SelectRealEstateTypeInput from 'src/components/Form/Select/SelectRealEstateTypeInput';
import { UseNumbers } from 'src/helpers/useNumbers';
import { UpdateProspectApplicationSingleSecurityDepositRequestDto } from 'src/types/api/prospectApplications';
import DatePickerInput from 'src/components/Form/DatePickerInput';

const SingleSecurityDeposit: React.FC<{
  index: number;
  deposit: UpdateProspectApplicationSingleSecurityDepositRequestDto;
  deposits: UpdateProspectApplicationSingleSecurityDepositRequestDto[];
  formikArrayHelpers: ArrayHelpers;
  isEditable: boolean;
}> = ({ index, isEditable }) => {
  const { t } = useTranslation();

  const realEstateType = `security_deposits[${index}].real_estate_type`;
  const pid = `security_deposits[${index}].id`;
  const realEstateUnique = `security_deposits[${index}].real_estate_unique`;
  const realEstatePlot = `security_deposits[${index}].real_estate_plot`;
  const realEstateAddress = `security_deposits[${index}].real_estate_address`;
  const realEstateValue = `security_deposits[${index}].real_estate_value`;
  const realEstateAppraiser = `security_deposits[${index}].real_estate_appraiser`;
  const realEstateAppraiserAt = `security_deposits[${index}].real_estate_appraised_at`;

  return (
    <>
      <tr>
        <td>
          <SelectRealEstateTypeInput
            isDisabled={!isEditable}
            placeholder={t('label.real_estate_type')}
            name={realEstateType}
            hideLabel={true}
          />
        </td>
        <td>
          <TextInput
            name={pid}
            disabled={!isEditable}
            placeholder={t('label.pid')}
            hideLabel={true}
          />
        </td>
        <td>
          <TextInput
            type={'text'}
            name={realEstateUnique}
            placeholder={t('label.real_estate_unique')}
            hideLabel={true}
            disabled={!isEditable}
          />
        </td>
        <td>
          <TextInput
            type={'text'}
            name={realEstatePlot}
            placeholder={t('label.real_estate_plot')}
            hideLabel={true}
            disabled={!isEditable}
          />
        </td>
        <td>
          <TextInput
            type={'text'}
            name={realEstateAddress}
            placeholder={t('label.real_estate_address')}
            hideLabel={true}
            disabled={!isEditable}
          />
        </td>
        <td>
          <TextInput
            type={'number'}
            step={'any'}
            name={realEstateValue}
            placeholder={t('label.real_estate_value')}
            hideLabel={true}
            onKeyPress={UseNumbers.preventNonNumericalInput}
            disabled={!isEditable}
          />
        </td>
        <td>
          <TextInput
            type={'text'}
            name={realEstateAppraiser}
            disabled={!isEditable}
            placeholder={t('label.real_estate_appraiser')}
            hideLabel={true}
          />
        </td>
        <td className={'align-top'}>
          <DatePickerInput
            disabled={!isEditable}
            name={realEstateAppraiserAt}
            placeholder={t('label.real_estate_appraised_at')}
            hideLabel={true}
          />
        </td>
      </tr>
    </>
  );
};

export default SingleSecurityDeposit;
