import React from 'react';
import { Row } from 'react-table';
import { IconArrowDown, IconArrowUp } from 'src/components/Table/AdvanceFilters/Icons';

interface Props {
  onClick: () => void;
  row: Row<any>;
}

const Expander = ({ onClick, row }: Props) => {
  return (
    <span
      {...row.getToggleRowExpandedProps({
        style: {
          paddingLeft: `${row.depth * 2}rem`,
        },
      })}
      onClick={onClick}
      className="link-primary"
    >
      {row.isExpanded ? (
        <IconArrowUp classes={'icon-dropdown'} />
      ) : (
        <IconArrowDown classes={'icon-dropdown'} />
      )}
    </span>
  );
};

export default Expander;
