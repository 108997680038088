export enum ProjectEmailCampaignStatusEnum {
  AX_CANCELLED = -3,
  DELETED = -2,
  ARCHIVED = -1,
  DRAFT = 0,
  PROGRAMMED = 1,
  SENT = 2,
  AX_TESTED = 3,
  AX_SELECTED = 4,
}
