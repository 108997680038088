import { Formik, FormikHelpers } from 'formik';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import SaveButton from 'src/components/Form/SaveButton';
import SelectInput from 'src/components/Form/Select/SelectInput';
import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import ModalCloseButton from 'src/components/Modal/ModalCloseButton';
import { ModalComponent } from 'src/components/Modal/ModalComponent';
import { ProspectApplicationStatusEnum } from 'src/helpers/Enums/ProspectApplication/ProspectApplicationStatusEnum';
import { success } from 'src/services/toastr'; // Assuming you have an error function in toastr service
import { UpdateProspectApplicationChangeStatusRequestDto } from 'src/types/api/prospectApplications';

interface ChangeProspectApplicationStatusModalProps {
  onAction: (request: UpdateProspectApplicationChangeStatusRequestDto) => Promise<any>;
  currentStatus: ProspectApplicationStatusEnum;
  title?: string;
  body?: string;
}

const ChangeProspectApplicationStatusModal: React.FC<ChangeProspectApplicationStatusModalProps> = ({
  onAction,
  currentStatus,
  title,
}) => {
  const { t } = useTranslation();
  const { hideModal } = useGlobalModalContext();
  const [changeProspectApplicationStatusRequest] =
    useState<UpdateProspectApplicationChangeStatusRequestDto>({
      status: currentStatus,
    });
  const [errorMessage, setErrorMessage] = useState<string>('');

  const onSubmit = useCallback(
    (
      request: UpdateProspectApplicationChangeStatusRequestDto,
      helper: FormikHelpers<UpdateProspectApplicationChangeStatusRequestDto>,
    ) => {
      return onAction(request)
        .then(() => {
          success(t('common.updated_success'));
          hideModal();
        })
        .catch((e) => {
          setErrorMessage(e.message);
          helper.setErrors(e.response?.errors);
        });
    },
    [hideModal, onAction, t],
  );

  return (
    <React.Fragment>
      <ModalComponent size={'md'}>
        <div>
          <ModalHeader toggle={hideModal} close={<ModalCloseButton />}>
            {title}
          </ModalHeader>
          <Formik initialValues={changeProspectApplicationStatusRequest} onSubmit={onSubmit}>
            {({ handleSubmit, isSubmitting }) => (
              <Form onSubmit={handleSubmit}>
                <ModalBody>
                  <SelectInput
                    name="status"
                    options={[
                      ...Object.values(ProspectApplicationStatusEnum)
                        .filter((status) => status !== ProspectApplicationStatusEnum.CONVERTED)
                        .map((status) => ({
                          value: status,
                          label: t('prospect_applications.status.' + status),
                        })),
                    ]}
                  />
                  {errorMessage && <p className={'invalid-feedback-visible'}>{errorMessage}</p>}
                </ModalBody>
                <ModalFooter className={'d-flex justify-content-center'}>
                  <Col>
                    <SaveButton title={t('common.submit')} submitting={isSubmitting} />
                  </Col>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </div>
      </ModalComponent>
    </React.Fragment>
  );
};

export default ChangeProspectApplicationStatusModal;
