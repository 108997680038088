import React, { useCallback, useEffect, useState } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { ProjectResponseDto } from 'src/types/api/projects';
import { setGlobalLoading } from 'src/modules/app/actions';
import Api from 'src/api';
import TabData, { BaseTabProps, TabInfo } from 'src/components/Tabs';
import ProjectProvider from 'src/pages/Project/Update/ProjectContext';
import TabInvestment from 'src/pages/Project/Update/Tabs/TabInvestment';
import TabProject from 'src/pages/Project/Update/Tabs/TabProject';
import TabPictures from 'src/pages/Project/Update/Tabs/TabPictures';
import TabDocuments from 'src/pages/Project/Update/Tabs/TabDocuments';
import TabSecurities from 'src/pages/Project/Update/Tabs/TabSecurities';
import TabProjectOwner from 'src/pages/Project/Update/Tabs/TabProjectOwner';
import TabNewsArticle from 'src/pages/Project/Update/Tabs/TabNewsArticle';
import TabCampaigns from 'src/pages/Project/Update/Tabs/TabCampaigns';
import { LanguageEnum } from 'src/helpers/Enums/LanguageEnum';
import LanguageSwitcher from 'src/components/LanguageSwitcher';
import { Permissions } from 'src/helpers/auth/permissions';
import PermissionAction from 'src/components/PermissionAction';
import PrimaryButton from 'src/components/Form/PrimaryButton';
import ActionModal from 'src/components/Modal/Modals/ActionModal';
import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import { navigate } from 'src/helpers/navigate';
import { RouteList } from 'src/routes';
import { ProjectStatus } from 'src/types/app/projects';
import TabWalletP2POperations from 'src/pages/Project/Update/Tabs/TabWalletP2POperations';
import TabInvestments from 'src/pages/Project/Update/Tabs/TabInvestments';
import usePermissions from 'src/helpers/usePermissions';
import TabFinancialInfo from 'src/pages/Project/Update/Tabs/TabFinancialInfo';
import { TabActionHistory, TabStatusHistory } from 'src/pages/Project/Update/Tabs';
import Loader from 'src/components/Loader';
import MarkProjectAsWithCheckbox from 'src/pages/Project/Update/MarkProjectAsWithCheckbox';
import MarkProjectConfirmedModal from 'src/pages/Project/Update/Tabs/Modals/MarkProjectConfirmedModal';
import UpdateLoanStartDateModal from 'src/pages/Project/Update/Tabs/Modals/UpdateLoanStartDateModal';
import TabDebtCollection from 'src/pages/Project/Update/Tabs/TabDebtCollection';
import RefinanceProjectModal from 'src/pages/Project/Update/Tabs/Modals/RefinanceProjectModal';
import UpdateRefinancingProjectModal from 'src/pages/Project/Update/Tabs/Modals/UpdateRefinancingProjectModal';

const Tabs: TabInfo<BaseTabProps>[] = [
  {
    name: 'projects.investment',
    iconClass: 'far fa-file',
    component: TabInvestment,
    permission: [Permissions.BO__PROJECTS__VIEW_INVESTMENTS],
  },
  {
    name: 'projects.project',
    iconClass: 'far fa-file',
    component: TabProject,
    permission: [Permissions.BO__PROJECTS__VIEW],
  },
  {
    name: 'projects.project_owner',
    iconClass: 'far fa-file',
    component: TabProjectOwner,
    permission: [Permissions.BO__PROJECTS__VIEW_OWNER],
  },
  {
    name: 'projects.securities',
    iconClass: 'far fa-file',
    component: TabSecurities,
    permission: [Permissions.BO__PROJECTS__VIEW_SECURITIES],
  },
  {
    name: 'projects.pictures',
    iconClass: 'far fa-file',
    component: TabPictures,
    permission: [Permissions.BO__PROJECTS__VIEW_PICTURES],
  },
  {
    name: 'projects.documents',
    iconClass: 'far fa-file',
    component: TabDocuments,
    permission: [Permissions.BO__PROJECTS__VIEW_DOCUMENTS],
  },
  {
    name: 'projects.articles.news',
    iconClass: 'far fa-file',
    component: TabNewsArticle,
    permission: [Permissions.BO__PROJECTS__VIEW_ARTICLES],
  },
  {
    name: 'projects.campaigns',
    iconClass: 'fas fa-bullhorn',
    component: TabCampaigns,
    permission: [Permissions.BO__PROJECTS__EMAIL_CAMPAIGNS],
  },
  {
    name: 'projects.status_history',
    iconClass: 'fas fa-history',
    component: TabStatusHistory,
    permission: [Permissions.BO__PROJECTS__VIEW_STATUS_HISTORY],
  },
  {
    name: 'projects.action_history',
    iconClass: 'fas fa-history',
    component: TabActionHistory,
    permission: [Permissions.BO__PROJECTS__VIEW_ACTION_HISTORY],
  },
  {
    name: 'projects.wallet_p2p_operations',
    iconClass: 'fas fa-exchange-alt',
    component: TabWalletP2POperations,
    permission: [Permissions.BO__PROJECTS__VIEW_WALLET_P2P_OPERATIONS],
  },
  {
    name: 'projects.investments',
    iconClass: 'fas fa-exchange-alt',
    component: TabInvestments,
    permission: [Permissions.BO__PROJECTS__VIEW_INVESTMENTS],
  },
  {
    name: 'projects.financial_info',
    iconClass: 'fas fa-coins',
    component: TabFinancialInfo,
    permission: [Permissions.BO__PROJECTS__VIEW_FINANCIAL_INFO],
  },
  {
    name: 'projects.debt_collection',
    iconClass: 'fas fa-info-circle',
    component: TabDebtCollection,
    permission: [Permissions.BO__PROJECTS__VIEW_DEBT_COLLECTION],
  },
];

//TODO add protocol tabs on permissions, do not show multiple tabs!!
const Project: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { showModal } = useGlobalModalContext();
  const { projectId } = useParams<{ projectId: string }>();
  const p = usePermissions();
  const [project, setProject] = useState<ProjectResponseDto>();
  const [language, setLanguage] = useState<LanguageEnum>(i18n.resolvedLanguage as LanguageEnum);
  const [previewLoading, setPreviewLoading] = useState<boolean>(false);
  const [pinLoading, setPinLoading] = useState<boolean>(false);

  useEffect(() => {
    setGlobalLoading(true);
    if (projectId) {
      Api.projects.fetchProject(projectId).then((response) => {
        setProject(response);
        setGlobalLoading(false);
      });
    }
  }, [projectId]);

  const stageProject = useCallback(() => {
    if (!project) {
      return;
    }

    showModal(
      <ActionModal
        title={t('projects.stage.title')}
        body={
          project.stageable_until !== null
            ? t('projects.stage.body_with_stageable_until_date', { date: project.stageable_until })
            : t('projects.stage.body')
        }
        successMessage={t('projects.stage.success')}
        actionText={t('common.submit')}
        onAction={async () => {
          return Api.projects.stageProject(project.id).then((response) => {
            navigate(generatePath(RouteList.APPLICATION.VIEW, { applicationId: response.id }));
          });
        }}
      />,
    );
  }, [project, showModal, t]);

  const reopenProjectForInvestmets = useCallback(() => {
    if (!project) {
      return;
    }

    showModal(
      <ActionModal
        title={t('projects.reopen.title')}
        body={t('projects.reopen.body')}
        successMessage={t('projects.reopen.success')}
        actionText={t('common.submit')}
        onAction={async () => {
          return Api.projects.reopenProjectForInvestments(project.id).then(setProject);
        }}
      />,
    );
  }, [project, showModal, t]);

  const closeProjectReopenStage = useCallback(() => {
    if (!project) {
      return;
    }

    showModal(
      <ActionModal
        title={t('projects.reopen.close_reopen.title')}
        body={t('projects.reopen.close_reopen.body')}
        successMessage={t('projects.reopen.close_reopen.success')}
        actionText={t('common.submit')}
        onAction={async () => {
          return Api.projects.closeProjectReopenStage(project.id).then(setProject);
        }}
      />,
    );
  }, [project, showModal, t]);

  const markProject = useCallback(
    (status: ProjectStatus) => {
      if (!project) {
        return;
      }

      if (status === ProjectStatus.Confirmed) {
        showModal(
          <MarkProjectConfirmedModal
            title={t('projects.mark_status.title', { status: status.replaceAll('_', ' ') })}
            body={t('projects.mark_status.body', { status: status.replaceAll('_', ' ') })}
            checkboxText={t('projects.mark_status.ignore_validation')}
            successMessage={t('projects.mark_status.success')}
            actionText={t('common.submit')}
            project={project}
            status={status}
            setProject={setProject}
          />,
        );

        return;
      }

      if (status === ProjectStatus.Finished) {
        showModal(
          <MarkProjectAsWithCheckbox
            title={t('projects.mark_status.title', { status: status.replaceAll('_', ' ') })}
            body={t('projects.mark_status.body', { status: status.replaceAll('_', ' ') })}
            checkboxText={t('projects.mark_status.disable_notifications')}
            successMessage={t('projects.mark_status.success')}
            actionText={t('common.submit')}
            project={project}
            status={status}
            setProject={setProject}
          />,
        );

        return;
      }

      showModal(
        <ActionModal
          title={t('projects.mark_status.title', { status: status.replaceAll('_', ' ') })}
          body={t('projects.mark_status.body', { status: status.replaceAll('_', ' ') })}
          successMessage={t('projects.mark_status.success')}
          actionText={t('common.submit')}
          onAction={async () => {
            return Api.projects.markProjectForStatus(project.id, status).then(setProject);
          }}
        />,
      );
    },
    [project, showModal, t],
  );
  const handleError = useCallback((errors: any) => {
    if (!errors?.response?.errors) {
      return;
    }

    for (const msg of Object.keys(errors.response?.errors)) {
      const lang = msg.split('.').pop();
      if (lang) {
        const match = Object.keys(LanguageEnum).find((key) => key === lang.toUpperCase());
        if (match) {
          setLanguage(LanguageEnum[match as keyof typeof LanguageEnum]);
          break;
        }
      }
    }
  }, []);

  const canChangeStatus = useCallback((status: string | undefined) => {
    const ready_for_investments =
      status === ProjectStatus.Preparing || status === ProjectStatus.ComingSoon;

    const coming_soon = status === ProjectStatus.ReadyForInvestments;

    const open_for_investment =
      status === ProjectStatus.ComingSoon || status === ProjectStatus.ReadyForInvestments;

    const funded = status === ProjectStatus.OpenForInvestments;

    const not_funded = status === ProjectStatus.OpenForInvestments;

    const confirmed = status === ProjectStatus.Funded;

    const finished = status === ProjectStatus.Confirmed;

    return {
      coming_soon,
      ready_for_investments,
      open_for_investment,
      funded,
      confirmed,
      not_funded,
      finished,
    };
  }, []);

  const showPreviewButton = () => {
    return p.can(Permissions.SS__ACTIONS__VIEW_NON_PUBLIC_PROJECT);
  };

  const showReopenButton = () => {
    if (project) {
      return (
        p.can(Permissions.BO__PROJECTS__REOPEN_PROJECT_FOR_INVESTMENTS) &&
        project.can_be_reopened_for_investments
      );
    }

    return false;
  };

  const showCloseReopenStageButton = () => {
    if (project) {
      return (
        p.can(Permissions.BO__PROJECTS__REOPEN_PROJECT_FOR_INVESTMENTS) &&
        project.can_close_reopened_stage
      );
    }

    return false;
  };

  const showRefinanceButton = () => {
    if (project) {
      return (
        p.can(Permissions.BO__PROJECTS__REFINANCE_PROJECT) &&
        project.refinancing_project !== null &&
        project.status === ProjectStatus.Confirmed &&
        project.refinanced_at === null
      );
    }

    return false;
  };

  const showUpdateRefinancingProjectButton = () => {
    if (project) {
      return p.can(Permissions.BO__PROJECTS__UPDATE) && project.status === ProjectStatus.Confirmed;
    }

    return false;
  };

  const handlePreviewProject = () => {
    if (project) {
      setPreviewLoading(true);
      Api.projects.previewProject(projectId).then((response) => {
        setPreviewLoading(false);
        window.open(response.url, '_blank');
      });
    }
  };

  const handlePinProject = () => {
    if (project) {
      setPinLoading(true);
      return Api.projects.pinProject(projectId).then((response) => {
        setPinLoading(false);
        setProject(response);
      });
    }
  };

  const handleUnpinProject = () => {
    if (project) {
      setPinLoading(true);
      return Api.projects.unpinProject(projectId).then((response) => {
        setPinLoading(false);
        setProject(response);
      });
    }
  };

  if (!project) {
    return <></>;
  }

  return (
    <React.Fragment>
      <ProjectProvider
        project={project}
        setProject={setProject}
        language={language}
        setLanguage={setLanguage}
        onErrorHandling={handleError}
      >
        <Container fluid>
          <Row>
            <Col xs="12" className={'mb-4'}>
              <Card className="card h-100">
                <CardBody>
                  <div className={'float-end'} />
                  <Row className={'mb-4'}>
                    <Col>
                      <div>{t('table.id')}</div>
                      <div>{project?.pid}</div>
                    </Col>
                    <Col>
                      <div>{t('projects.name')}</div>
                      <div>{project?.project_name?.[language]}</div>
                    </Col>
                    <Col>
                      <div>{t('projects.project_owner')}</div>
                      <div>{project?.project_owner_name?.[language]}</div>
                    </Col>
                    <Col>
                      <div>{t('projects.status')}</div>
                      <div>{t('projects.status.' + project.status)}</div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col xs="12">
              <Card className="mb-4">
                <CardBody>
                  <div className="hori-timeline">
                    <div className="events">
                      <div className={'d-flex justify-content-between align-items-center'}>
                        <div>
                          <LanguageSwitcher locale={language} setLocale={setLanguage} />
                        </div>
                        <div className={'d-flex gap-2'}>
                          {project.status === ProjectStatus.OpenForInvestments &&
                            (project.pinned_at ? (
                              <button
                                className={'btn btn-danger'}
                                type={'button'}
                                disabled={pinLoading}
                                onClick={() => handleUnpinProject()}
                              >
                                {pinLoading ? <Loader /> : t('common.unpin')}
                              </button>
                            ) : (
                              <button
                                className={'btn btn-primary'}
                                type={'button'}
                                disabled={pinLoading}
                                onClick={() => handlePinProject()}
                              >
                                {pinLoading ? <Loader /> : t('common.pin')}
                              </button>
                            ))}
                          {showPreviewButton() && (
                            <button
                              className={'btn btn-primary btn-success'}
                              type={'button'}
                              disabled={previewLoading}
                              onClick={handlePreviewProject}
                            >
                              {previewLoading ? <Loader /> : t('common.preview')}
                            </button>
                          )}
                          {project.can_edit_loan_dates && (
                            <button
                              className={'btn btn-primary btn-success'}
                              type={'button'}
                              onClick={() =>
                                showModal(
                                  <UpdateLoanStartDateModal
                                    projectId={project.id}
                                    loanStartDate={project?.loan_start_date ?? null}
                                    setProject={setProject}
                                  />,
                                )
                              }
                            >
                              {t('projects.modals.edit_start_date.title')}
                            </button>
                          )}
                          {showReopenButton() && (
                            <PrimaryButton
                              className={'btn btn-danger'}
                              onClick={reopenProjectForInvestmets}
                              title={t('projects.reopen')}
                              type={'button'}
                            />
                          )}
                          {showCloseReopenStageButton() && (
                            <PrimaryButton
                              className={'btn btn-danger'}
                              onClick={closeProjectReopenStage}
                              title={t('projects.reopen.close_reopen')}
                              type={'button'}
                            />
                          )}
                          {showRefinanceButton() && (
                            <button
                              className={'btn btn-primary'}
                              type={'button'}
                              onClick={() =>
                                showModal(
                                  <RefinanceProjectModal
                                    project={project}
                                    setProject={setProject}
                                  />,
                                )
                              }
                            >
                              {t('projects.modals.refinance')}
                            </button>
                          )}
                          {showUpdateRefinancingProjectButton() && (
                            <button
                              className={'btn btn-primary'}
                              type={'button'}
                              onClick={() =>
                                showModal(
                                  <UpdateRefinancingProjectModal
                                    project={project}
                                    setProject={setProject}
                                  />,
                                )
                              }
                            >
                              {t('projects.modals.update_refinancing_project')}
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xs="12">
              <Card className="mb-4">
                <CardBody>
                  <div>
                    <Row>
                      <Col>
                        <div className={'d-flex gap-2'}>
                          <PermissionAction permissions={Permissions.BO__PROJECTS__STAGE}>
                            <PrimaryButton
                              className={'btn btn-danger'}
                              onClick={stageProject}
                              title={t('projects.stage')}
                              type={'button'}
                            />
                          </PermissionAction>

                          <PermissionAction
                            permissions={Permissions.BO__PROJECTS__MARK_READY_FOR_INVESTMENTS}
                          >
                            <PrimaryButton
                              className={'btn btn-primary'}
                              onClick={() => markProject(ProjectStatus.ReadyForInvestments)}
                              title={t('projects.mark.ready_for_investments')}
                              type={'button'}
                              disabled={!canChangeStatus(project?.status).ready_for_investments}
                            />
                          </PermissionAction>

                          <PermissionAction
                            permissions={Permissions.BO__PROJECTS__MARK_COMING_SOON}
                          >
                            <PrimaryButton
                              className={'btn btn-primary'}
                              onClick={() => markProject(ProjectStatus.ComingSoon)}
                              title={t('projects.mark.coming_soon')}
                              type={'button'}
                              disabled={!canChangeStatus(project?.status).coming_soon}
                            />
                          </PermissionAction>

                          <PermissionAction
                            permissions={Permissions.BO__PROJECTS__MARK_OPEN_FOR_INVESTMENTS}
                          >
                            <PrimaryButton
                              className={'btn btn-primary'}
                              onClick={() => markProject(ProjectStatus.OpenForInvestments)}
                              title={t('projects.mark.open_for_investments')}
                              type={'button'}
                              disabled={!canChangeStatus(project?.status).open_for_investment}
                            />
                          </PermissionAction>

                          <PermissionAction permissions={Permissions.BO__PROJECTS__MARK_FUNDED}>
                            <PrimaryButton
                              className={'btn btn-primary'}
                              onClick={() => markProject(ProjectStatus.Funded)}
                              title={t('projects.mark.funded')}
                              type={'button'}
                              disabled={!canChangeStatus(project?.status).funded}
                            />
                          </PermissionAction>

                          <PermissionAction permissions={Permissions.BO__PROJECTS__MARK_NOT_FUNDED}>
                            <PrimaryButton
                              className={'btn btn-primary'}
                              onClick={() => markProject(ProjectStatus.NotFunded)}
                              title={t('projects.mark.not_funded')}
                              type={'button'}
                              disabled={!canChangeStatus(project?.status).not_funded}
                            />
                          </PermissionAction>

                          <PermissionAction permissions={Permissions.BO__PROJECTS__MARK_CONFIRMED}>
                            <PrimaryButton
                              className={'btn btn-primary'}
                              onClick={() => markProject(ProjectStatus.Confirmed)}
                              title={t('projects.mark.confirmed')}
                              type={'button'}
                              disabled={!canChangeStatus(project?.status).confirmed}
                            />
                          </PermissionAction>

                          <PermissionAction permissions={Permissions.BO__PROJECTS__MARK_FINISHED}>
                            <PrimaryButton
                              className={'btn btn-primary'}
                              onClick={() => markProject(ProjectStatus.Finished)}
                              title={t('projects.mark.finished')}
                              type={'button'}
                              disabled={!canChangeStatus(project?.status).finished}
                            />
                          </PermissionAction>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xs="12">
              <Card className="mb-4">
                <CardBody>{project && <TabData tabs={Tabs} vertical={true} />}</CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </ProjectProvider>
    </React.Fragment>
  );
};

export default Project;
