export enum UserMarketingAgreementsTypeEnum {
  NOTIFICATIONS_ALLOW_MARKETING_EDUCATIONAL = 'notifications_allow_marketing_educational',
  NOTIFICATIONS_ALLOW_MARKETING_NEWSLETTER = 'notifications_allow_marketing_newsletter',
  NOTIFICATIONS_ALLOW_MARKETING_PROMOTIONS = 'notifications_allow_marketing_promotions',
  NOTIFICATIONS_ALLOW_MARKETING_INVESTED_PROJECT_NEWS = 'notifications_allow_marketing_invested_project_news',
  NOTIFICATIONS_ALLOW_SURVEYS = 'notifications_allow_marketing_surveys',
}

export enum UserMarketingSettingTypeEnum {
  EDUCATIONAL = 'profile.notifications.allow_marketing.educational',
  NEWSLETTER = 'profile.notifications.allow_marketing.newsletter',
  PROMOTIONAL = 'profile.notifications.allow_marketing.promotional',
  INVESTED_PROJECT_NEWS = 'profile.notifications.allow_marketing.invested_project_news',
  DIRECT = 'profile.notifications.allow_marketing',
  SURVEYS = 'profile.notifications.allow_survey',
}
