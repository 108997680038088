import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Api from 'src/api';
import { Permissions } from 'src/helpers/auth/permissions';
import { success } from 'src/services/toastr';
import PermissionAction from 'src/components/PermissionAction';
import { FormikHelpers } from 'formik';
import ActivityFeedItem from 'src/components/ActivityFeedItem';
import {
  DebtCollectionContextState,
  withDebtCollection,
} from 'src/pages/DebtCollection/Update/DebtCollectionContext';
import { EventUpdateResponseDto, StoreEventUpdateRequestDto } from 'src/types/api/DebtCollection';
import Loader from 'src/components/Loader';
import SelectProjectEventUpdateInput from 'src/pages/DebtCollection/Update/Tabs/Actions/SelectProjectEventUpdateInput';

const ProjectEventUpdate: React.FC<DebtCollectionContextState> = ({ debtCollection }) => {
  const { t } = useTranslation();
  const [data, setData] = useState<Array<EventUpdateResponseDto>>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fetchData = useCallback(async () => {
    if (!debtCollection?.id) return Promise.resolve();
    setIsLoading(true);
    return Api.debtCollection.fetchEventUpdates(debtCollection.id).then((response) => {
      setData(response);
      setIsLoading(false);
    });
  }, [debtCollection]);

  const onSubmit = useCallback(
    async (
      request: StoreEventUpdateRequestDto,
      helpers: FormikHelpers<StoreEventUpdateRequestDto>,
    ) => {
      try {
        await Api.debtCollection.createEventUpdate(request).then(() => {
          fetchData();
        });
        success(t('common.updated_success'));
        helpers.resetForm();
      } catch (e: any) {
        helpers.setErrors(e.response?.errors);
      }
      return true;
    },
    [fetchData, t],
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (!debtCollection) {
    return null;
  }
  return (
    <React.Fragment>
      <PermissionAction permissions={Permissions.BO__DEBT_COLLECTION__VIEW}>
        <SelectProjectEventUpdateInput
          initial={{ stage_id: debtCollection.id, comment: '' }}
          onSubmit={onSubmit}
        />
      </PermissionAction>
      <PermissionAction permissions={Permissions.BO__DEBT_COLLECTION__VIEW}>
        {isLoading && <Loader />}
        <ul className="activity-feed mb-0 ps-2">
          {data?.map((item) => {
            return (
              <ActivityFeedItem
                key={item.id}
                data={{
                  id: item.id,
                  date: item.updated_at,
                  author: item.author_name,
                  message: t('debt_collection.project_event_update.' + item.comment),
                }}
                permissions={Permissions.BO__DEBT_COLLECTION__UPDATE_ACTIONS}
                onDelete={() =>
                  Api.debtCollection.deleteComment(item.id).then(() => {
                    success(t('common.deleted_success'));
                    fetchData();
                  })
                }
              />
            );
          })}
        </ul>
      </PermissionAction>
    </React.Fragment>
  );
};

export default withDebtCollection(ProjectEventUpdate);
