import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useField, useFormikContext } from 'formik';
import { StateManagerProps } from 'react-select/dist/declarations/src/stateManager';
import Select from 'react-select';
import { LanguageEnum } from 'src/helpers/Enums/LanguageEnum';
import { UncontrolledTooltip } from 'reactstrap';
import SanitizedHtmlBlock from 'src/components/SanitizedHtmlBlock';

export interface ReactSelectProps extends StateManagerProps {
  name: string;
  options: ReactSelectOption[] | undefined;
  hideLabel?: boolean;
  locale?: LanguageEnum;
  // This is used whenever we want to use something onChange() method without overriding it.
  onChangeAction?: (option: any) => void;
  tooltip?: string;
}

export type ReactSelectOption = {
  label: string | JSX.Element;
  value: string | boolean;
  customText?: string;
  isDisabled?: boolean;
};

const SelectInput: React.FC<ReactSelectProps> = ({ name, options, onChangeAction, ...props }) => {
  const { t } = useTranslation();
  const [field, meta, helper] = useField(name);
  const { submitCount } = useFormikContext();
  const labelRef = useRef<HTMLLabelElement>(null);

  if (!props.placeholder) {
    props.placeholder = t('label.' + name);
  }

  const isError = submitCount > 0 && !!meta.error;

  const selectValue = () => {
    if (Array.isArray(field.value)) {
      return field.value.map((value) => {
        return options?.find((option) => option.value?.toString() == value.toString()) ?? '';
      });
    }

    return options
      ? options.find((option) => option.value?.toString() === field.value?.toString())
      : '';
  };

  return (
    <>
      <div className={`${isError ? 'is-invalid' : ''}`}>
        {!props.hideLabel && (
          <label ref={labelRef} htmlFor={field.name}>
            {props.placeholder}
          </label>
        )}
        {props.tooltip && (
          <UncontrolledTooltip target={labelRef}>
            <SanitizedHtmlBlock content={props.tooltip} />
          </UncontrolledTooltip>
        )}
        <Select
          options={options}
          name={name}
          onChange={(option: any) => {
            if (Array.isArray(option)) {
              helper.setValue((option as ReactSelectOption[]).map((option) => option.value));
            } else {
              helper.setValue(option?.value);
            }

            if (onChangeAction) {
              onChangeAction(option);
            }
          }}
          onBlur={field.onBlur}
          value={selectValue()}
          isLoading={options === undefined}
          {...props}
        />
      </div>
      {isError && <div className={isError ? 'invalid-feedback' : ''}>{meta.error}</div>}
    </>
  );
};
export default SelectInput;
