// There is a known issue on Safari (up to 15.6) where it doesn't support "yyyy-MM-dd HH:mm:ss" format.
// So, we modify "yyyy-MM-dd HH:mm:ss" to "yyyy-MM-ddTHH:mm:ss"
// https://stackoverflow.com/questions/21883699/safari-javascript-date-nan-issue-yyyy-mm-dd-hhmmss

export const formatDate = (date: string) => {
  return date.replace(' ', 'T');
};

export const formatDateYyyyMm = (date: any) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  return `${year}-${month}`;
};
