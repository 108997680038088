import React, { useCallback, useMemo, useState } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { Cell } from 'react-table';
import {
  DebtCollectionDocumentResponseDto,
  DocumentResponseDto,
  MediaResponseDto,
} from 'src/types/api/common';
import IsOutdatedBadge from 'src/components/Badges/IsOutdatedBadge';
import IsVisibleBadge from 'src/components/Badges/IsVisibleBadge';
import { locales } from 'src/helpers';
import IsVisibleLocaleBadge from 'src/pages/Project/Update/Tabs/Badges/IsVisibleLocaleBadge';
import { useTranslation } from 'react-i18next';
import Api from 'src/api';
import { PaginationData, PaginationDataFilter } from 'src/types';
import Table, { Column } from 'src/components/Table';
import DownloadButton from 'src/components/DownloadButton';

const DebtCollectionDocuments: React.FC = () => {
  const { t } = useTranslation();
  const [documents, setDocuments] = useState<PaginationData<MediaResponseDto>>();

  const columns = useMemo<Column<DocumentResponseDto>[] | any>(
    () => [
      {
        Header: t('table.id'),
        accessor: 'id',
      },
      {
        Header: t('table.loan_agreement_number'),
        accessor: 'loan_agreement_number',
      },
      {
        Header: t('table.file_name'),
        accessor: 'name',
      },
      {
        Header: t('table.mime_type'),
        accessor: 'mime_type',
      },
      {
        Header: t('table.size'),
        accessor: 'size',
      },
      {
        Header: t('table.information'),
        Cell: (cell: Cell<DocumentResponseDto>) => (
          <div className={'d-flex justify-content-center gap-2 text-center'}>
            <IsOutdatedBadge show={!!cell.row.original.is_outdated} />
            <IsVisibleBadge show={!!cell.row.original.is_visible} />
            {locales.map((locale, key) => (
              <IsVisibleLocaleBadge
                className={'w-100'}
                key={key}
                locale={locale}
                show={!!cell.row.original.visible_locales?.find((s) => s === locale)}
              />
            ))}
          </div>
        ),
      },
      {
        Header: t('table.type'),
        accessor: 'type',
      },
      {
        Header: t('table.sent_at'),
        accessor: 'sent_at',
        Cell: (cell: Cell<DebtCollectionDocumentResponseDto>) => (
          <div>{t('common.date_full', { date: cell.value })}</div>
        ),
      },
      {
        Header: t('table.created_at'),
        accessor: 'created_at',
        Cell: (cell: Cell<DocumentResponseDto>) => (
          <div>{t('common.date_full', { date: cell.value })}</div>
        ),
      },
      {
        Header: t('table.action'),
        accessor: 'url',
        Cell: (cell: Cell<DocumentResponseDto>) => {
          return (
            <div className={'d-flex gap-2'}>
              <DownloadButton url={cell.value} />
            </div>
          );
        },
      },
    ],
    [t],
  );

  const fetchData = useCallback(async (request: PaginationDataFilter | undefined) => {
    const data = await Api.debtCollection.fetchDocuments(request);
    setDocuments(data);
  }, []);

  return (
    <React.Fragment>
      <Container fluid>
        <Col xs="12" className={'mb-4'}>
          <Card className="card h-100">
            <CardBody>
              <Row>
                <Col>
                  <div>
                    <Table
                      columns={columns}
                      data={documents}
                      onFetchData={fetchData}
                      title={'DebtCollectionDocuments'}
                    />
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Container>
    </React.Fragment>
  );
};

export default DebtCollectionDocuments;
