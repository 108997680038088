import { StateManagerProps } from 'react-select/dist/declarations/src/useStateManager';
import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import SelectInput, { ReactSelectOption } from 'src/components/Form/Select/SelectInput';
import { MarketingContactType } from 'src/helpers/Enums/MarketingContactType';
import { MarketingReportFilterEnum } from 'src/helpers/Enums/MarketingReportFilterEnum';
import { UserMarketingSettingTypeEnum } from 'src/helpers/Enums/UserMarketingAgreementsTypeEnum';

interface Props extends StateManagerProps {
  name: string;
  placeholder?: string;
  marketing_type?: string | null;
}

const SelectDirectMarketingInput: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const isDisabled = useCallback(
    (type: MarketingContactType) => {
      if (!props.marketing_type) {
        return false;
      }

      if (
        [
          UserMarketingSettingTypeEnum.EDUCATIONAL,
          UserMarketingSettingTypeEnum.NEWSLETTER,
          UserMarketingSettingTypeEnum.INVESTED_PROJECT_NEWS,
        ].includes(props.marketing_type as UserMarketingSettingTypeEnum) &&
        ![MarketingContactType.EMAIL].includes(type)
      ) {
        return true;
      }

      if (
        [UserMarketingSettingTypeEnum.SURVEYS, UserMarketingSettingTypeEnum.PROMOTIONAL].includes(
          props.marketing_type as UserMarketingSettingTypeEnum,
        ) &&
        ![MarketingContactType.PHONE, MarketingContactType.EMAIL].includes(type)
      ) {
        return true;
      }

      if (
        [UserMarketingSettingTypeEnum.DIRECT].includes(
          props.marketing_type as UserMarketingSettingTypeEnum,
        ) &&
        ![MarketingContactType.SMS].includes(type)
      ) {
        return true;
      }

      return false;
    },
    [props.marketing_type],
  );

  const [options, setOptions] = useState<ReactSelectOption[]>([
    {
      value: '',
      label: t('common.all'),
    },
    {
      value: MarketingContactType.PHONE,
      label: t('reports.filter.phone'),
      isDisabled: isDisabled(MarketingContactType.PHONE),
    },
    {
      value: MarketingContactType.EMAIL,
      label: t('reports.filter.email'),
      isDisabled: isDisabled(MarketingContactType.EMAIL),
    },
    {
      value: MarketingContactType.SMS,
      label: t('reports.filter.sms'),
      isDisabled: isDisabled(MarketingContactType.SMS),
    },
    {
      value: MarketingReportFilterEnum.NONE,
      label: t('reports.filter.none'),
    },
  ]);

  useEffect(() => {
    setOptions((prev) => {
      return prev.map((option) => {
        if (option.value === '') return option;
        if (option.value === MarketingReportFilterEnum.NONE) return option;

        return {
          ...option,
          isDisabled: isDisabled(option.value as MarketingContactType),
        };
      });
    });
  }, [props.marketing_type, isDisabled]);

  return <SelectInput {...props} options={options} />;
};

export default SelectDirectMarketingInput;
