import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, FormikHelpers } from 'formik';
import { Col, ColProps, Form, FormGroup, Row } from 'reactstrap';
import TextInput from 'src/components/Form/TextInput';
import Api from 'src/api';
import { success } from 'src/services/toastr';
import { transformErrors } from 'src/helpers';
import * as Yup from 'yup';
import SaveButton from 'src/components/Form/SaveButton';
import {
  ProspectApplicationContextState,
  withProspectApplication,
} from '../ProspectApplicationContext';
import { UpdateProspectUserRequestDto } from 'src/types/api/prospectApplications';
import SimpleTable from 'src/components/SimpleTable';
import { Column, Cell } from 'react-table';
import IsOutdatedBadge from 'src/components/Badges/IsOutdatedBadge';
import DownloadButton from 'src/components/DownloadButton';
import { AttachFilesRequestDto, DocumentResponseDto } from 'src/types/api/common';

const INITIAL_REQUEST: UpdateProspectUserRequestDto = {
  name: null,
  email: null,
  phone: null,
};

const TabUser: React.FC<ProspectApplicationContextState> = ({
  prospectApplication,
  setProspectApplication,
  isEditable,
}) => {
  const { t } = useTranslation();
  const [request, setRequest] = useState<UpdateProspectUserRequestDto>(INITIAL_REQUEST);

  const documentColumns = useMemo<Column<AttachFilesRequestDto>[] | any>(
    () => [
      {
        Header: t('table.id'),
        accessor: 'id',
      },
      {
        Header: t('table.file_name'),
        accessor: 'name',
      },
      {
        Header: t('table.mime_type'),
        accessor: 'mime_type',
      },
      {
        Header: t('table.size'),
        accessor: 'size',
      },
      {
        Header: t('table.information'),
        width: 100,
        Cell: (cell: Cell<DocumentResponseDto>) => (
          <div className={'d-flex justify-content-center gap-2 text-center'}>
            <IsOutdatedBadge show={!!cell.row.original.is_outdated} />
          </div>
        ),
      },
      {
        Header: t('table.uploader'),
        accessor: 'uploader',
      },
      {
        Header: t('table.created_at'),
        accessor: 'created_at',
        Cell: (cell: Cell<AttachFilesRequestDto>) => (
          <div>{t('common.date_full', { date: cell.value })}</div>
        ),
      },
      {
        Header: t('table.action'),
        accessor: 'url',
        Cell: (cell: Cell<DocumentResponseDto>) => {
          if (!prospectApplication) return null;
          return (
            <div className={'d-flex gap-2'}>
              <DownloadButton url={cell.value} />
            </div>
          );
        },
      },
    ],
    [prospectApplication, t],
  );

  useEffect(() => {
    if (!prospectApplication || !prospectApplication?.prospect_user_company) return;

    setRequest(() => ({
      name: prospectApplication.prospect_user?.name ?? null,
      email: prospectApplication.prospect_user?.email ?? null,
      phone: prospectApplication.prospect_user?.phone ?? null,
    }));
  }, [prospectApplication]);

  const onSubmit = useCallback(
    async (
      request: UpdateProspectUserRequestDto,
      helpers: FormikHelpers<UpdateProspectUserRequestDto>,
    ) => {
      if (!prospectApplication || !prospectApplication.prospect_user) return;

      try {
        const response = await Api.ProspectApplications.updateProspectApplicationUser(
          prospectApplication.id,
          prospectApplication.prospect_user.id,
          request,
        );

        setProspectApplication(response);
        success(t('common.updated_success'));
      } catch (e: any) {
        helpers.setErrors(transformErrors(e.response?.errors));
      }
    },
    [prospectApplication, t, setProspectApplication],
  );

  const colPropsTwoPerRow: ColProps = {
    md: 6,
    className: 'mb-4',
  };

  const UserSchema = Yup.object().shape({
    name: Yup.string().required(),
    email: Yup.string().email().required(),
  });

  return (
    <React.Fragment>
      <h3 className={'flex'}>{t('prospect_applications.tabs.user')}</h3>
      <hr />

      <Formik
        initialValues={request}
        enableReinitialize={true}
        onSubmit={onSubmit}
        validationSchema={UserSchema}
      >
        {({ handleSubmit, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Row>
                <Col {...colPropsTwoPerRow}>
                  <TextInput type={'name'} name={'name'} disabled={!isEditable} />
                </Col>
                <Col {...colPropsTwoPerRow}>
                  <TextInput type={'email'} name={'email'} disabled={!isEditable} />
                </Col>
                <Col {...colPropsTwoPerRow}>
                  <TextInput type={'phone'} name={'phone'} disabled={!isEditable} />
                </Col>
              </Row>
              <Row>
                <div className="mt-4 mb-4">
                  <SaveButton
                    title={t('common.save')}
                    submitting={isSubmitting}
                    disabled={!isEditable}
                  />
                </div>
              </Row>
            </FormGroup>
          </Form>
        )}
      </Formik>
      <hr />
      <h3>{t('applications.documents.list')}</h3>
      <SimpleTable
        columns={documentColumns}
        data={prospectApplication?.prospect_user?.documents ?? []}
      />
    </React.Fragment>
  );
};

export default withProspectApplication(TabUser);
