import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import ModalCloseButton from 'src/components/Modal/ModalCloseButton';
import { ModalComponent } from 'src/components/Modal/ModalComponent';
import SaveButton from 'src/components/Form/SaveButton';
import DenyButton from 'src/components/Form/DenyButton';
import { BorrowerPayoutResponseDto } from 'src/types/api/payments/borrowerPayouts';
import DatePickerInput from 'src/components/Form/DatePickerInput';
import usePermissions from 'src/helpers/usePermissions';
import { Permissions } from 'src/helpers/auth/permissions';
import CheckboxInput from 'src/components/Form/CheckboxInput';

interface Props {
  borrowerPayout: BorrowerPayoutResponseDto;
  onAction: (request: ConfirmRecalculateBorrowerPayoutRequestDto) => Promise<any>;
  onException?: () => void;
}

export interface ConfirmRecalculateBorrowerPayoutRequestDto {
  received_at: Date | null;
  skip_recalculation: boolean | null;
  skip_borrower_payout_recalculation_only: boolean | null;
}

const RecalculateBorrowerPayoutModal: React.FC<Props> = ({
  borrowerPayout,
  onAction,
  onException,
}) => {
  const { t } = useTranslation();
  const { hideModal } = useGlobalModalContext();
  const { can } = usePermissions();

  const [attachFilesRequest] = useState<ConfirmRecalculateBorrowerPayoutRequestDto>({
    received_at: borrowerPayout.received_at,
    skip_recalculation: false,
    skip_borrower_payout_recalculation_only: false,
  });

  const Schema = Yup.object().shape({
    received_at: Yup.date().required(),
    skip_recalculation: Yup.boolean().nullable(),
    skip_borrower_payout_recalculation_only: Yup.boolean().nullable(),
  });

  const onSubmit = useCallback(
    async (request: ConfirmRecalculateBorrowerPayoutRequestDto) => {
      if (borrowerPayout) {
        return onAction(request).catch(() => {
          onException && onException();
        });
      }
    },
    [borrowerPayout, onAction, onException],
  );

  return (
    <React.Fragment>
      <ModalComponent size={'md'}>
        <div>
          <ModalHeader toggle={hideModal} close={<ModalCloseButton />}>
            {t('payments.borrower_payouts.confirm.recalculate.title')}
          </ModalHeader>
          <Formik initialValues={attachFilesRequest} onSubmit={onSubmit} validationSchema={Schema}>
            {({ handleSubmit, isSubmitting }) => (
              <Form onSubmit={handleSubmit}>
                <ModalBody>
                  <p>{t('payments.borrower_payouts.confirm.recalculate.body')}</p>
                  <DatePickerInput
                    name={'received_at'}
                    showTimeSelect
                    disabled={!can(Permissions.BO__PAYMENTS__BORROWER_PAYOUTS__MODIFY_RECEIVED_AT)}
                  />
                  <div className={'mt-2'}>
                    <CheckboxInput
                      name={'skip_recalculation'}
                      tooltip={t('payments.borrower_payouts.skip_recalculation_tooltip')}
                    />
                  </div>
                  <div className={'mt-2'}>
                    <CheckboxInput
                      name={'skip_borrower_payout_recalculation_only'}
                      tooltip={t(
                        'payments.borrower_payouts.skip_borrower_payout_recalculation_only_tooltip',
                      )}
                    />
                  </div>
                </ModalBody>
                <ModalFooter className={'d-flex justify-content-center'}>
                  <Col>
                    <DenyButton title={t('common.cancel')} onClick={hideModal} />
                  </Col>
                  <Col>
                    <SaveButton
                      title={t('payments.borrower_payouts.recalculate')}
                      submitting={isSubmitting}
                    />
                  </Col>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </div>
      </ModalComponent>
    </React.Fragment>
  );
};

export default RecalculateBorrowerPayoutModal;
