import React, { useCallback, useState } from 'react';
import { FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { FormGroup, ModalBody, ModalHeader } from 'reactstrap';

import { ModalComponent } from 'src/components/Modal/ModalComponent';
import ModalCloseButton from 'src/components/Modal/ModalCloseButton';
import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import DatePickerInput from 'src/components/Form/DatePickerInput';
import { success } from 'src/services/toastr';
import SaveButton from 'src/components/Form/SaveButton';
import LocaleFormik from 'src/components/Form/LocaleFormik/LocaleFormik';
import { transformErrors } from 'src/helpers';
import SelectYesNo from 'src/components/Form/Select/SelectYesNoInput';
import Api from 'src/api';
import { ProjectResponseDto, UpdateProjectLoanStartDatesRequestDto } from 'src/types/api/projects';

interface Props {
  projectId: string;
  loanStartDate?: string | null | undefined | Date;
  setProject: (project: ProjectResponseDto) => void;
}

const INITIAL_VALUES: UpdateProjectLoanStartDatesRequestDto = {
  regenerate_developer_payment_schedule: false,
  loan_start_date: null,
};

const UpdateLoanStartDateModal: React.FC<Props> = ({
  projectId,
  setProject,
  loanStartDate = null,
}) => {
  const { hideModal } = useGlobalModalContext();
  const [request] = useState<UpdateProjectLoanStartDatesRequestDto>({
    ...INITIAL_VALUES,
    loan_start_date: loanStartDate,
  });
  const { t } = useTranslation();

  const onSubmit = useCallback(
    async (request, helpers: FormikHelpers<UpdateProjectLoanStartDatesRequestDto>) => {
      try {
        const response = await Api.projects.updateProjectLoanStartDate(projectId, request);
        hideModal();
        success(t('common.updated_success'));
        setProject(response);
      } catch (e: any) {
        helpers.setErrors(transformErrors(e.response?.errors));
      }
    },
    [projectId, hideModal, t, setProject],
  );

  return (
    <ModalComponent size={'lg'}>
      <ModalHeader toggle={hideModal} close={<ModalCloseButton />}>
        {t('projects.modals.edit_start_date.title')}
      </ModalHeader>
      <ModalBody>
        <LocaleFormik initialValues={request} enableReinitialize={true} onSubmit={onSubmit}>
          {({ isSubmitting }) => (
            <>
              <FormGroup>
                <DatePickerInput name={'loan_start_date'} />
              </FormGroup>
              <FormGroup>
                <SelectYesNo
                  name={'regenerate_developer_payment_schedule'}
                  placeholder={t(
                    'projects.modals.edit_start_date.regenerate_developer_payment_schedule',
                  )}
                  isClearable={false}
                />
              </FormGroup>
              <FormGroup>
                <SaveButton title={t('common.save')} submitting={isSubmitting} />
              </FormGroup>
            </>
          )}
        </LocaleFormik>
      </ModalBody>
    </ModalComponent>
  );
};

export default UpdateLoanStartDateModal;
