import {
  ProspectApplicationResponseDto,
  ProspectApplicationRiskResponseDto,
  ProspectApplicationsListResponseDto,
  UpdateProspectApplicationChangeStatusRequestDto,
  UpdateProspectApplicationDetailsRequestDto,
  UpdateProspectApplicationRequestDto,
  UpdateProspectApplicationSecurityDepositsRequestDto,
  UpdateProspectUserCompanyRequestDto,
  UpdateProspectUserRequestDto,
} from 'src/types/api/prospectApplications';
import Client from './client';
import { PaginationData, PaginationDataFilter } from 'src/types';
import { AssignCompanyManagerRequestDto } from 'src/types/api/userCompanies';

class ProspectApplications extends Client {
  api = {
    FETCH_FILTERED_PROSPECT_APPLICATIONS: `${this.http.baseUrl}/prospect-applications`,
    FETCH_PROSPECT_APPLICATION: `${this.http.baseUrl}/prospect-applications/{prospectApplicationId}`,
    FETCH_PROSPECT_APPLICATION_RISKS: `${this.http.baseUrl}/prospect-applications/{prospectApplicationId}/risks`,

    UPDATE_PROSPECT_APPLICATION: `${this.http.baseUrl}/prospect-applications/{prospectApplicationId}`,
    UPDATE_PROSPECT_APPLICATION_DETAILS: `${this.http.baseUrl}/prospect-applications/{prospectApplicationId}/details`,
    UPDATE_PROSPECT_APPLICATION_SECURITY_DEPOSITS: `${this.http.baseUrl}/prospect-applications/{prospectApplicationId}/security-deposits`,
    UPDATE_PROSPECT_APPLICATION_COMPANY_DETAIILS: `${this.http.baseUrl}/prospect-applications/{prospectApplicationId}/company/{prospectApplicationUserCompanyId}`,
    UPDATE_PROSPECT_APPLICATION_USER: `${this.http.baseUrl}/prospect-applications/{prospectApplicationId}/user/{prospectApplicationUserId}`,
    UPDATE_PROSPECT_APPLICATION_CHANGE_STATUS: `${this.http.baseUrl}/prospect-applications/{prospectApplicationId}/change-status`,
    CONVERT_PROSPECT_APPLICATION: `${this.http.baseUrl}/prospect-applications/{prospectApplicationId}/convert`,
    ASSIGN_MANAGER: `${this.http.baseUrl}/prospect-applications/{prospectApplicationId}/manager`,
  };

  fetchFilteredProspectApplications = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<ProspectApplicationsListResponseDto>> => {
    return this.http.get(this.api.FETCH_FILTERED_PROSPECT_APPLICATIONS, { params: request });
  };

  fetchProspectApplication = (
    prospectApplicationId: string | number,
  ): Promise<ProspectApplicationResponseDto> => {
    const url = this.buildUrl(this.api.FETCH_PROSPECT_APPLICATION, { prospectApplicationId });

    return this.http.get(url);
  };

  fetchProspectApplicationRisks = (
    prospectApplicationId: string | number,
  ): Promise<ProspectApplicationRiskResponseDto[]> => {
    const url = this.buildUrl(this.api.FETCH_PROSPECT_APPLICATION_RISKS, { prospectApplicationId });

    return this.http.get(url);
  };

  updateProspectApplication = (
    prospectApplicationId: string | number,
    data: UpdateProspectApplicationRequestDto,
  ): Promise<ProspectApplicationResponseDto> => {
    const url = this.buildUrl(this.api.UPDATE_PROSPECT_APPLICATION, { prospectApplicationId });

    return this.http.post(url, data);
  };

  updateProspectApplicationDetails = (
    prospectApplicationId: string | number,
    data: UpdateProspectApplicationDetailsRequestDto,
  ): Promise<ProspectApplicationResponseDto> => {
    const url = this.buildUrl(this.api.UPDATE_PROSPECT_APPLICATION_DETAILS, {
      prospectApplicationId,
    });

    return this.http.post(url, data);
  };

  updateProspectApplicationCompanyDetails = (
    prospectApplicationId: string | number,
    prospectApplicationUserCompanyId: string | number,
    data: UpdateProspectUserCompanyRequestDto,
  ): Promise<ProspectApplicationResponseDto> => {
    const url = this.buildUrl(this.api.UPDATE_PROSPECT_APPLICATION_COMPANY_DETAIILS, {
      prospectApplicationId,
      prospectApplicationUserCompanyId,
    });

    return this.http.post(url, data);
  };

  updateProspectApplicationSecurityDeposit = (
    prospectApplicationId: string | number,
    data: UpdateProspectApplicationSecurityDepositsRequestDto,
  ): Promise<ProspectApplicationResponseDto> => {
    const url = this.buildUrl(this.api.UPDATE_PROSPECT_APPLICATION_SECURITY_DEPOSITS, {
      prospectApplicationId,
    });

    return this.http.post(url, data);
  };

  updateProspectApplicationUser = (
    prospectApplicationId: string | number,
    prospectApplicationUserId: string | number,
    data: UpdateProspectUserRequestDto,
  ): Promise<ProspectApplicationResponseDto> => {
    const url = this.buildUrl(this.api.UPDATE_PROSPECT_APPLICATION_USER, {
      prospectApplicationId,
      prospectApplicationUserId,
    });

    return this.http.post(url, data);
  };

  convertProspectApplication = (prospectApplicationId: string | number): Promise<void> => {
    const url = this.buildUrl(this.api.CONVERT_PROSPECT_APPLICATION, { prospectApplicationId });

    return this.http.post(url);
  };

  assignManager = (
    prospectApplicationId: number | string,
    request: AssignCompanyManagerRequestDto,
  ): Promise<ProspectApplicationResponseDto> => {
    const url = this.buildUrl(this.api.ASSIGN_MANAGER, { prospectApplicationId });

    return this.http.put(url, request);
  };

  updateProspectApplicationChangeStatus = (
    prospectApplicationId: string | number,
    data: UpdateProspectApplicationChangeStatusRequestDto,
  ): Promise<ProspectApplicationResponseDto> => {
    const url = this.buildUrl(this.api.UPDATE_PROSPECT_APPLICATION_CHANGE_STATUS, {
      prospectApplicationId,
    });

    return this.http.post(url, data);
  };
}

export default ProspectApplications;
