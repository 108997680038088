import React, { useCallback, useEffect, useState } from 'react';
import { Card, CardBody, Container, Form, FormGroup } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { setGlobalLoading } from 'src/modules/app/actions';
import TextInput from 'src/components/Form/TextInput';
import { SelectAsyncInput, SelectInput } from 'src/components/Form/Select';
import { NotificationType } from 'src/helpers/Enums/NotificationType';
import WYSIWYGInput from 'src/components/Form/WYSIWYGInput';
import PrimaryButton from 'src/components/Form/PrimaryButton';
import { success } from 'src/services/toastr';
import Api from 'src/api';
import { NotifyUserGroupsRequestDto } from 'src/types/api/communication';
import CheckboxInput from 'src/components/Form/CheckboxInput';
import SelectNotifiableGroupInput from 'src/components/Form/Select/SelectNotifiableGroupInput';
import { ReactSelectOption } from 'src/components/Form/Select/SelectInput';
import { NotifiableGroupEnum } from 'src/helpers/Enums/NotifiableGroupEnum';
import { PaginationDataFilter } from 'src/types';
import { formatArray } from 'src/helpers/formatters/common';
import { UserMarketingAgreementsTypeEnum } from 'src/helpers/Enums/UserMarketingAgreementsTypeEnum';

const INITIAL_STATE: NotifyUserGroupsRequestDto = {
  notification: {
    message_types: [],
    subject: '',
    message: '',
  },
  notify_without_marketing_agreements: false,
  [UserMarketingAgreementsTypeEnum.NOTIFICATIONS_ALLOW_MARKETING_EDUCATIONAL]: false,
  [UserMarketingAgreementsTypeEnum.NOTIFICATIONS_ALLOW_MARKETING_NEWSLETTER]: false,
  [UserMarketingAgreementsTypeEnum.NOTIFICATIONS_ALLOW_MARKETING_PROMOTIONS]: false,
  [UserMarketingAgreementsTypeEnum.NOTIFICATIONS_ALLOW_MARKETING_INVESTED_PROJECT_NEWS]: false,
  [UserMarketingAgreementsTypeEnum.NOTIFICATIONS_ALLOW_SURVEYS]: false,
  notifiable_groups: [],
  project_ids: [],
};

const CommunicationManagement: React.FC = () => {
  const { t } = useTranslation();

  useEffect(() => {
    setGlobalLoading(false);
  }, []);

  const [request] = useState<NotifyUserGroupsRequestDto>(INITIAL_STATE);

  const fetchProjects = async (inputValue?: string, loadWith?: Array<string>) => {
    const request: PaginationDataFilter = {
      page: 1,
      limit: 100,
      sort: [],
      search: inputValue,
      with: loadWith,
    };

    const response = await Api.communication.fetchProjectsBriefList(request);
    const projects: ReactSelectOption[] = [];

    response.data.map((project) => {
      projects.push({
        value: project.id,
        label: formatArray([project.private_id, project.project_name]),
      });
    });

    return projects;
  };

  const UserCommentSchema = Yup.object().shape({
    notification: Yup.object().shape({
      message_types: Yup.array().required().min(1),
      subject: Yup.string().required(),
      message: Yup.string().required(),
    }),
    notify_without_marketing_agreements: Yup.boolean().optional(),
    [UserMarketingAgreementsTypeEnum.NOTIFICATIONS_ALLOW_MARKETING_EDUCATIONAL]:
      Yup.boolean().optional(),
    [UserMarketingAgreementsTypeEnum.NOTIFICATIONS_ALLOW_MARKETING_NEWSLETTER]:
      Yup.boolean().optional(),
    [UserMarketingAgreementsTypeEnum.NOTIFICATIONS_ALLOW_MARKETING_PROMOTIONS]:
      Yup.boolean().optional(),
    [UserMarketingAgreementsTypeEnum.NOTIFICATIONS_ALLOW_MARKETING_INVESTED_PROJECT_NEWS]:
      Yup.boolean().optional(),
    [UserMarketingAgreementsTypeEnum.NOTIFICATIONS_ALLOW_SURVEYS]: Yup.boolean().optional(),
    notifiable_groups: Yup.array().required().min(1),
    project_ids: Yup.array().when('notifiable_groups', {
      is: (val: Array<string>) => val.includes(NotifiableGroupEnum.PROJECT_INVESTORS),
      then: (schema) => schema.required().min(1),
      otherwise: (schema) => schema.optional(),
    }),
  });

  const onSubmit = useCallback(
    (request: NotifyUserGroupsRequestDto, helper: any) => {
      return Api.communication
        .notifyUserGroups(request)
        .then(() => {
          success(t('communication.messages_successfully_sent'));
        })
        .catch((e) => {
          helper.setErrors(e.response?.errors);
        });
    },
    [t],
  );

  return (
    <React.Fragment>
      <Container fluid>
        <Card>
          <CardBody>
            <div className="mb-4">
              <h4 className={'mb-4'}>{t('menu.management')}</h4>
            </div>

            <Formik
              initialValues={request}
              enableReinitialize={true}
              validationSchema={UserCommentSchema}
              onSubmit={onSubmit}
            >
              {({ handleSubmit, isSubmitting, values, setFieldValue }) => (
                <Form onSubmit={handleSubmit}>
                  <FormGroup>
                    <div className="mb-3">
                      <SelectNotifiableGroupInput name={'notifiable_groups'} isMulti={true} />
                    </div>
                    <div
                      className="mb-3"
                      hidden={
                        !values.notifiable_groups.includes(NotifiableGroupEnum.PROJECT_INVESTORS)
                      }
                    >
                      <SelectAsyncInput
                        placeholder={t('label.project_ids')}
                        name={'project_ids'}
                        isClearable={true}
                        isMulti={true}
                        loadOptions={fetchProjects}
                      />
                    </div>
                    <div className="mb-3">
                      <CheckboxInput
                        name="notify_without_marketing_agreements"
                        onClick={() => {
                          setFieldValue(
                            'notify_without_marketing_agreements',
                            !values.notify_without_marketing_agreements,
                          );
                          setFieldValue(
                            UserMarketingAgreementsTypeEnum.NOTIFICATIONS_ALLOW_MARKETING_EDUCATIONAL,
                            false,
                          );
                          setFieldValue(
                            UserMarketingAgreementsTypeEnum.NOTIFICATIONS_ALLOW_MARKETING_NEWSLETTER,
                            false,
                          );
                          setFieldValue(
                            UserMarketingAgreementsTypeEnum.NOTIFICATIONS_ALLOW_MARKETING_PROMOTIONS,
                            false,
                          );
                          setFieldValue(
                            UserMarketingAgreementsTypeEnum.NOTIFICATIONS_ALLOW_MARKETING_INVESTED_PROJECT_NEWS,
                            false,
                          );
                          setFieldValue(
                            UserMarketingAgreementsTypeEnum.NOTIFICATIONS_ALLOW_SURVEYS,
                            false,
                          );
                        }}
                      />
                      <hr />
                      <CheckboxInput
                        name={
                          UserMarketingAgreementsTypeEnum.NOTIFICATIONS_ALLOW_MARKETING_EDUCATIONAL
                        }
                        disabled={values.notify_without_marketing_agreements}
                      />
                      <CheckboxInput
                        name={
                          UserMarketingAgreementsTypeEnum.NOTIFICATIONS_ALLOW_MARKETING_NEWSLETTER
                        }
                        disabled={values.notify_without_marketing_agreements}
                      />
                      <CheckboxInput
                        name={
                          UserMarketingAgreementsTypeEnum.NOTIFICATIONS_ALLOW_MARKETING_PROMOTIONS
                        }
                        disabled={values.notify_without_marketing_agreements}
                      />
                      <CheckboxInput
                        name={
                          UserMarketingAgreementsTypeEnum.NOTIFICATIONS_ALLOW_MARKETING_INVESTED_PROJECT_NEWS
                        }
                        disabled={values.notify_without_marketing_agreements}
                      />
                      <CheckboxInput
                        name={UserMarketingAgreementsTypeEnum.NOTIFICATIONS_ALLOW_SURVEYS}
                        disabled={values.notify_without_marketing_agreements}
                      />
                      <hr />
                    </div>
                    <div className="mb-3">
                      <SelectInput
                        name={'notification.message_types'}
                        placeholder={t('label.message_type')}
                        isMulti={true}
                        options={[
                          { label: t('label.email'), value: NotificationType.EMAIL },
                          { label: t('label.notification'), value: NotificationType.NOTIFICATION },
                        ]}
                      />
                    </div>
                    <div className={'mb-3'}>
                      <TextInput name={'notification.subject'} placeholder={t('label.subject')} />
                    </div>
                    <div className={'mb-3'}>
                      <WYSIWYGInput
                        name={'notification.message'}
                        placeholder={t('label.message')}
                      />
                    </div>
                    <div className={'mt-4 mb-4'}>
                      <PrimaryButton title={t('common.submit')} submitting={isSubmitting} />
                    </div>
                  </FormGroup>
                </Form>
              )}
            </Formik>
          </CardBody>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default CommunicationManagement;
