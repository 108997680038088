import create from 'zustand';
import * as H from 'history';
import { Permissions } from 'src/helpers/auth/permissions';

interface AppState {
  globalLoading: boolean;
  setGlobalLoading: (globalLoading: boolean) => void;
  sidebarOpen: boolean;
  toggleSidebar: () => void;

  permissions: string[];
  setPermissions: (permissions: string[]) => void;
  resetPermissions: () => void;

  isAppStarted: boolean;
  setAppStarted: (isAppLoading: boolean) => void;
  initialRoute: H.Location | null;
  setInitialRoute: (route: H.Location) => void;
  resetInitialRoute: () => void;
}

const useAppState = create<AppState>()((set) => ({
  globalLoading: false,
  setGlobalLoading: (globalLoading) => set({ globalLoading }),
  sidebarOpen: true,
  toggleSidebar: () =>
    set((state) => {
      return { sidebarOpen: !state.sidebarOpen };
    }),

  permissions: [Permissions.BO__GUEST],
  setPermissions: (permissions) => set(() => ({ permissions })),
  resetPermissions: () => set(() => ({ permissions: [Permissions.BO__GUEST] })),

  isAppStarted: false,
  setAppStarted: (isAppLoading) => set({ isAppStarted: isAppLoading }),
  initialRoute: null,
  setInitialRoute: (route) => set({ initialRoute: route }),
  resetInitialRoute: () => set({ initialRoute: null }),
}));

export default useAppState;
