import React, { useCallback, useState } from 'react';
import { Col, Input, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Form, Formik, FormikHelpers } from 'formik';
import Api from 'src/api';
import FileUpload, {
  DOCUMENT_TEMPLATE_DEVELOPER_INVESTOR_AGREEMENT,
  FileUploadHandle,
} from 'src/components/Form/FileUpload/FileUpload';
import { ProjectContextState, withProject } from 'src/pages/Project/Update/ProjectContext';
import { AttachFilesRequestDto } from 'src/types/api/common';
import { FormikProps } from 'formik/dist/types';
import DataBlock from 'src/components/DataBlocks/DataBlock';
import DownloadButton from 'src/components/DownloadButton';
import StreamFileButton from 'src/components/Table/Buttons/StreamFileButton';
import { success } from 'src/services/toastr';

type Props = ProjectContextState;

const ProjectDeveloperLoanAgreementSection: React.FC<Props> = ({ project, setProject }) => {
  const { t } = useTranslation();
  const fileUploadRef = React.useRef<FileUploadHandle>(null);
  const formRef = React.useRef<FormikProps<any>>(null);
  const [initialFormValues] = useState<AttachFilesRequestDto>({
    media_ids: [],
  });
  const [isSent, setIsSent] = useState<boolean>(
    project?.has_sent_developer_loan_agreement_draft ?? false,
  );

  const onSubmit = useCallback(
    async (request: AttachFilesRequestDto, helpers: FormikHelpers<any>) => {
      if (!project) return;

      try {
        // this method returns true, if new files added, which indicates to resubmit form
        if (await fileUploadRef?.current?.upload()) {
          await helpers.submitForm();
          return;
        }

        const response = await Api.projects.attachProjectDeveloperLoanAgreementDocument(
          project.id,
          request,
        );
        setProject(response);
        fileUploadRef?.current?.reset();
      } catch (e: any) {
        helpers.setErrors(e.response?.errors);
      }
    },
    [project, setProject],
  );

  const handleIsSent = useCallback(
    async (value: boolean) => {
      if (!project) return;
      setIsSent(value);
      await Api.projects
        .markProjectDeveloperLoanAgreementSent(project.id, {
          has_sent_developer_loan_agreement_draft: value,
        })
        .then((response) => {
          setProject(response);
          success(t('common.updated_success'));
        });
    },
    [t, project, setProject],
  );

  if (!project) {
    return <></>;
  }

  return (
    <>
      <div className={'d-flex justify-content-between mt-5'}>
        <div>
          <h3>{t('projects.documents.types.project_developer_loan_agreement')}</h3>
        </div>
        <div>
          <StreamFileButton
            buttonTitle={t('common.preview')}
            fileName={'ProjectDeveloperLoanAgreement'}
            extension={'docx'}
            request={() => Api.projects.previewProjectDeveloperLoanAgreement(project.id)}
          />
        </div>
      </div>
      <hr />
      {project.developer_loan_agreement_draft && (
        <>
          <div className={'mb-5'}>
            <h4 className={'mb-4'}>
              {t('projects.documents.types.project_developer_loan_agreement_draft')}
            </h4>
            {project.developer_loan_agreement_draft && (
              <Row>
                <DataBlock
                  label={t('table.file_name')}
                  value={project.developer_loan_agreement_draft.name}
                />
                <Col md={2}>
                  <DataBlock
                    colProps={{ md: 12 }}
                    label={t('table.size')}
                    value={project.developer_loan_agreement_draft.size}
                  />
                </Col>
                <Col md={2}>
                  <DataBlock
                    colProps={{ md: 12 }}
                    label={t('table.created_at')}
                    value={t('common.date_full', {
                      date: project.developer_loan_agreement_draft.created_at,
                    })}
                  />
                </Col>
                <Col md={3}>
                  <div>
                    {t('projects.documents.types.project_developer_loan_agreement_draft.is_sent')}
                  </div>
                  <Input
                    className="cursor-pointer"
                    type="checkbox"
                    placeholder={'Check'}
                    checked={isSent}
                    onChange={() => handleIsSent(!isSent)}
                  />
                </Col>
                {project.developer_loan_agreement_draft.url && (
                  <Col>
                    <div className={'float-end'}>
                      <DownloadButton url={project.developer_loan_agreement_draft.url} />
                    </div>
                  </Col>
                )}
              </Row>
            )}
          </div>
          <hr />
        </>
      )}
      <div className={'mb-5'}>
        <div className={'mb-4'}>
          <h4 className={'mb-4'}>
            {t('projects.documents.types.project_developer_loan_agreement_final')}
          </h4>
          {project.developer_loan_agreement && (
            <Row>
              <DataBlock
                label={t('table.file_name')}
                value={project.developer_loan_agreement.name}
              />
              <Col md={2}>
                <DataBlock
                  colProps={{ md: 12 }}
                  label={t('table.size')}
                  value={project.developer_loan_agreement.size}
                />
              </Col>
              <Col md={2}>
                <DataBlock
                  colProps={{ md: 12 }}
                  label={t('table.created_at')}
                  value={t('common.date_full', {
                    date: project.developer_loan_agreement.created_at,
                  })}
                />
              </Col>
              {project.developer_loan_agreement.url && (
                <Col>
                  <div className={'float-end'}>
                    <DownloadButton url={project.developer_loan_agreement.url} />
                  </div>
                </Col>
              )}
            </Row>
          )}
        </div>
        <Formik
          innerRef={formRef}
          enableReinitialize={true}
          initialValues={initialFormValues}
          onSubmit={onSubmit}
        >
          {({ submitForm, handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <FileUpload
                ref={fileUploadRef}
                name={'media_ids'}
                onPresign={(request) =>
                  Api.projects.uploadProjectDeveloperLoanAgreementDocument(project.id, request)
                }
                accept={DOCUMENT_TEMPLATE_DEVELOPER_INVESTOR_AGREEMENT}
                maxFiles={1}
                onDropAccepted={submitForm}
              />
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default withProject(ProjectDeveloperLoanAgreementSection);
