import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Badge } from 'reactstrap';
import { ProjectEmailCampaignStatusEnum } from 'src/helpers/Enums/Project/ProjectEmailCampaignStatusEnum';

interface Props {
  value: ProjectEmailCampaignStatusEnum;
}

const ProjectEmailCampaignStatusBadge: React.FC<Props> = ({ value }) => {
  const { t } = useTranslation();

  const [color, setColor] = useState<string>('#bc3e55');
  const [translation, setTranslation] = useState<string>('');

  useEffect(() => {
    switch (value) {
      case ProjectEmailCampaignStatusEnum.AX_CANCELLED:
        setColor('#514f44');
        setTranslation('projects.campaigns.status.AX_CANCELLED');
        break;
      case ProjectEmailCampaignStatusEnum.DELETED:
        setColor('#f47424');
        setTranslation('projects.campaigns.status.DELETED');
        break;
      case ProjectEmailCampaignStatusEnum.ARCHIVED:
        setColor('#dc2626');
        setTranslation('projects.campaigns.status.ARCHIVED');
        break;
      case ProjectEmailCampaignStatusEnum.DRAFT:
        setColor('#2563eb');
        setTranslation('projects.campaigns.status.DRAFT');
        break;
      case ProjectEmailCampaignStatusEnum.PROGRAMMED:
        setColor('#2c3c64');
        setTranslation('projects.campaigns.status.PROGRAMMED');
        break;
      case ProjectEmailCampaignStatusEnum.SENT:
        setColor('#16a34a');
        setTranslation('projects.campaigns.status.SENT');
        break;
      case ProjectEmailCampaignStatusEnum.AX_TESTED:
        setColor('#2c3c64');
        setTranslation('projects.campaigns.status.AX_TESTED');
        break;
      case ProjectEmailCampaignStatusEnum.AX_SELECTED:
        setColor('#2c3c64');
        setTranslation('projects.campaigns.status.AX_SELECTED');
        break;
      default:
        setColor('#bc3e55');
        setTranslation('projects.campaigns.status.NO_STATUS');
    }
  }, [value]);

  return (
    <Badge style={{ backgroundColor: color }} color={''}>
      {t(translation)}
    </Badge>
  );
};

export default ProjectEmailCampaignStatusBadge;
