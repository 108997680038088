import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import {
  SendCustomNotificationRequestDto,
  UserMarketingSettingResponseDto,
} from 'src/types/api/user';
import { Formik } from 'formik';
import { Form, FormGroup } from 'reactstrap';
import PrimaryButton from 'src/components/Form/PrimaryButton';
import TextInput from 'src/components/Form/TextInput';
import { SelectInput } from 'src/components/Form/Select';
import { NotificationType } from 'src/helpers/Enums/NotificationType';
import WYSIWYGInput from 'src/components/Form/WYSIWYGInput';
import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import { success } from 'src/services/toastr';

const INITIAL_STATE: SendCustomNotificationRequestDto = {
  user_id: '',
  user_type: '',
  notification: {
    message_types: [],
    subject: '',
    message: '',
  },
};

interface CustomNotificationFormProps {
  initial?: SendCustomNotificationRequestDto;
  onSubmit: (request: SendCustomNotificationRequestDto, helper: any) => Promise<any>;
  successMessage: string;
  marketing_settings?: UserMarketingSettingResponseDto[];
}

const CustomNotificationForm: React.FC<CustomNotificationFormProps> = ({
  initial,
  onSubmit,
  successMessage,
  marketing_settings,
}) => {
  const { t } = useTranslation();

  const UserCommentSchema = Yup.object().shape({
    user_id: Yup.number().required(),
    user_type: Yup.string().required(),
    notification: Yup.object().shape({
      message_types: Yup.array().required().min(1),
      subject: Yup.string().required(),
      message: Yup.string().required(),
    }),
  });
  const [userCommentRequest, setUserCommentRequest] =
    useState<SendCustomNotificationRequestDto>(INITIAL_STATE);
  const { hideModal } = useGlobalModalContext();

  useEffect(() => {
    if (initial) {
      setUserCommentRequest(initial);
    }
  }, [initial]);

  const onAction = useCallback(
    (request: SendCustomNotificationRequestDto, helper: any) => {
      return onSubmit(request, helper)
        .then(() => {
          success(successMessage);
          hideModal();
        })
        .catch((e) => {
          helper.setErrors(e.response?.errors);
        });
    },
    [hideModal, onSubmit, successMessage],
  );

  return (
    <React.Fragment>
      <Formik
        initialValues={userCommentRequest}
        enableReinitialize={true}
        validationSchema={UserCommentSchema}
        onSubmit={onAction}
      >
        {({ handleSubmit, isSubmitting, values }) => (
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <div hidden>
                <TextInput name={'user_id'} />
              </div>
              <div hidden>
                <TextInput name={'user_type'} />
              </div>
              <div className="mb-3">
                <SelectInput
                  name={'notification.message_types'}
                  placeholder={t('label.message_type')}
                  className={'z-100'}
                  isMulti={true}
                  options={[
                    { label: t('label.email'), value: NotificationType.EMAIL },
                    { label: t('label.notification'), value: NotificationType.NOTIFICATION },
                  ]}
                />
              </div>
              <div className={'mb-3'}>
                <TextInput name={'notification.subject'} placeholder={t('label.subject')} />
              </div>
              <div className={'mb-3'}>
                <WYSIWYGInput name={'notification.message'} placeholder={t('label.message')} />
              </div>
              {marketing_settings &&
                values.notification.message_types.includes(NotificationType.EMAIL) && (
                  <div className={'mb-3 alert alert-warning'}>
                    {marketing_settings.filter((setting) => setting.via_email).length ? (
                      <>
                        <p>{t('users.marketing.agreed_to_email_marketing_settings')}</p>
                        <ul>
                          {marketing_settings
                            .filter((setting) => setting.via_email)
                            .map((setting, index) => (
                              <li key={`email-settings-${index}`}>
                                {t(`users.marketing.${setting.name}`)}
                              </li>
                            ))}
                        </ul>
                      </>
                    ) : (
                      <p>{t('users.email_marketing_warning')}</p>
                    )}
                  </div>
                )}
              <div className={'mt-4 mb-4'}>
                <PrimaryButton title={t('common.submit')} submitting={isSubmitting} />
              </div>
            </FormGroup>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};
export default CustomNotificationForm;
