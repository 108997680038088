import React, { useState } from 'react';

import { getEnumSelectValues } from 'src/helpers/Enums/enumHelper';
import { SelectInput } from 'src/components/Form/Select/index';
import { ReactSelectOption } from 'src/components/Form/Select/SelectInput';
import { CompanyManagementStructureEnum } from 'src/helpers/Enums/CompanyManagementStructureEnum';

interface Props {
  name: string;
  placeholder: string;
}

const SelectManagementStructureInput: React.FC<Props> = ({ name, placeholder }) => {
  const [options] = useState<ReactSelectOption[]>(() =>
    getEnumSelectValues(CompanyManagementStructureEnum, 'users.company'),
  );

  return <SelectInput name={name} options={options} placeholder={placeholder} />;
};

export default SelectManagementStructureInput;
