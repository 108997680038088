import React from 'react';
import AuthRoute from '../AuthRoute';
import { RouteList } from 'src/routes';
import PageLayout from 'src/containers/PageLayout';
import RC from 'src/pages/RC/View';
import { Permissions } from 'src/helpers/auth/permissions';

const RCRoutes: any = () => [
  <AuthRoute
    path={RouteList.RC.LIST}
    component={RC}
    layout={PageLayout}
    permissions={[Permissions.BO__RC_REPORTS_LIST]}
    key={RouteList.RC.LIST}
    exact
  />,
];

export default RCRoutes;
