import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SelectInput, { ReactSelectOption } from 'src/components/Form/Select/SelectInput';
import { ProjectEventUpdateEnum } from 'src/helpers/Enums/Debt_collection/ProjectEventUpdateEnum';
import { Formik } from 'formik';
import { Form, FormGroup } from 'reactstrap';
import TextInput from 'src/components/Form/TextInput';
import PrimaryButton from 'src/components/Form/PrimaryButton';
import * as Yup from 'yup';
import { StoreEventUpdateRequestDto } from 'src/types/api/DebtCollection';

const INITIAL_STATE: StoreEventUpdateRequestDto = {
  stage_id: '',
  comment: '',
};

interface EventUpdateFormProps {
  initial?: StoreEventUpdateRequestDto;
  onSubmit: (request: StoreEventUpdateRequestDto, helper: any) => void;
}

const SelectProjectEventUpdateInput: React.FC<EventUpdateFormProps> = ({ initial, onSubmit }) => {
  const { t } = useTranslation();

  const SelectInputProps = {
    placeholder: t('label.debt_collection.project_event_update'),
    name: 'comment',
  };

  const [options] = useState<ReactSelectOption[]>([
    ...Object.values(ProjectEventUpdateEnum).map((type) => ({
      value: type,
      label: t('debt_collection.project_event_update.' + type),
    })),
  ]);

  const EventUpdateSchema = Yup.object().shape({
    stage_id: Yup.number().required(),
    comment: Yup.string().required(),
  });
  const [eventUpdateRequest, setEventUpdateRequest] =
    useState<StoreEventUpdateRequestDto>(INITIAL_STATE);

  useEffect(() => {
    if (initial) {
      setEventUpdateRequest(initial);
    }
  }, [initial]);

  return (
    <React.Fragment>
      <Formik
        initialValues={eventUpdateRequest}
        enableReinitialize={true}
        validationSchema={EventUpdateSchema}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <div hidden>
                <TextInput name={'stage_id'} />
              </div>
              <div className="mb-3">
                <SelectInput {...SelectInputProps} options={options} />
              </div>
              <div className={'mt-4 mb-4'}>
                <PrimaryButton title={t('common.submit')} submitting={isSubmitting} />
              </div>
            </FormGroup>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default SelectProjectEventUpdateInput;
