import Client from './client';
import {
  ForecastPricingToolRequestDto,
  ForecastPricingToolResponseDto,
} from 'src/types/api/forecast';

class Forecasts extends Client {
  api = {
    FETCH_PRICING_TOOL_FORECAST: `${this.http.baseUrl}/forecast/pricing-tool`,
  };

  fetchPricingToolForecast = (
    request: ForecastPricingToolRequestDto,
  ): Promise<ForecastPricingToolResponseDto> => {
    return this.http.post(this.api.FETCH_PRICING_TOOL_FORECAST, request);
  };
}

export default Forecasts;
