import React, { useCallback, useEffect, useState } from 'react';
import { Card, CardBody, Container, Input, Table } from 'reactstrap';
import { setGlobalLoading } from 'src/modules/app/actions';
import Api from 'src/api';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { success } from 'src/services/toastr';
import { Permissions } from 'src/helpers/auth/permissions';
import usePermissions from 'src/helpers/usePermissions';
import PermissionAction from 'src/components/PermissionAction';
import { capitalize, upperCase } from 'lodash';
import {
  PermissionListResponseDto,
  RoleMatrixListResponseDto,
  RoleMatrixListUpdateRequestDto,
} from 'src/types/api/roles';
import SaveButton from 'src/components/Form/SaveButton';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from 'reactstrap';

const RoleMatrix: React.FC = () => {
  const { t } = useTranslation();
  const p = usePermissions();
  const [roles, setRoles] = useState<RoleMatrixListResponseDto[]>([]);
  const [permissions, setPermissions] = useState<PermissionListResponseDto[]>([]);
  const [openAccordion, setOpenAccordion] = useState<string>('');

  const onRoleChange = useCallback(
    (roleId: string, permissionId: string) => {
      if (p.cannot(Permissions.BO__ROLES__UPDATE)) {
        return;
      }

      const roleIndex = roles.findIndex((r) => r.id === roleId);
      const permission = permissions.find((p) => p.id === permissionId);

      if (roleIndex == -1 || !permission) return;

      const newRoles = [...roles];
      const selectedRole = newRoles[roleIndex];

      const selectedRolePermissionIndex = selectedRole.permissions.findIndex(
        (p) => p.id === permissionId,
      );

      // permission not found in current role, adding it
      if (selectedRolePermissionIndex === -1) {
        selectedRole.permissions.push(permission);
      } else {
        selectedRole.permissions.splice(selectedRolePermissionIndex, 1);
      }

      setRoles(newRoles);
    },
    [p, permissions, roles],
  );

  const removeNamesFromDto = (roles: RoleMatrixListResponseDto[]) => {
    const clonedRoles: RoleMatrixListUpdateRequestDto[] = JSON.parse(JSON.stringify(roles));

    for (let i = 0; i < clonedRoles.length; i++) {
      delete clonedRoles[i].name;

      for (let j = 0; j < clonedRoles[i].permissions.length; j++) {
        delete clonedRoles[i].permissions[j].name;
      }
    }

    return clonedRoles;
  };

  const onSubmit = useCallback(
    async (roles: RoleMatrixListResponseDto[]) => {
      await Api.roles.updateRolesPermissions({ roles: removeNamesFromDto(roles) });
      success(t('common.updated_success'));
    },
    [t],
  );

  useEffect(() => {
    setGlobalLoading(true);

    Promise.all([Api.roles.fetchRoles(), Api.roles.fetchAllPermissions()]).then(
      ([roles, permissions]) => {
        setRoles(roles);
        setPermissions(permissions);
        setGlobalLoading(false);
      },
    );
  }, []);

  const toggle = (id: string) => {
    if (openAccordion === id) {
      setOpenAccordion('');
    } else {
      setOpenAccordion(id);
    }
  };

  return (
    <React.Fragment>
      <Container fluid>
        <Card>
          <CardBody>
            <h4 className={'mb-4'}>{t('menu.role_matrix')}</h4>

            <Formik
              initialValues={roles}
              onSubmit={async () => {
                await onSubmit(roles);
              }}
            >
              {({ handleSubmit, isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    //@ts-ignore
                    <Accordion open={openAccordion} toggle={toggle}>
                      {roles?.map((role, key) => (
                        <AccordionItem key={key}>
                          <AccordionHeader targetId={role.id}>{role.name} </AccordionHeader>
                          <AccordionBody accordionId={role.id}>
                            <div style={{ height: '500px', overflowY: 'scroll' }}>
                              <Table
                                striped
                                bordered
                                hover
                                className="table"
                                style={{ maxHeight: '500px', overflow: 'scroll' }}
                              >
                                <thead></thead>
                                <tbody>
                                  {permissions?.map((permission, key) => {
                                    const [currentSubsystem, currentScope] = permission.name.split(
                                      '.',
                                      2,
                                    );
                                    let previousScope = null;

                                    if (key > 0) {
                                      [, previousScope] = permissions[key - 1].name.split('.', 2);
                                    }

                                    return (
                                      <React.Fragment key={key}>
                                        {currentScope !== previousScope && (
                                          <tr>
                                            <th colSpan={2 + roles.length}>
                                              {upperCase(currentSubsystem)}{' '}
                                              {capitalize(currentScope)}
                                            </th>
                                          </tr>
                                        )}
                                        <tr>
                                          <th></th>
                                          <th>{t('permissions.' + permission.name)}</th>
                                          <th key={key} className={'text-center'}>
                                            <Input
                                              type="checkbox"
                                              checked={
                                                !!role.permissions.find(
                                                  (r) => r.id === permission.id,
                                                )
                                              }
                                              onChange={() => onRoleChange(role.id, permission.id)}
                                            />
                                          </th>
                                        </tr>
                                      </React.Fragment>
                                    );
                                  })}
                                </tbody>
                              </Table>
                            </div>
                          </AccordionBody>
                        </AccordionItem>
                      ))}
                    </Accordion>
                  }
                  <PermissionAction permissions={Permissions.BO__ROLES__UPDATE}>
                    <div className={'mt-4 mb-4'}>
                      <SaveButton title={t('common.submit')} submitting={isSubmitting} />
                    </div>
                  </PermissionAction>
                </Form>
              )}
            </Formik>
          </CardBody>
        </Card>
      </Container>
    </React.Fragment>
  );
};
export default RoleMatrix;
