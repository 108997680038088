import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Card, CardBody, Container } from 'reactstrap';
import { setGlobalLoading } from 'src/modules/app/actions';
import { useTranslation } from 'react-i18next';
import { PaginationData, PaginationDataFilter } from 'src/types';
import { Cell, Column } from 'react-table';
import Api from 'src/api';
import Table from 'src/components/Table';
import { Permissions } from 'src/helpers/auth/permissions';

import { CustomHistoryResponseDto } from 'src/types/api/communication';
import PermissionAction from 'src/components/PermissionAction';
import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import ViewCustomHistoryNoticiationModal from 'src/pages/Communication/CustomHistory/ViewCustomHistoryNoticiationModal';

const INITIAL_SORT_ORDER = [
  {
    id: 'created_at',
    desc: true,
  },
];

const CustomHistoryList: React.FC = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<PaginationData<CustomHistoryResponseDto>>();
  const { showModal } = useGlobalModalContext();
  const capitalizeFirst = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  const columns = useMemo<Column<CustomHistoryResponseDto>[] | any>(
    () => [
      {
        Header: t('table.id'),
        accessor: 'id',
        sortType: 'string',
        width: 200,
      },
      {
        Header: t('table.group'),
        accessor: 'group',
        sortType: 'string',
        width: 200,
      },
      {
        Header: t('table.type'),
        accessor: 'type',
        sortType: 'string',
        width: 150,
        Cell: (cell: Cell<CustomHistoryResponseDto>) => {
          const val = cell.row.original.type;
          return <span>{capitalizeFirst(val)}</span>;
        },
      },
      {
        Header: t('table.include_marketing_educational'),
        accessor: 'education_marketing',
        sortType: 'bool',
        width: 150,
        Cell: (cell: Cell<CustomHistoryResponseDto>) => {
          const val = cell.row.original;
          return (
            <span>
              {val.marketing_settings?.allow_marketing_education ? t('common.yes') : t('common.no')}
            </span>
          );
        },
      },
      {
        Header: t('table.include_marketing_newsletter'),
        accessor: 'newsletter_marketing',
        sortType: 'bool',
        width: 150,
        Cell: (cell: Cell<CustomHistoryResponseDto>) => {
          const val = cell.row.original;
          return (
            <span>
              {val.marketing_settings?.allow_marketing_newsletter
                ? t('common.yes')
                : t('common.no')}
            </span>
          );
        },
      },
      {
        Header: t('table.include_marketing_promotional'),
        accessor: 'promotional_marketing',
        sortType: 'bool',
        width: 150,
        Cell: (cell: Cell<CustomHistoryResponseDto>) => {
          const val = cell.row.original;
          return (
            <span>
              {val.marketing_settings?.allow_marketing_promotions
                ? t('common.yes')
                : t('common.no')}
            </span>
          );
        },
      },
      {
        Header: t('table.include_marketing_invested_project_news'),
        accessor: 'invested_project_news_marketing',
        sortType: 'bool',
        width: 150,
        Cell: (cell: Cell<CustomHistoryResponseDto>) => {
          const val = cell.row.original;
          return (
            <span>
              {val.marketing_settings?.allow_marketing_invested_project_news
                ? t('common.yes')
                : t('common.no')}
            </span>
          );
        },
      },
      {
        Header: t('table.include_marketing_survey'),
        accessor: 'marketing_surveys',
        sortType: 'bool',
        width: 150,
        Cell: (cell: Cell<CustomHistoryResponseDto>) => {
          const val = cell.row.original;
          return (
            <span>
              {val.marketing_settings?.allow_marketing_surveys ? t('common.yes') : t('common.no')}
            </span>
          );
        },
      },
      {
        Header: t('table.created_at_history'),
        accessor: 'created_at',
        Cell: (cell: Cell<CustomHistoryResponseDto>) => (
          <div>{t('common.date_full', { date: cell.value })}</div>
        ),
      },
      {
        Header: t('table.action'),
        width: 50,
        Cell: (cell: Cell<CustomHistoryResponseDto>) => {
          return (
            <div className={'actions d-flex gap-2 justify-content-center'}>
              <PermissionAction permissions={Permissions.BO__COMMUNICATION__CUSTOM_HISTORY__VIEW}>
                <a
                  className={'link-primary'}
                  style={{ cursor: 'pointer' }}
                  title={t('common.view')}
                  onClick={() => {
                    showModal(
                      <ViewCustomHistoryNoticiationModal
                        notification={cell.row.original.notification}
                        date={t('common.date_full', { date: cell.row.original.created_at })}
                      />,
                    );
                  }}
                >
                  <i className={'fas fa-eye'} />
                </a>
              </PermissionAction>
            </div>
          );
        },
      },
    ],
    [showModal, t],
  );

  useEffect(() => {
    setGlobalLoading(false);
  }, []);

  const fetchData = useCallback(async (request: PaginationDataFilter | undefined) => {
    return Api.communication.fetchFilteredUsersCustomNotifications(request).then((response) => {
      setData(response);
    });
  }, []);

  return (
    <React.Fragment>
      <Container fluid>
        <Card>
          <CardBody>
            <h4 className={'mb-4'}>{t('menu.custom_history')}</h4>
            <Table
              title={'CustomNotificationHistoryListIndex'}
              onFetchData={fetchData}
              columns={columns}
              data={data}
              searchable={true}
              enableQueryFilter={true}
              initialSortBy={INITIAL_SORT_ORDER}
              disableFiltersInsideTable={false}
            />
          </CardBody>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default CustomHistoryList;
