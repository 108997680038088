import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, ModalBody, ModalFooter, ModalHeader, Form } from 'reactstrap';
import { success } from 'src/services/toastr';
import DenyButton from 'src/components/Form/DenyButton';
import SaveButton from 'src/components/Form/SaveButton';
import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import { ModalComponent } from 'src/components/Modal/ModalComponent';
import ModalCloseButton from 'src/components/Modal/ModalCloseButton';
import Api from 'src/api';
import { ChangeProjectStatusRequestDto, ProjectResponseDto } from 'src/types/api/projects';
import { ProjectStatus } from 'src/types/app/projects';
import { Formik } from 'formik';
import CheckboxInput from 'src/components/Form/CheckboxInput';
import TextInput from 'src/components/Form/TextInput';
import SelectYesNo from 'src/components/Form/Select/SelectYesNoInput';
import StreamFileButton from 'src/components/Table/Buttons/StreamFileButton';

interface Props {
  project: ProjectResponseDto;
  onException?: () => void;
  actionText: string;
  title: string;
  body: string;
  checkboxText: string;
  successMessage?: string;
  status: ProjectStatus;
  setProject: (response: ProjectResponseDto) => void;
}

const MarkProjectConfirmedModal: React.FC<Props> = ({
  project,
  onException,
  title,
  body,
  checkboxText,
  successMessage,
  actionText,
  setProject,
}) => {
  const { t } = useTranslation();
  const { hideModal } = useGlobalModalContext();
  const [isInAction, setIsInAction] = useState(false);
  const [request, setRequest] = useState<ChangeProjectStatusRequestDto>({
    ignore_validation: false,
    amount: 0,
    loan_start_date: undefined,
  });

  useEffect(() => {
    if (project.loan_start_date) {
      setRequest({ ...request, loan_start_date: project.loan_start_date });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = useCallback(
    async (request) => {
      if (isInAction) return;
      setIsInAction(true);

      return Api.projects
        .markAsConfirmed(project.id, request)
        .then((response) => {
          success(successMessage);
          hideModal();
          setProject(response);
        })
        .finally(() => {
          setIsInAction(false);
        })
        .catch(() => {
          onException && onException();
        });
    },
    [hideModal, isInAction, onException, project.id, successMessage, setProject],
  );

  return (
    <React.Fragment>
      <ModalComponent size={'lg'}>
        <div>
          <ModalHeader toggle={hideModal} close={<ModalCloseButton />}>
            {title}
          </ModalHeader>
          <ModalBody>
            <Formik initialValues={request} enableReinitialize={true} onSubmit={onSubmit}>
              {({ handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                  <ModalBody className={''}>
                    <p className={'text-pre'}>{body}</p>
                    <div>
                      <CheckboxInput name={'ignore_validation'} placeholder={checkboxText} />
                    </div>
                    <hr />
                    <div>
                      <div className={'mb-4'}>
                        <StreamFileButton
                          buttonTitle={t('common.preview_payment_instructions')}
                          fileName={'ProjectMoneyTransferInstructions'}
                          extension={'xlsx'}
                          request={() =>
                            Api.projects.previewProjectMoneyTransferInstructions(project.id)
                          }
                        />
                      </div>
                      <ul>
                        <li>
                          {t('label.platform_fee')} -{' '}
                          {t('common.money', { value: project.platform_fee })}
                        </li>
                        <li>
                          {t('label.contract_administration_fee')} -{' '}
                          {t('common.money', { value: project.contract_administration_fee })}
                        </li>
                        <li>
                          {t('label.mortgage_administration_fee')} -{' '}
                          {t('common.money', { value: project.mortgage_administration_fee })}
                        </li>
                        <li>
                          {t('label.project_uploaded_to_platform_fee')} -{' '}
                          {t('common.money', { value: project.project_uploaded_to_platform_fee })}
                        </li>
                      </ul>
                      <hr />
                      <p>
                        <b>
                          Total funded amount: {t('common.money', { value: project.funded_amount })}
                        </b>
                      </p>
                      <p>
                        <b>
                          Total funded amount after fees:{' '}
                          {t('common.money', { value: project.funded_amount_after_fees })}
                        </b>
                      </p>
                    </div>
                    <hr />
                    <div>
                      {' '}
                      <TextInput
                        type={'number'}
                        placeholder={t(
                          'projects.modals.mark_as_confirmed.transfer_funds_to_developer',
                        )}
                        name={'amount'}
                      />
                      <SelectYesNo
                        name={'allow_0_amount_reservation'}
                        placeholder={t(
                          'projects.modals.mark_as_confirmed.allow_0_amount_reservation',
                        )}
                        isClearable={false}
                      />
                    </div>
                    <hr />
                    <div>
                      <div>{t('label.payments_information')} </div>
                      <div> {project.payments_information ?? '-'}</div>
                    </div>
                  </ModalBody>
                  <ModalFooter className={'d-flex justify-content-center'}>
                    <Col>
                      <DenyButton
                        title={t('common.cancel')}
                        onClick={(e) => {
                          e.preventDefault();
                          hideModal();
                        }}
                      />
                    </Col>
                    <Col>
                      <SaveButton title={actionText} submitting={isInAction} />
                    </Col>
                  </ModalFooter>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </div>
      </ModalComponent>
    </React.Fragment>
  );
};
export default MarkProjectConfirmedModal;
