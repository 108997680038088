import React, { useCallback, useEffect, useState } from 'react';
import { UserCompanyResponseDto } from 'src/types/api/userCompanies';
import { useTranslation } from 'react-i18next';
import { ModalComponent } from 'src/components/Modal/ModalComponent';
import { Col, Form, FormGroup, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import ModalCloseButton from 'src/components/Modal/ModalCloseButton';
import { Formik, FormikHelpers } from 'formik';
import TextAreaInput from 'src/components/Form/TextAreaInput';
import DenyButton from 'src/components/Form/DenyButton';
import SaveButton from 'src/components/Form/SaveButton';
import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import * as Yup from 'yup';
import { success } from 'src/services/toastr';
import PrimaryButton from 'src/components/Form/PrimaryButton';
import Loader from 'src/components/Loader';
import Api from 'src/api';
import { LegalEntitySearchMatchItem } from 'src/types/api/amlManagement';
import MatchesTraitsTable from 'src/pages/AmlManagement/MatchesTraitsTable';

interface Props {
  company: UserCompanyResponseDto | undefined;
  onAction: (reason: string) => Promise<any>;
}

interface ReasonRequest {
  reason: string;
}

enum AccreditationStep {
  AML_VERIFY_STEP = 'AML_VERIFY_STEP',
  ADD_ACCREDITATION_STEP = 'ADD_ACCREDITATION_STEP',
}

const AddAccreditationDialog = ({ company, onAction }: Props) => {
  const { t } = useTranslation();

  const { hideModal } = useGlobalModalContext();

  const SuspendRequestSchema = Yup.object().shape({
    reason: Yup.string().required(),
  });

  const [reasonRequest] = useState<ReasonRequest>({ reason: '' });
  const [step, setStep] = useState<AccreditationStep>(AccreditationStep.AML_VERIFY_STEP);
  const [amlIssues, setAmlIssues] = useState<LegalEntitySearchMatchItem[] | null>(null);

  const onSubmit = useCallback(
    (request: ReasonRequest, helper: FormikHelpers<ReasonRequest>) => {
      return onAction(request.reason)
        .then(() => {
          success(t('users.add_accreditation_success'));
          hideModal();
        })
        .catch((e) => {
          helper.setErrors(e.response?.errors);
        });
    },
    [hideModal, onAction, t],
  );

  useEffect(() => {
    // TODO: Perform AML screening and display matches if found any
    // setStep(AccreditationStep.ADD_ACCREDITATION_STEP);

    if (company) {
      Api.amlManagement.performAmlScreeningForCompany(company.id).then((response) => {
        setAmlIssues(response.matches);
      });
    }
  }, [company]);

  const formatAmlTraitMatches = (traits: LegalEntitySearchMatchItem) => {
    return {
      resourceId: traits.resourceId,
      score: traits.score,
      match: traits.match ? t('common.yes') : t('common.no'),
      name: traits.name,
      countries: traits.countries?.join(', '),
      dataSets: traits.dataSets?.join(', '),
    };
  };

  return (
    <ModalComponent size={'lg'}>
      {step === AccreditationStep.AML_VERIFY_STEP && (
        <div>
          <ModalHeader toggle={hideModal} close={<ModalCloseButton />}>
            {t('users.add_accreditation')}
          </ModalHeader>
          <ModalBody className={''}>
            <div
              className="d-flex justify-content-center align-items-center mt-5"
              style={{ flexDirection: 'column' }}
            >
              {amlIssues === null && (
                <>
                  <span className={'text-center'}>
                    {t('label.aml.executing_loading_aml_screening')}
                  </span>
                  <Loader />
                </>
              )}
              {amlIssues && Array.isArray(amlIssues) && amlIssues.length === 0 && (
                <span className={'text-center'}>
                  <b className={'text-success'}>{t('label.aml.screening_no_issues_found')}</b>
                </span>
              )}
              {amlIssues && Array.isArray(amlIssues) && amlIssues.length > 0 && (
                <>
                  <span className={'text-center'}>
                    <b className={'text-danger'}>{t('label.aml.found_some_issues')}</b>
                  </span>
                  {amlIssues.map((item, index) => (
                    <MatchesTraitsTable key={index} traits={formatAmlTraitMatches(item)} />
                  ))}
                </>
              )}
            </div>
          </ModalBody>
          <ModalFooter className={'d-flex justify-content-center'}>
            <Col>
              <DenyButton title={t('common.cancel')} onClick={hideModal} />
            </Col>
            <Col>
              <PrimaryButton
                className={'btn btn-success col-md-12'}
                title={t('common.continue')}
                onClick={() => setStep(AccreditationStep.ADD_ACCREDITATION_STEP)}
                disabled={amlIssues === null}
              />
            </Col>
          </ModalFooter>
        </div>
      )}
      {step === AccreditationStep.ADD_ACCREDITATION_STEP && (
        <div>
          <ModalHeader toggle={hideModal} close={<ModalCloseButton />}>
            {t('users.add_accreditation')}
          </ModalHeader>
          <Formik
            initialValues={reasonRequest}
            validationSchema={SuspendRequestSchema}
            onSubmit={onSubmit}
          >
            {({
              values,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <>
                <ModalBody className={''}>
                  <p className={'text-pre'}>
                    {t('users.add_accreditation_body', { name: company?.name })}
                  </p>

                  <Form onSubmit={handleSubmit} id={'reason-form'}>
                    <FormGroup>
                      <div className="mb-3">
                        <TextAreaInput value={values.reason} name="reason" />
                      </div>
                    </FormGroup>
                  </Form>
                </ModalBody>
                <ModalFooter className={'d-flex justify-content-center'}>
                  <Col>
                    <DenyButton title={t('common.cancel')} onClick={hideModal} />
                  </Col>
                  <Col>
                    <SaveButton
                      form={'reason-form'}
                      submitting={isSubmitting}
                      title={t('users.add_accreditation')}
                      type={'submit'}
                    />
                  </Col>
                </ModalFooter>
              </>
            )}
          </Formik>
        </div>
      )}
    </ModalComponent>
  );
};

export default AddAccreditationDialog;
