import React, { useCallback, useMemo, useState } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import Table, { Column } from 'src/components/Table';
import { Cell } from 'react-table';
import {
  AttachFilesRequestDto,
  DebtCollectionDocumentResponseDto,
  DocumentResponseDto,
  MediaResponseDto,
} from 'src/types/api/common';
import IsOutdatedBadge from 'src/components/Badges/IsOutdatedBadge';
import IsVisibleBadge from 'src/components/Badges/IsVisibleBadge';
import { locales } from 'src/helpers';
import IsVisibleLocaleBadge from 'src/pages/Project/Update/Tabs/Badges/IsVisibleLocaleBadge';
import { useTranslation } from 'react-i18next';
import { ProjectContextState, withProject } from 'src/pages/Project/Update/ProjectContext';
import Api from 'src/api';
import { BaseTabProps } from 'src/components/Tabs';
import { PaginationData, PaginationDataFilter } from 'src/types';
import { HiddenColumnsProvider } from 'src/components/ShowTableColumnsDropdown';
import DownloadButton from 'src/components/DownloadButton';
import { Form, Formik, FormikHelpers } from 'formik';
import FileUpload, { FileUploadHandle } from 'src/components/Form/FileUpload/FileUpload';
import { FormikProps } from 'formik/dist/types';
import ConfirmButton from 'src/components/ConfirmButton';
import { success } from 'src/services/toastr';

interface Props extends ProjectContextState, BaseTabProps {}

const TabDebtCollection: React.FC<Props> = ({ project, setProject }) => {
  const { t } = useTranslation();
  const [documents, setDocuments] = useState<PaginationData<MediaResponseDto>>();
  const fileUploadRef = React.useRef<FileUploadHandle>(null);
  const formRef = React.useRef<FormikProps<any>>(null);
  const [initialFormValues] = useState<AttachFilesRequestDto>({
    media_ids: [],
  });

  const columns = useMemo<Column<DocumentResponseDto>[] | any>(
    () => [
      {
        Header: t('table.id'),
        accessor: 'id',
      },
      {
        Header: t('table.file_name'),
        accessor: 'name',
      },
      {
        Header: t('table.mime_type'),
        accessor: 'mime_type',
        size: 80,
      },
      {
        Header: t('table.size'),
        accessor: 'size',
        width: 60,
      },
      {
        Header: t('table.information'),
        width: 100,
        Cell: (cell: Cell<DebtCollectionDocumentResponseDto>) => (
          <div className={'d-flex justify-content-center gap-2 text-center'}>
            <IsOutdatedBadge show={!!cell.row.original.is_outdated} />
            <IsVisibleBadge show={!!cell.row.original.is_visible} />
            {locales.map((locale, key) => (
              <IsVisibleLocaleBadge
                className={'w-100'}
                key={key}
                locale={locale}
                show={!!cell.row.original.visible_locales?.find((s) => s === locale)}
              />
            ))}
          </div>
        ),
      },
      {
        Header: t('table.type'),
        accessor: 'type',
      },
      {
        Header: t('table.sent_at'),
        accessor: 'sent_at',
        Cell: (cell: Cell<DebtCollectionDocumentResponseDto>) => (
          <div>{t('common.date_full', { date: cell.value })}</div>
        ),
      },
      {
        Header: t('table.created_at'),
        accessor: 'created_at',
        Cell: (cell: Cell<DebtCollectionDocumentResponseDto>) => (
          <div>{t('common.date_full', { date: cell.value })}</div>
        ),
      },
      {
        Header: t('table.action'),
        width: 200,
        accessor: 'url',
        Cell: (cell: Cell<DebtCollectionDocumentResponseDto> | any) => {
          if (!project) return null;
          return (
            <div className={'d-flex gap-2'}>
              <DownloadButton url={cell.value} />

              {!cell.row.original.sent_at && (
                <ConfirmButton
                  onAction={async () =>
                    Api.projects
                      .sendDebtCollectionDocumentToProjectOwner(project.id, cell.row.original.id)
                      .then(() => {
                        cell.dispatch({ type: 'refreshData' });
                        success(t('common.success'));
                      })
                  }
                  body={t('projects.debt_collection.send_email.body')}
                  actionText={t('projects.debt_collection.send_email.action_text')}
                  title={t('projects.debt_collection.send_email.title')}
                />
              )}
            </div>
          );
        },
      },
    ],
    [project, t],
  );

  const fetchDebtCollectionDocuments = useCallback(
    async (request: PaginationDataFilter | undefined): Promise<any> => {
      if (!project) return Promise.reject();

      const data = await Api.projects.fetchDebtCollectionDocuments(project.id, request);
      setDocuments(data);
    },
    [project],
  );

  const onSubmit = useCallback(
    async (request: AttachFilesRequestDto, helpers: FormikHelpers<any>) => {
      if (!project) return;

      try {
        // this method returns true, if new files added, which indicates to resubmit form
        if (await fileUploadRef?.current?.upload()) {
          await helpers.submitForm();
          return;
        }
        const response = await Api.projects.attachDebtCollectionDocuments(project.id, request);
        setProject(response);
        fileUploadRef?.current?.reset();
      } catch (e: any) {
        helpers.setErrors(e.response?.errors);
      }
    },
    [project, setProject],
  );

  if (!project) {
    return null;
  }

  return (
    <React.Fragment>
      <Container fluid>
        <Col xs="12" className={'mb-4'}>
          <Card className="card h-100">
            <CardBody>
              <Formik
                innerRef={formRef}
                enableReinitialize={true}
                initialValues={initialFormValues}
                onSubmit={onSubmit}
              >
                {({ submitForm, handleSubmit }) => (
                  <Form onSubmit={handleSubmit}>
                    <FileUpload
                      ref={fileUploadRef}
                      name={'media_ids'}
                      onPresign={(request) =>
                        Api.projects.uploadDebtCollectionDocuments(project.id, request)
                      }
                      onDropAccepted={submitForm}
                    />
                  </Form>
                )}
              </Formik>
              <hr />
              <Row>
                <Col>
                  <div>
                    <HiddenColumnsProvider title={'ProjectInvestments'}>
                      <Table
                        columns={columns}
                        data={documents}
                        onFetchData={fetchDebtCollectionDocuments}
                        title={'ProjectDebtCollectionDocuments'}
                      />
                    </HiddenColumnsProvider>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Container>
    </React.Fragment>
  );
};

export default withProject(TabDebtCollection);
