import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Badge } from 'reactstrap';
import { StageHistoryTypeEnum } from 'src/helpers/Enums/Debt_collection/StageHistoryTypeEnum';

interface Props {
  value: StageHistoryTypeEnum;
}

const StageHistoryType: React.FC<Props> = ({ value }) => {
  const { t } = useTranslation();

  const [color, setColor] = useState<string>('#bc3e55');

  useEffect(() => {
    switch (value) {
      case StageHistoryTypeEnum.COMMENT:
        setColor('#81CF74');
        break;
      case StageHistoryTypeEnum.EVENT_UPDATE:
        setColor('#7b3ebc');
        break;
      case StageHistoryTypeEnum.STATUS_CHANGE:
        setColor('#bc3e55');
        break;
      case StageHistoryTypeEnum.BORROWER_PAYOUT:
        setColor('#323c77');
        break;

      default:
        setColor('#d1cd45');
    }
  }, [value]);

  return (
    <Badge style={{ backgroundColor: color }} color={''}>
      {t('debt_collection.stage_history_type.' + value)}
    </Badge>
  );
};

export default StageHistoryType;
