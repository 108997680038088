import Client from 'src/api/services/client';
import { PaginationData, PaginationDataFilter } from 'src/types';
import {
  FetchAutoInvestorDraftQueueByFilterRequest,
  AutoInvestorDraftQueueResponse,
} from 'src/types/api/autoInvestment';
import { FetchProjectsBriefResponse } from 'src/types/api/projects';

class AutoInvestment extends Client {
  api = {
    FETCH_PROJECTS_BRIEF: `${this.http.baseUrl}/auto-investor/predict/projects/brief`,
    FETCH_PROJECT_DRAFT_QUEUE: `${this.http.baseUrl}/auto-investor/predict/projects/{projectId}`,
    FETCH_FILTER_DRAFT_QUEUE: `${this.http.baseUrl}/auto-investor/predict/filter`,
  };

  fetchAutoInvestmentProjectDraftQueue = (
    projectId: string | number,
  ): Promise<AutoInvestorDraftQueueResponse> => {
    const url = this.buildUrl(this.api.FETCH_PROJECT_DRAFT_QUEUE, { projectId });

    return this.http.get(url);
  };

  fetchAutoInvestmentFilterDraftQueue = (
    request: FetchAutoInvestorDraftQueueByFilterRequest,
  ): Promise<AutoInvestorDraftQueueResponse> => {
    return this.http.get(this.api.FETCH_FILTER_DRAFT_QUEUE, { params: request });
  };

  fetchProjectsBriefList = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<FetchProjectsBriefResponse>> => {
    return this.http.get(this.api.FETCH_PROJECTS_BRIEF, { params: request });
  };
}

export default AutoInvestment;
