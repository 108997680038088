import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Cell, Column } from 'react-table';
import Select, { SingleValue } from 'react-select';

import Api from 'src/api';
import { PaginationData, PaginationDataFilter } from 'src/types';
import Table from 'src/components/Table';
import { printObjects } from 'src/helpers/formatters/object';
import { HiddenColumnsProvider } from 'src/components/ShowTableColumnsDropdown';
import { ProjectContextState, withProject } from 'src/pages/Project/Update/ProjectContext';
import { CommonActionListResponseDto } from 'src/types/api/common';
import { ReactSelectOption } from 'src/components/Form/Select/SelectInput';

const INITIAL_SORT_ORDER = [
  {
    id: 'created_at',
    desc: true,
  },
];

const TabActionHistory: React.FC<ProjectContextState> = ({ project }) => {
  const { t } = useTranslation();
  const [response, setResponse] = useState<PaginationData<CommonActionListResponseDto>>();

  const fieldFilterOptions: ReactSelectOption[] = [
    {
      value: '',
      label: t('common.all'),
    },
    {
      value: 'risk_category',
      label: 'risk_category',
    },
    {
      value: 'initial_rating',
      label: 'initial_rating',
    },
  ];

  const [selectedFieldFilter, setSelectedFieldFilter] = useState<ReactSelectOption>({
    value: '',
    label: t('common.all'),
  });

  const columns = useMemo<Column<CommonActionListResponseDto>[] | any>(
    () => [
      {
        Header: t('table.user'),
        accessor: 'user_name',
        Cell: (cell: Cell<CommonActionListResponseDto>) => {
          const row = cell.row.original;
          return (
            <div>
              {row.user_name} {row.original_user_name && <>({row.original_user_name})</>}
            </div>
          );
        },
      },
      {
        Header: t('table.event'),
        width: 250,
        accessor: 'event',
        Cell: (cell: Cell<CommonActionListResponseDto>) => {
          const row = cell.row.original;
          return (
            <div>
              {t('audit.event.' + row.event, { type: t('audit.entity.' + row.audit_type) })}
            </div>
          );
        },
      },
      {
        Header: t('table.old_values'),
        width: 400,
        accessor: 'old_values',
        Cell: (cell: Cell<CommonActionListResponseDto>) => (
          <div className={'d-flex flex-wrap'}>{printObjects(cell.value)}</div>
        ),
      },
      {
        Header: t('table.new_values'),
        width: 400,
        accessor: 'new_values',
        Cell: (cell: Cell<CommonActionListResponseDto>) => (
          <div className={'d-flex flex-wrap'}>{printObjects(cell.value)}</div>
        ),
      },
      {
        Header: t('table.created_at'),
        accessor: 'created_at',
        Cell: (cell: Cell<CommonActionListResponseDto>) => (
          <div>{t('common.date_full', { date: cell.value })}</div>
        ),
      },
    ],
    [t],
  );

  const fetchData = useCallback(
    (request: PaginationDataFilter | undefined) => {
      if (!project) return Promise.resolve();

      if (request && request.filters) {
        // Remove previously set filters so they do not stack
        request.filters = request?.filters.filter(
          (filter) => filter.id !== 'selected_field_filter',
        );

        // Append new filter value
        request.filters.push({
          id: 'selected_field_filter',
          value: selectedFieldFilter.value,
        });
      }

      return Api.projects
        .fetchProjectActions(project.id, request)
        .then((response) => setResponse(response));
    },
    [project, selectedFieldFilter],
  );

  return (
    <React.Fragment>
      <h3>{t('projects.action_history')}</h3>
      <hr />
      <div className={'small lh-sm'}>
        <HiddenColumnsProvider title={'ProjectUpdateTabsTabActionHistory'}>
          <Table
            title={'ProjectUpdateTabsTabActionHistory'}
            columns={columns}
            data={response}
            onFetchData={fetchData}
            initialSortBy={INITIAL_SORT_ORDER}
            createComponent={
              <div className="text-start">
                <Select
                  name={'field_name'}
                  options={fieldFilterOptions}
                  onChange={(value: SingleValue<ReactSelectOption>) => {
                    if (value) {
                      setSelectedFieldFilter(value);
                    }
                  }}
                />
              </div>
            }
          />
        </HiddenColumnsProvider>
      </div>
    </React.Fragment>
  );
};

export default withProject(TabActionHistory);
