import Api from 'src/api';
import { ExternalMediaResponseDto } from 'src/types/api/common';

export function openMedia(url: string): Promise<ExternalMediaResponseDto> {
  return Api.media.fetchExternal(url).then((response) => {
    window.open(response.url, '_blank');
    return response;
  });
}

export function downloadXMLFile(url: string, name: string): Promise<any> {
  return Api.media.fetchExternal(url).then((response) => {
    fetch(response.url)
      .then((response) => response.blob())
      .then((blob) => {
        responseToCsv(blob, `${name}.xml`);
      });

    return response;
  });
}

export function downloadXLSXFile(url: string, name: string): Promise<any> {
  return Api.media.fetchExternal(url).then((response) => {
    fetch(response.url)
      .then((response) => response.blob())
      .then((blob) => {
        responseToCsv(blob, `${name}.xlsx`);
      });

    return response;
  });
}

const responseToCsv = (response: any, fileName: string) => {
  const blob = new Blob(
    [
      // new Uint8Array([0xef, 0xbb, 0xbf]), // UTF-8-BOM encoding for excel
      response,
    ],
    {
      type: 'application/octet-stream',
    },
  );

  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  a.click();
};
