import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Permissions } from 'src/helpers/auth/permissions';

import PermissionAction from 'src/components/PermissionAction';
import {
  DebtCollectionContextState,
  withDebtCollection,
} from 'src/pages/DebtCollection/Update/DebtCollectionContext';
import { Col, Form, Row } from 'reactstrap';

import DatePickerInput from 'src/components/Form/DatePickerInput';
import { Formik } from 'formik';
import { format } from 'date-fns';
import { debounce } from 'lodash';
import {
  DebtCollectionResponseDto,
  UpdateREDNoticeDateRequestDto,
} from 'src/types/api/DebtCollection';
import Api from 'src/api';
import { success } from 'src/services/toastr';

const RealEstateDeveloperNoticeDates: React.FC<DebtCollectionContextState> = ({
  debtCollection,
  setDebtCollection,
}) => {
  const { t } = useTranslation();

  const debouncedEventHandler = useCallback(
    debounce(
      (
        stageId: string,
        request: UpdateREDNoticeDateRequestDto,
        callback: (r: DebtCollectionResponseDto) => void,
      ) => {
        Api.debtCollection.updateREDNoticeDate(stageId, request).then(callback);
      },
      300,
    ),
    [],
  );

  if (!debtCollection) {
    return null;
  }
  return (
    <React.Fragment>
      <PermissionAction permissions={Permissions.BO__DEBT_COLLECTION__VIEW}>
        <Row>
          <Col xs={12} className={'mb-4'}>
            <Formik
              initialValues={{
                notice_1_at: debtCollection.notice_1_at,
                notice_2_at: debtCollection.notice_2_at,
              }}
              enableReinitialize={true}
              onSubmit={(e) => {
                console.log(e);
              }} //don't need any logic on submit
            >
              {({ handleChange, handleSubmit }) => (
                <Form onChange={handleChange} onSubmit={handleSubmit}>
                  <Row>
                    <div className="col-6">
                      <DatePickerInput
                        name={'notice_1_at'}
                        onChangeAction={(e): void => {
                          const d = format(e, 'yyyy-MM-dd');
                          debouncedEventHandler(
                            debtCollection.id,
                            { notice_date: d, key: 'notice_1_at' },
                            (response: DebtCollectionResponseDto) => {
                              setDebtCollection(response);
                              success(t('common.updated_success'));
                            },
                          );
                        }}
                      />
                    </div>
                    <div className="col-6">
                      <DatePickerInput
                        name={'notice_2_at'}
                        onChangeAction={(e): void => {
                          const d = format(e, 'yyyy-MM-dd');
                          debouncedEventHandler(
                            debtCollection.id,
                            { notice_date: d, key: 'notice_2_at' },
                            (response: DebtCollectionResponseDto) => {
                              setDebtCollection(response);
                              success(t('common.updated_success'));
                            },
                          );
                        }}
                      />
                    </div>
                  </Row>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </PermissionAction>
    </React.Fragment>
  );
};

export default withDebtCollection(RealEstateDeveloperNoticeDates);
