import React, { useCallback, useEffect, useRef, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { useTranslation } from 'react-i18next';
import { useField, useFormikContext } from 'formik';
import { debounce } from 'lodash';
import { StateManagerProps } from 'react-select/dist/declarations/src/stateManager';
import { ActionMeta } from 'react-select/dist/declarations/src/types';
import { ReactSelectOption } from 'src/components/Form/Select/SelectInput';

interface ReactSelectProps extends StateManagerProps {
  name: string;
  loadOptions: (inputValue?: string, loadWith?: Array<string>) => Promise<ReactSelectOption[]>;
}

const SelectAsyncInput: React.FC<ReactSelectProps> = ({
  name,
  loadOptions,
  onChange,
  ...props
}) => {
  const [options, setOptions] = useState<ReactSelectOption[]>([]);
  const [selectValue, setSelectValue] = useState<any | null>(null);
  const innerRef = useRef<any>();
  const { t, i18n } = useTranslation();
  const [field, meta, helper] = useField(name);
  const { submitCount } = useFormikContext();

  if (!props.placeholder) {
    props.placeholder = t('label.' + name);
  }

  const isError = submitCount > 0 && !!meta.error;

  const setOptionsUnique = (items: ReactSelectOption[]) => {
    setOptions((prevState) => {
      return [...(prevState || []), ...items].filter(
        (item, index, self) => index === self.findIndex((t) => t.value === item.value),
      );
    });
  };

  useEffect(() => {
    let loadWith: Array<string> = [];
    if (Array.isArray(field.value)) {
      loadWith = field.value;
    } else if (field.value) {
      loadWith = [field.value];
    }

    loadOptions(undefined, loadWith).then((items) => {
      setOptionsUnique(items);
    });

    if (meta.touched) {
      helper.setTouched(true, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language, loadOptions]);

  useEffect(() => {
    if (!options) return;

    if (field.value === null || field.value === undefined) {
      setSelectValue(null);
      return;
    }

    if (Array.isArray(field.value)) {
      setSelectValue(options.filter((item) => field.value.includes(item.value)));
    } else {
      setSelectValue(options.find((item) => item.value === field.value));
    }
  }, [field.value, options]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedEventHandler = useCallback(
    debounce((inputValue: string, callback: any) => {
      loadOptions(inputValue).then((items) => {
        callback(items);
        setOptionsUnique(items);
      });
    }, 300),
    [loadOptions],
  );

  return (
    <>
      <div className={`${isError ? 'is-invalid' : ''}`}>
        <label htmlFor={field.name}>{props.placeholder}</label>
        <AsyncSelect
          ref={innerRef}
          styles={{
            menu: (provided) => ({
              ...provided,
              zIndex: '1000',
            }),
          }}
          name={name}
          defaultOptions={options}
          loadOptions={debouncedEventHandler}
          onBlur={field.onBlur}
          value={selectValue}
          isLoading={options.length === 0}
          onChange={(
            option: ReactSelectOption | ReactSelectOption[],
            actionMeta: ActionMeta<ReactSelectOption>,
          ) => {
            if (Array.isArray(option)) {
              helper.setValue(option.map((option) => option.value));
            } else {
              helper.setValue(option?.value);
            }

            onChange && onChange(option, actionMeta);
          }}
          {...props}
        />
      </div>
      {isError && <div className={isError ? 'invalid-feedback' : ''}>{meta.error}</div>}
    </>
  );
};

export default SelectAsyncInput;
