import React, { useState } from 'react';
import { StateManagerProps } from 'react-select/dist/declarations/src/stateManager';
import { RETypeEnum } from 'src/helpers/Enums/RC/RETypeEnum';
import { useTranslation } from 'react-i18next';
import { ReactSelectOption } from 'src/components/Form/Select/SelectInput';
import { SelectInput } from 'src/components/Form/Select';

interface Props extends StateManagerProps {
  name: string;
  placeholder: string;
}

const RETypes: Array<string> = Object.values(RETypeEnum);

const RETypesInput: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const [options] = useState<ReactSelectOption[]>([
    ...RETypes.map((type) => ({
      label: t('rc.re_type.' + type),
      value: type,
    })),
  ]);

  return <SelectInput {...props} options={options} isMulti={true} />;
};

export default RETypesInput;
