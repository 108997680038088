import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Col, FormGroup, Row } from 'reactstrap';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import Api from 'src/api';
import useReportTypeStore from 'src/pages/Reports/View/ReportTypeStore';
import usePermissions from 'src/helpers/usePermissions';
import { ReactSelectOption } from 'src/components/Form/Select/SelectInput';
import { mapManagePermission, ReportTypeEnum } from 'src/helpers/Enums/ReportTypeEnum';
import { success } from 'src/services/toastr';
import TextInput from 'src/components/Form/TextInput';
import SaveButton from 'src/components/Form/SaveButton';
import DatePickerInput from 'src/components/Form/DatePickerInput';
import { SelectInput } from 'src/components/Form/Select';
import { GenerateReportRequestDto, InvestmentReportFilterForm } from 'src/types/api/reports';
import { appendDateFilter, appendFilter, initialRequest } from 'src/pages/Reports/View/helpers';
import SelectColumnsInput from 'src/pages/Reports/View/Filters/Selects/SelectColumnsInput';
import { ProjectInvestmentTypeEnum } from 'src/helpers/Enums/ProjectInvestmentTypeEnum';
import CheckboxInput from 'src/components/Form/CheckboxInput';
import { UserFinancialStatusEnum } from 'src/helpers/Enums/UserFinancialStatusEnum';
import { ProjectInvestmentStatusEnum } from 'src/helpers/Enums/ProjectInvestmentStatusEnum';

const AVAILABLE_COLUMNS: string[] = [
  'investor_pid',
  'investor_general_type',
  'investment_provider',
  'investor_name',
  'investor_legal_code',
  'investor_city',
  'investor_residence',
  'investor_wallet_status',
  'personal_income_tax',
  'basic_interest',
  'profitus_bonus_interest',
  'project_bonus_interest',
  'xirr',
  'calculated_status',
  'bonus_type',
  'bonus_codes',
  'bonus_codes_amounts',
  'investor_registered_at',
  'amount',
  'project_pid',
  'project_name',
  'project_status',
  'type',
  'invested_at',
  'sellable',
  'user_roles',
  'financial_status',
  'email',
  'survey_via_email',
  'invested_project_news_via_email',
  'educational_via_email',
  'promotional_via_email',
  'newsletter_via_email',
  'survey_via_phone',
  'promotional_via_phone',
  'marketing_via_sms',
];

const TYPE: ReportTypeEnum = ReportTypeEnum.INVESTMENTS;

const InvestmentReportFilter: React.FC = () => {
  const { t } = useTranslation();
  const p = usePermissions();
  const { refreshTable } = useReportTypeStore();

  const [request] = useState<InvestmentReportFilterForm>({
    name: null,
    columns: AVAILABLE_COLUMNS.slice(0, -9),
    invested_at_from: null,
    invested_at_to: null,
    project_pid: '',
    investor_pid: '',
    type: null,
    financial_status: null,
    project_investment_status: null,
    only_employees_related_persons: null,
  });

  const Schema = Yup.object().shape({
    invested_at_from: Yup.date().nullable(),
    invested_at_to: Yup.date().when(
      'invested_at_from',
      (invested_at_from: Date, schema: Yup.DateSchema) => {
        return invested_at_from
          ? schema.min(invested_at_from, t('label.invested_at_from'))
          : schema.nullable();
      },
    ),
  });

  const mapData = (form: InvestmentReportFilterForm): GenerateReportRequestDto => {
    const request = initialRequest(form, TYPE);

    appendDateFilter(request, 'invested_at', form.invested_at_from, form.invested_at_to);
    appendFilter(request, 'project_pid', form.project_pid);
    appendFilter(request, 'investor_pid', form.investor_pid);
    appendFilter(request, 'type', form.type);
    appendFilter(request, 'financial_status', form.financial_status);
    appendFilter(request, 'project_investment_status', form.project_investment_status);
    appendFilter(request, 'only_employees_related_persons', form.only_employees_related_persons);

    return request;
  };

  const [projectInvestmentStatusOptions] = useState<ReactSelectOption[]>([
    {
      value: '',
      label: t('common.all'),
    },
    ...Object.values(ProjectInvestmentStatusEnum).map((type) => ({
      value: type,
      label: t('reports.project_investment_status.' + type),
    })),
  ]);

  const onSubmit = useCallback(
    async (
      request: InvestmentReportFilterForm,
      helper: FormikHelpers<InvestmentReportFilterForm>,
    ) => {
      try {
        await Api.reports.generateReport(mapData(request)).then(() => {
          refreshTable();
          success(t('common.success'));
        });
      } catch (e: any) {
        helper.setErrors(e.response?.errors);
      }
    },
    [refreshTable, t],
  );

  const [investmentTypeOptions] = useState<ReactSelectOption[]>([
    {
      value: '',
      label: t('common.all'),
    },
    {
      value: ProjectInvestmentTypeEnum.MANUAL,
      label: t('common.manual'),
    },
    {
      value: ProjectInvestmentTypeEnum.AUTO,
      label: t('common.auto'),
    },
  ]);

  const [financialStatusOptions] = useState<ReactSelectOption[]>([
    {
      value: '',
      label: t('common.all'),
    },
    {
      value: UserFinancialStatusEnum.RETAIL,
      label: t('reports.financial_status.retail'),
    },
    {
      value: UserFinancialStatusEnum.HNW,
      label: t('reports.financial_status.hnw'),
    },
  ]);

  if (!p.hasAll([mapManagePermission(TYPE)])) return null;

  return (
    <div className={'mb-4'}>
      <Card>
        <CardBody>
          <h4 className={'mb-4'}>{t('reports.filter.' + TYPE)}</h4>
          <div className={'mb-4'}>
            <Formik
              initialValues={request}
              onSubmit={onSubmit}
              enableReinitialize={true}
              validationSchema={Schema}
              validateOnChange={true}
            >
              {({ handleSubmit, isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <FormGroup>
                    <Row>
                      <Col sm={2} className={'mb-4'}>
                        <TextInput name={'name'} />
                      </Col>
                      <Col sm={1} className={'mb-4'}>
                        <SelectColumnsInput columns={AVAILABLE_COLUMNS} />
                      </Col>
                      <Col sm={2} className={'mb-4'}>
                        <DatePickerInput name={'invested_at_from'} showTimeSelect />
                      </Col>
                      <Col sm={2} className={'mb-4'}>
                        <DatePickerInput name={'invested_at_to'} showTimeSelect />
                      </Col>
                      <Col sm={2} className={'mb-4'}>
                        <TextInput name={'project_pid'} />
                      </Col>
                      <Col sm={3} className={'mb-4'}>
                        <TextInput name={'investor_pid'} />
                      </Col>
                      <Col sm={2} className={'mb-4'}>
                        <SelectInput name={'type'} options={investmentTypeOptions} />
                      </Col>
                      <Col sm={2} className={'mb-4'}>
                        <SelectInput name={'financial_status'} options={financialStatusOptions} />
                      </Col>
                      <Col sm={2} className={'mb-4'}>
                        <SelectInput
                          name={'project_investment_status'}
                          options={projectInvestmentStatusOptions}
                        />
                      </Col>
                      <Col sm={2} className={'mt-4'}>
                        <CheckboxInput name={'only_employees_related_persons'} />
                      </Col>
                    </Row>
                    <div className={'mb-4 mt-3'}>
                      <SaveButton title={t('common.generate')} submitting={isSubmitting} />
                    </div>
                  </FormGroup>
                </Form>
              )}
            </Formik>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default InvestmentReportFilter;
