import React, { useState } from 'react';
import { StateManagerProps } from 'react-select/dist/declarations/src/stateManager';
import { useTranslation } from 'react-i18next';
import { ReactSelectOption } from 'src/components/Form/Select/SelectInput';
import { SelectInput } from 'src/components/Form/Select';
import { DTBTypeEnum } from 'src/helpers/Enums/RC/DTBEnum';

interface Props extends StateManagerProps {
  name: string;
  placeholder: string;
}

const SelectBasisOfProvidingDataInput: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const [options] = useState<ReactSelectOption[]>([
    {
      label: t('rc.address'),
      value: DTBTypeEnum.ADRESAS,
    },
    {
      label: t('rc.aob_code'),
      value: DTBTypeEnum.AOB_KODAS,
    },
    {
      label: t('rc.obj_code'),
      value: DTBTypeEnum.OBJ_KODAS,
    },
  ]);

  return <SelectInput {...props} options={options} />;
};

export default SelectBasisOfProvidingDataInput;
