import { BaseFilterForm } from 'src/types/api/RC';
import { GenerateRcRequestDto } from 'src/types/api/RC';
import { RCTypeEnum } from 'src/helpers/Enums/RC/RCTypeEnum';

export const initialRequest = (request: BaseFilterForm, type: RCTypeEnum): GenerateRcRequestDto => {
  return {
    type: type,
    name: request.name,
    filters: [],
  };
};

export const appendFilter = (
  request: GenerateRcRequestDto,
  id: string,
  value: string | object | boolean | null | undefined,
): void => {
  if (value !== null && value !== undefined && value !== '') {
    request.filters.push({
      id: id,
      value: value,
    });
  }
};

export const appendDateFilter = (
  request: GenerateRcRequestDto,
  id: string,
  from: Date | null | undefined,
  to: Date | null | undefined,
): void => {
  if (from || to) {
    appendFilter(request, id, {
      startDate: from,
      endDate: to,
    });
  }
};
