import { useQueryClient } from 'react-query';
import Api, { Http } from 'src/api';
import { AuthUtils } from 'src/services/useAuth/Auth.utils';
import { isAuthJwtObject } from 'src/services/useAuth/Auth.guards';
import { AuthJwtObject, jwtStorageKey } from 'src/services/useAuth/Auth.types';
import { queries, useProfileQuery } from 'src/api/queries';
import { navigate } from 'src/helpers/navigate';
import usePermissions from 'src/helpers/usePermissions';
import { RefreshTokenResponseDto } from 'src/types/api/currentUser';
import useAppState from 'src/modules/appState';
import * as H from 'history';

const useAuth = () => {
  const { setAppStarted, resetInitialRoute } = useAppState();
  const { setPermissions, resetPermissions } = usePermissions();

  const queryClient = useQueryClient();

  const query = useProfileQuery({ enabled: false });

  const isLoaded = query.isSuccess || query.isError,
    isLoading = query.isLoading;

  const startSession = async (jwt?: string | AuthJwtObject, permissions?: string[]) => {
    const token = !jwt ? AuthUtils.getJwt() : isAuthJwtObject(jwt) ? jwt : AuthUtils.make(jwt);

    Http.setBearer(token.token);
    AuthUtils.setJwt(token.token);

    if (permissions === undefined) {
      try {
        permissions = (await Api.currentUser.fetchPermissions()).permissions;
      } catch (e) {
        return endSession();
      }
    }

    setPermissions(permissions);

    const { isError, error } = await query.refetch();

    if (isError) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      throw Error(`Failed to fetch profile: ${error}`);
    }
  };

  const endSession = async () => {
    setAppStarted(false);

    try {
      await Promise.all(
        Object.values(queries).map(async (queryKey) => {
          await queryClient.cancelQueries({ queryKey: [queryKey] });
          queryClient.removeQueries({ queryKey: [queryKey] });
        }),
      );

      Http.removeBearer();
      AuthUtils.removeJwt();
      resetPermissions();
      resetInitialRoute();
    } finally {
      setAppStarted(true);
    }
  };

  const login = async (
    access_token: string,
    permissions: string[],
    redirect_url: string | H.Location | null = null,
  ): Promise<void> => {
    setAppStarted(false);

    try {
      await startSession(access_token, permissions);

      navigate(redirect_url ?? '/dashboard');
    } finally {
      setAppStarted(true);
    }
  };

  const logout = async (logout: () => Promise<void>): Promise<void> => {
    logout();

    await endSession();
  };

  const refresh = async (promise: () => Promise<RefreshTokenResponseDto>) => {
    try {
      // No token
      if (AuthUtils.getPayload() === null) {
        return;
      }

      if (AuthUtils.isExpired()) {
        await endSession();

        return;
      }

      Http.setBearer(AuthUtils.getJwt().token);
      const response = await promise();

      await startSession(response.access_token, response.permissions);
    } finally {
      setAppStarted(true);
    }
  };

  const handleStorageTokenChange = async (e: StorageEvent): Promise<void> => {
    if (e.key !== jwtStorageKey) {
      return;
    }

    if (e.oldValue && !e.newValue) {
      // Means user has logged out
      await endSession();

      navigate('/login');
      return;
    }

    const newJwt = AuthUtils.make(e.newValue ?? '');

    if (!isAuthJwtObject(newJwt)) {
      await endSession();

      navigate('/login');

      return;
    }

    if (!e.oldValue) {
      // Means user has logged in
      await startSession();
      navigate('/dashboard');
      return;
    }

    if (e.oldValue && e.newValue) {
      // Means token has been refreshed, probably
      const oldJwt = AuthUtils.make(e.oldValue);
      if (
        isAuthJwtObject(oldJwt) &&
        !AuthUtils.isExpired(newJwt) &&
        oldJwt.token !== newJwt.token
      ) {
        await startSession(newJwt);
      }
    }
  };

  const mount = () => {
    window.addEventListener('storage', handleStorageTokenChange);
  };

  const dismount = () => {
    window.removeEventListener('storage', handleStorageTokenChange);
  };

  return {
    isLoaded,
    isLoading,
    // isInitialLoading,
    login,
    logout,
    refresh,
    startSession,
    endSession,
    mount,
    dismount,
  };
};
export { useAuth };
