import React from 'react';
import { Container } from 'reactstrap';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import InvestmentsDashboardCharts from 'src/pages/Common/Dashboard/Investments/InvestmentsDashboardCharts';

const InvestmentsInfo: React.FC<RouteComponentProps> = () => {
  return (
    <React.Fragment>
      <div>
        <Container fluid={true}>
          <InvestmentsDashboardCharts />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(InvestmentsInfo);
