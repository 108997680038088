import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactJson from 'react-json-view';

interface Props {
  traits: any;
}

const MatchesTraitsTable = ({ traits }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <table className={'w-100 table'}>
        <thead>
          <tr>
            <th>{t('table.match_key')}</th>
            <th>{t('table.match_values')}</th>
          </tr>
          {Object.keys(traits).map((key, index) => {
            const trait = traits[key];
            if (typeof trait === 'string') {
              return (
                <tr key={index}>
                  <td>{key}</td>
                  <td>{trait}</td>
                </tr>
              );
            }
          })}
        </thead>
        <tbody></tbody>
      </table>
      <hr />
      <span>{t('common.more_information')}</span>
      <ReactJson
        displayDataTypes={false}
        collapseStringsAfterLength={20}
        collapsed={true}
        enableClipboard={true}
        src={traits}
      />
    </>
  );
};

export default MatchesTraitsTable;
