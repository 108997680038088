import React from 'react';
import {
  Column,
  useExpanded,
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import { Table as BoostrapTable } from 'reactstrap';
import SortOrder from './Table/SortOrder';
import { useTranslation } from 'react-i18next';

interface Props {
  columns: Column<any>[];
  data: any | undefined;
  id?: any;
}

const Table: React.FC<Props> = ({ columns, data, id }) => {
  const { t } = useTranslation();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    visibleColumns,
    footerGroups,
  } = useTable(
    {
      columns,
      data: data ?? [],
      manualPagination: true,
      manualFilters: true,
      manualSortBy: true,
      manualGlobalFilter: true,
      defaultCanSort: false,
      autoResetPage: false,
      autoResetResize: false,
      autoResetExpanded: false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
  );

  return (
    <>
      <BoostrapTable responsive striped bordered hover {...getTableProps()} id={id}>
        <thead>
          {headerGroups.map((headerGroup, i) => {
            return (
              <tr {...headerGroup.getHeaderGroupProps()} key={i}>
                {headerGroup.headers.map((column, i) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())} key={i}>
                    <div className={'d-flex'}>
                      <span className={'me-auto'}>{column.render('Header')}</span>
                      <span>
                        {column.isSorted && (
                          <SortOrder type={column.sortType} isDesc={column.isSortedDesc} />
                        )}
                      </span>
                    </div>
                    <div>{column.Filter ? column.render('Filter') : null}</div>
                  </th>
                ))}
              </tr>
            );
          })}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.length === 0 && (
            <>
              <tr>
                <td className={'text-center'} colSpan={visibleColumns.length}>
                  {t('table.no_data')}
                </td>
              </tr>
            </>
          )}
          {rows.length !== 0 &&
            rows.map((row, i) => {
              prepareRow(row);
              return (
                <React.Fragment key={i}>
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell, i) => {
                      return (
                        <td {...cell.getCellProps()} key={i}>
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                </React.Fragment>
              );
            })}
        </tbody>
        <tfoot>
          {footerGroups.map((footerGroup, i) => {
            return (
              <tr {...footerGroup.getFooterGroupProps()} key={i}>
                {footerGroup.headers.map((column, i) => (
                  <th {...column.getFooterProps(column.getSortByToggleProps())} key={i}>
                    <div className={'d-flex'}>
                      <span className={'me-auto'}>{column.render('Footer')}</span>
                    </div>
                  </th>
                ))}
              </tr>
            );
          })}
        </tfoot>
      </BoostrapTable>
    </>
  );
};

export default Table;
