import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProjectContextState, withProject } from 'src/pages/Project/Update/ProjectContext';
import { Form, Formik, FormikHelpers } from 'formik';
import { Col, ColProps, FormGroup, Row } from 'reactstrap';
import { UpdateProjectInformationRequestDto } from 'src/types/api/projects';
import TextInput from 'src/components/Form/TextInput';
import TextAreaInput from 'src/components/Form/TextAreaInput';
import WYSIWYGInput from 'src/components/Form/WYSIWYGInput';
import Api from 'src/api';
import { success } from 'src/services/toastr';
import { transformErrors } from 'src/helpers';
import SaveButton from 'src/components/Form/SaveButton';
import ActiveLocaleFlag from 'src/components/ActiveLocaleFlag';
import { UseNumbers } from 'src/helpers/useNumbers';
import SelectCountryInput from '../../../../components/Form/Select/SelectCountryInput';
import DatePickerInput from 'src/components/Form/DatePickerInput';

const INITIAL_REQUEST: UpdateProjectInformationRequestDto = {
  area_of_object: null,
  end_construction_at: null,
  number_of_re: null,
  object_energy_class: null,
  project_place_description: null,
  start_construction_at: null,
  project_name: null,
  project_address: null,
  country: null,
  project_description: null,
  stageable_until: null,
};

const TabProject: React.FC<ProjectContextState> = ({
  project,
  setProject,
  language,
  onErrorHandling,
}) => {
  const { t } = useTranslation();

  const [request, setRequest] = useState<UpdateProjectInformationRequestDto>(INITIAL_REQUEST);

  useEffect(() => {
    if (!project) return;

    setRequest(() => ({
      project_name: project.project_name,
      country: project.country,
      project_address: project.project_address,
      project_description: project.project_description,
      area_of_object: project.area_of_object,
      end_construction_at: project.end_construction_at,
      number_of_re: project.number_of_re,
      object_energy_class: project.object_energy_class,
      project_place_description: project.project_place_description,
      start_construction_at: project.start_construction_at,
      stageable_until: project.stageable_until,
    }));
  }, [project]);

  const onSubmit = useCallback(
    async (
      request: UpdateProjectInformationRequestDto,
      helpers: FormikHelpers<UpdateProjectInformationRequestDto>,
    ) => {
      if (!project) return;

      try {
        const response = await Api.projects.storeProjectInformation(project.id, request);
        setProject(response);
        success(t('common.updated_success'));
      } catch (e: any) {
        helpers.setErrors(transformErrors(e.response?.errors));
        onErrorHandling(e);
      }

      return true;
    },
    [onErrorHandling, project, setProject, t],
  );

  const colProps: ColProps = {
    md: 4,
    className: 'mb-4',
  };

  // Method used to generate common attributes by input name
  const multiLocaleAttrs = useCallback(
    (inputName: string) => {
      return {
        name: inputName + '.' + language,
        placeholder: t('label.' + inputName),
      };
    },
    [language, t],
  );

  return (
    <React.Fragment>
      <h3 className={'flex'}>
        {t('projects.project')}
        <ActiveLocaleFlag locale={language} className={'tabs'} />
      </h3>
      <hr />
      <Formik initialValues={request} enableReinitialize={true} onSubmit={onSubmit}>
        {({ handleSubmit, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Row>
                <Col {...colProps}>
                  <TextInput {...multiLocaleAttrs('project_name')} />
                </Col>
                <Col {...colProps}>
                  <TextAreaInput {...multiLocaleAttrs('project_address')} />
                </Col>
                <Col {...colProps}>
                  <SelectCountryInput name={'country'} className={'z-100'} />
                </Col>
                <Col {...colProps} md={12}>
                  <WYSIWYGInput {...multiLocaleAttrs('project_description')} />
                </Col>
                <Col {...colProps}>
                  <TextInput
                    type={'number'}
                    step={'any'}
                    name={'number_of_re'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                  />
                </Col>
                <Col {...colProps}>
                  <TextInput {...multiLocaleAttrs('area_of_object')} />
                </Col>
                <Col {...colProps}>
                  <TextInput name={'object_energy_class'} />
                </Col>
                <Col {...colProps}>
                  <TextInput name={'start_construction_at'} />
                </Col>
                <Col {...colProps}>
                  <TextInput name={'end_construction_at'} />
                </Col>
                <Col {...colProps}>
                  <DatePickerInput name={'stageable_until'} matchFieldValueAndDisplayValue={true} />
                </Col>
                <Col {...colProps} md={12}>
                  <TextAreaInput {...multiLocaleAttrs('project_place_description')} />
                </Col>
                <div className={'mt-4 mb-4'}>
                  <SaveButton title={t('common.save')} submitting={isSubmitting} />
                </div>
              </Row>
            </FormGroup>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default withProject(TabProject);
