import Client from './client';
import { PaginationDataFilter } from 'src/types';
import {
  DashboardHNWChartResponseDto,
  DashboardPageGenericGraphResponseDto,
  DashboardPageInvestorsCategoriesInformationDto,
  DashboardPageResponseDto,
  DashboardPageWalletInformationDto,
  DashboardPortfolioInformationDto,
  DashboardPortfolioWeightedRatingRequestDto,
  DashboardWalletInfoWalletInformationDto,
  GeneralMarketingDashboardPageResponseDto,
  MarketingDashboardPageResponseDto,
} from 'src/types/api/dashboard';

class Dashboard extends Client {
  api = {
    FETCH_DASHBOARD_CURRENT_MONTH: `${this.http.baseUrl}/dashboard/current-month`,
    FETCH_DASHBOARD_LAST_MONTH: `${this.http.baseUrl}/dashboard/last-month`,
    FETCH_MARKETING_DASHBOARD_CURRENT_MONTH: `${this.http.baseUrl}/dashboard/marketing/current-month`,
    FETCH_MARKETING_DASHBOARD_LAST_MONTH: `${this.http.baseUrl}/dashboard/marketing/last-month`,
    FETCH_MARKETING_DASHBOARD_GENERAL: `${this.http.baseUrl}/dashboard/marketing/general`,
    FETCH_MARKETING_DASHBOARD_HISTORICAL: `${this.http.baseUrl}/dashboard/marketing/historical`,
    FETCH_DASHBOARD_ALL: `${this.http.baseUrl}/dashboard/all`,
    FETCH_REGISTRATION_BAR_CHART: `${this.http.baseUrl}/dashboard/chart/registrations`,
    FETCH_INVESTORS_BAR_CHART: `${this.http.baseUrl}/dashboard/chart/investors`,
    FETCH_COLLECTED_MONEY_BAR_CHART: `${this.http.baseUrl}/dashboard/chart/collected-money`,
    FETCH_PAID_INTEREST_BAR_CHART: `${this.http.baseUrl}/dashboard/chart/paid-interest`,
    FETCH_WALLET_SUMS_BY_DAY_CHART: `${this.http.baseUrl}/dashboard/chart/wallet-sums-by-day`,
    FETCH_PROJECTS_WALLET_SUMS_BY_DAY_CHART: `${this.http.baseUrl}/dashboard/chart/projects-wallet-sums-by-day`,
    FETCH_WALLET_INFORMATION: `${this.http.baseUrl}/dashboard/wallets`,
    FETCH_INVESTORS_CATEGORIES_INFORMATION: `${this.http.baseUrl}/dashboard/investors-categories`,
    FETCH_PORTFOLIO_INFORMATION: `${this.http.baseUrl}/dashboard/portfolio`,
    FETCH_PORTFOLIO_WEIGHTED_RATING_BAR_CHART: `${this.http.baseUrl}/dashboard/portfolio/weighted-rating`,
    EXPORT_PORTFOLIO_WEIGHTED_RATING_AS_XLSX: `${this.http.baseUrl}/dashboard/portfolio/weighted-rating/export/xlsx`,
    FETCH_RICHEST_WALLETS: `${this.http.baseUrl}/dashboard/richest-wallets`,
    FETCH_HIGH_NET_WORTH_BAR_CHART: `${this.http.baseUrl}/dashboard/investments/chart/high_net_worth_bar`,
    FETCH_HIGH_NET_WORTH_PIE_CHART: `${this.http.baseUrl}/dashboard/investments/chart/high_net_worth_pie`,
  };

  fetchDashboardCurrentMonth = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<DashboardPageResponseDto> => {
    return this.http.get(this.api.FETCH_DASHBOARD_CURRENT_MONTH, { params: request });
  };

  fetchDashboardLastMonth = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<DashboardPageResponseDto> => {
    return this.http.get(this.api.FETCH_DASHBOARD_LAST_MONTH, { params: request });
  };

  fetchMarketingDashboardLastMonth = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<MarketingDashboardPageResponseDto> => {
    return this.http.get(this.api.FETCH_MARKETING_DASHBOARD_LAST_MONTH, { params: request });
  };

  fetchMarketingDashboardCurrentMonth = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<MarketingDashboardPageResponseDto> => {
    return this.http.get(this.api.FETCH_MARKETING_DASHBOARD_CURRENT_MONTH, { params: request });
  };

  fetchMarketingDashboardGeneral = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<GeneralMarketingDashboardPageResponseDto> => {
    return this.http.get(this.api.FETCH_MARKETING_DASHBOARD_GENERAL, { params: request });
  };

  fetchMarketingDashboardHistorical = (
    start_date: string,
    end_date: string,
  ): Promise<MarketingDashboardPageResponseDto> => {
    const request = {
      start_date,
      end_date,
    };
    return this.http.get(this.api.FETCH_MARKETING_DASHBOARD_HISTORICAL, { params: request });
  };

  fetchDashboardAllMonth = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<DashboardPageResponseDto> => {
    return this.http.get(this.api.FETCH_DASHBOARD_ALL, { params: request });
  };

  fetchRegistrationsBarChartData = (
    start_date: string,
    end_date: string,
  ): Promise<DashboardPageGenericGraphResponseDto> => {
    const request = {
      start_date,
      end_date,
    };
    return this.http.get(this.api.FETCH_REGISTRATION_BAR_CHART, { params: request });
  };

  fetchInvestorsBarChartData = (
    start_date: string,
    end_date: string,
  ): Promise<DashboardPageGenericGraphResponseDto> => {
    const request = {
      start_date,
      end_date,
    };
    return this.http.get(this.api.FETCH_INVESTORS_BAR_CHART, { params: request });
  };

  fetchCollectedMoneyBarChartData = (
    start_date: string,
    end_date: string,
  ): Promise<DashboardPageGenericGraphResponseDto> => {
    const request = {
      start_date,
      end_date,
    };
    return this.http.get(this.api.FETCH_COLLECTED_MONEY_BAR_CHART, { params: request });
  };

  fetchPaidInterestBarChartData = (
    start_date: string,
    end_date: string,
  ): Promise<DashboardPageGenericGraphResponseDto> => {
    const request = {
      start_date,
      end_date,
    };
    return this.http.get(this.api.FETCH_PAID_INTEREST_BAR_CHART, { params: request });
  };

  fetchWalletSumsByDayChartData = (
    start_date: string,
    end_date: string,
  ): Promise<DashboardPageGenericGraphResponseDto> => {
    const request = {
      start_date,
      end_date,
    };
    return this.http.get(this.api.FETCH_WALLET_SUMS_BY_DAY_CHART, { params: request });
  };

  fetchProjectsWalletSumsByDayChartData = (
    start_date: string,
    end_date: string,
  ): Promise<DashboardPageGenericGraphResponseDto> => {
    const request = {
      start_date,
      end_date,
    };
    return this.http.get(this.api.FETCH_PROJECTS_WALLET_SUMS_BY_DAY_CHART, { params: request });
  };

  fetchWalletInformation = (): Promise<DashboardPageWalletInformationDto> => {
    return this.http.get(this.api.FETCH_WALLET_INFORMATION);
  };

  fetchPortfolioInformation = (
    start_date: string | null,
    end_date: string,
  ): Promise<DashboardPortfolioInformationDto> => {
    const request = {
      start_date,
      end_date,
    };
    return this.http.get(this.api.FETCH_PORTFOLIO_INFORMATION, { params: request });
  };

  fetchRichestWallets = (count = 20): Promise<DashboardWalletInfoWalletInformationDto[]> => {
    const request = {
      count,
    };
    return this.http.get(this.api.FETCH_RICHEST_WALLETS, { params: request });
  };

  fetchInvestorsCategoriesInformation =
    (): Promise<DashboardPageInvestorsCategoriesInformationDto> => {
      return this.http.get(this.api.FETCH_INVESTORS_CATEGORIES_INFORMATION);
    };

  fetchHighNetWorthBarChartData = (
    start_date: string,
    end_date: string,
  ): Promise<DashboardHNWChartResponseDto> => {
    const request = {
      start_date,
      end_date,
    };
    return this.http.get(this.api.FETCH_HIGH_NET_WORTH_BAR_CHART, { params: request });
  };

  fetchHighNetWorthPieChartData = (
    start_date: string,
    end_date: string,
  ): Promise<DashboardPageGenericGraphResponseDto> => {
    const request = {
      start_date,
      end_date,
    };
    return this.http.get(this.api.FETCH_HIGH_NET_WORTH_PIE_CHART, { params: request });
  };

  fetchWeightedRatingBarChartData = (
    request: DashboardPortfolioWeightedRatingRequestDto,
  ): Promise<DashboardPageGenericGraphResponseDto> => {
    return this.http.get(this.api.FETCH_PORTFOLIO_WEIGHTED_RATING_BAR_CHART, { params: request });
  };

  exportWeightedRatingsAsXlsx = (
    request: DashboardPortfolioWeightedRatingRequestDto,
  ): Promise<any> => {
    return this.http.get(this.api.EXPORT_PORTFOLIO_WEIGHTED_RATING_AS_XLSX, {
      params: request,
      responseType: 'blob',
    });
  };
}

export default Dashboard;
