import React, { useCallback, useMemo, useState } from 'react';
import { Cell } from 'react-table';
import { useTranslation } from 'react-i18next';
import { PaginationData, PaginationDataFilter } from 'src/types';
import Api from 'src/api';
import { ModelField } from 'src/components/Table/Fields/ModelField';
import PrimaryButton from 'src/components/Form/PrimaryButton';
import Table from 'src/components/Table';
import { ResidenceCountryResponseDto, ResidenceCountryStatus } from 'src/types/api/amlManagement';
import { success } from 'src/services/toastr';

const INITIAL_SORT_ORDER = [
  {
    id: 'status',
    desc: true,
  },
];

const ResidenceCountryTable = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<PaginationData<ResidenceCountryResponseDto>>();

  const fetchData = useCallback(
    async (request: PaginationDataFilter | undefined) => {
      return Api.amlManagement.fetchResidenceCountry(request).then((response) => setData(response));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleUpdatedResident = useCallback(
    (updatedIssue: ResidenceCountryResponseDto) => {
      if (data) {
        const clonedData = { ...data };

        const residentToBeUpdated = clonedData.data.findIndex(
          (item) => item.id === updatedIssue.id,
        );

        if (residentToBeUpdated !== -1) {
          clonedData.data[residentToBeUpdated] = updatedIssue;

          setData(clonedData);
        }
      }
    },
    [data],
  );

  const columns = useMemo(() => {
    const approveAccount = (id: string) => {
      return Api.amlManagement.approveAccount(id).then((res) => {
        handleUpdatedResident(res);
        success(t('aml.residence_country.approved'));
      });
    };

    const declineAccount = (id: string) => {
      return Api.amlManagement.declineAccount(id).then((res) => {
        handleUpdatedResident(res);
        success(t('aml.residence_country.declined'));
      });
    };

    return [
      {
        Header: t('table.id'),
        accessor: 'id',
        sortType: 'number',
        width: 100,
      },
      {
        Header: t('table.registered_at'),
        accessor: 'created_at',
        width: 125,
        Cell: (cell: Cell<ResidenceCountryResponseDto>) => (
          <div>{t('common.date_full', { date: cell.value })}</div>
        ),
      },
      {
        Header: t('table.account_id'),
        accessor: 'account.id',
        width: 100,
      },
      {
        Header: t('table.account'),
        accessor: 'account',
        Cell: (cell: Cell<ResidenceCountryResponseDto>) => {
          const value = cell.row.original.account;

          return <ModelField model={value} />;
        },
      },
      {
        Header: t('table.residence'),
        accessor: 'residence',
        width: 125,
        Cell: (cell: Cell<ResidenceCountryResponseDto>) => {
          return <div> {t(`countries.${cell.row.original.residence}`)}</div>;
        },
      },
      {
        Header: t('table.status_changed_at'),
        accessor: 'status_changed_at',
        width: 125,
        Cell: (cell: Cell<ResidenceCountryResponseDto>) => (
          <div>{t('common.date_full', { date: cell.value })}</div>
        ),
      },
      {
        Header: t('table.status'),
        accessor: 'status',
        width: 125,
        Cell: (cell: Cell<ResidenceCountryResponseDto>) => {
          return <div>{cell.row.original.status}</div>;
        },
      },
      {
        Header: t('table.approve'),
        accessor: 'approve',
        width: 100,
        Cell: (cell: Cell<ResidenceCountryResponseDto>) => {
          return (
            <PrimaryButton
              className={'btn btn-success col-md-12'}
              title={t('common.approve')}
              disabled={cell.row.original.status === ResidenceCountryStatus.APPROVED}
              onClick={() => approveAccount(cell.row.original.id)}
            />
          );
        },
      },
      {
        Header: t('table.decline'),
        accessor: 'decline',
        width: 100,
        Cell: (cell: Cell<ResidenceCountryResponseDto>) => {
          return (
            <PrimaryButton
              className={'btn btn-danger col-md-12'}
              title={t('common.decline')}
              disabled={cell.row.original.status === ResidenceCountryStatus.DECLINED}
              onClick={() => declineAccount(cell.row.original.id)}
            />
          );
        },
      },
    ];
  }, [handleUpdatedResident, t]);

  return (
    <Table
      title={'AmlRiskLevelChangeLogListIndex'}
      onFetchData={fetchData}
      columns={columns}
      data={data}
      searchable={true}
      enableQueryFilter={true}
      initialSortBy={INITIAL_SORT_ORDER}
      disableFiltersOutsideTable={true}
    />
  );
};

export default ResidenceCountryTable;
