import { LanguageEnum } from 'src/helpers/Enums/LanguageEnum';
import { TranslatableValueDto } from 'src/types/api/common';
import { BaseSchema } from 'yup';
import * as Yup from 'yup';
import { ObjectShape } from 'yup/lib/object';
import i18n from 'i18next';
export { default as useLocalStorage } from './localStorage';
export { default as transformErrors } from './formik';

export const passwordRegExp = RegExp(
  '^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})',
);

export const hasClass = (ele: HTMLElement, cls: string): boolean => {
  const arr = ele.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'));
  return !!(arr && arr.length > 0);
};

export const locales = ['en', 'lt', 'de', 'ee'];

export const formatInputPlaceholder = (
  placeholder: string | null | undefined,
  length = 36,
): string => {
  if (!placeholder) return '-';

  if (!placeholder.includes(' ') && placeholder.length >= length) {
    const trimmedPlaceholder = placeholder.slice(0, length);
    return trimmedPlaceholder + '...';
  }

  return placeholder;
};

export const defaultTranslatableValue = () => {
  return {
    ...Object.values(LanguageEnum).reduce((accumulator, currentValue) => {
      return {
        ...accumulator,
        [currentValue]: '',
      };
    }, {} as TranslatableValueDto<string>),
  };
};

export const translatableValueSchema = (schema: BaseSchema) => {
  return Yup.object().shape({
    ...Object.values(LanguageEnum).reduce((accumulator, currentValue) => {
      return {
        ...accumulator,
        [currentValue]: schema,
      };
    }, {} as ObjectShape),
  });
};

export const getObjectKeys = <T, K extends keyof T>(obj: T): K[] => {
  return Object.keys(obj as any) as K[];
};

export const formatMultipleSelectValues = (
  values: string[] | Array<string> | null,
  otherValue: string | null,
  prefix: string | null = 'placeholder.',
): string => {
  return (
    values
      ?.map((item) =>
        item === 'other'
          ? i18n.t(`${prefix}${item}`) + ' (' + otherValue + ')'
          : i18n.t(`${prefix}${item}`),
      )
      .join('; ') ?? ''
  );
};
