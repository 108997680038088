import React, { useCallback, useEffect, useState } from 'react';
import { Link, generatePath, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { setGlobalLoading } from 'src/modules/app/actions';
import Api from 'src/api';
import ProspectApplicationProvider from './ProspectApplicationContext';
import { ProspectApplicationResponseDto } from 'src/types/api/prospectApplications';
import {
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from 'reactstrap';
import TabData, { BaseTabProps, TabInfo } from 'src/components/Tabs';
import {
  TabApplication,
  TabApplicationDetails,
  TabCompany,
  TabSecurities,
  TabSignatures,
  TabUser,
} from './Tabs';
import { Permissions } from 'src/helpers/auth/permissions';
import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import ChangeProspectApplicationStatusModal from './Modals/ChangeProspectApplicationStatusModal';
import SaveButton from 'src/components/Form/SaveButton';
import { ProspectApplicationStatusEnum } from 'src/helpers/Enums/ProspectApplication/ProspectApplicationStatusEnum';
import { RouteList } from 'src/routes';
import PermissionAction from 'src/components/PermissionAction';
import AssignManagerModal from 'src/components/Modal/Modals/AssignManagerModal';
import { UserCompanyResponseDto } from 'src/types/api/userCompanies';

const Tabs: TabInfo<BaseTabProps>[] = [
  {
    name: 'prospect_applications.user',
    iconClass: 'far fa-file',
    component: TabUser,
    permission: [Permissions.BO__PROSPECT_APPLICATIONS__VIEW],
  },
  {
    name: 'prospect_applications.company',
    iconClass: 'far fa-file',
    component: TabCompany,
    permission: [Permissions.BO__PROSPECT_APPLICATIONS__VIEW],
  },
  {
    name: 'prospect_applications.application',
    iconClass: 'far fa-file',
    component: TabApplication,
    permission: [Permissions.BO__PROSPECT_APPLICATIONS__VIEW],
  },
  {
    name: 'prospect_applications.application_details',
    iconClass: 'far fa-file',
    component: TabApplicationDetails,
    permission: [Permissions.BO__PROSPECT_APPLICATIONS__VIEW],
  },
  {
    name: 'prospect_applications.application_security_deposits',
    iconClass: 'far fa-file',
    component: TabSecurities,
    permission: [Permissions.BO__PROSPECT_APPLICATIONS__VIEW],
  },
  {
    name: 'prospect_applications.signatures',
    iconClass: 'far fa-file',
    component: TabSignatures,
    permission: [Permissions.BO__PROSPECT_APPLICATIONS__VIEW],
  },
];

const ProspectApplication: React.FC = () => {
  const { t } = useTranslation();
  const { showModal } = useGlobalModalContext();
  const { prospectApplicationId } = useParams<{ prospectApplicationId: string }>();
  const [prospectApplication, setProspectApplication] = useState<ProspectApplicationResponseDto>();
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [company, setCompany] = useState<UserCompanyResponseDto>();

  useEffect(() => {
    setGlobalLoading(true);
    if (prospectApplicationId) {
      Api.ProspectApplications.fetchProspectApplication(prospectApplicationId).then((response) => {
        setProspectApplication(response);
        setIsEditable(
          ![
            ProspectApplicationStatusEnum.APPROVED,
            ProspectApplicationStatusEnum.CONVERTED,
          ].includes(response.status),
        );
        setGlobalLoading(false);
      });
    }
  }, [prospectApplicationId]);

  const handleConvertProspectApplication = useCallback(() => {
    if (prospectApplicationId) {
      Api.ProspectApplications.convertProspectApplication(prospectApplicationId).then(
        (response: any) => {
          setProspectApplication(response);
          setIsEditable(
            ![
              ProspectApplicationStatusEnum.APPROVED,
              ProspectApplicationStatusEnum.CONVERTED,
            ].includes(response.status),
          );
        },
      );
    }
  }, [prospectApplicationId]);

  const assignManager = useCallback(
    async (event: any) => {
      event.preventDefault();
      showModal(
        <AssignManagerModal
          currentManagerId={prospectApplication?.manager?.id}
          onAction={async (request) => {
            return Api.ProspectApplications.assignManager(prospectApplicationId, request).then(
              (response) => setProspectApplication(response),
            );
          }}
          title={t('users.assign_manager')}
          body={t('users.assign_manager_modal_body')}
        />,
      );
    },
    [t, showModal],
  );

  const changeStatus = useCallback(() => {
    if (!prospectApplication) {
      return;
    }

    showModal(
      <ChangeProspectApplicationStatusModal
        currentStatus={prospectApplication.status}
        onAction={async (request) => {
          return Api.ProspectApplications.updateProspectApplicationChangeStatus(
            prospectApplication.id,
            request,
          ).then((response) => {
            setProspectApplication(response);
          });
        }}
        title={t('table.change_status')}
      />,
    );
  }, [prospectApplication, showModal, setProspectApplication]);

  if (!prospectApplication) {
    return <></>;
  }

  return (
    <React.Fragment>
      <ProspectApplicationProvider
        prospectApplication={prospectApplication}
        setProspectApplication={setProspectApplication}
        isEditable={isEditable}
        setIsEditable={setIsEditable}
      >
        <Container fluid>
          <Row>
            <Col xs="12" className={'mb-4'}>
              <Card className="card h-100 bg-blue-400 text-light">
                <CardBody>
                  <Row>
                    <Col>
                      <div>{t('label.name')}</div>
                      <div>{prospectApplication.prospect_user?.name}</div>
                    </Col>
                    <Col>
                      <div>{t('label.email')}</div>
                      <div>{prospectApplication.prospect_user?.email}</div>
                    </Col>
                    <Col>
                      <div>{t('label.company_name')}</div>
                      <div>{prospectApplication.prospect_user_company?.name}</div>
                    </Col>
                    <Col>
                      <div>{t('label.company_email')}</div>
                      <div>{prospectApplication.prospect_user_company?.email}</div>
                    </Col>
                    <Col>
                      <div>{t('label.status')}</div>
                      <div>{t('prospect_applications.status.' + prospectApplication.status)}</div>
                    </Col>
                    {prospectApplication?.manager?.name && (
                      <Col className={'flex-center text-light'}>
                        <Row>
                          <div>{t('users.manager_name')}</div>
                          <span>{prospectApplication?.manager?.name}</span>
                        </Row>
                      </Col>
                    )}
                    {prospectApplication.application_id && (
                      <Col>
                        <Link
                          className="btn btn-dark"
                          to={generatePath(RouteList.APPLICATION.VIEW, {
                            applicationId: prospectApplication.application_id,
                          })}
                        >
                          {t('prospect_applications.view_application')}
                        </Link>
                      </Col>
                    )}
                    {prospectApplication.status === ProspectApplicationStatusEnum.APPROVED && (
                      <Col>
                        <SaveButton
                          title={t('prospect_applications.convert')}
                          onClick={handleConvertProspectApplication}
                        />
                      </Col>
                    )}
                    <Col>
                      <UncontrolledDropdown>
                        <DropdownToggle tag="a" className="cursor text-light font-size-16" caret>
                          <i className="fas fa-ellipsis-h" />
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end">
                          <DropdownItem onClick={changeStatus}>
                            {t('prospect_applications.change_status')}
                          </DropdownItem>
                          <DropdownItem href="#" onClick={(e) => assignManager(e)}>
                            {t('users.assign_manager')}
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs="12" className={'mb-4'}>
              <Card>
                <CardBody>
                  {prospectApplication && <TabData tabs={Tabs} vertical={true} />}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </ProspectApplicationProvider>
    </React.Fragment>
  );
};

export default ProspectApplication;
