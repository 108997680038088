import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Cell, Column } from 'react-table';
import { useParams } from 'react-router-dom';

import Api from 'src/api';
import { PaginationData, PaginationDataFilter } from 'src/types';
import { BaseTabProps } from 'src/components/Tabs';
import Table from 'src/components/Table';
import { HiddenColumnsProvider } from 'src/components/ShowTableColumnsDropdown';
import { UserContextState, withUser } from 'src/pages/User/Update/User/UserContext';
import { UserAmlRiskLevelChangeLogListResponseDto } from 'src/types/api/user';
import UserRiskLevelBadge from 'src/components/Badges/UserRiskLevelBadge';
import ReasonModal from 'src/components/Modal/Modals/ReasonModal';
import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import PrimaryButton from 'src/components/Form/PrimaryButton';
import SidebarDataRow from 'src/components/DataBlocks/SidebarDataRow';
import UpdateRiskLevelModal from 'src/pages/User/Update/User/UpdateRiskLevelModal';
import { Permissions } from 'src/helpers/auth/permissions';
import PermissionAction from 'src/components/PermissionAction';
import { UserCompanyAmlRiskLevelChangeLogListResponseDto } from 'src/types/api/userCompanies';
import UserFrozenStatusBadge from 'src/components/Badges/UserFrozenStatusBadge';
import AmlIssuesTable from 'src/pages/AmlManagement/AmlIssuesTable';
import { LegalEntityClassEnum } from 'src/helpers/Enums/LegalEntityClassEnum';

const INITIAL_SORT_ORDER = [
  {
    id: 'created_at',
    desc: true,
  },
];

interface Props extends UserContextState, BaseTabProps {}

const TabAML: React.FC<Props> = ({ user, setUser }) => {
  const { t } = useTranslation();
  const { userId } = useParams<{ userId: string }>();
  const { showModal } = useGlobalModalContext();
  const [data, setData] = useState<PaginationData<UserAmlRiskLevelChangeLogListResponseDto>>();

  const riskLevelColumns = useMemo<Column<UserAmlRiskLevelChangeLogListResponseDto>[] | any>(
    () => [
      {
        Header: t('table.id'),
        accessor: 'id',
        sortType: 'number',
      },
      {
        Header: t('table.created_at'),
        accessor: 'created_at',
        Cell: (cell: Cell<UserAmlRiskLevelChangeLogListResponseDto>) => (
          <div>{t('common.date_full', { date: cell.value })}</div>
        ),
      },
      {
        Header: t('table.old_risk_level'),
        accessor: 'old_risk_level',
        Cell: (cell: Cell<UserAmlRiskLevelChangeLogListResponseDto>) => {
          return <UserRiskLevelBadge value={cell.value} />;
        },
      },
      {
        Header: t('table.new_risk_level'),
        accessor: 'new_risk_level',
        Cell: (cell: Cell<UserAmlRiskLevelChangeLogListResponseDto>) => {
          return <UserRiskLevelBadge value={cell.value} />;
        },
      },
      {
        Header: t('table.risk_level_trigger'),
        accessor: 'risk_level_trigger',
        width: 400,
        Cell: (cell: Cell<UserCompanyAmlRiskLevelChangeLogListResponseDto>) => {
          const row = cell.row.original;
          return (
            <div>
              {t('users.aml.triggers.' + row.risk_level_trigger, {
                reason: row.manual_change_reason ?? '-',
              })}
            </div>
          );
        },
      },
    ],
    [t],
  );

  const fetchData = useCallback(
    async (request: PaginationDataFilter | undefined) => {
      if (user) {
        return Api.user
          .fetchAmlRiskLevelChangeLog(user.id, request)
          .then((response) => setData(response));
      }
    },
    [user],
  );

  const freezeUser = useCallback(
    async (event: any) => {
      event.preventDefault();
      showModal(
        <ReasonModal
          title={t('users.aml.freeze')}
          body={t('users.aml.freeze.body', { name: user?.email })}
          successMessage={t('users.aml.freeze.success')}
          actionText={t('users.aml.freeze.submit')}
          onAction={async (reason) => {
            return Api.user
              .freezeUserAccount(userId, { reason })
              .then((response) => setUser(response));
          }}
          required={false}
        />,
      );
    },
    [setUser, showModal, t, user?.email, userId],
  );

  const unfreezeUser = useCallback(
    async (event: any) => {
      event.preventDefault();
      showModal(
        <ReasonModal
          title={t('users.aml.unfreeze')}
          body={t('users.aml.unfreeze.body', { name: user?.email })}
          successMessage={t('users.aml.unfreeze.success')}
          actionText={t('users.aml.unfreeze.submit')}
          onAction={async (reason) => {
            return Api.user
              .unfreezeUserAccount(userId, { reason })
              .then((response) => setUser(response));
          }}
          required={false}
        />,
      );
    },
    [setUser, showModal, t, user?.email, userId],
  );

  if (!user) {
    return <></>;
  }

  return (
    <React.Fragment>
      <div className={'d-flex justify-content-between'}>
        <div className={'d-flex flex-fill'}>
          <SidebarDataRow
            label={t('users.aml.risk_level')}
            value={user.risk_level}
            colProps={{ className: 'mb-0' }}
            displayValue={<UserRiskLevelBadge value={user.risk_level} />}
          />
          <SidebarDataRow
            label={t('users.aml.frozen_status')}
            value={user.account_frozen_at === null ? 'unfrozen' : 'frozen'}
            displayValue={<UserFrozenStatusBadge value={user.account_frozen_at} />}
            colProps={{ className: 'mb-0' }}
          />
          <SidebarDataRow
            label={t('users.aml.in_aml_monitoring_worklist')}
            value={user.in_aml_monitoring_worklist ? t('common.yes') : t('common.no')}
            colProps={{ className: 'mb-0' }}
          />
        </div>
        <div className={'d-flex justify-content-end flex-fill gap-2'}>
          <PermissionAction permissions={Permissions.BO__USERS__UPDATE_RISK_STATUS}>
            <PrimaryButton
              className={'btn btn-primary col-md-3'}
              title={t('users.aml.change_risk_level')}
              type={'button'}
              onClick={() => {
                showModal(
                  <UpdateRiskLevelModal
                    onAction={(request) =>
                      Api.user.updateRiskLevel(userId, request).then((user) => setUser(user))
                    }
                    user={user}
                  />,
                );
              }}
            />
          </PermissionAction>
          <PermissionAction permissions={Permissions.BO__USERS__FREEZE_ACCOUNT}>
            {user.account_frozen_at !== null ? (
              <PrimaryButton
                className={'btn btn-primary col-md-3'}
                onClick={unfreezeUser}
                title={t('users.aml.unfreeze')}
              />
            ) : (
              <PrimaryButton
                className={'btn btn-primary col-md-3'}
                onClick={freezeUser}
                title={t('users.aml.freeze')}
              />
            )}
          </PermissionAction>
        </div>
      </div>
      <hr />
      <HiddenColumnsProvider title={'UserUpdateCompanyTabsTabAML'}>
        <Table
          title={'UserUpdateCompanyTabsTabAML'}
          onFetchData={fetchData}
          columns={riskLevelColumns}
          data={data}
          searchable={true}
          enableQueryFilter={true}
          initialSortBy={INITIAL_SORT_ORDER}
        />
        <AmlIssuesTable model={{ id: userId, type: LegalEntityClassEnum.USER }} />
      </HiddenColumnsProvider>
    </React.Fragment>
  );
};

export default withUser(TabAML);
