import React, { useEffect } from 'react';
import { RouterProps, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Container } from 'reactstrap';

import { setGlobalLoading } from 'src/modules/app/actions';
import { HiddenColumnsProvider } from 'src/components/ShowTableColumnsDropdown';
import ResidenceCountryTable from 'src/pages/AmlManagement/ResidenceCountry/ResidenceCountryTable';

const ResidenceCountry: React.FC<RouterProps> = () => {
  const { t } = useTranslation();

  useEffect(() => {
    setGlobalLoading(false);
  }, []);

  return (
    <React.Fragment>
      <Container fluid>
        <Card>
          <CardBody>
            <div className={'d-flex align-items-center mb-4'}>
              <h4 className={'m-0'}>{t('menu.aml_management.residence_country')}</h4>
            </div>
            <HiddenColumnsProvider title={'AmlRiskLevelChangeLogListIndex'}>
              <ResidenceCountryTable />
            </HiddenColumnsProvider>
          </CardBody>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default withRouter(ResidenceCountry);
