import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Card, CardBody, Container } from 'reactstrap';
import { Cell, Column } from 'react-table';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';

import { setGlobalLoading } from 'src/modules/app/actions';
import Table from 'src/components/Table';
import Api from 'src/api';
import { PaginationData, PaginationDataFilter } from 'src/types';
import EditEntityButton from 'src/components/Table/Buttons/EditEntityButton';
import { Permissions } from 'src/helpers/auth/permissions';
import ControlDropdown from 'src/pages/Application/List/ControlDropdown';
import { RouteList } from 'src/routes';
import { HiddenColumnsProvider } from 'src/components/ShowTableColumnsDropdown';
import { ProspectApplicationsListResponseDto } from 'src/types/api/prospectApplications';
import { getEnumFilterSelectValues } from 'src/helpers/Enums/enumHelper';
import { ProspectApplicationStatusEnum } from 'src/helpers/Enums/ProspectApplication/ProspectApplicationStatusEnum';
import ColumnMultiSelectFilter from 'src/components/Table/Filters/ColumnMultiSelectFilter';

const INITIAL_SORT_ORDER = [
  {
    id: 'created_at',
    desc: true,
  },
];

const ProspectApplicationList: React.FC = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<PaginationData<ProspectApplicationsListResponseDto>>();

  useEffect(() => {
    setGlobalLoading(false);
  }, []);

  const columns = useMemo<Column<ProspectApplicationsListResponseDto>[] | any>(
    () => [
      {
        Header: t('table.id'),
        accessor: 'uuid',
        sortType: 'string',
        width: 200,
        Cell: (cell: Cell<ProspectApplicationsListResponseDto>) => (
          <>
            {cell.row.original.status === ProspectApplicationStatusEnum.IN_PROGRESS ? (
              <a href={cell.row.original.url} target={'_blank'} rel="noreferrer">
                {cell.value}
              </a>
            ) : (
              cell.value
            )}
          </>
        ),
      },
      {
        Header: t('table.project_name'),
        accessor: 'project_name',
        sortType: 'string',
        width: 200,
      },
      {
        Header: t('table.company_name'),
        accessor: 'company_name',
        sortType: 'string',
        width: 200,
        disableSortBy: true,
      },
      {
        Header: t('table.project_amount'),
        accessor: 'project_amount',
        Cell: (cell: Cell<ProspectApplicationsListResponseDto>) => (
          <div>{t('common.money', { value: cell.value })}</div>
        ),
      },
      {
        Header: t('table.status'),
        accessor: 'status',
        sortType: 'string',
        width: 200,
        Filter: ColumnMultiSelectFilter,
        filterProps: {
          options: getEnumFilterSelectValues(
            ProspectApplicationStatusEnum,
            'prospect_applications.status',
          ),
        },
        Cell: (cell: Cell<ProspectApplicationsListResponseDto>) => (
          <div>{t(`prospect_applications.status.${cell.value}`)}</div>
        ),
      },
      {
        Header: t('table.converted_at'),
        accessor: 'converted_at',
        sortType: 'string',
        width: 200,
        Cell: (cell: Cell<ProspectApplicationsListResponseDto>) => (
          <div>{t('common.date_full', { date: cell.value })}</div>
        ),
      },
      {
        Header: t('table.created_at'),
        accessor: 'created_at',
        sortType: 'string',
        width: 200,
        Cell: (cell: Cell<ProspectApplicationsListResponseDto>) => (
          <div>{t('common.date_full', { date: cell.value })}</div>
        ),
      },
      {
        Header: t('table.action'),
        Cell: (cell: Cell<ProspectApplicationsListResponseDto>) => {
          const row = cell.row.original;
          return (
            <div className={'actions d-flex gap-2'}>
              <EditEntityButton
                permissions={Permissions.BO__PROSPECT_APPLICATIONS__VIEW}
                route={generatePath(RouteList.PROSPECT_APPLICATION.VIEW, {
                  prospectApplicationId: row.id,
                })}
              />
            </div>
          );
        },
      },
    ],
    [t],
  );

  const fetchData = useCallback(async (request: PaginationDataFilter | undefined) => {
    return Api.ProspectApplications.fetchFilteredProspectApplications(request).then((response) =>
      setData(response),
    );
  }, []);

  return (
    <React.Fragment>
      <HiddenColumnsProvider title={'ProspectApplicationListIndex'}>
        <Container fluid>
          <Card>
            <CardBody>
              <div className={'d-flex align-items-center mb-4'}>
                <h4 className={'m-0'}>{t('menu.prospect_applications')}</h4>
                <ControlDropdown className={'ms-auto'} />
              </div>
              <Table
                title={'ApplicationListIndex'}
                onFetchData={fetchData}
                columns={columns}
                data={data}
                searchable={true}
                enableQueryFilter={true}
                disableFiltersOutsideTable={true}
                initialSortBy={INITIAL_SORT_ORDER}
              />
            </CardBody>
          </Card>
        </Container>
      </HiddenColumnsProvider>
    </React.Fragment>
  );
};

export default ProspectApplicationList;
