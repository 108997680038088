import React, { useCallback, useEffect, useState } from 'react';
import { MediaOrderRequestDto, MediaResponseDto, TranslatableValueDto } from 'src/types/api/common';
import { Picture } from 'src/types/app';
import Api from 'src/api';
import { success } from 'src/services/toastr';
import { Card, CardBody, CardImg, CardSubtitle } from 'reactstrap';
import { ReactSortable } from 'react-sortablejs';
import { useTranslation } from 'react-i18next';
import DeleteButton from 'src/components/DeleteButton';
import Lightbox from 'react-image-lightbox';
import EditButton from 'src/components/EditButton';
import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import PictureControlModal from 'src/components/PictureList/PictureControlModal';
import ViewButton from 'src/components/ViewButton';
import { Field } from 'formik';
import { FormikHelpers } from 'formik';
import SelectCustomValueInput from 'src/components/Form/Select/SelectCustomValueInput';
import LocaleFormik from 'src/components/Form/LocaleFormik/LocaleFormik';
import { LanguageEnum } from 'src/helpers/Enums/LanguageEnum';
import SaveButton from '../Form/SaveButton';
import * as Yup from 'yup';
import { transformErrors, translatableValueSchema } from 'src/helpers';

enum PictureLabelEnum {
  DEVELOPMENT = 'development',
  PLEDGED = 'pledged',
  SIMILAR = 'similar',
  VISUALIZATION = 'visualization',
}

interface Props {
  language?: LanguageEnum | undefined;
  pictures: Array<MediaResponseDto>;
  onOrderUpdate?: (request: MediaOrderRequestDto) => Promise<void>;
  onPictureDelete?: (mediaId: string) => Promise<void>;
  onPictureRename?: (mediaId: string, name: string) => Promise<void>;
  onPictureLabel?: (label: { [key in LanguageEnum]: string }, mediaId: string) => Promise<void>;
}

const PictureList: React.FC<Props> = ({
  language,
  pictures,
  onOrderUpdate,
  onPictureDelete,
  onPictureRename,
  onPictureLabel,
}) => {
  const { t } = useTranslation();
  const [statePictures, setStatePictures] = useState<Array<Picture>>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [photoIndex, setPhotoIndex] = useState<number>(0);
  const { showModal } = useGlobalModalContext();

  const DataSchema = Yup.object().shape({
    label: translatableValueSchema(Yup.string().nullable().max(255)),
  });

  const multiLocaleAttrs = useCallback(
    (inputName: string) => {
      return {
        name: inputName + '.' + language,
        placeholder: t('label.' + inputName),
      };
    },
    [language, t],
  );

  function adjustLabels(label: TranslatableValueDto<string> | null) {
    if (!label || Object.values(label).every((value) => value === null)) {
      return Object.values(LanguageEnum).reduce((accumulator, currentValue) => {
        return {
          ...accumulator,
          [currentValue]: null,
        };
      }, {} as TranslatableValueDto<string>);
    } else {
      return Object.keys(LanguageEnum).reduce((accumulator, key) => {
        const languageKey = LanguageEnum[key as keyof typeof LanguageEnum];
        accumulator[languageKey] = label[languageKey] ?? '';
        return accumulator;
      }, {} as TranslatableValueDto<string>);
    }
  }

  useEffect(() => {
    const newPictures: Array<Picture> = [];

    pictures.forEach((picture) => {
      const pic: Picture = {
        id: picture.id,
        name: picture.name,
        external_url: statePictures.find((i) => i.id === picture.id)?.external_url ?? null,
        mime_type: picture.mime_type,
        order_column: picture.order_column,
        size: picture.size,
        label: adjustLabels(picture.label),
      };

      newPictures.push(pic);

      if (pic?.external_url) return;

      Api.media.fetchExternal(picture.url).then((media) => {
        setStatePictures((prevState) => {
          const state = [...prevState];
          const index = state.findIndex((i) => i.id === picture.id);
          state[index].external_url = media.url;

          return state;
        });
      });
    });

    setStatePictures(newPictures);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pictures]);

  const onView = useCallback((index: number) => {
    setPhotoIndex(index);
    setIsOpen(true);
  }, []);

  const onSubmit = useCallback(
    async (request: Picture, helpers: FormikHelpers<Picture>) => {
      if (onPictureLabel && request.label) {
        try {
          onPictureLabel(request.label, request.id);
          success(t('common.updated_success'));
        } catch (e: any) {
          helpers.setErrors(transformErrors(e.response?.errors));
        }
      }
    },
    [onPictureLabel, t],
  );

  return (
    <>
      <ReactSortable
        className={'gap-4 card-group'}
        list={statePictures ?? []}
        setList={setStatePictures}
        disabled={!onOrderUpdate}
        handle="#sortable-handle"
        onEnd={() => {
          const request: MediaOrderRequestDto = {
            media_ids: statePictures.map((i) => i.id),
          };

          onOrderUpdate &&
            onOrderUpdate(request).then(() => {
              success(t('common.updated_success'));
            });
        }}
      >
        {statePictures.map((media, key) => {
          return (
            <Card key={media.id} style={{ minWidth: '225px', maxWidth: '300px' }}>
              <div
                id="sortable-handle"
                className={onOrderUpdate ? 'move ' : '' + 'placeholder-glow'}
              >
                {media.external_url && (
                  <CardImg
                    onClick={() => {
                      setPhotoIndex(key);
                      setIsOpen(true);
                    }}
                    top
                    src={media.external_url}
                    className={'fit-image placeholder-glow'}
                    style={{ height: '150px', width: '100%' }}
                  />
                )}
                {!media.external_url && (
                  <div
                    className={'fit-image placeholder '}
                    style={{ height: '150px', width: '100%' }}
                  />
                )}
              </div>
              <CardBody className={'p-0 py-3'}>
                <CardSubtitle className="mb-2 text-muted" tag="h6">
                  {media.name}
                </CardSubtitle>
                <div className={'d-flex gap-2'}>
                  {onPictureDelete && <DeleteButton onDelete={() => onPictureDelete(media.id)} />}
                  {onPictureRename && (
                    <EditButton
                      onEdit={() =>
                        showModal(
                          <PictureControlModal
                            name={media.name}
                            onAction={(name) => onPictureRename(name, media.id)}
                          />,
                        )
                      }
                    />
                  )}
                  <ViewButton onView={() => onView(key)} />
                </div>
                {onPictureLabel && (
                  <LocaleFormik
                    locale={language}
                    initialValues={media}
                    enableReinitialize={true}
                    validationSchema={DataSchema}
                    onSubmit={onSubmit}
                  >
                    <Field
                      as={SelectCustomValueInput}
                      options={Object.values(PictureLabelEnum).map((label) => {
                        return {
                          label: t(`projects.picture_label.${label}`, {
                            lng: language,
                          }),
                          value: `projects.picture_label.${label}`,
                        };
                      })}
                      otherOptionLabel={t(`projects.picture_label.other`, {
                        lng: language,
                      })}
                      isTranslatable={true}
                      language={language}
                      name={'label'}
                      placeholder={multiLocaleAttrs('picture_label').placeholder}
                      otherPlaceholder={t('label.picture_label_other')}
                    />
                    <SaveButton title={t('common.submit')} />
                  </LocaleFormik>
                )}
              </CardBody>
            </Card>
          );
        })}
      </ReactSortable>
      {isOpen && statePictures[photoIndex]?.external_url && (
        <Lightbox
          mainSrc={statePictures[photoIndex]?.external_url ?? ''}
          nextSrc={statePictures[photoIndex]?.external_url ?? ''}
          prevSrc={statePictures[photoIndex]?.external_url ?? ''}
          imageTitle={statePictures[photoIndex].name}
          toolbarButtons={[
            <div key={1}>
              {onPictureDelete && (
                <DeleteButton
                  className={'me-2'}
                  onDelete={() =>
                    onPictureDelete(statePictures[photoIndex].id).then(() => setIsOpen(false))
                  }
                />
              )}
              {onPictureRename && (
                <EditButton
                  className={'me-2'}
                  onEdit={() =>
                    showModal(
                      <PictureControlModal
                        name={statePictures[photoIndex]?.name}
                        onAction={(name) => onPictureRename(name, statePictures[photoIndex]?.id)}
                      />,
                    )
                  }
                />
              )}
            </div>,
          ]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((prevState) =>
              prevState - 1 < 0 ? statePictures.length - 1 : prevState - 1,
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex((prevState) =>
              prevState + 1 >= statePictures.length ? 0 : prevState + 1,
            )
          }
        />
      )}
    </>
  );
};

export default PictureList;
