import React from 'react';
import { useTranslation } from 'react-i18next';
import { Badge } from 'reactstrap';

interface Props {
  value: boolean;
}

const IsActiveBadge: React.FC<Props> = ({ value }) => {
  const { t } = useTranslation();

  function getBadgeClassname(value: boolean): string {
    return value ? 'bg-success' : 'bg-danger';
  }

  return <Badge className={getBadgeClassname(value)}>{t(`common.status.${value}`)}</Badge>;
};

export default IsActiveBadge;
