import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BaseTabProps } from 'src/components/Tabs';
import {
  InvestorKybDataV1ResponseDto,
  InvestorKybDataV2ResponseDto,
  ProjectDeveloperKybDataV1ResponseDto,
  ProjectDeveloperKybDataV2ResponseDto,
  UserCompanyKybResponseDto,
  UserCompanyResponseDto,
} from 'src/types/api/userCompanies';
import Api from 'src/api';
import { Container, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { PaginationData, PaginationDataFilter, UserType } from 'src/types';
import { FinancialRiskCalculatorResponseDto } from 'src/types/api/user';
import { Cell, Column } from 'react-table';
import Table from 'src/components/Table';
import InvestorKybV1 from 'src/pages/User/Update/Company/Kyb/InvestorKybV1';
import ProjectDeveloperKybV1 from 'src/pages/User/Update/Company/Kyb/ProjectDeveloperKybV1';
import { KybVersionEnum } from 'src/helpers/Enums/KybVersionEnum';
import { UserTypeEnum } from 'src/helpers/Enums/UserTypeEnum';
import InvestorKybV2 from 'src/pages/User/Update/Company/Kyb/InvestorKybV2';
import ProjectDeveloperKybV2 from 'src/pages/User/Update/Company/Kyb/ProjectDeveloperKybV2';

interface Props extends BaseTabProps {
  company?: UserCompanyResponseDto;
}

const INITIAL_SORT_ORDER = [
  {
    id: 'submitted_at',
    desc: true,
  },
];

const TabLegal: React.FC<Props> = ({ id, ...props }) => {
  const { t } = useTranslation();
  const [company, setCompany] = useState<UserCompanyResponseDto | null>(null);
  const [companyKnowYourBusiness, setCompanyKnowYourBusiness] =
    useState<UserCompanyKybResponseDto | null>(null);

  const [financialRiskSubmits, setFinancialRiskSubmits] =
    useState<PaginationData<FinancialRiskCalculatorResponseDto>>();

  useEffect(() => {
    if (props.company) {
      setCompany(props.company);
    } else if (id) {
      Api.userCompanies.fetchCompany(id).then((response: UserCompanyResponseDto) => {
        setCompany(response);
      });
    }

    if (company && company.kyb) {
      setCompanyKnowYourBusiness(company.kyb);
    }
  }, [props, id, company]);

  const columns = useMemo<Column[] | any>(
    () => [
      {
        Header: t('table.id'),
        accessor: 'id',
        sortType: 'string',
      },
      {
        Header: t('table.annual_income'),
        accessor: 'annual_income',
        sortType: 'string',
      },
      {
        Header: t('table.liquid_net_worth'),
        accessor: 'liquid_net_worth',
        sortType: 'string',
      },
      {
        Header: t('table.annual_obligations'),
        accessor: 'annual_obligations',
        sortType: 'number',
        Cell: (cell: Cell<FinancialRiskCalculatorResponseDto>) => (
          <div>{t('common.money', { value: cell.value })}</div>
        ),
      },
      {
        Header: t('table.result'),
        accessor: 'result',
        sortType: 'number',
        Cell: (cell: Cell<FinancialRiskCalculatorResponseDto>) => (
          <div>{t('common.money', { value: cell.value })}</div>
        ),
      },
      {
        Header: t('table.submitted_at'),
        accessor: 'submitted_at',
        sortType: 'string',
        Cell: (cell: Cell<FinancialRiskCalculatorResponseDto>) => (
          <div>{t('common.date_full', { date: cell.value })}</div>
        ),
      },
    ],
    [t],
  );

  const fetchData = useCallback(
    (request: PaginationDataFilter | undefined) => {
      if (!props.company?.id) return Promise.resolve();

      return Api.userCompanies
        .fetchFinancialRiskSubmits(props.company.id, request)
        .then((response) => setFinancialRiskSubmits(response));
    },
    [props.company?.id],
  );

  const resolveKyb = (
    kyb: UserCompanyKybResponseDto,
    userType: UserTypeEnum,
    version: KybVersionEnum,
  ): React.ReactElement | null => {
    if (userType === UserTypeEnum.INVESTOR) {
      switch (version) {
        case KybVersionEnum.V1:
          return <InvestorKybV1 kyb={kyb?.kyb_data as InvestorKybDataV1ResponseDto} />;
        case KybVersionEnum.V2:
          return <InvestorKybV2 kyb={kyb.kyb_data as InvestorKybDataV2ResponseDto} />;
      }
    }

    if (userType === UserTypeEnum.PROJECT_DEVELOPER) {
      switch (version) {
        case KybVersionEnum.V1:
          return (
            <ProjectDeveloperKybV1 kyb={kyb.kyb_data as ProjectDeveloperKybDataV1ResponseDto} />
          );
        case KybVersionEnum.V2:
          return (
            <ProjectDeveloperKybV2 kyb={kyb.kyb_data as ProjectDeveloperKybDataV2ResponseDto} />
          );
      }
    }

    return null;
  };

  return (
    <>
      {companyKnowYourBusiness && company ? (
        <Container fluid>
          <Row>
            {resolveKyb(
              companyKnowYourBusiness,
              company.type as UserTypeEnum,
              companyKnowYourBusiness.version,
            )}
          </Row>
          {props.company?.type === UserType.Investor && (
            <>
              <hr />
              <h1>{t('common.calculated_financial_risks')}</h1>
              <Table
                title={'financial-risk-submits'}
                columns={columns}
                data={financialRiskSubmits}
                onFetchData={fetchData}
                initialSortBy={INITIAL_SORT_ORDER}
              />
            </>
          )}
        </Container>
      ) : (
        <p>{t('common.no_data')}</p>
      )}
    </>
  );
};

export default TabLegal;
