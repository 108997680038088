import React from 'react';
import AuthRoute from '../AuthRoute';
import { RouteList } from 'src/routes';
import PageLayout from 'src/containers/PageLayout';
import Dashboard from './Dashboard';
import NotFound from './NotFound';
import AuthenticationLayout from 'src/containers/AuthenticationLayout';
import { Permissions } from 'src/helpers/auth/permissions';
import WalletInfo from 'src/pages/Common/Dashboard/WalletInfo';
import Marketing from 'src/pages/Common/Dashboard/Marketing';
import PortfolioInfo from './Dashboard/Portfolio';
import InvestmentsInfo from 'src/pages/Common/Dashboard/Investments';

const CommonRoutes: any = () => [
  <AuthRoute
    path={RouteList.DASHBOARD.DASHBOARD.LIST}
    component={Dashboard}
    layout={PageLayout}
    permissions={[Permissions.BO__ACTIONS__ACCESS_PANEL]}
    redirect={RouteList.AUTHENTICATION.LOGIN}
    key={RouteList.DASHBOARD.DASHBOARD.LIST}
    exact
  />,
  <AuthRoute
    path={RouteList.DASHBOARD.MARKETING.LIST}
    component={Marketing}
    layout={PageLayout}
    permissions={[Permissions.BO__DASHBOARD__MARKETING_DATA]}
    redirect={RouteList.AUTHENTICATION.LOGIN}
    key={RouteList.DASHBOARD.MARKETING.LIST}
    exact
  />,
  <AuthRoute
    path={RouteList.DASHBOARD.WALLET_INFO.LIST}
    component={WalletInfo}
    layout={PageLayout}
    permissions={[Permissions.BO__DASHBOARD__WALLETS_DATA]}
    redirect={RouteList.AUTHENTICATION.LOGIN}
    key={RouteList.DASHBOARD.WALLET_INFO.LIST}
    exact
  />,
  <AuthRoute
    path={RouteList.DASHBOARD.PORTFOLIO_INFO.LIST}
    component={PortfolioInfo}
    layout={PageLayout}
    permissions={[Permissions.BO__DASHBOARD__PORTFOLIO_DATA]}
    redirect={RouteList.AUTHENTICATION.LOGIN}
    key={RouteList.DASHBOARD.PORTFOLIO_INFO.LIST}
    exact
  />,
  <AuthRoute
    path={RouteList.DASHBOARD.INVESTMENTS.LIST}
    component={InvestmentsInfo}
    layout={PageLayout}
    permissions={[Permissions.BO__DASHBOARD__INVESTMENTS_DATA]}
    redirect={RouteList.AUTHENTICATION.LOGIN}
    key={RouteList.DASHBOARD.INVESTMENTS.LIST}
    exact
  />,
  <AuthRoute
    path={RouteList.NOT_FOUND}
    component={NotFound}
    layout={AuthenticationLayout}
    permissions={[]}
    key={RouteList.NOT_FOUND}
  />,
];

export default CommonRoutes;
