import React, { useCallback, useState } from 'react';
import { ModalComponent } from '../ModalComponent';
import { useTranslation } from 'react-i18next';
import { useGlobalModalContext } from '../GlobalModal';
import { Col, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import ModalCloseButton from '../ModalCloseButton';
import { success } from 'src/services/toastr';
import DenyButton from 'src/components/Form/DenyButton';
import SaveButton from 'src/components/Form/SaveButton';

interface Props {
  onAction: () => Promise<any>;
  onException?: () => void;
  actionText: string;
  title: string;
  body: string;
  successMessage?: string;
  size?: 'sm' | 'md' | 'lg' | 'xl';
}

const ActionModal: React.FC<Props> = ({
  onAction,
  onException,
  title,
  body,
  successMessage,
  actionText,
  size = 'sm',
}) => {
  const { t } = useTranslation();
  const { hideModal } = useGlobalModalContext();
  const [isInAction, setIsInAction] = useState(false);

  const onSubmit = useCallback(() => {
    if (isInAction) return;
    setIsInAction(true);

    onAction()
      .then(() => {
        success(successMessage);
        hideModal();
      })
      .finally(() => {
        setIsInAction(false);
      })
      .catch(() => {
        onException && onException();
      });
  }, [hideModal, isInAction, onAction, onException, successMessage]);

  return (
    <React.Fragment>
      <ModalComponent size={size}>
        <div>
          <ModalHeader toggle={hideModal} close={<ModalCloseButton />}>
            {title}
          </ModalHeader>
          <ModalBody className={''}>
            <p className={'text-pre'}>{body}</p>
          </ModalBody>
          <ModalFooter className={'d-flex justify-content-center'}>
            <Col>
              <DenyButton
                title={t('common.cancel')}
                onClick={(e) => {
                  e.preventDefault();
                  hideModal();
                }}
              />
            </Col>
            <Col>
              <SaveButton
                title={actionText}
                onClick={(e) => {
                  e.preventDefault();
                  onSubmit();
                }}
                submitting={isInAction}
              />
            </Col>
          </ModalFooter>
        </div>
      </ModalComponent>
    </React.Fragment>
  );
};

export default ActionModal;
