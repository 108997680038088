import React from 'react';
import AuthRoute from '../AuthRoute';
import { RouteList } from 'src/routes';
import PageLayout from 'src/containers/PageLayout';
import { Permissions } from 'src/helpers/auth/permissions';
import ProspectApplicationList from 'src/pages/ProspectApplication/List';
import ProspectApplication from './Update';

const ProspectApplicationRoutes: any = () => [
  <AuthRoute
    path={RouteList.PROSPECT_APPLICATION.LIST}
    component={ProspectApplicationList}
    layout={PageLayout}
    permissions={[Permissions.BO__PROSPECT_APPLICATIONS__FILTER]}
    redirect={RouteList.AUTHENTICATION.LOGIN}
    key={RouteList.PROSPECT_APPLICATION.LIST}
    exact
  />,
  <AuthRoute
    path={RouteList.PROSPECT_APPLICATION.VIEW}
    component={ProspectApplication}
    layout={PageLayout}
    permissions={[Permissions.BO__PROSPECT_APPLICATIONS__VIEW]}
    redirect={RouteList.AUTHENTICATION.LOGIN}
    key={RouteList.PROSPECT_APPLICATION.VIEW}
    exact
  />,
];

export default ProspectApplicationRoutes;
