import React from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import { ModalComponent } from 'src/components/Modal/ModalComponent';
import { ModalBody, ModalHeader } from 'reactstrap';
import ModalCloseButton from 'src/components/Modal/ModalCloseButton';
import { WorklistInformationResponseDto } from 'src/pages/AmlManagement/Issues';

interface Props {
  worklistInformation: WorklistInformationResponseDto;
}

const ViewWorklistInformationModal: React.FC<Props> = ({ worklistInformation }: Props) => {
  const { t } = useTranslation();
  const { hideModal } = useGlobalModalContext();

  return (
    <React.Fragment>
      <ModalComponent size={'lg'}>
        <div>
          <ModalHeader toggle={hideModal} close={<ModalCloseButton />}>
            {t('table.worklist_information')}
          </ModalHeader>
          <ModalBody>
            <table className={'w-100 table'}>
              <thead>
                <tr>
                  <th>{t('table.title')}</th>
                  <th>{t('table.value')}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{t('table.name')}</td>
                  <td>{worklistInformation.name}</td>
                </tr>
                <tr>
                  <td>{t('table.id')}</td>
                  <td>{worklistInformation.id}</td>
                </tr>
                <tr>
                  <td>{t('table.threshold')}</td>
                  <td>{worklistInformation.threshold}</td>
                </tr>
                <tr>
                  <td>{t('table.frequency')}</td>
                  <td>{worklistInformation.frequency}</td>
                </tr>
                <tr>
                  <td>{t('table.dataSets')}</td>
                  <td>{worklistInformation.dataSets.join(', ')}</td>
                </tr>
                <br /> <br />
                <a rel="noreferrer" target="_blank" href={t('table.values_information')}>
                  View values information
                </a>
                <br />
                <br />
                <tr>
                  <td>{t('table.lastScreenedDate')}</td>
                  <td>{worklistInformation.lastScreenedDate}</td>
                </tr>
                <tr>
                  <td>{t('table.businessMonitorRecordsCount')}</td>
                  <td>{worklistInformation.businessMonitorRecordsCount}</td>
                </tr>
                <tr>
                  <td>{t('table.individualMonitorRecordsCount')}</td>
                  <td>{worklistInformation.individualMonitorRecordsCount}</td>
                </tr>
                <tr>
                  <td>{t('table.individualOpenMonitorRecordsCount')}</td>
                  <td>{worklistInformation.individualOpenMonitorRecordsCount}</td>
                </tr>
                <tr>
                  <td>{t('table.individualOpenMatchesCount')}</td>
                  <td>{worklistInformation.individualOpenMatchesCount}</td>
                </tr>
                <tr>
                  <td>{t('table.individualFalsePositiveMatchesCount')}</td>
                  <td>{worklistInformation.individualFalsePositiveMatchesCount}</td>
                </tr>
                <tr>
                  <td>{t('table.individualTruePositiveMatchesCount')}</td>
                  <td>{worklistInformation.individualTruePositiveMatchesCount}</td>
                </tr>
                <tr>
                  <td>{t('table.individualDiscardedMatchesCount')}</td>
                  <td>{worklistInformation.individualDiscardedMatchesCount}</td>
                </tr>
                <tr>
                  <td>{t('table.businessOpenMonitorRecordsCount')}</td>
                  <td>{worklistInformation.businessOpenMonitorRecordsCount}</td>
                </tr>
                <tr>
                  <td>{t('table.businessOpenMatchesCount')}</td>
                  <td>{worklistInformation.businessOpenMatchesCount}</td>
                </tr>
                <tr>
                  <td>{t('table.businessFalsePositiveMatchesCount')}</td>
                  <td>{worklistInformation.businessFalsePositiveMatchesCount}</td>
                </tr>
                <tr>
                  <td>{t('table.businessTruePositiveMatchesCount')}</td>
                  <td>{worklistInformation.businessTruePositiveMatchesCount}</td>
                </tr>
                <tr>
                  <td>{t('table.businessDiscardedMatchesCount')}</td>
                  <td>{worklistInformation.businessDiscardedMatchesCount}</td>
                </tr>
                {/*<tr>*/}
                {/*  <td>{t('table.scheduleDayOfTheWeek')}</td>*/}
                {/*  <td>{worklistInformation.scheduleDayOfTheWeek}</td>*/}
                {/*</tr>*/}
                {/*<tr>*/}
                {/*  <td>{t('table.scheduleMonth')}</td>*/}
                {/*  <td>{worklistInformation.scheduleMonth}</td>*/}
                {/*</tr>*/}
              </tbody>
            </table>
          </ModalBody>
        </div>
      </ModalComponent>
    </React.Fragment>
  );
};

export default ViewWorklistInformationModal;
