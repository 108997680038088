import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { success } from 'src/services/toastr';
import { Col, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Form, Formik, FormikHelpers } from 'formik';

import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';

import Api from 'src/api';
import { ModalComponent } from 'src/components/Modal/ModalComponent';
import ModalCloseButton from 'src/components/Modal/ModalCloseButton';
import DenyButton from 'src/components/Form/DenyButton';
import SaveButton from 'src/components/Form/SaveButton';
import { ProjectLoanFineType } from 'src/helpers/Enums/ProjectLoanFineType';
import { StoreProjectLoanFineRequestDto } from 'src/types/api/payments/projectPayouts';
import DatePickerInput from 'src/components/Form/DatePickerInput';
import TextInput from 'src/components/Form/TextInput';
import { setGlobalLoading } from 'src/modules/app/actions';
import SelectLoanFineTypeInput from 'src/components/Form/Select/SelectLoanFineTypeInput';

type Props = {
  projectId: string;
  loanFineId: string;
  onAction: () => Promise<any>;
};

const INITIAL_STATE: StoreProjectLoanFineRequestDto = {
  type: ProjectLoanFineType.CUSTOM,
  fine_start_at: '',
  fine_end_at: '',
  reason: '',
};

const StoreLoanFineModal: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { hideModal } = useGlobalModalContext();
  const projectId = props.projectId;
  const loanFineId = props.loanFineId;

  const [loanFineRequest, setLoanFineRequest] =
    useState<StoreProjectLoanFineRequestDto>(INITIAL_STATE);

  useEffect(() => {
    Api.payments.projectPayouts.fetchLoanFine(projectId, loanFineId).then((loanFine) => {
      setLoanFineRequest(loanFine);
      setGlobalLoading(false);
    });
  }, [loanFineId, projectId]);

  const onSubmit = useCallback(
    async (
      request: StoreProjectLoanFineRequestDto,
      helper: FormikHelpers<StoreProjectLoanFineRequestDto>,
    ) => {
      try {
        await Api.payments.projectPayouts.updateProjectLoanFine(projectId, loanFineId, request);
        success(t('common.updated_success'));
        hideModal();
        await props.onAction();
      } catch (e: any) {
        helper.setErrors(e.response?.errors);
      }
    },
    [hideModal, loanFineId, projectId, t, props],
  );

  return (
    <React.Fragment>
      <ModalComponent size={'md'}>
        <div>
          <ModalHeader toggle={hideModal} close={<ModalCloseButton />}>
            {t('payments.loan_fines.update_title')}
          </ModalHeader>
          <Formik initialValues={loanFineRequest} onSubmit={onSubmit} enableReinitialize={true}>
            {({ handleSubmit, isSubmitting }) => (
              <Form onSubmit={handleSubmit}>
                <ModalBody>
                  <div className="mb-3">
                    <SelectLoanFineTypeInput
                      placeholder={t('label.fine_type')}
                      name={'type'}
                      isClearable={true}
                    />
                  </div>
                  <div className="mb-3">
                    <DatePickerInput name={'fine_start_at'} matchFieldValueAndDisplayValue={true} />
                  </div>
                  <div className="mb-3">
                    <DatePickerInput name={'fine_end_at'} matchFieldValueAndDisplayValue={true} />
                  </div>
                  <div className="mb-3">
                    <TextInput type={'text'} name={'reason'} />
                  </div>
                </ModalBody>
                <ModalFooter className={'d-flex justify-content-center'}>
                  <Col>
                    <DenyButton title={t('common.cancel')} onClick={hideModal} />
                  </Col>
                  <Col>
                    <SaveButton title={t('common.submit')} submitting={isSubmitting} />
                  </Col>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </div>
      </ModalComponent>
    </React.Fragment>
  );
};

export default StoreLoanFineModal;
