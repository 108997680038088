import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, FormikHelpers } from 'formik';
import { Col, ColProps, Form, FormGroup, Row } from 'reactstrap';
import TextInput from 'src/components/Form/TextInput';
import Api from 'src/api';
import { success } from 'src/services/toastr';
import { transformErrors } from 'src/helpers';
import * as Yup from 'yup';
import SaveButton from 'src/components/Form/SaveButton';
import {
  ProspectApplicationContextState,
  withProspectApplication,
} from '../ProspectApplicationContext';
import {
  ProspectApplicationBeneficiary,
  UpdateProspectUserCompanyRequestDto,
} from 'src/types/api/prospectApplications';
import { Cell, Column } from 'react-table';
import { AttachFilesRequestDto, DocumentResponseDto } from 'src/types/api/common';
import IsOutdatedBadge from 'src/components/Badges/IsOutdatedBadge';
import SimpleTable from 'src/components/SimpleTable';
import DownloadButton from 'src/components/DownloadButton';
import SidebarDataRow from 'src/components/DataBlocks/SidebarDataRow';
import { UseNumbers } from 'src/helpers/useNumbers';
import DatePickerInput from 'src/components/Form/DatePickerInput';
import SelectCountryInput from 'src/components/Form/Select/SelectCountryInput';
import PhoneNumberInput from 'src/components/Form/PhoneNumberInput';
import SelectYesNo from 'src/components/Form/Select/SelectYesNoInput';

const INITIAL_REQUEST: UpdateProspectUserCompanyRequestDto = {
  name: null,
  legal_code: null,
  vat_code: null,
  country: null,
  address: null,
  office_address: null,
  activity_duration: null,
  established_at: null,
  website: null,
  email: null,
  phone: null,
  representative_first_name: null,
  representative_last_name: null,
  representative_email: null,
  representative_country: null,
  representative_gov_code: null,
  representative_phone: null,
  representative_address: null,
  representative_is_public_official: null,
  executive_first_name: null,
  executive_type: null,
  executive_country: null,
  executive_last_name: null,
  executive_gov_code: null,
  executive_address: null,
  executive_phone: null,
  executive_email: null,
  is_public_official: null,
  accountant_first_name: null,
  accountant_last_name: null,
  accountant_phone: null,
  accountant_email: null,
  has_confirmed_data_integrity: null,
  has_confirmed_business_relations: null,
  has_confirmed_fact_checking: null,
  experience: null,
};
const TabCompany: React.FC<ProspectApplicationContextState> = ({
  prospectApplication,
  setProspectApplication,
  isEditable,
}) => {
  const { t } = useTranslation();

  const [request, setRequest] = useState<UpdateProspectUserCompanyRequestDto>(INITIAL_REQUEST);

  useEffect(() => {
    if (!prospectApplication || !prospectApplication?.prospect_user_company) return;

    setRequest(() => ({
      name: prospectApplication.prospect_user_company?.name ?? null,
      legal_code: prospectApplication.prospect_user_company?.legal_code ?? null,
      vat_code: prospectApplication.prospect_user_company?.vat_code ?? null,
      country: prospectApplication.prospect_user_company?.country ?? null,
      address: prospectApplication.prospect_user_company?.address ?? null,
      office_address: prospectApplication.prospect_user_company?.office_address ?? null,
      activity_duration: prospectApplication.prospect_user_company?.activity_duration ?? null,
      established_at: prospectApplication.prospect_user_company?.established_at ?? null,
      website: prospectApplication.prospect_user_company?.website ?? null,
      email: prospectApplication.prospect_user_company?.email ?? null,
      phone: prospectApplication.prospect_user_company?.phone ?? null,
      representative_first_name:
        prospectApplication.prospect_user_company?.representative_first_name ?? null,
      representative_last_name:
        prospectApplication.prospect_user_company?.representative_last_name ?? null,
      representative_email: prospectApplication.prospect_user_company?.representative_email ?? null,
      representative_country:
        prospectApplication.prospect_user_company?.representative_country ?? null,
      representative_gov_code:
        prospectApplication.prospect_user_company?.representative_gov_code ?? null,
      representative_phone: prospectApplication.prospect_user_company?.representative_phone ?? null,
      representative_address:
        prospectApplication.prospect_user_company?.representative_address ?? null,
      representative_is_public_official:
        prospectApplication.prospect_user_company?.representative_is_public_official ?? null,
      executive_first_name: prospectApplication.prospect_user_company?.executive_first_name ?? null,
      executive_type: prospectApplication.prospect_user_company?.executive_type ?? null,
      executive_country: prospectApplication.prospect_user_company?.executive_country ?? null,
      executive_last_name: prospectApplication.prospect_user_company?.executive_last_name ?? null,
      executive_gov_code: prospectApplication.prospect_user_company?.executive_gov_code ?? null,
      executive_address: prospectApplication.prospect_user_company?.executive_address ?? null,
      executive_phone: prospectApplication.prospect_user_company?.executive_phone ?? null,
      executive_email: prospectApplication.prospect_user_company?.executive_email ?? null,
      is_public_official: prospectApplication.prospect_user_company?.is_public_official ?? null,
      accountant_first_name:
        prospectApplication.prospect_user_company?.accountant_first_name ?? null,
      accountant_last_name: prospectApplication.prospect_user_company?.accountant_last_name ?? null,
      accountant_phone: prospectApplication.prospect_user_company?.accountant_phone ?? null,
      accountant_email: prospectApplication.prospect_user_company?.accountant_email ?? null,
      has_confirmed_data_integrity:
        prospectApplication.prospect_user_company?.has_confirmed_data_integrity ?? null,
      has_confirmed_business_relations:
        prospectApplication.prospect_user_company?.has_confirmed_business_relations ?? null,
      has_confirmed_fact_checking:
        prospectApplication.prospect_user_company?.has_confirmed_fact_checking ?? null,
      experience: {
        manager_experience_in_area_size:
          prospectApplication.prospect_user_company?.experience?.manager_experience_in_area_size ??
          null,
        manager_comments:
          prospectApplication.prospect_user_company?.experience?.manager_comments ?? null,
        group_experience_in_area_size:
          prospectApplication.prospect_user_company?.experience?.group_experience_in_area_size ??
          null,
        group_comments:
          prospectApplication.prospect_user_company?.experience?.group_comments ?? null,
      },
    }));
  }, [prospectApplication]);

  const onSubmit = useCallback(
    async (
      request: UpdateProspectUserCompanyRequestDto,
      helpers: FormikHelpers<UpdateProspectUserCompanyRequestDto>,
    ) => {
      if (!prospectApplication || !prospectApplication.prospect_user_company) return;

      try {
        const response = await Api.ProspectApplications.updateProspectApplicationCompanyDetails(
          prospectApplication.id,
          prospectApplication.prospect_user_company?.id,
          request,
        );

        setProspectApplication(response);
        success(t('common.updated_success'));
      } catch (e: any) {
        helpers.setErrors(transformErrors(e.response?.errors));
      }
    },
    [prospectApplication, t, setProspectApplication],
  );

  const colPropsThreePerRow: ColProps = {
    md: 4,
    className: 'mb-4',
  };

  const colPropsTwoPerRow: ColProps = {
    md: 6,
    className: 'mb-4',
  };

  const documentColumns = useMemo<Column<AttachFilesRequestDto>[] | any>(
    () => [
      {
        Header: t('table.id'),
        accessor: 'id',
      },
      {
        Header: t('table.file_name'),
        accessor: 'name',
      },
      {
        Header: t('table.mime_type'),
        accessor: 'mime_type',
      },
      {
        Header: t('table.size'),
        accessor: 'size',
      },
      {
        Header: t('table.information'),
        width: 100,
        Cell: (cell: Cell<DocumentResponseDto>) => (
          <div className={'d-flex justify-content-center gap-2 text-center'}>
            <IsOutdatedBadge show={!!cell.row.original.is_outdated} />
          </div>
        ),
      },
      {
        Header: t('table.uploader'),
        accessor: 'uploader',
      },
      {
        Header: t('table.created_at'),
        accessor: 'created_at',
        Cell: (cell: Cell<AttachFilesRequestDto>) => (
          <div>{t('common.date_full', { date: cell.value })}</div>
        ),
      },
      {
        Header: t('table.action'),
        accessor: 'url',
        Cell: (cell: Cell<DocumentResponseDto>) => {
          if (!prospectApplication) return null;
          return (
            <div className={'d-flex gap-2'}>
              <DownloadButton url={cell.value} />
            </div>
          );
        },
      },
    ],
    [prospectApplication, t],
  );

  const ProspectCompanySchema = Yup.object().shape({
    name: Yup.string().companyName().required(),
    legal_code: Yup.string().companyLegalCode().required(),
    vat_code: Yup.string().companyVatCode(),
    address: Yup.string().addressLine().nullable(),
    activity_duration: Yup.number().required(),
    established_at: Yup.date().required(),
    website: Yup.string().nullable(),
    email: Yup.string().email().required(),
    phone: Yup.string().phone().required(),
    country: Yup.string().required(),
    is_representative_executive: Yup.boolean().nullable(),
    executive_first_name: Yup.string().firstName().nullable(),
    executive_last_name: Yup.string().lastName().nullable(),
    executive_gov_code: Yup.string()
      .governmentCode('executive_gov_document_issue_country')
      .nullable(),
    executive_phone: Yup.string().phone().nullable(),
    executive_email: Yup.string().email().nullable(),
    executive_gov_document_type: Yup.string().governmentDocument().nullable(),
    executive_gov_document_number: Yup.string().nullable(),
    executive_gov_document_issue_country: Yup.string().residence().nullable(),
    accountant_first_name: Yup.string().firstName().nullable(),
    accountant_last_name: Yup.string().lastName().nullable(),
    accountant_phone: Yup.string().phone().nullable(),
    accountant_email: Yup.string().email().nullable(),
  });

  return (
    <React.Fragment>
      <h3 className={'flex'}>{t('prospect_applications.tabs.company_details')}</h3>
      <hr />

      <Formik
        initialValues={request}
        enableReinitialize={true}
        onSubmit={onSubmit}
        validationSchema={ProspectCompanySchema}
      >
        {({ handleSubmit, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Row>
                <Col {...colPropsThreePerRow}>
                  <TextInput type={'name'} name={'name'} disabled={!isEditable} />
                </Col>
                <Col {...colPropsThreePerRow}>
                  <TextInput type={'legal_code'} name={'legal_code'} disabled={!isEditable} />
                </Col>
                <Col {...colPropsThreePerRow}>
                  <TextInput type={'vat_code'} name={'vat_code'} disabled={!isEditable} />
                </Col>
                <Col>
                  <SelectCountryInput name={'country'} isDisabled={!isEditable} />
                </Col>
                <Col {...colPropsThreePerRow}>
                  <TextInput type={'address'} name={'address'} disabled={!isEditable} />
                </Col>
                <Col {...colPropsThreePerRow}>
                  <TextInput
                    type={'office_address'}
                    name={'office_address'}
                    disabled={!isEditable}
                  />
                </Col>
                <Col {...colPropsThreePerRow}>
                  <TextInput
                    type={'number'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                    name={'activity_duration'}
                    disabled={!isEditable}
                  />
                </Col>
                <Col {...colPropsThreePerRow}>
                  <DatePickerInput
                    name={'established_at'}
                    disabled={!isEditable}
                    matchFieldValueAndDisplayValue={true}
                  />
                </Col>
                <Col {...colPropsThreePerRow}>
                  <TextInput type={'website'} name={'website'} disabled={!isEditable} />
                </Col>
                <Col {...colPropsThreePerRow}>
                  <TextInput type={'email'} name={'email'} disabled={!isEditable} />
                </Col>
                <Col {...colPropsThreePerRow}>
                  <PhoneNumberInput name={'phone'} disabled={!isEditable} />
                </Col>
              </Row>
              <Row>
                <hr />
                <h4 className="mb-4">{t('prospect_applications.company.representative')}</h4>
                <Col {...colPropsThreePerRow}>
                  <TextInput
                    type={'representative_first_name'}
                    name={'representative_first_name'}
                    disabled={!isEditable}
                  />
                </Col>
                <Col {...colPropsThreePerRow}>
                  <TextInput
                    type={'representative_last_name'}
                    name={'representative_last_name'}
                    disabled={!isEditable}
                  />
                </Col>
                <Col {...colPropsThreePerRow}>
                  <TextInput
                    type={'representative_email'}
                    name={'representative_email'}
                    disabled={!isEditable}
                  />
                </Col>
                <Col {...colPropsThreePerRow}>
                  <SelectCountryInput name={'representative_country'} isDisabled={!isEditable} />
                </Col>
                <Col {...colPropsThreePerRow}>
                  <TextInput
                    type={'representative_gov_code'}
                    name={'representative_gov_code'}
                    disabled={!isEditable}
                  />
                </Col>
                <Col {...colPropsThreePerRow}>
                  <PhoneNumberInput name={'representative_phone'} disabled={!isEditable} />
                </Col>
                <Col {...colPropsThreePerRow}>
                  <TextInput
                    type={'representative_address'}
                    name={'representative_address'}
                    disabled={!isEditable}
                  />
                </Col>
              </Row>
              <Row>
                <hr />
                <h4 className="mb-4">{t('prospect_applications.company.executive')}</h4>
                <Col {...colPropsThreePerRow}>
                  <TextInput
                    type={'executive_first_name'}
                    name={'executive_first_name'}
                    disabled={!isEditable}
                  />
                </Col>
                <Col {...colPropsThreePerRow}>
                  <TextInput
                    type={'executive_last_name'}
                    name={'executive_last_name'}
                    disabled={!isEditable}
                  />
                </Col>
                <Col {...colPropsThreePerRow}>
                  <TextInput
                    type={'executive_type'}
                    name={'executive_type'}
                    disabled={!isEditable}
                  />
                </Col>
                <Col {...colPropsThreePerRow}>
                  <SelectCountryInput name={'executive_country'} isDisabled={!isEditable} />
                </Col>
                <Col {...colPropsThreePerRow}>
                  <TextInput name={'executive_gov_code'} disabled={!isEditable} />
                </Col>
                <Col {...colPropsThreePerRow}>
                  <TextInput
                    type={'executive_address'}
                    name={'executive_address'}
                    disabled={!isEditable}
                  />
                </Col>
                <Col {...colPropsThreePerRow}>
                  <TextInput
                    type={'executive_phone'}
                    name={'executive_phone'}
                    disabled={!isEditable}
                  />
                </Col>
                <Col {...colPropsThreePerRow}>
                  <TextInput
                    type={'executive_email'}
                    name={'executive_email'}
                    disabled={!isEditable}
                  />
                </Col>
                <Col {...colPropsThreePerRow}>
                  <SelectYesNo
                    placeholder={t('label.is_public_official')}
                    name={'is_public_official'}
                    isDisabled={!isEditable}
                  />
                </Col>
              </Row>
              <hr />
              <h4 className="mb-4">{t('prospect_applications.company.accountant')}</h4>
              <Row>
                <Col {...colPropsTwoPerRow}>
                  <TextInput
                    type={'accountant_first_name'}
                    name={'accountant_first_name'}
                    disabled={!isEditable}
                  />
                </Col>
                <Col {...colPropsTwoPerRow}>
                  <TextInput
                    type={'accountant_last_name'}
                    name={'accountant_last_name'}
                    disabled={!isEditable}
                  />
                </Col>
                <Col {...colPropsTwoPerRow}>
                  <PhoneNumberInput name={'accountant_phone'} disabled={!isEditable} />
                </Col>
                <Col {...colPropsTwoPerRow}>
                  <TextInput
                    type={'accountant_email'}
                    name={'accountant_email'}
                    disabled={!isEditable}
                  />
                </Col>
              </Row>
              <Row>
                <hr />
                <h4 className="mb-4">{t('prospect_applications.company.confirmations')}</h4>
                <Col {...colPropsThreePerRow}>
                  <SelectYesNo
                    placeholder={t('label.has_confirmed_data_integrity')}
                    name={'has_confirmed_data_integrity'}
                    isDisabled={!isEditable}
                  />
                </Col>
                <Col {...colPropsThreePerRow}>
                  <SelectYesNo
                    placeholder={t('label.has_confirmed_business_relations')}
                    name={'has_confirmed_business_relations'}
                    isDisabled={!isEditable}
                  />
                </Col>
                <Col {...colPropsThreePerRow}>
                  <SelectYesNo
                    placeholder={t('label.has_confirmed_fact_checking')}
                    name={'has_confirmed_fact_checking'}
                    isDisabled={!isEditable}
                  />
                </Col>
              </Row>

              <hr />
              <h4 className="mb-4">{t('prospect_applications.company.experience')}</h4>
              <Row>
                <Col {...colPropsTwoPerRow}>
                  <TextInput
                    type={'experience.manager_experience_in_area_size'}
                    name={'experience.manager_experience_in_area_size'}
                    disabled={!isEditable}
                  />
                </Col>
                <Col {...colPropsTwoPerRow}>
                  <TextInput
                    type={'experience.manager_comments'}
                    name={'experience.manager_comments'}
                    disabled={!isEditable}
                  />
                </Col>
                <Col {...colPropsTwoPerRow}>
                  <TextInput
                    type={'experience.group_experience_in_area_size'}
                    name={'experience.group_experience_in_area_size'}
                    disabled={!isEditable}
                  />
                </Col>
                <Col {...colPropsTwoPerRow}>
                  <TextInput
                    type={'experience.group_comments'}
                    name={'experience.group_comments'}
                    disabled={!isEditable}
                  />
                </Col>
              </Row>
              <Row>
                <div className="mt-4 mb-4">
                  <SaveButton
                    title={t('common.save')}
                    submitting={isSubmitting}
                    disabled={!isEditable}
                  />
                </div>
              </Row>
            </FormGroup>
          </Form>
        )}
      </Formik>
      <Row>
        <hr />
        <h3>{t('label.company.beneficiaries')}</h3>
        {prospectApplication?.beneficiaries?.data?.map(
          (beneficiary: ProspectApplicationBeneficiary, index: number) => {
            return (
              <Row key={index} className={'mb-5'}>
                <SidebarDataRow label={t('label.full_name')} value={beneficiary.full_name} />
                <SidebarDataRow
                  label={t('label.shares_direct')}
                  value={beneficiary.shares_direct}
                />
                <SidebarDataRow
                  label={t('label.shares_not_direct')}
                  value={beneficiary.shares_not_direct}
                />
                <SidebarDataRow label={t('label.gov_code')} value={beneficiary.gov_code} />
                <SidebarDataRow
                  label={t('label.nationality')}
                  value={t(`countries.${beneficiary.nationality}`)}
                />
                <SidebarDataRow
                  label={t('label.nationality')}
                  value={t(`countries.${beneficiary.tax_residency_country}`)}
                />
                <SidebarDataRow
                  label={t('label.shareholder_id')}
                  value={beneficiary.shareholder_id}
                />
                <SidebarDataRow
                  label={t('label.company.is_public_official')}
                  value={beneficiary.is_public_official ? t('common.yes') : t('common.no')}
                />
              </Row>
            );
          },
        )}
      </Row>
      <hr />
      <Row>
        <h3>{t('applications.documents.list')}</h3>
        <SimpleTable
          columns={documentColumns}
          data={prospectApplication?.prospect_user_company?.documents ?? []}
        />
      </Row>
    </React.Fragment>
  );
};
export default withProspectApplication(TabCompany);
