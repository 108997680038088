import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import React from 'react';

import { AutoInvestorDraftQueueResponse } from 'src/types/api/autoInvestment';

interface Props {
  results?: AutoInvestorDraftQueueResponse;
}

const AutoInvestmentDraftResults: React.FC<Props> = ({ results }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className={'mb-3'}>
        <h4>{t('auto_investment.results')}</h4>
        <hr />
      </div>
      <Row>
        <Col>
          <h5>{t('label.required_amount')}</h5>
          <h5>
            {results?.required_amount ? t('common.money', { value: results.required_amount }) : '-'}
          </h5>
        </Col>
        <Col>
          <h5>{t('auto_investment.investment_estimate')}</h5>
          <h5>{t('common.money', { value: results?.investment_estimate })}</h5>
        </Col>
        <Col>
          <h5>{t('auto_investment.number_of_investors')}</h5>
          <h5>{results?.number_of_investors}</h5>
        </Col>
      </Row>
    </>
  );
};

export default AutoInvestmentDraftResults;
