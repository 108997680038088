import React, { useState } from 'react';
import SelectInput, { ReactSelectOption } from './SelectInput';
import { StateManagerProps } from 'react-select/dist/declarations/src/stateManager';
import { RatingEnum } from 'src/helpers/Enums/Project/RatingEnum';

interface Props extends StateManagerProps {
  name: string;
  placeholder: string;
}

const SelectRatingInput: React.FC<Props> = (props) => {
  const [options] = useState<ReactSelectOption[]>([
    ...Object.values(RatingEnum).map((rating) => ({
      label: rating,
      value: rating,
    })),
  ]);

  return <SelectInput {...props} options={options} />;
};

export default SelectRatingInput;
