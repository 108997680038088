import { PaginationData, PaginationDataFilter } from 'src/types';
import Client from './client';
import {
  StoreBonusRequestDto,
  BonusListResponseDto,
  BonusSystemResponseDto,
  BonusRuleResponseDto,
  BonusSystemByProjectAndInvestorRequestDto,
  BonusSystemBriefResponseDto,
} from 'src/types/api/bonusSystem';
import { SignUploadRequestDto } from 'src/types/api/common';

class BonusSystem extends Client {
  api = {
    FETCH_BONUS: `${this.http.baseUrl}/bonus-system/{bonusId}`,
    CREATE_BONUS: `${this.http.baseUrl}/bonus-system`,
    FETCH_FILTERED_BONUSES: `${this.http.baseUrl}/bonus-system/filter`,
    GET_BONUSES_BY_USER_AND_PROJECT: `${this.http.baseUrl}/bonus-system/get-by-user-project`,
    UPDATE_BONUS: `${this.http.baseUrl}/bonus-system/{bonusId}`,
    DISABLE_BONUS: `${this.http.baseUrl}/bonus-system/{bonusId}`,
    FETCH_BONUS_RULES: `${this.http.baseUrl}/bonus-system/rules`,
    GET_BONUSES_BY_INVESTMENT: `${this.http.baseUrl}/bonus-system/get-by-investment/{investmentId}`,
    UPLOAD_EMAIL_LIST: `${this.http.baseUrl}/bonus-system/upload-email-list`,
  };

  fetchBonus = (bonusId: number | string): Promise<BonusSystemResponseDto> => {
    const url = this.buildUrl(this.api.FETCH_BONUS, { bonusId });

    return this.http.get(url);
  };

  fetchBonusRules = (): Promise<Array<BonusRuleResponseDto>> => {
    return this.http.get(this.api.FETCH_BONUS_RULES);
  };

  createBonus = (request: StoreBonusRequestDto): Promise<BonusSystemResponseDto> => {
    return this.http.post(this.api.CREATE_BONUS, request);
  };

  fetchFilteredBonuses = (
    filter?: PaginationDataFilter,
  ): Promise<PaginationData<BonusListResponseDto>> => {
    return this.http.get(this.api.FETCH_FILTERED_BONUSES, { params: filter });
  };

  getBonusesByUserAndProject = (
    request: BonusSystemByProjectAndInvestorRequestDto,
  ): Promise<BonusSystemBriefResponseDto[]> => {
    return this.http.get(this.api.GET_BONUSES_BY_USER_AND_PROJECT, { params: request });
  };

  getBonusesByInvestment = (investmentId: string): Promise<BonusSystemBriefResponseDto[]> => {
    const url = this.buildUrl(this.api.GET_BONUSES_BY_INVESTMENT, { investmentId });

    return this.http.get(url);
  };

  updateBonus = (
    bonusId: number | string,
    request: StoreBonusRequestDto,
  ): Promise<BonusSystemResponseDto> => {
    const url = this.buildUrl(this.api.UPDATE_BONUS, { bonusId });

    return this.http.put(url, request);
  };

  disableBonus = (bonusId: number | string): Promise<any> => {
    const url = this.buildUrl(this.api.DISABLE_BONUS, { bonusId });

    return this.http.delete(url);
  };

  importCsvEmailList = (request: SignUploadRequestDto): Promise<any> => {
    return this.http.post(this.api.UPLOAD_EMAIL_LIST, request);
  };
}

export default BonusSystem;
