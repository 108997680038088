import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Form, FormGroup } from 'reactstrap';
import PrimaryButton from 'src/components/Form/PrimaryButton';
import TextAreaInput from 'src/components/Form/TextAreaInput';
import TextInput from 'src/components/Form/TextInput';
import { StoreCommentRequestDto } from 'src/types/api/DebtCollection';

const INITIAL_STATE: StoreCommentRequestDto = {
  stage_id: '',
  comment: '',
};

interface CommentFormProps {
  initial?: StoreCommentRequestDto;
  onSubmit: (request: StoreCommentRequestDto, helper: any) => void;
}

const CommentForm: React.FC<CommentFormProps> = ({ initial, onSubmit }) => {
  const { t } = useTranslation();

  const CommentSchema = Yup.object().shape({
    stage_id: Yup.number().required(),
    comment: Yup.string().required(),
  });
  const [commentRequest, setCommentRequest] = useState<StoreCommentRequestDto>(INITIAL_STATE);

  useEffect(() => {
    if (initial) {
      setCommentRequest(initial);
    }
  }, [initial]);

  return (
    <React.Fragment>
      <Formik
        initialValues={commentRequest}
        enableReinitialize={true}
        validationSchema={CommentSchema}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <div hidden>
                <TextInput name={'stage_id'} />
              </div>
              <div className="mb-3">
                <TextAreaInput name={'comment'} />
              </div>
              <div className={'mt-4 mb-4'}>
                <PrimaryButton title={t('common.submit')} submitting={isSubmitting} />
              </div>
            </FormGroup>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};
export default CommentForm;
