import React from 'react';
import AuthRoute from '../AuthRoute';
import { RouteList } from 'src/routes';
import PageLayout from 'src/containers/PageLayout';
import { Permissions } from 'src/helpers/auth/permissions';
import PricingTool from 'src/pages/Forecast/PricingTool';

const ForecastRoutes: any = () => [
  <AuthRoute
    path={RouteList.FORECAST.PRICING_TOOL}
    component={PricingTool}
    layout={PageLayout}
    permissions={[Permissions.BO__FORECAST__PRICING_TOOL]}
    redirect={RouteList.AUTHENTICATION.LOGIN}
    key={RouteList.FORECAST.PRICING_TOOL}
    exact
  />,
];

export default ForecastRoutes;
