import React, { useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import Api from 'src/api';
import { RouteList } from 'src/routes';
import { Permissions } from 'src/helpers/auth/permissions';
import { setGlobalLoading } from 'src/modules/app/actions';
import TabData, { BaseTabProps, TabInfo } from 'src/components/Tabs';
import PrimaryButton from 'src/components/Form/PrimaryButton';
import DebtCollectionProvider from 'src/pages/DebtCollection/Update/DebtCollectionContext';
import { DebtCollectionResponseDto } from 'src/types/api/DebtCollection';
import TabProjectOwner from 'src/pages/DebtCollection/Update/Tabs/TabProjectOwner';
import TabSecurities from 'src/pages/DebtCollection/Update/Tabs/TabSecurities';
import TabLatePayoutsList from 'src/pages/DebtCollection/Update/Tabs/TabLatePayoutsList';
import TabActions from 'src/pages/DebtCollection/Update/Tabs/TabActions';
import TabContractTermination from 'src/pages/DebtCollection/Update/Tabs/TabContractTermination';
import TabHistory from 'src/pages/DebtCollection/Update/Tabs/TabHistory';
import Loader from 'src/components/Loader';
import IconRefresh from 'src/components/Table/AdvanceFilters/Icons/IconRefresh';
import { debounce } from 'lodash';

const Tabs: TabInfo<BaseTabProps>[] = [
  {
    name: 'debt_collection.project_owner',
    iconClass: 'far fa-file',
    component: TabProjectOwner,
    permission: [Permissions.BO__DEBT_COLLECTION__VIEW],
  },
  {
    name: 'debt_collection.securities',
    iconClass: 'far fa-file',
    component: TabSecurities,
    permission: [Permissions.BO__DEBT_COLLECTION__VIEW],
  },
  {
    name: 'debt_collection.late_payouts',
    iconClass: 'far fa-file',
    component: TabLatePayoutsList,
    permission: [Permissions.BO__DEBT_COLLECTION__VIEW],
  },
  {
    name: 'debt_collection.contract_termination',
    iconClass: 'far fa-file',
    component: TabContractTermination,
    permission: [Permissions.BO__DEBT_COLLECTION__TERMINATE_CONTRACT],
  },
  {
    name: 'debt_collection.actions',
    iconClass: 'far fa-file',
    component: TabActions,
    permission: [Permissions.BO__DEBT_COLLECTION__VIEW],
  },
  {
    name: 'debt_collection.history',
    iconClass: 'far fa-file',
    component: TabHistory,
    permission: [Permissions.BO__DEBT_COLLECTION__VIEW],
  },
];

const DebtCollection: React.FC = () => {
  const { t } = useTranslation();
  const { stageId } = useParams<{ stageId: string }>();
  const [debtCollection, setDebtCollection] = useState<DebtCollectionResponseDto>();

  const debouncedEventHandler = useCallback(
    debounce((stageId: string) => {
      Api.debtCollection.fetchDebtCollection(stageId).then((response) => {
        setDebtCollection(response);
      });
    }, 1000),
    [],
  );

  useEffect(() => {
    setGlobalLoading(true);
    if (stageId) {
      Api.debtCollection.fetchDebtCollection(stageId).then((response) => {
        setDebtCollection(response);
        setGlobalLoading(false);
      });
    }
  }, [stageId]);

  if (!debtCollection) {
    return <></>;
  }

  return (
    <React.Fragment>
      <DebtCollectionProvider debtCollection={debtCollection} setDebtCollection={setDebtCollection}>
        <Container fluid>
          <Row>
            <Col md={1} className={'mb-4'}>
              <div>
                <Link to={RouteList.DEBT_COLLECTION.LIST}>
                  <PrimaryButton title={t('common.back')} type={'button'} />
                </Link>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs="12" className={'mb-4'}>
              <Card className="card h-100">
                <CardBody>
                  <Row>
                    <Col>
                      <div>{debtCollection.loan_agreement_number}</div>
                      <div>{debtCollection.status}</div>
                    </Col>
                    <Col>
                      <div>{debtCollection.owner.name}</div>
                      <div>{debtCollection.owner.pid}</div>
                    </Col>
                    <Col>
                      <div>{t('common.money', { value: debtCollection.total_amount })}</div>
                    </Col>
                    <Col>
                      <div>
                        {t('debt_collection.contract_terminated')}&nbsp;
                        {debtCollection.contract_terminated_at
                          ? debtCollection.contract_terminated_at
                          : t('common.no')}
                      </div>
                      <div>
                        {t('debt_collection.interest_calculation_terminated')}&nbsp;
                        {debtCollection.interest_calculation_terminated_at
                          ? debtCollection.interest_calculation_terminated_at
                          : t('common.no')}
                      </div>
                    </Col>
                  </Row>
                  {debtCollection.calculation_in_progress_at && (
                    <div
                      className={
                        'd-flex justify-content-center align-items-center mt-2 bg-warning badge bg-secondary p-2 position-relative'
                      }
                    >
                      <Loader />
                      &nbsp;
                      {t('debt_collection.calculation_in_progress')}
                      &nbsp;
                      <button
                        type="button"
                        className="btn btn-info btn-sm position-absolute end-0 h-100"
                        onClick={() => debouncedEventHandler(stageId)}
                      >
                        <IconRefresh classes={'icon-dropdown'} /> {t('common.refresh')}
                      </button>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
            <Col xs="12">
              <Card className="mb-4">
                <CardBody>{debtCollection && <TabData tabs={Tabs} vertical={true} />}</CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </DebtCollectionProvider>
    </React.Fragment>
  );
};

export default DebtCollection;
