import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Col, Form, Row, UncontrolledTooltip } from 'reactstrap';
import { addMonths, format, startOfMonth, subMonths } from 'date-fns';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import Loader from 'src/components/Loader';
import DatePickerInput from 'src/components/Form/DatePickerInput';
import SaveButton from 'src/components/Form/SaveButton';
import Api from 'src/api';
import {
  DashboardPageGraphDataDto,
  DashboardPortfolioWeightedRatingRequestDto,
} from 'src/types/api/dashboard';
import { SelectAsyncInput, SelectInput } from 'src/components/Form/Select';
import { PaginationDataFilter } from 'src/types';
import { ReactSelectOption } from 'src/components/Form/Select/SelectInput';
import { UserBriefResponseDto } from 'src/types/api/user';
import CountryLabel from 'src/components/Labels/CountryLabel';
import SliderWithRange from 'src/components/SliderWithRange';
import SanitizedHtmlBlock from 'src/components/SanitizedHtmlBlock';
import StreamFileButton from 'src/components/Table/Buttons/StreamFileButton';
import { round } from 'lodash';

const WightedRatingChartTick: React.FC = (props: any) => {
  const { payload } = props;
  const { value } = payload;

  const mapWeightedRatingToRating = (value: number) => {
    const ratingMap = new Map<number, string>([
      [1, 'AAA'],
      [2, 'AA+'],
      [3, 'AA'],
      [4, 'AA-'],
      [5, 'A+'],
      [6, 'A'],
      [7, 'A-'],
      [8, 'BBB+'],
      [9, 'BBB'],
      [10, 'BBB-'],
    ]);

    let rating = ratingMap.get(value);

    if (rating) {
      return rating;
    }

    rating = ratingMap.get(round(value, 0));

    return rating ? `~${rating}` : null;
  };

  const rating = mapWeightedRatingToRating(value);

  return (
    <g>
      <g>
        <text {...props} alignmentBaseline={'middle'}>
          {rating ? `${value.toString()} (${rating})` : value.toString()}
        </text>
      </g>
    </g>
  );
};

export const DashboardWeightedRatingBlock = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<DashboardPageGraphDataDto[]>([]);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const [formValues, setFormValues] = useState<DashboardPortfolioWeightedRatingRequestDto>({
    calculation_mode: 'individual_stages',
    stages: null,
    sales_manager_options: [],
    sales_managers: [],
    analytic_options: [],
    analytics: [],
    countries: [],
    types: [],
    amount_from: null,
    amount_to: null,
    funded_at_from: format(startOfMonth(subMonths(new Date(), 11)), 'yyyy-MM-dd'),
    funded_at_to: format(startOfMonth(addMonths(new Date(), 0)), 'yyyy-MM-dd'),
  });

  const [amountValues, setAmountValues] = useState<[number, number]>([
    formValues.amount_from ?? 0,
    formValues.amount_to ?? 5000000,
  ]);

  useEffect(() => {
    handleSubmit(formValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchSalesManagers = async (inputValue?: string, loadWith?: Array<string>) => {
    const request: PaginationDataFilter = {
      page: 1,
      limit: 100,
      sort: [],
      search: inputValue,
      with: loadWith,
    };

    const response = await Api.user.fetchFilterableManagersHistoric(request);
    const items: ReactSelectOption[] = [];

    response.data.map((salesManager: UserBriefResponseDto) => {
      items.push({
        value: salesManager.id ?? '',
        label: salesManager.name ?? '',
      });
    });

    return items;
  };

  const fetchAnalytics = async (inputValue?: string, loadWith?: Array<string>) => {
    const request: PaginationDataFilter = {
      page: 1,
      limit: 100,
      sort: [],
      search: inputValue,
      with: loadWith,
    };

    const response = await Api.user.fetchFilterableAnalyticsHistoric(request);
    const items: ReactSelectOption[] = [];

    response.data.map((analytic: UserBriefResponseDto) => {
      items.push({
        value: analytic.id ?? '',
        label: analytic.name ?? '',
      });
    });

    return items;
  };

  const handleSubmit = useCallback(
    async (request) => {
      setIsDisabled(true);
      const transformedRequest = {
        ...request,
        amount_from: Number(amountValues[0]),
        amount_to: Number(amountValues[1]),
      };

      try {
        const response = await Api.dashboard.fetchWeightedRatingBarChartData(transformedRequest);

        setFormValues(transformedRequest);
        setData(response.data);
      } finally {
        setIsDisabled(false);
      }
    },
    [amountValues],
  );

  const Schema = Yup.object().shape({
    funded_at_from: Yup.date().required(),
    funded_at_to: Yup.date()
      .required()
      .when('funded_at_from', (funded_at_from, schema) => {
        return funded_at_from
          ? schema.min(funded_at_from, t('validation.date.min', { min: funded_at_from }))
          : schema;
      }),
  });

  const [calculationModeOptions] = useState<ReactSelectOption[]>([
    {
      label: t('dashboard.weighted_rating.calculation_mode.individual_stages'),
      value: 'individual_stages',
    },
    {
      label: t('dashboard.weighted_rating.calculation_mode.group_stages'),
      value: 'group_stages',
    },
  ]);

  const [stagesOptions] = useState<ReactSelectOption[]>([
    {
      label: t('dashboard.weighted_rating.stages.stageable'),
      value: 'stageable',
    },
    {
      label: t('dashboard.weighted_rating.stages.not_stageable'),
      value: 'not_stageable',
    },
  ]);

  const [salesManagerOptions] = useState<ReactSelectOption[]>([
    {
      label: t('dashboard.weighted_rating.sales_manager_options.specific'),
      value: 'specific',
    },
    {
      label: t('dashboard.weighted_rating.sales_manager_options.blank'),
      value: 'blank',
    },
  ]);

  const [analyticOptions] = useState<ReactSelectOption[]>([
    {
      label: t('dashboard.weighted_rating.analytic_options.specific'),
      value: 'specific',
    },
    {
      label: t('dashboard.weighted_rating.analytic_options.blank'),
      value: 'blank',
    },
  ]);

  const [countryOptions] = useState<ReactSelectOption[]>([
    {
      value: 'lt',
      label: <CountryLabel code={'lt'} />,
      customText: t('countries.lt'),
    },
    {
      value: 'lv',
      label: <CountryLabel code={'lv'} />,
      customText: t('countries.lv'),
    },
    {
      value: 'ee',
      label: <CountryLabel code={'ee'} />,
      customText: t('countries.ee'),
    },
    {
      value: 'es',
      label: <CountryLabel code={'es'} />,
      customText: t('countries.es'),
    },
  ]);

  const [typeOptions] = useState<ReactSelectOption[]>([
    {
      label: t('dashboard.weighted_rating.types.active'),
      value: 'active',
    },
    {
      label: t('dashboard.weighted_rating.types.late'),
      value: 'late',
    },
    {
      label: t('dashboard.weighted_rating.types.recovering'),
      value: 'recovering',
    },
    {
      label: t('dashboard.weighted_rating.types.other'),
      value: 'other',
    },
  ]);

  return (
    <div>
      <h3 className="card-ml-1">{t('dashboard.weighted_rating')}</h3>
      <div className={'d-flex'}>
        <div className={'w-100 card-m-1'}>
          <Formik
            initialValues={formValues}
            enableReinitialize={true}
            onSubmit={handleSubmit}
            validationSchema={Schema}
          >
            {({ handleSubmit, values }) => (
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col md={6} className={'mb-3'}>
                    <div>
                      <SelectInput
                        name={'calculation_mode'}
                        placeholder={t('dashboard.weighted_rating.calculation_mode')}
                        options={calculationModeOptions}
                        tooltip={t('dashboard.weighted_rating.calculation_mode.tooltip')}
                      />
                    </div>
                  </Col>
                  <Col md={6} className={'mb-3'}>
                    <div>
                      <SelectInput
                        name={'stages'}
                        placeholder={t('dashboard.weighted_rating.stages')}
                        options={stagesOptions}
                        isClearable={true}
                        tooltip={t('dashboard.weighted_rating.stages.tooltip')}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} className={'mb-3'}>
                    <div>
                      <SelectInput
                        name={'sales_manager_options'}
                        placeholder={t('dashboard.weighted_rating.sales_manager_options')}
                        options={salesManagerOptions}
                        isMulti={true}
                        tooltip={t('dashboard.weighted_rating.sales_manager_options.tooltip')}
                      />
                    </div>
                    {values.sales_manager_options.includes('specific') && (
                      <div className={'mt-3'}>
                        <SelectAsyncInput
                          name={'sales_managers'}
                          placeholder={t('dashboard.weighted_rating.sales_managers')}
                          isClearable={true}
                          isMulti={true}
                          loadOptions={fetchSalesManagers}
                        />
                      </div>
                    )}
                  </Col>
                  <Col md={6} className={'mb-3'}>
                    <div>
                      <SelectInput
                        name={'analytic_options'}
                        placeholder={t('dashboard.weighted_rating.analytic_options')}
                        options={analyticOptions}
                        isMulti={true}
                        tooltip={t('dashboard.weighted_rating.analytic_options.tooltip')}
                      />
                    </div>
                    {values.analytic_options.includes('specific') && (
                      <div className={'mt-3'}>
                        <SelectAsyncInput
                          name={'analytics'}
                          placeholder={t('dashboard.weighted_rating.analytics')}
                          isClearable={true}
                          isMulti={true}
                          loadOptions={fetchAnalytics}
                        />
                      </div>
                    )}
                  </Col>
                  <Col md={6} className={'mb-3'}>
                    <div>
                      <SelectInput
                        name={'countries'}
                        placeholder={t('dashboard.weighted_rating.countries')}
                        options={countryOptions}
                        isMulti={true}
                      />
                    </div>
                  </Col>
                  <Col md={6} className={'mb-3'}>
                    <div>
                      <SelectInput
                        name={'types'}
                        placeholder={t('dashboard.weighted_rating.types')}
                        options={typeOptions}
                        isMulti={true}
                        isClearable={true}
                        tooltip={t('dashboard.weighted_rating.types.tooltip')}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className={'mb-3'}>
                  <div>
                    <label htmlFor="amount_range" id={'amount_range_label'}>
                      {t('dashboard.weighted_rating.amount_range')}
                    </label>
                    <UncontrolledTooltip target={'amount_range_label'}>
                      <SanitizedHtmlBlock
                        content={t('dashboard.weighted_rating.amount_range.tooltip')}
                      />
                    </UncontrolledTooltip>
                    <SliderWithRange
                      min={0}
                      max={5000000}
                      step={10000}
                      values={amountValues}
                      translation={'common.money'}
                      onChange={setAmountValues}
                    />
                  </div>
                </Row>
                <Row>
                  <Col md={6} className={'mb-3'}>
                    <div>
                      <DatePickerInput
                        name={'funded_at_from'}
                        placeholder={t(
                          values.calculation_mode == 'individual_stages'
                            ? 'dashboard.weighted_rating.funded_at_from.phase_based'
                            : 'dashboard.weighted_rating.funded_at_from.project_based',
                        )}
                        showMonthYearPicker
                      />
                    </div>
                  </Col>
                  <Col md={6} className={'mb-3'}>
                    <div>
                      <DatePickerInput
                        name={'funded_at_to'}
                        placeholder={t(
                          values.calculation_mode == 'individual_stages'
                            ? 'dashboard.weighted_rating.funded_at_to.phase_based'
                            : 'dashboard.weighted_rating.funded_at_to.project_based',
                        )}
                        showMonthYearPicker
                      />
                    </div>
                  </Col>
                </Row>
                <div className={'mt-4 mb-3'}>
                  <SaveButton title={t('common.submit')} submitting={isDisabled} />
                </div>
              </Form>
            )}
          </Formik>
          <div>
            {data.length === 0 ? (
              <div className="d-flex justify-content-center align-items-center mt-5">
                <Loader />
              </div>
            ) : (
              <>
                <div>
                  <StreamFileButton
                    buttonTitle={t('common.export')}
                    fileName={'weighted_rating'}
                    extension={'xlsx'}
                    buttonClassName={'w-100'}
                    request={() => Api.dashboard.exportWeightedRatingsAsXlsx(formValues)}
                  />
                </div>

                <ResponsiveContainer
                  className="mt-5"
                  width="100%"
                  height="100%"
                  minHeight="500px"
                  maxHeight={500}
                >
                  <LineChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{ top: 30, right: 10, left: 25 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis
                      allowDecimals={false}
                      domain={[5, 7]}
                      reversed={true}
                      tick={<WightedRatingChartTick />}
                      tickCount={20}
                    />
                    <ReferenceLine y={6} stroke="red" />
                    <Tooltip />
                    <Legend />
                    <Line
                      dataKey="value"
                      fill="#8884d8"
                      name={t('dashboard.weighted_rating')}
                      strokeWidth={2}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
