import { ProspectApplicationResponseDto } from 'src/types/api/prospectApplications';
import React, { createContext, useContext } from 'react';

export interface ProspectApplicationContextState {
  prospectApplication: ProspectApplicationResponseDto | undefined;
  setProspectApplication: (prospectApplication: ProspectApplicationResponseDto) => void;
  setIsEditable: (isEditable: boolean) => void;
  isEditable: boolean;
}

const ProspectApplicationContext = createContext<ProspectApplicationContextState>({
  prospectApplication: undefined,
  setProspectApplication: () => {
    return;
  },
  setIsEditable: () => {
    return;
  },
  isEditable: false,
});

export const ProspectApplicationProvider: React.FC<ProspectApplicationContextState> = ({
  prospectApplication,
  setProspectApplication,
  isEditable,
  setIsEditable,
  children,
}) => {
  return (
    <ProspectApplicationContext.Provider
      value={{ prospectApplication, setProspectApplication, isEditable, setIsEditable }}
    >
      {children}
    </ProspectApplicationContext.Provider>
  );
};

type Optionalize<T extends K, K> = Omit<T, keyof K>;

export function withProspectApplication<
  P extends ProspectApplicationContextState = ProspectApplicationContextState,
>(Component: React.ComponentType<P>) {
  const WithProspectApplication = ({
    ...props
  }: Optionalize<P, ProspectApplicationContextState>) => {
    const c = useContext(ProspectApplicationContext);
    return <Component {...c} {...(props as P)} />;
  };

  WithProspectApplication.displayName = `withProspectApplication${Component.displayName}`;

  return WithProspectApplication;
}

export default ProspectApplicationProvider;
