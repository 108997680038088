import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Col, FormGroup, Row } from 'reactstrap';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import Api from 'src/api';
import useReportTypeStore from 'src/pages/Reports/View/ReportTypeStore';
import usePermissions from 'src/helpers/usePermissions';
import { mapManagePermission, ReportTypeEnum } from 'src/helpers/Enums/ReportTypeEnum';
import { success } from 'src/services/toastr';
import TextInput from 'src/components/Form/TextInput';
import SaveButton from 'src/components/Form/SaveButton';
import DatePickerInput from 'src/components/Form/DatePickerInput';
import { GenerateReportRequestDto, ReferralReportFilterForm } from 'src/types/api/reports';
import { appendDateFilter, appendFilter, initialRequest } from 'src/pages/Reports/View/helpers';
import SelectColumnsInput from 'src/pages/Reports/View/Filters/Selects/SelectColumnsInput';

const AVAILABLE_COLUMNS: string[] = [
  'user_invited_by_user_id',
  'user_invited_email',
  'user_invited_user_id',
  'user_invited_user_name',
  'user_invested_per_period',
  'user_invited_count_global',
  'user_invited_count_registered',
  'user_first_investment_date',
  'user_invitation_date',
  'user_registration_date',
  'user_is_registered',
  'user_first_investment_without_bonus_global',
  'user_first_investment_bonus_global',
  'user_how_much_money_invested',
  'user_how_many_investments',
  'user_average_investment',
];

const TYPE: ReportTypeEnum = ReportTypeEnum.REFERRALS;

const ReferralsReportFilter: React.FC = () => {
  const { t } = useTranslation();
  const p = usePermissions();
  const { refreshTable } = useReportTypeStore();

  const [request] = useState<ReferralReportFilterForm>({
    columns: [],
    name: null,
    from: null,
    to: null,
    user_invited_by_user_id: null,
  });

  const Schema = Yup.object().shape({
    from: Yup.date().nullable(),
    to: Yup.date().nullable(),
    user_invited_by_user_id: Yup.string().nullable(),
  });

  const mapData = (form: ReferralReportFilterForm): GenerateReportRequestDto => {
    const request = initialRequest(form, TYPE);

    appendDateFilter(request, 'daterange', form.from, form.to);
    appendFilter(request, 'user_invited_by_user_id', form.user_invited_by_user_id);
    return request;
  };

  const onSubmit = useCallback(
    async (request: ReferralReportFilterForm, helper: FormikHelpers<ReferralReportFilterForm>) => {
      try {
        await Api.reports.generateReport(mapData(request)).then(() => {
          refreshTable();
          success(t('common.success'));
        });
      } catch (e: any) {
        helper.setErrors(e.response?.errors);
      }
    },
    [refreshTable, t],
  );

  if (!p.hasAll([mapManagePermission(TYPE)])) return null;

  return (
    <div className={'mb-4'}>
      <Card>
        <CardBody>
          <h4 className={'mb-4'}>{t('reports.filter.' + TYPE)}</h4>
          <div className={'mb-4'}>
            <Formik
              initialValues={request}
              onSubmit={onSubmit}
              enableReinitialize={true}
              validationSchema={Schema}
              validateOnChange={true}
            >
              {({ handleSubmit, isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <FormGroup>
                    <Row>
                      <Col sm={3} className={'mb-4'}>
                        <TextInput name={'name'} />
                      </Col>
                      <Col sm={1} className={'mb-4'}>
                        <SelectColumnsInput columns={AVAILABLE_COLUMNS} />
                      </Col>
                      <Col sm={2} className={'mb-4'}>
                        <DatePickerInput name={'from'} showTimeSelect />
                      </Col>
                      <Col sm={2} className={'mb-4'}>
                        <DatePickerInput name={'to'} showTimeSelect />
                      </Col>
                      <Col sm={3} className={'mb-4'}>
                        <TextInput name={'user_invited_by_user_id'} />
                      </Col>
                    </Row>
                    <div className={'mb-4 mt-3'}>
                      <SaveButton title={t('common.generate')} submitting={isSubmitting} />
                    </div>
                  </FormGroup>
                </Form>
              )}
            </Formik>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default ReferralsReportFilter;
