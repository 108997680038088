import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProjectContextState, withProject } from 'src/pages/Project/Update/ProjectContext';
import { Field, FieldArray, FormikHelpers } from 'formik';
import { Col, ColProps, FormGroup, Row } from 'reactstrap';
import TextInput from 'src/components/Form/TextInput';
import PrimaryButton from 'src/components/Form/PrimaryButton';
import { UpdateProjectInvestmentRequestDto } from 'src/types/api/projects';
import SelectRatingInput from 'src/components/Form/Select/SelectRatingInput';
import SelectCreditDurationInput from 'src/components/Form/Select/SelectCreditDurationInput';
import SelectInterestFrequencyInput from 'src/components/Form/Select/SelectInterestFrequencyInput';
import SelectSecurityMeasureInput from 'src/components/Form/Select/SelectSecurityMeasureInput';
import SelectCreditReturnInput from 'src/components/Form/Select/SelectCreditReturnInput';
import TextAreaInput from 'src/components/Form/TextAreaInput';
import Api from 'src/api';
import { success } from 'src/services/toastr';
import { transformErrors } from 'src/helpers';
import * as Yup from 'yup';

import SingleInvestmentBonus from 'src/pages/Project/Update/Tabs/Investments/SingleInvestmentBonus';
import SaveButton from 'src/components/Form/SaveButton';
import { TypeOfInvestmentEnum } from 'src/helpers/Enums/Project/TypeOfInvestmentEnum';
import SelectCustomValueInput, {
  formatObjectForCustomValueInput,
} from 'src/components/Form/Select/SelectCustomValueInput';
import { UseNumbers } from 'src/helpers/useNumbers';
import ActiveLocaleFlag from 'src/components/ActiveLocaleFlag';
import { BonusInterestRequestDto } from 'src/types/api/common';
import LocaleFormik from 'src/components/Form/LocaleFormik/LocaleFormik';
import SelectRiskCategoryInput from 'src/components/Form/Select/SelectRiskCategoryInput';
import usePermissions from 'src/helpers/usePermissions';
import { Permissions } from 'src/helpers/auth/permissions';
import { ProjectStatus } from 'src/types/app/projects';
import SelectInvestmentPurposeInput from 'src/components/Form/Select/SelectInvestmentPurposeInput';

const INITIAL_INVESTMENT_BONUS: BonusInterestRequestDto = {
  amount: 0,
  bonus_interest: 0,
};
const INITIAL_REQUEST: UpdateProjectInvestmentRequestDto = {
  required_amount: null,
  basic_interest: null,
  initial_rating: null,
  actual_rating: null,
  risk_category: null,
  loan_ratio: null,
  loan_ratio_external: null,
  loan_ratio_internal: null,
  loan_ratio_max: null,
  total_project_duration: null,
  total_project_amount: null,
  credit_duration: null,
  interest_frequency: null,
  security_measures: null,
  credit_return: null,
  days_to_get_money: null,
  minimal_credit_amount: null,
  minimal_investment: 0,
  investment_purpose: null,
  type_of_investment: null,
  source_of_funds: null,
  early_return_rules: null,
  exceptional_investment_conditions_description: null,
  bonus_interests: null,
};

const TabInvestment: React.FC<ProjectContextState> = ({
  project,
  setProject,
  language,
  onErrorHandling,
}) => {
  const { t } = useTranslation();
  const p = usePermissions();

  const [request, setRequest] = useState<UpdateProjectInvestmentRequestDto>(INITIAL_REQUEST);

  useEffect(() => {
    if (!project) return;

    setRequest(() => ({
      required_amount: project.required_amount,
      basic_interest: project.basic_interest,
      initial_rating: project.initial_rating,
      actual_rating: project.actual_rating,
      risk_category: project.risk_category,
      loan_ratio: project.loan_ratio,
      loan_ratio_external: project.loan_ratio_external,
      loan_ratio_internal: project.loan_ratio_internal,
      loan_ratio_max: project.loan_ratio_max,
      total_project_duration: project.total_project_duration,
      total_project_amount: project.total_project_amount,
      credit_duration: project.credit_duration,
      interest_frequency: project.interest_frequency,
      security_measures: project.security_measures,
      credit_return: project.credit_return,
      days_to_get_money: project.days_to_get_money,
      minimal_credit_amount: project.minimal_credit_amount,
      minimal_investment: project.minimal_investment,
      investment_purpose: project.investment_purpose,
      type_of_investment: project.type_of_investment,
      source_of_funds: project.source_of_funds,
      early_return_rules: project.early_return_rules,
      exceptional_investment_conditions_description:
        project.exceptional_investment_conditions_description,
      bonus_interests: project.bonus_interests,
    }));
  }, [project]);

  const onSubmit = useCallback(
    async (
      request: UpdateProjectInvestmentRequestDto,
      helpers: FormikHelpers<UpdateProjectInvestmentRequestDto>,
    ) => {
      if (!project) return;

      try {
        request = {
          ...request,
          type_of_investment: formatObjectForCustomValueInput(request.type_of_investment),
        };

        const response = await Api.projects.storeProjectInvestment(project.id, request);
        setProject(response);
        success(t('common.updated_success'));
      } catch (e: any) {
        helpers.setErrors(transformErrors(e.response?.errors));
        onErrorHandling(e);
      }

      return true;
    },
    [onErrorHandling, project, setProject, t],
  );

  const colProps: ColProps = {
    md: 4,
    className: 'mb-4',
  };

  // Method used to generate common attributes by input name
  const multiLocaleAttrs = useCallback(
    (inputName: string) => {
      return {
        name: inputName + '.' + language,
        placeholder: t('label.' + inputName),
      };
    },
    [language, t],
  );

  const InvestmentSchema = Yup.object().shape({
    minimal_investment: Yup.number()
      .lessThanField('required_amount', t('label.required_amount'))
      .required(),
    required_amount: Yup.number().required(),
  });

  const fundedAndAbove = (status: ProjectStatus) => {
    return [ProjectStatus.Funded, ProjectStatus.Confirmed, ProjectStatus.Finished].includes(status);
  };

  return (
    <React.Fragment>
      <h3 className={'flex'}>
        {t('projects.investment')}
        <ActiveLocaleFlag locale={language} className={'tabs'} />
      </h3>
      <hr />

      <LocaleFormik
        locale={language}
        initialValues={request}
        enableReinitialize={true}
        onSubmit={onSubmit}
        validationSchema={InvestmentSchema}
      >
        {({ isSubmitting, values }) => (
          <FormGroup>
            <Row>
              <Col {...colProps}>
                <TextInput
                  type={'number'}
                  step={'any'}
                  name={'required_amount'}
                  onKeyPress={UseNumbers.preventNonNumericalInput}
                />
              </Col>
              <Col {...colProps}>
                <TextInput
                  type={'number'}
                  step={'any'}
                  name={'basic_interest'}
                  onKeyPress={UseNumbers.preventNonNumericalInput}
                />
              </Col>
              <Col {...colProps}>
                <SelectRatingInput
                  name={'initial_rating'}
                  placeholder={t('label.initial_rating')}
                  isDisabled={true}
                />
              </Col>
              <Col {...colProps}>
                <SelectRatingInput
                  name={'actual_rating'}
                  placeholder={t('label.actual_rating')}
                  isDisabled={
                    fundedAndAbove(project?.status as ProjectStatus) &&
                    !p.can(Permissions.BO__PROJECTS__UPDATE_SPECIAL_FIELDS_AFTER_FUNDED)
                  }
                />
              </Col>
              <Col {...colProps}>
                <SelectRiskCategoryInput
                  name={'risk_category'}
                  placeholder={t('label.risk_category')}
                  isDisabled={
                    fundedAndAbove(project?.status as ProjectStatus) &&
                    !p.can(Permissions.BO__PROJECTS__UPDATE_SPECIAL_FIELDS_AFTER_FUNDED)
                  }
                />
              </Col>
              <Col {...colProps}>
                <TextInput type={'text'} step={'any'} name={'loan_ratio'} disabled={true} />
              </Col>
              <Col {...colProps}>
                <TextInput
                  type={'number'}
                  step={'any'}
                  name={'loan_ratio_external'}
                  onKeyPress={UseNumbers.preventNonNumericalInput}
                />
              </Col>
              <Col {...colProps}>
                <TextInput
                  type={'number'}
                  step={'any'}
                  name={'loan_ratio_internal'}
                  onKeyPress={UseNumbers.preventNonNumericalInput}
                />
              </Col>
              <Col {...colProps}>
                <TextInput
                  type={'number'}
                  step={'any'}
                  name={'loan_ratio_max'}
                  onKeyPress={UseNumbers.preventNonNumericalInput}
                />
              </Col>
              <Col {...colProps}>
                <SelectCreditDurationInput name={'total_project_duration'} />
              </Col>
              <Col {...colProps}>
                <TextInput
                  type={'number'}
                  step={'any'}
                  name={'total_project_amount'}
                  onKeyPress={UseNumbers.preventNonNumericalInput}
                />
              </Col>
              <Col {...colProps}>
                <SelectCreditDurationInput
                  name={'credit_duration'}
                  placeholder={t('label.credit_duration')}
                />
              </Col>
              <Col {...colProps}>
                <SelectInterestFrequencyInput
                  name={'interest_frequency'}
                  placeholder={t('label.interest_frequency')}
                />
              </Col>
              <Col {...colProps}>
                <SelectSecurityMeasureInput
                  name={'security_measures'}
                  placeholder={t('label.security_measures')}
                />
              </Col>
              <Col {...colProps}>
                <SelectCreditReturnInput
                  placeholder={t('label.credit_return')}
                  name={'credit_return'}
                />
              </Col>
              <Col {...colProps}>
                <TextInput
                  type={'number'}
                  step={'any'}
                  name={'days_to_get_money'}
                  onKeyPress={UseNumbers.preventNonNumericalInput}
                />
              </Col>
              <Col {...colProps}>
                <TextInput
                  type={'number'}
                  step={'any'}
                  name={'minimal_credit_amount'}
                  onKeyPress={UseNumbers.preventNonNumericalInput}
                />
              </Col>
              <Col {...colProps}>
                <TextInput
                  type={'number'}
                  step={'any'}
                  name={'minimal_investment'}
                  onKeyPress={UseNumbers.preventNonNumericalInput}
                />
              </Col>
            </Row>
            <hr />
            <Row>
              <Col {...colProps} md={6}>
                <SelectInvestmentPurposeInput
                  placeholder={t('label.investment_purpose')}
                  name={'investment_purpose'}
                />
              </Col>
              <Col {...colProps} md={6}>
                <Field
                  as={SelectCustomValueInput}
                  options={Object.values(TypeOfInvestmentEnum).map((investmentType) => ({
                    label: t(`projects.type_of_investments.${investmentType}`, {
                      lng: language,
                    }),
                    value: `projects.type_of_investments.${investmentType}`,
                  }))}
                  otherOptionLabel={t(`projects.type_of_investments.other`, {
                    lng: language,
                  })}
                  isTranslatable={true}
                  language={language}
                  name={'type_of_investment'}
                  placeholder={multiLocaleAttrs('type_of_investment').placeholder}
                  otherPlaceholder={t('label.type_of_investment_other')}
                />
              </Col>
              <Col {...colProps}>
                <TextAreaInput {...multiLocaleAttrs('source_of_funds')} />
              </Col>
              <Col {...colProps}>
                <TextAreaInput {...multiLocaleAttrs('early_return_rules')} />
              </Col>
              <Col {...colProps}>
                <TextAreaInput
                  {...multiLocaleAttrs('exceptional_investment_conditions_description')}
                />
              </Col>
            </Row>
            <hr />
            <FieldArray name="bonus_interests">
              {(formikArrayHelpers) => (
                <>
                  <h3>{t('label.bonus_interests')}</h3>
                  <PrimaryButton
                    title={t('common.add')}
                    type={'button'}
                    className={'btn btn-success'}
                    submitting={isSubmitting}
                    onClick={() => formikArrayHelpers.push(INITIAL_INVESTMENT_BONUS)}
                  />
                  <table className={'table table-sm small'}>
                    <thead>
                      <tr>
                        <th className={'col-2'}>{t('label.bonus_amount')}</th>
                        <th className={'col-2'}>{t('label.bonus_interest')}</th>
                        <th className={'col-1'}></th>
                      </tr>
                    </thead>
                    <tbody>
                      {values.bonus_interests
                        ? values.bonus_interests.map((bonus, index) => {
                            return (
                              <SingleInvestmentBonus
                                formikArrayHelpers={formikArrayHelpers}
                                index={index}
                                key={index}
                              />
                            );
                          })
                        : null}
                    </tbody>
                  </table>
                </>
              )}
            </FieldArray>
            <hr />
            <Row>
              <div className={'mt-4 mb-4'}>
                <SaveButton title={t('common.save')} submitting={isSubmitting} />
              </div>
            </Row>
          </FormGroup>
        )}
      </LocaleFormik>
    </React.Fragment>
  );
};

export default withProject(TabInvestment);
