import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, FormGroup } from 'reactstrap';
import { FieldArray, Formik, FormikHelpers } from 'formik';
import PrimaryButton from 'src/components/Form/PrimaryButton';
import { success } from 'src/services/toastr';
import Api from 'src/api';
import { transformErrors } from 'src/helpers';
import SubmitTabButton from 'src/pages/Application/Update/Tabs/Components/SubmitTabButton';
import SaveButton from 'src/components/Form/SaveButton';
import { ApplicationTabProps } from 'src/pages/Application/Update/Tabs/ApplicationTabData';
import {
  ProspectApplicationContextState,
  withProspectApplication,
} from '../ProspectApplicationContext';
import {
  UpdateProspectApplicationSecurityDepositsRequestDto,
  UpdateProspectApplicationSingleSecurityDepositRequestDto,
} from 'src/types/api/prospectApplications';
import SingleSecurityDeposit from './Securities/SingleSecurityDeposit';

interface Props extends ApplicationTabProps, ProspectApplicationContextState {}

const initialDeposit: UpdateProspectApplicationSingleSecurityDepositRequestDto = {
  id: null,
  real_estate_type: '',
  real_estate_unique: '',
  real_estate_plot: '',
  real_estate_address: '',
  real_estate_value: null,
  real_estate_appraiser: '',
  real_estate_appraised_at: null,
};

const INITIAL_REQUEST: UpdateProspectApplicationSecurityDepositsRequestDto = {
  security_deposits: [initialDeposit],
};

export type DynamicObject<T> = { [key: string]: T };

const TabSecurities: React.FC<Props> = ({
  prospectApplication,
  setProspectApplication,
  showSubmit,
  isEditable,
}) => {
  const { t } = useTranslation();

  const [request, setRequest] =
    useState<UpdateProspectApplicationSecurityDepositsRequestDto>(INITIAL_REQUEST);

  useEffect(() => {
    if (!prospectApplication) return;

    setRequest({
      security_deposits: prospectApplication.security_deposits ?? [],
    });
  }, [prospectApplication]);

  const onSubmit = useCallback(
    async (
      request: any,
      helpers: FormikHelpers<UpdateProspectApplicationSecurityDepositsRequestDto>,
    ) => {
      if (!prospectApplication) return;

      try {
        const response = await Api.ProspectApplications.updateProspectApplicationSecurityDeposit(
          prospectApplication.id,
          request,
        );
        setProspectApplication(response);
        success(t('common.updated_success'));
      } catch (e: any) {
        helpers.setErrors(transformErrors(e.response?.errors));
      }

      return true;
    },
    [prospectApplication, setProspectApplication, t],
  );

  return (
    <React.Fragment>
      <div className={'d-flex justify-content-between'}>
        <div>
          <h3>{t('applications.securities')}</h3>
        </div>
        {showSubmit && (
          <div>
            <SubmitTabButton
              title={t('applications.tabs_submit.securities')}
              tabName={'securities'}
            />
          </div>
        )}
      </div>
      <hr />
      <Formik
        initialValues={request}
        enableReinitialize={true}
        validateOnChange={false}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, isSubmitting, values }) => (
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <FieldArray name="security_deposits">
                {(formikArrayHelpers) => (
                  <>
                    <table className={'table table-sm small'}>
                      <thead>
                        <tr>
                          <th className={'col-2'}>{t('label.real_estate_type')}</th>
                          <th className={'col-2'}>{t('label.pid')}</th>
                          <th>{t('label.real_estate_unique')}</th>
                          <th>{t('label.real_estate_plot')}</th>
                          <th>{t('label.real_estate_address')}</th>
                          <th>{t('label.real_estate_value')}</th>
                          <th>{t('label.real_estate_appraiser')}</th>
                          <th>{t('label.real_estate_appraised_at')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {values.security_deposits.map((deposit, index) => {
                          return (
                            <SingleSecurityDeposit
                              formikArrayHelpers={formikArrayHelpers}
                              index={index}
                              key={index}
                              deposit={deposit}
                              deposits={values.security_deposits}
                              isEditable={isEditable}
                            />
                          );
                        })}
                      </tbody>
                    </table>
                    <PrimaryButton
                      title={t('common.add')}
                      type={'button'}
                      className={'btn btn-secondary w-100'}
                      submitting={isSubmitting}
                      disabled={!isEditable}
                      onClick={() => formikArrayHelpers.push(initialDeposit)}
                    />
                  </>
                )}
              </FieldArray>
              <div className={'mt-4 mb-4'}>
                <SaveButton
                  title={t('common.save')}
                  submitting={isSubmitting}
                  disabled={!isEditable}
                />
              </div>
            </FormGroup>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default withProspectApplication(TabSecurities);
