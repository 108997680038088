import React, { useState } from 'react';
import { StateManagerProps } from 'react-select/dist/declarations/src/stateManager';
import { ReactSelectOption } from 'src/components/Form/Select/SelectInput';
import { SelectInput } from 'src/components/Form/Select';

interface Props extends StateManagerProps {
  name: string;
  placeholder: string;
}

const MortgageInput: React.FC<Props> = (props) => {
  const [options] = useState<ReactSelectOption[]>([
    { value: '', label: 'Neuzstatytas' },
    { value: '1', label: 'Uzstatytas' },
  ]);
  return <SelectInput {...props} options={options} />;
};
export default MortgageInput;
